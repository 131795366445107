/** This module contains utilities for editing AI nodes and validating AI nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef } from "../../types/GraphTypes";
import { NodeUtils } from "../../../../../utils/runbooks/NodeUtil";
import { getProperties } from "utils/runbooks/RunbookUtils";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";

/** an enum with all of the valid ai node properties. */
export enum AI_NODE_EDIT_PROPS {
    /** the enumberated value for the instructions location. */
    INSTRUCTIONS_LOC    = "instructionsLocation",
    /** the enumberated value for the instructions content. */
    INSTRUCTIONS        = "instructions",
    /** the enumberated value for the query location. */
    QUERY_LOC           = "queryLocation",
    /** the enumberated value for the query content. */
    QUERY               = "query",
}

/** Utility class for AI node,*/
export class AiNodeUtils extends NodeUtils {
    /** the error messages for the trigger node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.aiNode;

    /** Check if a input node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes. 
     *  @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables);
        this.validateNodeProperties(getProperties(curNode), errors);
    }

    /** Validates the property values for a given AI node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        currentProperties: Record<string, any>, errors: Array<string>
    ): Array<string> {
        if (!currentProperties.instructionsLocation) {
            errors.push(AiNodeUtils.errMsgs.noInstructionsLocation);
        }
        if (currentProperties.instructionsLocation === "enter" && !currentProperties.instructions) {
            errors.push(AiNodeUtils.errMsgs.noInstructions);
        }
        if (currentProperties.queryLocation === "enter" && !currentProperties.query) {
            errors.push(AiNodeUtils.errMsgs.noQuery);
        }
        return errors;
    }
}
