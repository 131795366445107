/** This module contains the component for creating the runbook input form.  This form allows
 *  the user to select the inputs necessary to run a runbook.
 *  @module
 */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { startCase, toLower } from 'lodash';
import { ItemListRenderer, ItemRenderer, Suggest } from "@blueprintjs/select";
import { HELP, STRINGS } from "app-strings";
import RunbookTimeControl from "pages/view-runbook/views/view-runbook/RunbookTimeControl";
import { 
    INVOCATION_TYPE, RunbookConfig, RunbookInputEntity, RunbookInputInputs, RunbookInputObject, 
    RunbookInputPrimaryIndicator, RunbookInputs, 
    RunbookMetadata, RunbookMetadataApplication, RunbookMetadataLocation 
} from "utils/services/RunbookApiService";
import { InputType, NamesByTriggerType, Variant } from "components/common/graph/types/GraphTypes";
import { FixedSizeList as List } from 'react-window';
import { getAttributesObject, getEntityAttribute } from "utils/runbooks/EntityUtils";
import { Checkbox, HTMLSelect, InputGroup, IRef, TextArea } from "@blueprintjs/core";
import { useStateSafePromise, useUserPreferences } from "utils/hooks";
import { 
    runbookService, getTriggerMetricIds, decisionNodes, tableNodes, getFirstParentOfType, triggerNodes, 
    dataOceanNodes, transformNodes, aggregatorNodes 
} from "utils/runbooks/RunbookUtils";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { getUuidV4, getUuidV5 } from "utils/unique-ids/UniqueIds";
import { AuthService } from "@tir-ui/azure-auth";
import { ENTITY_KIND } from "components/enums/EntityKinds";
import { AutocompleteControl, SearchItemDal } from "../../../../components/hyperion/controls/autocomplete/AutocompleteControl";
import { FIELDS, SearchItem, SuggestItem } from "utils/services/SearchApiService";
import { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { DECISION_NODE_EDIT_PROPS } from "components/common/graph/editors/decision/DecisionNodeUtil";
import { TriggerBaselineOperators } from "components/common/graph/editors/LogicOperators";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp";
import { Icon } from "@tir-ui/react-components";
import { IconNames } from "@blueprintjs/icons";
import { getArDataSources, getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { RunbookVariableMappingEditor } from "./RunbookVariableMappingEditor";
import { useQuery } from "utils/hooks";
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { DURATION, durationToRoundedTimeRange } from "utils/stores/GlobalTimeStore";
import { ProfileInterface, ThirdPartyIntegrationService } from "utils/services/ThirdPartyIntegrationApiService";
import { validIP } from "utils/validators/Validators";
import { OutputDataElement, OutputDataValueType } from "components/common/graph/editors/transform/OutputDataElement";
import { RunbookContextUtils } from "utils/runbooks/RunbookContextUtils.class";
import { generateRandomID } from 'components/common/condition-tree-builder/condition/ConditionUtils';

/** a constant that specifies the value to use for the no trigger metric case. */
const NO_TRIGGER_METRIC_VALUE: string = "no-trigger-metric";

/** a boolean value, if true show the webhook runbooks in the runbook list, this affects the on-demand runbooks page. */
const SHOW_WEBHOOKS_IN_RUNBOOK_LIST: boolean = false;

//copied from jalebi-service-metadata/common/data_unification_layer/uuid/generator.py
export const NAMESPACE_MAP = {
    [ENTITY_KIND.LOCATION]: "876857fa-4df6-5a89-ac06-6be6e165222f",
    [ENTITY_KIND.DEVICE]: "ebff34c2-19a2-5b55-a86e-6ce7e78f1c24",
    [ENTITY_KIND.INTERFACE]: "536ee3e3-c774-54e7-b053-815b465cd5f0",
    [ENTITY_KIND.APPLICATION]: "885fb25b-2585-5112-b462-5d602e255326",
    [ENTITY_KIND.APPLICATION_LOCATION]: "acb83a17-c716-53b7-892d-ad1593f575eb",
    [ENTITY_KIND.NETWORK_SERVER]: "bddb4a1d-1687-5c7e-86fd-f9044d633d87",
    [ENTITY_KIND.APPLICATION_SERVER]: "e89d6270-8617-5bed-9aae-e2e292d057b2",
}

/** this interface defines the object that contains the configuration of the trigger metric. */
export interface TriggerMetricConfig {
    /** a String with the metric id value. */
    metric: string;
    /** a String with the actual metric value. */
    actualValue: string; 
    /** a String with the low value. */
    lowValue: string;
    /** a String with the high value. */
    highValue: string;
}

/** this interface defines the object that contains the configuration of the vantage point. */
export interface VantagePointConfig {
    /** an object with the selected vantage point of undefined. */
    vantagePoint: {id: string, name: string} | undefined;
}

/** this interface defines the object that contains the configuration of the webhook inputs. */
export interface WebhookConfig {
    /** a String with the request body. */
    requestBody: string;
    /** a String with the request headers. */
    requestHeaders: string;
    /** a String with the request url. */
    requestUrl: string;
    /** a String with the request query parameters. */
    requestParams: string;
}

/** this interfaces defines the object that holds the input configuration for the runbook completed lifecycle trigger. */
export interface RunbookAnalysisConfig {
    /** a string with the old runbook completion status in the form of New, Investigating, Closed. */
    status: string;
    /** a number with the time in milliseconds. */
    time: number;
}

/** this interfaces defines the object that holds the input configuration for the incident status changed lifecycle trigger. */
export interface IncidentStatusConfig {
    /** a string with the old incident status in the form of New, Investigating, Closed. */
    oldStatus: string;
    /** a string with the current incident status in the form of New, Investigating, Closed. */
    newStatus: string;
    /** a number with the time in milliseconds. */
    time: number;
}

/** this interfaces defines the object that holds the input configuration for the note added/updated/deleted lifecycle trigger. */
export interface NoteConfig {
    /** a String with the note content. */
    content: string;
    /** a number with the time in milliseconds. */
    time: number;
}

/** this interfaces defines the object that holds the input configuration for the indicators changed lifecycle trigger. */
export interface IndicatorsConfig {
    /** a number with the count of indicators. */
    count: number;
}

/** this interfaces defines the object that holds the input configuration for the ongoing status changed lifecycle trigger. */
export interface OngoingStatusConfig {
    /** a number with the time in milliseconds. */
    time: number;
}
 
/** this interface defines the object that contains the input configuration. */
export interface InputConfig {
    /* the primary entity that the user selected, for example the interface, device, 
     *      or application the user wants to run the runbook on. */   
    primarySearchItem?: SuggestItem | SearchItem | SearchItemDal | string | undefined;
    /** secondarySearchItem the secondary entity that the user selected, for example applications can 
     *      also include a location so the location is the secondary entity. */
    secondarySearchItem?: SuggestItem | SearchItem | SearchItemDal |string | undefined;
    /** the end time in milliseconds. */
    endTime: number;
    /** the configuration for the trigger metric inputs. */
    triggerMetricConfig?: TriggerMetricConfig;
    /** this stores the configuration for the webhook inputs. */
    webhook?: WebhookConfig;
    /** a String with the configuration of the incident variable inputs. */
    incidentVariables?: string;
    /** a boolean value, true if the request should mimic the multi-device or multi-application use case. */
    mimicMultipleDeviceOrApplication: boolean;
    /** a boolean value, true if the request should mimic the multi-location use case. */
    mimicMultipleLocation: boolean;
    /** these are the input configuration values for the runbook analysis options. */
    runbookAnalysisConfig: RunbookAnalysisConfig;
    /** these are the input configuration values for the incident status options. */
    incidentStatusConfig: IncidentStatusConfig;
    /** these are the input configuration values for the notes options. */
    noteConfig: NoteConfig;
    /** these are the input configuration values for the indicator options. */
    indicatorsConfig: IndicatorsConfig;
    /** these are the input configuration values for the ongoing status options. */
    ongoingStatusConfig: OngoingStatusConfig;
    /** these are the input configuration values for the vantage point options. */
    vantagePointConfig: VantagePointConfig;
    /** the timeout in minutes. */
    timeoutInMinutes?: number;
    /** a JSON object with the variable overrides. */
    variableOverrides?: {[x: string]: any};
    /** a JSON object with the variable overrides types. */
    variableTypes?: {[x: string]: any};
    /** An object containing the edges and auth profiles, which is used to extract the friendly edge or auth profile name and display it in the scheduled runbook wizard review step */
    edgesAndAuthProfilesForScheduled?: { edges: Array<any> | undefined, authProfiles: Array<any> | undefined}
}

/** this interface defines the object that contains the input configuration. */
export interface InitialValues {
    /** a String with the initial value for the request body. */
    requestBody?: string;
    /** a String with the initial values for the request headers. */
    requestHeaders?: string;
    /** a String with the initial value for the request URL. */
    requestUrl?: string;
    /** a String with the initial values for the request parameters. */
    requestParams?: string;
    /** a number with the initial value for the timeout in minutes. */
    timeout?: number;
}

/** This interface defines the properties passed into the runbook inputs form React component.*/
interface RunbookInputsFormProps {
    /** the runbook to display initially. */
    runbook?: RunbookConfig;
    /** a string with the id ot the runbook to run or undefined if none. */
    selectedRunbook?: string;
    /**  a boolean value which is true if the runbook is run on demand or false otherwise. */
    runOnDemandRunbook?: boolean;
    /**  a boolean value which is true if the runbook is a scheduled runbook or false otherwise. */
    isScheduledRunbook?: boolean;
    /** a boolean value, if true show the runbook selection, if false, do not. */
    allowRunbookSelection?: boolean;
    /** a boolean value, if try show the vantage point selection, if false, do not. */
    allowVantagePointSelection?: boolean;
    /** a boolean value, true if the timeout should be shown, false otherwise. */
    showTimeout?: boolean;
    /** the timeout in minutes. */
    timeoutInMinutes?: number;
    /** the type of invocation: correlation engine, test, or on demand. */
    invocationType: INVOCATION_TYPE;
    /** a boolean value, true if the trigger metric should be displayed. */
    showTriggerMetric?: boolean;
    /** if true show the incident variables input. */
    showIncidentVariables?: boolean;
    /** a boolean value, if true show the rollup options, if false or not provided then do not show the option. */
    showRollupOptions?: boolean;
    /** the handler for runbook input changes. */
    onRunbookInputChange?: (input: RunbookInputs | undefined, inputConfig: InputConfig) => void;
    /** the handler for timeout changes. */
    onTimeoutChanged?: (timeout: number) => void;
    /** the initial values passed in for webhook runbook tests. */
    initialValues?: InitialValues;
    /** An object which remembers the current values, between scheduled runbook wizard steps, for the additional parameters inputs. */
    currentInputs?: any;
    /** a string value with the name of the schedule used in the Schedule step of the Schedule Runbook Wizard */
    scheduleName?: string;
    /** an object containing the saved information belonging to a schedule runbook job */
    editInfos?: any;
}

/** Renders the runbook inputs form.
 *  @param props the properties passed into the component.
 *  @returns JSX with the runbook inputs form component.*/
export function RunbookInputsForm (props: RunbookInputsFormProps): JSX.Element {
    const userPreferences = useUserPreferences({listenOnlyTo: {runbookInputs: {types: {}, runbooks: {}}}});

    const [executeSafely] = useStateSafePromise();
    const [runbookConfig, setRunbookConfig] = useState<RunbookConfig | undefined>(props.runbook);
    const [runbookConfigs, setRunbookConfigs] = useState<Array<RunbookConfig>>([]);
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false);
    const [validIpAddress, setValidIpAddress] = useState<boolean>(true);
    const [runRunbookEntityType, setRunRunbookEntityType] = useState<string>("");
    const [isLoadingRunbooks, setIsLoadingRunbooks] = useState<boolean>(true);
    const [noRunbooksFound, setNoRunbooksFound] = useState<boolean>(false);

    const primaryMimicCheckbox = useRef<IRef<HTMLInputElement>>();
    const secondaryMimicCheckbox = useRef<IRef<HTMLInputElement>>();

    const noRunbooksFoundForEntityName = useRef<string>("");

    // A list of references to the virtual dom spans with the metric unit labels
    const metricUnitLabels = useRef<Array<HTMLSpanElement>>([]);

    const [authProfiles, setAuthProfiles] = useState<ProfileInterface[]>();
    const fetchProfiles = useCallback(() => {
        return executeSafely(ThirdPartyIntegrationService.getRunbookAndIntegrationAuthProfiles()).then(
            (response: ProfileInterface[]) => {
                const authProfiles = response.filter(profile => profile.isEnabled).sort((a, b) => a.name.localeCompare(b.name));
                setAuthProfiles(authProfiles);
            },
            _ => {
                setAuthProfiles([]);
            }
        );
    }, [executeSafely]);

    useEffect(() => {
        if (props.runOnDemandRunbook) {
            // Fetch profiles on load.
            fetchProfiles();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const edgeConfig = useQuery({
        name: 'EdgeConfig',
        query: new Query(loader('../../../../components/common/graph/editors/subflow/edge-config-list.graphql')),
        queryVariables: {
            ...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
        },
    });

    useEffect(
        () => {
            async function fetchMyAPI() {
                try {
                    let runbooks: Array<RunbookConfig> = [];
                    if (props.runOnDemandRunbook && props.selectedRunbook) {
                        // running a specific runbook on demand
                        const runbook = await runbookService.getRunbook(props.selectedRunbook);
                        runbooks.push(runbook);
                    } else if (props.runOnDemandRunbook && !props.selectedRunbook) { 
                        // running a runbook from the runbook analysis page
                        const runbooksResponse = await runbookService.getRunbooks(Variant.ON_DEMAND);
                        if (runbooksResponse?.length && runRunbookEntityType) {
                            for (const runbook of runbooksResponse) {
                                const onDemandRunbookInputNode = runbook.nodes?.find(node => node.type === InputType.ON_DEMAND);
                                if (onDemandRunbookInputNode) {
                                    const runbookEntityType = onDemandRunbookInputNode.properties?.synthKeys?.[0]?.dataOceanId;
                                    if (runbookEntityType === runRunbookEntityType) {
                                        runbooks.push(runbook);
                                    } else if (runRunbookEntityType === "none" && !runbookEntityType) {
                                        runbooks.push(runbook);
                                    } else if (runRunbookEntityType === "any") {
                                        runbooks.push(runbook);
                                    }
                                }
                            }
                            if (!runbooks.length) {
                                noRunbooksFoundForEntityName.current = runRunbookEntityType;
                                setRunRunbookEntityType("any");
                                setNoRunbooksFound(true);
                            }
                        } else {
                            runbooks = runbooksResponse;
                        }
                    } else {
                        runbooks = await runbookService.getRunbooks(Variant.INCIDENT);
                    }
                    if (runbooks && runbooks.length > 0) {
                        runbooks.sort((a, b) => {
                            if (a?.name && b?.name) {
                                return a.name.localeCompare(b.name);
                            } else if (a?.name) {
                                return -1;
                            } else if (b?.name) {
                                return +1;
                            }
                            return 0;
                        });
                        runbooks = runbooks.filter(runbook => {
                            return props.invocationType !== INVOCATION_TYPE.onDemand || runbook.triggerType === InputType.ON_DEMAND ||
                                (
                                    !hasTriggerMetricCondition(runbook) && runbook.isValidated && 
                                    (SHOW_WEBHOOKS_IN_RUNBOOK_LIST || runbook.triggerType !== InputType.WEBHOOK)
                                );
                        });
                        setRunbookConfigs(runbooks);
                        if (!runbookConfig) {
                            if (props?.selectedRunbook) {
                                for (const runbook of runbooks) {
                                    if (runbook.id === props.selectedRunbook) {
                                        setRunbookConfig(runbook);
                                        break;
                                    }
                                }
                            } else {
                                setRunbookConfig(runbooks[0]);
                            }
                        }
                    }
                    setIsLoadingRunbooks(false);
                } catch (error) {
                    console.log(error);
                }
            }
            if (props.allowRunbookSelection) {
                fetchMyAPI();
            } else {
                setIsLoadingRunbooks(false);
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [runRunbookEntityType]
    );

    const fetchDoMetaData = useCallback(() => {
        return executeSafely(DataOceanUtils.init()).then(
            (response: any) => {
                setObjMetricMetaData(response);
            },
            (error) => {
                console.error(error);
            }
        );
    }, [executeSafely]);

    useEffect(() => {
        // Fetch Meta data on load.
        fetchDoMetaData();
    }, [fetchDoMetaData]);
    let initialInputConfig: InputConfig = {
        primarySearchItem: undefined,
        secondarySearchItem: undefined,
        endTime: 0,
        triggerMetricConfig: undefined,
        webhook: {
            requestBody: props?.initialValues?.requestBody ? JSON.stringify(props.initialValues.requestBody) : "", 
            requestHeaders: props?.initialValues?.requestHeaders ? JSON.stringify(props.initialValues.requestHeaders) : "", 
            requestUrl: props?.initialValues?.requestUrl ? JSON.stringify(props.initialValues.requestUrl) : "", 
            requestParams: props?.initialValues?.requestParams ? JSON.stringify(props.initialValues.requestParams) : ""
        }, 
        incidentVariables: "",
        mimicMultipleDeviceOrApplication: false,
        mimicMultipleLocation: false,
        runbookAnalysisConfig: {status: "Succeeded", time: 0},
        incidentStatusConfig: {oldStatus: "New", newStatus: "New", time: 0},
        noteConfig: {content: "", time: 0},
        indicatorsConfig: {count: 0},
        ongoingStatusConfig: {time: 0},
        vantagePointConfig: {vantagePoint: undefined},
        timeoutInMinutes: props.timeoutInMinutes || 5
    };
    if (userPreferences?.runbookInputs) {
        let foundMatch = false;
        for (const id in userPreferences.runbookInputs?.runbooks || {}) {
            if (id === props.runbook?.id) {
                initialInputConfig = JSON.parse(JSON.stringify(userPreferences.runbookInputs.runbooks[id]));
                foundMatch = true;
                break;
            }
        }
        if (!foundMatch && userPreferences.runbookInputs?.types?.[props.runbook?.triggerType || ""]) {
            initialInputConfig = JSON.parse(JSON.stringify(userPreferences.runbookInputs.types[props.runbook?.triggerType || ""]));
        }
    }
    const inputConfig = useRef<InputConfig>(initialInputConfig);

    const [triggerMetricDefs, setTriggerMetricDefs] = useState<Array<{value: string, label: string}>>([]);

    useEffect(
        () => {
            if (objMetricMetaData) {
                let triggerMetricIds: Array<string> = runbookConfig ? getTriggerMetricIds(
                    runbookConfig.triggerType as InputType, [], objMetricMetaData, getTypes()) : [];
                const newTriggerMetricDefs: Array<{value: string, label: string}> = objMetricMetaData ? triggerMetricIds.map(metricId => {
                    const metricDef = objMetricMetaData.metrics[metricId];
                    return {value: metricDef?.id, label: metricDef?.label};
                }) : [];
                newTriggerMetricDefs.push({value: NO_TRIGGER_METRIC_VALUE, label: "Do Not Pass Trigger Metric"});

                // The problem I discussed below should be fixed now that this is in a useEffect hook.
                // This is not good, this is going to reset the value on every re-render, this is okay for now because the test
                // dialog does not re-render while the auto-update on the on-demand page does cause a re-render.  Since triggers
                // are not supported on on-demand runbooks and only for tests, this will only get called once where it is used.
                // If we add trigger metrics to on-demand runbooks then we need to fix this.
                inputConfig.current.triggerMetricConfig = props.showTriggerMetric ? {
                    metric: newTriggerMetricDefs?.length ? newTriggerMetricDefs[0].value : "", actualValue: "0", 
                    lowValue: "0", highValue: "0"
                } : undefined; 
                setTriggerMetricDefs(newTriggerMetricDefs);                                       
            }        

            // Retrieve the primary and secondary entities from user preferences for an on demand runbook or a scheduled on demand runbook
            if (runbookConfig && props.runOnDemandRunbook) {
                const storedOnDemandRunbookInputs = props.invocationType === "test" ? userPreferences.runbookInputs : userPreferences.onDemandRunbookInputs;
                let storedPrimarySearchItem = (storedOnDemandRunbookInputs?.runbooks && runbookConfig?.id) ? storedOnDemandRunbookInputs.runbooks[runbookConfig?.id]?.primarySearchItem : null;
                let storedSecondarySearchItem = (storedOnDemandRunbookInputs?.runbooks && runbookConfig?.id) ? storedOnDemandRunbookInputs.runbooks[runbookConfig?.id]?.secondarySearchItem : null;

                if (props.isScheduledRunbook && props.editInfos) {

                    storedPrimarySearchItem = {};
                    const attributes = props.editInfos?.details?.runbook?.detection?.entity?.attributes;
                    const kind = props.editInfos?.details?.runbook?.detection?.entity?.kind;
                    const metadata = attributes ? attributes.find(item => item.field === "metadata")?.value : null;
                    let parsedAttributes = metadata ? JSON.parse(metadata) : null;

                    if (kind && parsedAttributes) {
                        const convertKeysToLowercase = (obj) => {
                            if (typeof obj !== 'object' || obj === null) {
                            return obj;
                            }
                        
                            if (Array.isArray(obj)) {
                            return obj.map(convertKeysToLowercase);
                            }
                        
                            return Object.keys(obj).reduce((acc, key) => {
                            const lowerCaseKey = key.toLowerCase();
                            acc[lowerCaseKey] = convertKeysToLowercase(obj[key]);
                            return acc;
                            }, {});
                        }

                        parsedAttributes = convertKeysToLowercase(parsedAttributes);

                        storedPrimarySearchItem[props.editInfos.details.runbook.detection.entity.kind] = parsedAttributes;

                        if (!parsedAttributes.name) {
                            storedPrimarySearchItem = { [kind.split("_")[0]]: parsedAttributes[kind.split("_")[0]]};
                            storedSecondarySearchItem = { [kind.split("_")[1]]: parsedAttributes[kind.split("_")[1]] }
                        }

                        if (["network_client", "network_server", "network_host"].includes(kind)) {
                            storedPrimarySearchItem = parsedAttributes.ipaddr;
                        }

                    }
                }

                const onDemandRunbookInputNode = runbookConfig.nodes?.find(node => node.type === InputType.ON_DEMAND);
                let triggerType = onDemandRunbookInputNode ? onDemandRunbookInputNode.properties?.synthKeys?.[0]?.dataOceanId : null;

                let clientOrServerLocationTriggerType: string = "";

                if (["client_location", "server_location"].includes(triggerType)) {
                    clientOrServerLocationTriggerType = triggerType;
                    triggerType = "location";
                }

                const secondaryTriggerType = (triggerType === "application") ? "location" : null;
                const hasAutocompletePrimaryEntity = ["network_interface", "network_device", "application", "location", "client_location", "server_location"].includes(triggerType);

                if (storedPrimarySearchItem) {
                    inputConfig.current.primarySearchItem = hasAutocompletePrimaryEntity ? 
                        { [triggerType]: storedPrimarySearchItem[triggerType] || storedPrimarySearchItem[clientOrServerLocationTriggerType]} : 
                        typeof storedPrimarySearchItem === "object" ? "" : 
                        storedPrimarySearchItem;
                }

                if (storedSecondarySearchItem && secondaryTriggerType) {
                    inputConfig.current.secondarySearchItem = hasAutocompletePrimaryEntity ? 
                        { [secondaryTriggerType]: storedSecondarySearchItem[secondaryTriggerType] } : 
                        typeof storedSecondarySearchItem === "object" ? "" : 
                        storedSecondarySearchItem ;
                }

                if (props.currentInputs?.primarySearchItem) {
                    inputConfig.current.primarySearchItem = props.currentInputs?.primarySearchItem;
                }

                if (props.currentInputs?.secondarySearchItem) {
                    inputConfig.current.secondarySearchItem = props.currentInputs?.secondarySearchItem;
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [runbookConfig, objMetricMetaData, props.showTriggerMetric]
    );

    let triggerMetricUnit: string = objMetricMetaData && inputConfig.current.triggerMetricConfig?.metric && objMetricMetaData.metrics[inputConfig.current.triggerMetricConfig.metric]?.unit &&
        Unit.parseUnit(objMetricMetaData.metrics[inputConfig.current.triggerMetricConfig.metric].unit || "").getDisplayName() ?
        "(" + Unit.parseUnit(objMetricMetaData.metrics[inputConfig.current.triggerMetricConfig.metric].unit || "").getDisplayName() + ")" : "";
    
    let inputsForm: Array<JSX.Element> = [];

    if (runbookConfig && objMetricMetaData) {
        const ItemSuggest = Suggest.ofType<{label: string, value: any, type: string}>();

        const renderItemVirtualized: ItemRenderer<{label: string, value: any, type: string}> = (item, { handleClick, modifiers }) => {
            if (!modifiers.matchesPredicate) {
                return null;
            }
            return (
                <div key={item.label} onClick={handleClick} className={"pt-2 pb-2 d-flex justify-content-between align-items-center ac-suggest-row" + (modifiers.active ? " selected": "")}>
                    <span className="ml-2">{item.label}</span>
                    <span className="mr-2">{item.type}</span>
                </div>
            );
        };
    
        const renderMenuVirtualized: ItemListRenderer<{label: string, value: any, type: string}> = ({ items, itemsParentRef, query, renderItem, renderCreateItem }) => {
            const renderedItems = items.map(renderItem).filter(item => item != null);
            if (query) {
                const createItem = renderCreateItem();
                if (createItem) {
                    renderedItems.push(createItem);
                }
            }
            const Row = ({ index, style }) => (
                <div style={style}>{renderedItems[index]}</div>
            );
            return (
                <List height={300} itemCount={renderedItems.length} itemSize={30} width={500} className="ac-suggest-list" >
                    {Row}
                </List>
            );
        };
    
        let menuItems: Array<{label: string, value: Record<string, any>, type: string}> = [];

        if (props.allowRunbookSelection) {
            // Add the runbook list to the form
            let runbookSelectedItem: any = undefined;
            //const runbookOptions: Array<{ label: string, value: string }> = [];
            for (let runbookIndex = 0; runbookIndex < runbookConfigs.length; runbookIndex++) {
                const runbook = runbookConfigs[runbookIndex];
                const menuItem = {label: runbook.name || "", value: runbook, type: runbook.triggerType ? NamesByTriggerType[runbook.triggerType] : "Unknown"};
                menuItems.push(menuItem);
                //runbookOptions.push({ label: runbook.name || "", value: runbook.id || "" });
                if (!runbookConfig) {
                    if (runbookIndex === 0) {
                        runbookSelectedItem = menuItem;
                        setRunbookConfig(runbook);
                    }
                } else if (runbookConfig.id === runbook.id) {
                    runbookSelectedItem = menuItem;
                }
            }
            if (props?.runOnDemandRunbook && !props?.selectedRunbook) {
                // creating the entity type input

                const item = {id: generateRandomID(), label: "", type: OutputDataValueType[0], unit: "", dataOceanId: ""};
                
                const outputDataList = [item];
                const onDemandRunbooksSupportedEntities = ["network_interface", "network_device", "application", "location", "network_host", "network_client", "network_server", "client_location", "server_location"];
                const suggestionsList = RunbookContextUtils.getAvailableKeysForKeyMap(objMetricMetaData.keys, [], getTypes());
                for (const [key] of Object.entries(suggestionsList) as any) {
                    if (!onDemandRunbooksSupportedEntities.includes(key)) {
                        delete suggestionsList[key];
                    }
                }
                const newSuggestionsList = suggestionsList;
                if (runRunbookEntityType) {
                    item.dataOceanId = runRunbookEntityType;
                    if (runRunbookEntityType !== "none" && runRunbookEntityType !== "any") {
                        item.label = suggestionsList[runRunbookEntityType].label;
                    } else {
                        item.label = runRunbookEntityType.charAt(0).toUpperCase() + runRunbookEntityType.substring(1);
                    }
                } else {
                    item.dataOceanId = "";
                    item.label = "";
                }
                if (noRunbooksFound) {
                    inputsForm.push(
                        <tr>
                            <td className="p-1 pb-4 align-top" colSpan={2}>
                                <div className="text-danger">
                                    {`No runbooks found for entity type ${noRunbooksFoundForEntityName.current}.`}
                                </div>
                            </td>
                        </tr>
                    );
                }
                inputsForm.push(<tr key="on-demand-runbooks-primary-entity">
                    <td className="p-1 align-top">
                        <label className="mt-0 mb-0">
                            {STRINGS.runbookInvocations.entityTypeLabel}
                        </label>
                    </td>
                    <td className="p-1 pb-4 on-demand-runbooks-entities">
                        <OutputDataElement
                            onChange={(event) => {
                                setRunRunbookEntityType(event.id ? event.id : "none");
                                inputConfig.current.primarySearchItem = undefined;
                                setIsLoadingRunbooks(true);
                                setRunbookConfig(undefined);
                                setNoRunbooksFound(false);
                            }} 
                            loadFromVar={false}
                            sectionName={""}
                            outputDataElementDefinition={item}
                            suggestionsList={newSuggestionsList}
                            isFirstItem={outputDataList.indexOf(item) === 0}
                            isSubflow={true}
                            isOnDemandNodeEditor={true}
                            isOnDemandRunDialog={true}
                        />
                    </td>
                </tr>);
            }
            if (!props?.runOnDemandRunbook || (props?.runOnDemandRunbook && !props?.selectedRunbook)) {
                inputsForm.push(<tr key={"runbook-list"}>
                    <td className={props.runOnDemandRunbook ? "p-1 align-top" : ""}>
                        <label className={props?.runOnDemandRunbook ? "mt-0 mb-0" : ""}>
                            {STRINGS.viewRunbooks.inputRunbookLabel}
                        </label>
                    </td> 
                    <td className={props?.runOnDemandRunbook ? "p-1 pb-4" : ""}>
                        <ItemSuggest 
                            items={menuItems} 
                            defaultSelectedItem={props?.runOnDemandRunbook ? menuItems[0] : runbookSelectedItem} 
                            inputProps={{placeholder: "Select a runbook"}}
                            itemRenderer={renderItemVirtualized}
                            itemListRenderer={renderMenuVirtualized}
                            onItemSelect={(item) => {
                                const runbook = item.value;
                                setRunbookConfig(runbook);
                                inputConfig.current.primarySearchItem = undefined;
                            }}
                            inputValueRenderer={(item) => {
                                return item.label || "";
                            }}
                            itemsEqual="value"
                            itemPredicate={(query, item, index, exactMatch) => {
                                let label: string = item?.label || "";
                                let type: string = item?.type || "";
                                let queryText: string = query || "";
                                return label.toLowerCase().includes(queryText.toLowerCase()) || 
                                    type.toLowerCase().includes(queryText.toLowerCase()) || false;
                            }}
                            popoverProps={{minimal: true, boundary: "viewport"}}
                            className="input-width"
                        />
                        {/*<HTMLSelect options={runbookOptions} defaultValue={runbookConfig ? runbookConfig.id : undefined} style={{width: "360px"}} onChange={(event) => {
                            const runbookId = event.target.value;
                            for (const runbook of runbookConfigs) {
                                if (runbook.id === runbookId) {
                                    if (runbookConfig && runbookConfig.triggerType !== runbook.triggerType) {
                                        setPrimaryEntities(undefined);
                                        setSecondaryEntities(undefined);
                                    }
                                    setRunbookConfig(runbook);
                                    break;
                                }
                            }
                        }}/>*/}
                    </td>
                </tr>);
            }
        }


        let primaryField: FIELDS | undefined = undefined;
        let primaryReportByField: FIELDS | undefined = undefined;
        let secondaryField: FIELDS | undefined = undefined;
        let secondaryReportByField: FIELDS | undefined = undefined;
        let primaryInputLabel = "", secondaryInputLabel = "";
        let primaryInputPlaceholder = "", secondaryInputPlaceholder = "";
        let triggerType = runbookConfig.triggerType;
        const onDemandRunbookInputNode = runbookConfig.nodes?.find(node => node.type === InputType.ON_DEMAND);
        if (props.runOnDemandRunbook) {
            triggerType = onDemandRunbookInputNode ? onDemandRunbookInputNode.properties?.synthKeys?.[0]?.dataOceanId : triggerType;
        }
        switch (triggerType) {
            case InputType.INTERFACE:
                primaryField = FIELDS.network_interface;
                primaryReportByField = FIELDS.ifcReportedBy;
                primaryInputLabel = STRINGS.viewRunbooks.inputInterfaceLabel;
                primaryInputPlaceholder = STRINGS.viewRunbooks.inputInterfacePlaceholder;
                break;
            case InputType.DEVICE:
                primaryField = FIELDS.network_device;
                primaryReportByField = FIELDS.devReportedBy;
                primaryInputLabel = STRINGS.viewRunbooks.inputDeviceLabel;
                primaryInputPlaceholder = STRINGS.viewRunbooks.inputDevicePlaceholder;
                break;
            case InputType.APPLICATION_LOCATION:
                primaryField = FIELDS.application;
                primaryReportByField = FIELDS.appReportedBy;
                secondaryField = FIELDS.location;
                secondaryReportByField = FIELDS.locReportedBy;
                primaryInputLabel = STRINGS.viewRunbooks.inputApplicationLabel;
                secondaryInputLabel = STRINGS.viewRunbooks.inputLocationLabel;
                primaryInputPlaceholder = STRINGS.viewRunbooks.inputApplicationPlaceholder;
                secondaryInputPlaceholder = STRINGS.viewRunbooks.inputLocationPlaceholder;
                break;
            case InputType.LOCATION:
                primaryField = FIELDS.location;
                primaryReportByField = FIELDS.locReportedBy;
                primaryInputLabel = STRINGS.viewRunbooks.inputLocationLabel;
                primaryInputPlaceholder = STRINGS.viewRunbooks.inputLocationPlaceholder;
                break;
            case InputType.APPLICATION:
                primaryField = FIELDS.application;
                primaryReportByField = FIELDS.appReportedBy;
                primaryInputLabel = STRINGS.viewRunbooks.inputApplicationLabel;
                primaryInputPlaceholder = STRINGS.viewRunbooks.inputApplicationPlaceholder;
                break;
        }

        if (triggerType as string === "client_location") {
            primaryField = FIELDS.location;
            primaryReportByField = FIELDS.locReportedBy;
            primaryInputLabel = STRINGS.viewRunbooks.inputLocationLabel;
            primaryInputPlaceholder = STRINGS.viewRunbooks.inputLocationPlaceholder;
        } else if (triggerType as string === "server_location") {
            primaryField = FIELDS.location;
            primaryReportByField = FIELDS.locReportedBy;
            primaryInputLabel = STRINGS.viewRunbooks.inputServerLocationLabel;
            primaryInputPlaceholder = STRINGS.viewRunbooks.inputServerLocationPlaceholder;
        }
    
        menuItems = [];

        menuItems.sort((optA, optB) => {
            const labelA = optA?.label || "";
            const labelB = optB?.label || "";
            return labelA.localeCompare(labelB);
        });
    
        if (runbookConfig.triggerType === InputType.IMPACT_ANALYSIS_READY) {
            inputsForm.push(<tr key="impact-analysis-created-at">
                <td className="p-1"><label>{STRINGS.viewRunbooks.runbookAnalysisAddedLabel}</label></td>
                <td className="p-1"><RunbookTimeControl defaultTime={inputConfig.current.runbookAnalysisConfig.time} onTimeChange={(time: number | undefined) => {
                    if (props.onRunbookInputChange) {
                        inputConfig.current.runbookAnalysisConfig!.time = time ? time : 0;
                        props.onRunbookInputChange(
                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                            inputConfig.current
                        );
                    }
                }}/></td>
            </tr>);

            inputsForm.push(<tr key="impact-analysis-status">
                <td className="p-1"><label>{STRINGS.viewRunbooks.statusLabel}</label></td>
                <td className="p-1"><HTMLSelect
                    onChange={(event) => {
                        if (props.onRunbookInputChange) {
                            inputConfig.current.runbookAnalysisConfig!.status = event.currentTarget.value;
                            props.onRunbookInputChange(
                                generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                inputConfig.current
                            );
                        }    
                    }}
                    defaultValue={inputConfig.current.runbookAnalysisConfig.status}
                    options={[
                        {label: "Succeeded", value: "Succeeded"}, {label: "Succeeded With Errors", value: "SucceededWithErrors"}, {label: "Failed", value: "Failed"}
                    ]}
                /></td>
                </tr>);
        }

        if (runbookConfig.triggerType === InputType.INCIDENT_STATUS_CHANGED) {
            inputsForm.push(<tr key="incident-old-status">
                <td className="p-1"><label>{STRINGS.viewRunbooks.oldStatusLabel}</label></td>
                <td className="p-1"><HTMLSelect
                    onChange={(event) => {
                        if (props.onRunbookInputChange) {
                            inputConfig.current.incidentStatusConfig!.oldStatus = event.currentTarget.value;
                            props.onRunbookInputChange(
                                generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                inputConfig.current
                            );
                        }    
                    }}
                    defaultValue={inputConfig.current.incidentStatusConfig.oldStatus}
                    options={[
                        {label: "New", value: "New"}, {label: "Investigating", value: "Investigating"}, {label: "Closed", value: "Closed"}
                    ]}
                /></td>
                </tr>);

            inputsForm.push(<tr key="incident-new-status">
                <td className="p-1"><label>{STRINGS.viewRunbooks.statusLabel}</label></td>
                <td className="p-1"><HTMLSelect
                    onChange={(event) => {
                        if (props.onRunbookInputChange) {
                            inputConfig.current.incidentStatusConfig!.newStatus = event.currentTarget.value;
                            props.onRunbookInputChange(
                                generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                inputConfig.current
                            );
                        }    
                    }}
                    defaultValue={inputConfig.current.incidentStatusConfig.newStatus}
                    options={[
                        {label: "New", value: "New"}, {label: "Investigating", value: "Investigating"}, {label: "Closed", value: "Closed"}
                    ]}
                /></td>
                </tr>);

            inputsForm.push(<tr key="incident-created-at">
                <td className="p-1"><label>{STRINGS.viewRunbooks.incidentStatusChangedAtLabel}</label></td>
                <td className="p-1"><RunbookTimeControl defaultTime={inputConfig.current.incidentStatusConfig.time} onTimeChange={(time: number | undefined) => {
                    if (props.onRunbookInputChange) {
                        inputConfig.current.incidentStatusConfig!.time = time ? time : 0;
                        props.onRunbookInputChange(
                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                            inputConfig.current
                        );
                    }
                }}/></td>
            </tr>);
        }

        if (runbookConfig.triggerType === InputType.INCIDENT_NOTE_ADDED) {
            inputsForm.push(<tr key="incident-note-content">
                <td className="p-1"><label>{STRINGS.viewRunbooks.noteContentLabel}</label></td>
                <td className="p-1"><div className="d-flex">
                    <TextArea id="incident-note-content"
                        defaultValue={inputConfig.current.noteConfig.content} style={{resize: "both", width: "360px", height: "120px"}}
                        onChange={(event) => {
                            inputConfig.current.noteConfig!.content = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                </div></td>
            </tr>);

            inputsForm.push(<tr key="incident-note-created-at">
                <td className="p-1"><label>{STRINGS.viewRunbooks.noteAddedAtLabel}</label></td>
                <td className="p-1"><RunbookTimeControl defaultTime={inputConfig.current.noteConfig.time} onTimeChange={(time: number | undefined) => {
                    if (props.onRunbookInputChange) {
                        inputConfig.current.noteConfig!.time = time ? time : 0;
                        props.onRunbookInputChange(
                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                            inputConfig.current
                        );
                    }
                }}/></td>
            </tr>);
        }

        if (runbookConfig.triggerType === InputType.INCIDENT_NOTE_UPDATED) {
            inputsForm.push(<tr key="incident-note-content">
                <td className="p-1"><label>{STRINGS.viewRunbooks.noteContentLabel}</label></td>
                <td className="p-1"><div className="d-flex">
                    <TextArea id="incident-note-content"
                        defaultValue={inputConfig.current.noteConfig.content} style={{resize: "both", width: "360px", height: "120px"}}
                        onChange={(event) => {
                            inputConfig.current.noteConfig!.content = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                </div></td>
            </tr>);

            inputsForm.push(<tr key="incident-note-updated-at">
                <td className="p-1"><label>{STRINGS.viewRunbooks.noteUpdatedAtLabel}</label></td>
                <td className="p-1"><RunbookTimeControl defaultTime={inputConfig.current.noteConfig.time} onTimeChange={(time: number | undefined) => {
                    if (props.onRunbookInputChange) {
                        inputConfig.current.noteConfig!.time = time ? time : 0;
                        props.onRunbookInputChange(
                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                            inputConfig.current
                        );
                    }
                }}/></td>
            </tr>);
        }


        if (runbookConfig.triggerType === InputType.INCIDENT_INDICATORS_UPDATED) {
            inputsForm.push(<tr key="incident-indicators">
                <td className="p-1">
                        <label>{STRINGS.viewRunbooks.indicatorsCountLabel}</label>
                </td>
                <td className="p-1"><div className="d-flex">
                    <InputGroup id="incident-indicators"
                        defaultValue={inputConfig.current.indicatorsConfig.count + ""} 
                        style={{width: "360px"}}
                        onChange={(event) => {
                            if (props.onRunbookInputChange) {
                                const value = parseInt(event.currentTarget.value);
                                inputConfig.current.indicatorsConfig!.count = Number.isNaN(value) ? 0 : value;
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }    
                        }}
                    />
                </div></td>
            </tr>);
        }

        if (runbookConfig.triggerType === InputType.INCIDENT_ONGOING_CHANGED) {
            inputsForm.push(<tr key="incident-ongoing-changed">
                <td className="p-1">
                        <label>{STRINGS.viewRunbooks.incidentEndTimeLabel}</label>
                </td>
                <td className="p-1"><RunbookTimeControl defaultTime={inputConfig.current.ongoingStatusConfig.time} onTimeChange={(time: number | undefined) => {
                    if (props.onRunbookInputChange) {
                        inputConfig.current.ongoingStatusConfig!.time = time ? time : 0;
                        props.onRunbookInputChange(
                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                            inputConfig.current
                        );
                    }
                }}/></td>
            </tr>);
        }

        if (props.runOnDemandRunbook) {
            inputsForm.push(<tr key="primary-autocomplete-label">
                <td className="p-1 pb-4" colSpan={2}><div className="font-size-md-large">{STRINGS.runbookInvocations.primaryEntityLabel}</div></td>
            </tr>);

            if (triggerType && !triggerType?.includes("device") && !triggerType?.includes("interface") && !triggerType?.includes("location") && !triggerType?.includes("app")) {
                primaryInputLabel = startCase(toLower(triggerType.split("_").join(" ")))+":";
                primaryInputLabel = primaryInputLabel.replace("Ip","IP");
                primaryInputLabel = primaryInputLabel.replace("Id","ID");
                primaryInputLabel = primaryInputLabel.replace("Dscp","DSCP");
                let typingTimer;
                inputsForm.push(<tr key="primary-text-field">
                    <td className="p-1">
                        <label>{primaryInputLabel}</label>
                    </td> 
                    <td className="p-1">
                        <InputGroup
                            className={!validIpAddress ? "is-invalid": ""}
                            defaultValue={inputConfig.current.primarySearchItem as string || ""}
                            onChange={(event) => {
                                const inputValue = event.currentTarget.value.trim();
                                inputConfig.current.primarySearchItem = inputValue;
                                if (props.currentInputs) {
                                    props.currentInputs.primarySearchItem = inputValue;
                                }
                                if (props.onRunbookInputChange) {
                                    props.onRunbookInputChange(
                                        generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                        inputConfig.current
                                    );
                                }
                                if (["network_client", "network_server", "network_host"].includes(triggerType)) {
                                    clearTimeout(typingTimer);
                                    if (inputValue) {
                                        typingTimer = setTimeout(() => {
                                            if (inputValue && !validIP(inputValue)) {
                                                setValidIpAddress(false);
                                            } else {
                                                setValidIpAddress(true);
                                            }
                                        }, 800);
                                    }
                                }
                            }}
                            placeholder={["network_client", "network_server", "network_host"].includes(triggerType) ? STRINGS.runbookInvocations.ipv4v6 : ""}
                        />
                        {!validIpAddress && <p className="text-danger mb-0">{STRINGS.runbookInvocations.invalidIpAddress}</p>}
                    </td>
                </tr>);
            } else if (!triggerType) {
                inputsForm.push(<tr key="primary-text-field">
                    <td className="p-1" colSpan={2}>
                        <label>{STRINGS.runbookInvocations.primaryEntityNotSet}</label>
                    </td>
                </tr>);
            }
        }

        if (runbookConfig.triggerType !== InputType.WEBHOOK &&
            runbookConfig.triggerType !== InputType.IMPACT_ANALYSIS_READY &&
            runbookConfig.triggerType !== InputType.INCIDENT_STATUS_CHANGED &&
            runbookConfig.triggerType !== InputType.INCIDENT_NOTE_ADDED &&
            runbookConfig.triggerType !== InputType.INCIDENT_NOTE_UPDATED &&
            runbookConfig.triggerType !== InputType.INCIDENT_INDICATORS_UPDATED &&
            runbookConfig.triggerType !== InputType.INCIDENT_ONGOING_CHANGED) {
            if (primaryField) {
                let autocompleteFieldReactkey = (runbookConfig ? runbookConfig.triggerType : "") + "primary-ac";
                if (props.runOnDemandRunbook && !props?.selectedRunbook) {
                    // Changing the key so that the autocomplete field gets rerendered when selecting the entity type or the runbook
                    autocompleteFieldReactkey = (runbookConfig?.id ? runbookConfig.id : "") + "primary-ac " + runRunbookEntityType;
                }
                let helpMapping = HELP.triggerInputs?.objectInput;
                if (triggerType === "client_location" as string) {
                    helpMapping = HELP.triggerInputs?.clientLocation;
                } else if (triggerType === "server_location" as string) {
                    helpMapping = HELP.triggerInputs?.serverLocation;
                }
                inputsForm.push(<tr key={"primary-autocomplete"}>
                    <td className="p-1">
                        <InlineHelp 
                            helpMapping={helpMapping}
                        >
                            <label>{primaryInputLabel}</label>
                        </InlineHelp>
                    </td> 
                    <td className="p-1">
                        <AutocompleteControl field={primaryField!} reportedBy={primaryReportByField!} showHighlightedText={true}
                            allowCreateItem={true} 
                            onItemSelected={(item) => {
                                inputConfig.current.primarySearchItem = item;
                                if (props.currentInputs) {
                                    props.currentInputs.primarySearchItem = item;
                                }
                                if (props.onRunbookInputChange) {
                                    //props.onRunbookInputChange(generateRunbookInputs(
                                    //    runbookConfig, primarySelectedItem, primaryEntities, secondarySelectedItem, 
                                    //    secondaryEntities, props.invocationType
                                    //));
                                    props.onRunbookInputChange(
                                        generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                        inputConfig.current
                                    );
                                }
                            }} 
                            placeholder={primaryInputPlaceholder}
                            className="input-width"
                            key={autocompleteFieldReactkey}
                            defaultSelectedItem={inputConfig.current.primarySearchItem}
                            isScheduledRunbookInEditMode={!!props.editInfos}
                        />
                    </td>
                </tr>);

                // Application runbooks also require a location
                if (triggerType?.includes("app") && props?.runOnDemandRunbook) {
                    menuItems = [];

                    menuItems.sort((optA, optB) => {
                        const labelA = optA?.label || "";
                        const labelB = optB?.label || "";
                        return labelA.localeCompare(labelB);
                    });
                
                    inputsForm.push(<tr key="secondary-autocomplete">
                        <td className="p-1">
                            <InlineHelp 
                                helpMapping={HELP.triggerInputs?.clientLocation}
                            >
                                <label>{secondaryInputLabel}</label>
                            </InlineHelp>
                        </td> 
                        <td className="p-1">
                            <AutocompleteControl field={secondaryField!} reportedBy={secondaryReportByField!} showHighlightedText={true}
                                allowCreateItem={true} 
                                onItemSelected={(item) => {
                                    inputConfig.current.secondarySearchItem = item;
                                    if (props.currentInputs) {
                                        props.currentInputs.secondarySearchItem = item;
                                    }
                                    if (props.onRunbookInputChange) {
                                        props.onRunbookInputChange(
                                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                            inputConfig.current
                                        );
                                    }
                                }} 
                                placeholder={secondaryInputPlaceholder}
                                className="input-width"
                                key={(runbookConfig ? runbookConfig.triggerType : "") + "secondary-ac"}
                                defaultSelectedItem={inputConfig.current.secondarySearchItem}
                                isScheduledRunbookInEditMode={!!props.editInfos}
                                isSecondary={true}
                            />
                        </td>
                    </tr>);
                }
            }
            if (props.showRollupOptions && [InputType.DEVICE, InputType.APPLICATION_LOCATION].includes(runbookConfig.triggerType as InputType)) {
                inputsForm.push(<tr key="primary-autocomplete-multi-checkbox">
                    <td className="p-1"></td>
                    <td className="p-1">
                        <Checkbox type="checkbox" id="primary-autocomplete-multi-checkbox" 
                            name="primary-autocomplete-multi-checkbox" key="primary-autocomplete-multi-checkbox"
                            className="text-nowrap primary-autocomplete-multi-checkbox"
                            inputRef={primaryMimicCheckbox as any}
                            label={
                                InputType.DEVICE === runbookConfig.triggerType ? 
                                    STRINGS.viewRunbooks.inputs.mimicDeviceRollup : STRINGS.viewRunbooks.inputs.mimicApplicationRollup
                            }
                            //defaultChecked={facetValue.selected === true}
                            defaultChecked={inputConfig.current.mimicMultipleDeviceOrApplication}
                            onChange={(event: any) => {
                                inputConfig.current.mimicMultipleDeviceOrApplication = event.target.checked;
                                if (event.target.checked && secondaryMimicCheckbox.current) {
                                    (secondaryMimicCheckbox.current as any).value = false;
                                    (secondaryMimicCheckbox.current as any).checked = false;
                                    inputConfig.current.mimicMultipleLocation = false;
                                }
                                if (props.onRunbookInputChange) {
                                    props.onRunbookInputChange(
                                        generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                        inputConfig.current
                                    );
                                }
                            }}
                        />
                    </td>
                </tr>);
            }

            if (props?.runOnDemandRunbook && runbookConfig?.runtimeVariables?.primitiveVariables.length) {
                const inputVariables = onDemandRunbookInputNode?.properties.inputVariables;
                if (inputVariables.length) {
                    const storedOnDemandRunbookInputs = props.invocationType === "test" ? userPreferences.runbookInputs : userPreferences.onDemandRunbookInputs;
                    let storedDefaultValues;
                    if (storedOnDemandRunbookInputs?.runbooks && runbookConfig?.id) {
                        const storedInput = storedOnDemandRunbookInputs.runbooks[runbookConfig.id];
                        storedDefaultValues = storedInput?.variableOverrides;
                    }
                    if (props?.isScheduledRunbook && props?.editInfos?.details?.runbook?.inputs) {
                        storedDefaultValues = {};
                        props.editInfos.details.runbook.inputs.forEach(item => {
                            storedDefaultValues[item.name] = item.value;
                        });
                    }
                    const inputAndRuntimeVariables = runbookConfig.runtimeVariables.primitiveVariables.filter(variable => inputVariables.includes(variable.name));
                    inputAndRuntimeVariables.forEach((variable) => {
                        inputConfig.current.variableOverrides = {
                            ...(inputConfig.current?.variableOverrides && inputConfig.current.variableOverrides),
                            [variable.name]: props.currentInputs?.variableOverrides?.[variable.name] || storedDefaultValues?.[variable.name] || variable.defaultValue || ""
                        };
                        if (props.isScheduledRunbook) {
                            inputConfig.current.variableTypes = {
                                ...(inputConfig.current?.variableTypes && inputConfig.current.variableTypes),
                                [variable.name]: variable.type || ""
                            };
                            inputConfig.current.edgesAndAuthProfilesForScheduled = {
                                edges: edgeConfig?.data?.edges,
                                authProfiles
                            }
                        }
                    });
                    if (props.onRunbookInputChange) {
                        props.onRunbookInputChange(
                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                            inputConfig.current
                        );    
                    }
                    inputsForm.push(<><tr key="additional-parameters-label">
                        <td className="p-1 pt-4 pb-3" colSpan={2}><div className="font-size-md-large">{STRINGS.runbookInvocations.additionalParametersLabel}</div></td>
                    </tr>
                    <tr>
                        <td className="display-9 pt-2" colSpan={2}>
                            {inputAndRuntimeVariables.map((variable, index) => {
                                return <RunbookVariableMappingEditor 
                                    key={"variable-editor-" + variable.name}
                                    isFirstRow={index === 0} onDemandRunbookVariable={variable} 
                                    isLastRow={index === inputAndRuntimeVariables.length - 1}
                                    initValue={props.currentInputs?.variableOverrides?.[variable.name] || storedDefaultValues?.[variable.name] || variable.defaultValue || ""}
                                    authProfiles={authProfiles} edges={edgeConfig?.data?.edges}
                                    onVariableMappingSet={(onDemandVariable: string, mappedVariable: string) => {
                                        if (props.currentInputs?.variableOverrides?.[onDemandVariable]) {
                                            props.currentInputs.variableOverrides[onDemandVariable] = mappedVariable;
                                        }
                                        inputConfig.current.variableOverrides = {
                                            ...(inputConfig.current?.variableOverrides && inputConfig.current.variableOverrides),
                                            [onDemandVariable]: mappedVariable
                                        };
                                        if (props.onRunbookInputChange) {
                                            props.onRunbookInputChange(
                                                generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                                inputConfig.current
                                            );    
                                        }
                                    }}
                                />;
                            })}
                        </td>
                    </tr>
                </>);
                }
            }
        }

        if (runbookConfig.triggerType === InputType.APPLICATION_LOCATION) {
            // Application runbooks also require a location
            menuItems = [];
            /* This used DAL and not search
            names = [];  
            if (secondaryEntities) {
                for (const entity of secondaryEntities) {
                    const attributes = getAttributesObject(entity);
                    let name = getEntityName(entity, runbookConfig);
                    let types: Array<string> = [];
                    if (entity?.reportedBy?.length > 0) {
                        for (const reportedBy of entity.reportedBy) {
                            if (!types.includes(reportedBy.type)) {
                                types.push(reportedBy.type);
                            }
                        }
                    }
                    if (!names.includes(name)) {
                        menuItems.push({label: name, value: attributes, type: types.join(", ")});
                        names.push(name);
                    }
                }
            }
            */

            menuItems.sort((optA, optB) => {
                const labelA = optA?.label || "";
                const labelB = optB?.label || "";
                return labelA.localeCompare(labelB);
            });
        
            inputsForm.push(<tr key="secondary-autocomplete">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.triggerInputs?.clientLocation}
                    >
                        <label>{secondaryInputLabel}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1">
                    <AutocompleteControl field={secondaryField!} reportedBy={secondaryReportByField!} showHighlightedText={true}
                        allowCreateItem={true} 
                        onItemSelected={(item) => {
                            inputConfig.current.secondarySearchItem = item;
                            if (props.currentInputs) {
                                props.currentInputs.secondarySearchItem = item;
                            }
                            if (props.onRunbookInputChange) {
                                //props.onRunbookInputChange(generateRunbookInputs(
                                //    runbookConfig, primarySelectedItem, primaryEntities, secondarySelectedItem, 
                                //    secondaryEntities, props.invocationType
                                //));
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }} 
                        placeholder={secondaryInputPlaceholder}
                        className="input-width"
                        key={(runbookConfig ? runbookConfig.triggerType : "") + "secondary-ac"}
                        defaultSelectedItem={inputConfig.current.secondarySearchItem}
                        isScheduledRunbookInEditMode={!!props.editInfos}
                        isSecondary={true}
                    />
                </td>
            </tr>);

            if (props.showRollupOptions && [InputType.APPLICATION_LOCATION].includes(runbookConfig.triggerType as InputType)) {
                inputsForm.push(<tr key="secondary-autocomplete-multi-checkbox">
                    <td className="p-1"></td>
                    <td className="p-1">
                        <Checkbox type="checkbox" id="secondary-autocomplete-multi-checkbox" 
                            name="secondary-autocomplete-multi-checkbox" key="secondary-autocomplete-multi-checkbox"
                            inputRef={secondaryMimicCheckbox as any}
                            className="text-nowrap secondary-autocomplete-multi-checkbox"
                            label={STRINGS.viewRunbooks.inputs.mimicLocationRollup}
                            //defaultChecked={facetValue.selected === true}
                            defaultChecked={inputConfig.current.mimicMultipleLocation}
                            onChange={(event: any) => {
                                inputConfig.current.mimicMultipleLocation = event.target.checked;
                                if (event.target.checked && primaryMimicCheckbox.current) {
                                    (primaryMimicCheckbox.current as any).value = false;
                                    (primaryMimicCheckbox.current as any).checked = false;
                                    inputConfig.current.mimicMultipleDeviceOrApplication = false;
                                }
                                if (props.onRunbookInputChange) {
                                    props.onRunbookInputChange(
                                        generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                        inputConfig.current
                                    );
                                }
                            }}
                        />
                    </td>
                </tr>);
            }

        }

        if (
            props.allowVantagePointSelection && 
            [InputType.APPLICATION_LOCATION, InputType.LOCATION, InputType.APPLICATION].includes(runbookConfig.triggerType as InputType)
        ) {
            menuItems = [];

            // Add the vantage point list to the form
            let vpSelectedItem: any = undefined;
            const vpDataSources = getArDataSources() || [];
            for (let vpIndex = 0; vpIndex < vpDataSources.length; vpIndex++) {
                const vp = vpDataSources[vpIndex];
                const menuItem = {label: vp.name || "", value: vp, type: "AppResponse"};
                menuItems.push(menuItem);
                //runbookOptions.push({ label: runbook.name || "", value: runbook.id || "" });
                if (!inputConfig.current.vantagePointConfig.vantagePoint) {
                    if (vpIndex === 0) {
                        vpSelectedItem = menuItem;
                        inputConfig.current.vantagePointConfig.vantagePoint = vp;
                    }
                } else if (inputConfig.current.vantagePointConfig.vantagePoint?.id === vp.id) {
                    vpSelectedItem = menuItem;
                }
            }
            inputsForm.push(<tr key="vantage-point-list">
                <td className="p-1"><label>{STRINGS.viewRunbooks.inputVantagePointLabel}</label></td> 
                <td className="p-1">
                    <ItemSuggest 
                        items={menuItems} 
                        defaultSelectedItem={vpSelectedItem} 
                        inputProps={{placeholder: "Select a vantage point"}}
                        itemRenderer={renderItemVirtualized}
                        itemListRenderer={renderMenuVirtualized}
                        onItemSelect={(item) => {
                            const vp = item.value;
                            inputConfig.current.vantagePointConfig.vantagePoint = vp;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );    
                            }
                        }}
                        inputValueRenderer={(item) => {
                            return item.label || "";
                        }}
                        itemsEqual="value"
                        itemPredicate={(query, item, index, exactMatch) => {
                            let label: string = item?.label || "";
                            let type: string = item?.type || "";
                            let queryText: string = query || "";
                            return label.toLowerCase().includes(queryText.toLowerCase()) || 
                                type.toLowerCase().includes(queryText.toLowerCase()) || false;
                        }}
                        popoverProps={{minimal: true, boundary: "viewport"}}
                        className="input-width"
                    />
                </td>
            </tr>);
        }

        if (runbookConfig.triggerType === InputType.WEBHOOK) {
            inputsForm.push(<tr key="http-request-body">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.webhookInputs.httpRequestBody}
                    >
                        <label>{STRINGS.viewRunbooks.inputs.httpRequestBody}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><div className="d-flex">
                    <TextArea id="input-http-request-body"
                        defaultValue={inputConfig.current.webhook!.requestBody} style={{resize: "both", width: "360px", height: "120px"}}
                        onChange={(event) => {
                            inputConfig.current.webhook!.requestBody = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                </div></td>
            </tr>);

            inputsForm.push(<tr key="http-request-headers">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.webhookInputs.httpRequestHeaders}
                    >
                        <label>{STRINGS.viewRunbooks.inputs.httpRequestHeaders}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><div className="d-flex">
                    <TextArea id="input-http-request-headers"
                        defaultValue={inputConfig.current.webhook?.requestHeaders} style={{resize: "both", width: "360px", height: "120px"}}
                        onChange={(event) => {
                            inputConfig.current.webhook!.requestHeaders = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                </div></td>
            </tr>);

            inputsForm.push(<tr key="http-request-url">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.webhookInputs.httpRequestUrl}
                    >
                        <label>{STRINGS.viewRunbooks.inputs.httpRequestUrl}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><div className="d-flex">
                    <InputGroup id="input-http-request-url"
                        defaultValue={inputConfig.current.webhook?.requestUrl} style={{width: "360px"}} 
                        onChange={(event) => {
                            inputConfig.current.webhook!.requestUrl = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                </div></td>
            </tr>);

            inputsForm.push(<tr key="http-request-query-params">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.webhookInputs.httpRequestQueryParams}
                    >
                        <label>{STRINGS.viewRunbooks.inputs.httpRequestQueryParams}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><div className="d-flex">
                    <TextArea id="input-http-request"
                        defaultValue={inputConfig.current.webhook?.requestParams} style={{resize: "both", width: "360px", height: "80px"}}
                        onChange={(event) => {
                            inputConfig.current.webhook!.requestParams = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                </div></td>
            </tr>);
        }

        if (props?.runOnDemandRunbook && !props.isScheduledRunbook) {
            inputsForm.push(<tr key="end-time-label">
                <td className="p-1 pb-4 pt-4" colSpan={2}><div className="font-size-md-large">{STRINGS.runbookInvocations.referenceTimeLabel}</div></td>
            </tr>);
        }

        if (runbookConfig.triggerType !== InputType.IMPACT_ANALYSIS_READY &&
            runbookConfig.triggerType !== InputType.INCIDENT_STATUS_CHANGED &&
            runbookConfig.triggerType !== InputType.INCIDENT_NOTE_ADDED &&
            runbookConfig.triggerType !== InputType.INCIDENT_NOTE_UPDATED &&
            runbookConfig.triggerType !== InputType.INCIDENT_INDICATORS_UPDATED &&
            runbookConfig.triggerType !== InputType.INCIDENT_ONGOING_CHANGED && 
            !props.isScheduledRunbook) {
            inputsForm.push(<tr key="end-time">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.triggerInputs?.inputTime}
                    >
                        <label>{STRINGS.viewRunbooks.inputEndTimeLabel}</label>
                    </InlineHelp>
                </td>
                <td className="p-1"><RunbookTimeControl defaultTime={inputConfig.current.endTime} onTimeChange={(time: number | undefined) => {
                    if (props.onRunbookInputChange) {
                        inputConfig.current.endTime = time ? time : 0;
                        //props.onRunbookInputChange(generateRunbookInputs(
                        //    runbookConfig, primarySelectedItem, primaryEntities, secondarySelectedItem, secondaryEntities,
                        //    endTime, props.invocationType
                        //));
                        props.onRunbookInputChange(
                            generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                            inputConfig.current
                        );
                    }
                }}/></td>
            </tr>);
        }

        if (props.showTriggerMetric && inputConfig.current.triggerMetricConfig && (hasTriggerMetricCondition(runbookConfig) || hasVisualizationConnectedToTrigger(runbookConfig))) {
            inputsForm.push(<tr key="trigger-metric">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.triggerInputs?.triggerMetric}
                    >
                        <label>{STRINGS.viewRunbooks.inputTriggerMetricLabel}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><HTMLSelect 
                    onChange={(event) => {
                        inputConfig.current.triggerMetricConfig!.metric = event.currentTarget.value;
                        if (metricUnitLabels.current?.length) {
                            let unit = "";
                            const metricDef = objMetricMetaData.metrics[inputConfig.current.triggerMetricConfig?.metric || ""];
                            if (metricDef && metricDef.unit) {
                                unit = Unit.parseUnit(metricDef.unit).getDisplayName() ?
                                    "(" + Unit.parseUnit(metricDef.unit).getDisplayName() + ")" : "";
                            }
                            metricUnitLabels.current.forEach((element) => {
                                if (element?.textContent) {
                                    element.textContent = unit;
                                }
                            });
                        }
                        if (props.onRunbookInputChange) {
                            props.onRunbookInputChange(
                                generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                inputConfig.current
                            );
                        }
                    }}
                    defaultValue={inputConfig.current?.triggerMetricConfig?.metric}
                    options={triggerMetricDefs}
                /></td>
            </tr>);
            inputsForm.push(<tr key="actual-value">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.triggerInputs?.triggerActualValue}
                    >
                        <label>{STRINGS.viewRunbooks.inputTriggerMetricActualValueLabel}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><div className="d-flex">
                    <InputGroup id="input-actual-value" type="number"
                        defaultValue={inputConfig.current?.triggerMetricConfig?.actualValue} style={{width: "210px"}} 
                        onChange={(event) => {
                            inputConfig.current.triggerMetricConfig!.actualValue = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                    <span ref={(element) => metricUnitLabels.current.push(element as HTMLSpanElement)} className="ml-2">{triggerMetricUnit}</span>
                </div></td>
            </tr>);
            inputsForm.push(<tr key="acceptable-low-value">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.triggerInputs?.triggerLowAcceptable}
                    >
                        <label>{STRINGS.viewRunbooks.inputTriggerMetricAcceptableLowValueLabel}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><div className="d-flex">
                    <InputGroup id="input-acceptable-low-value" type="number" 
                        defaultValue={inputConfig.current?.triggerMetricConfig?.lowValue} style={{width: "210px"}} 
                        onChange={(event) => {
                            inputConfig.current.triggerMetricConfig!.lowValue = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                    <span ref={(element) => metricUnitLabels.current.push(element as HTMLSpanElement)} className="ml-2">{triggerMetricUnit}</span>
                </div></td>
            </tr>);
            inputsForm.push(<tr key="acceptable-high-value">
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.triggerInputs?.triggerHighAcceptable}
                    >
                        <label>{STRINGS.viewRunbooks.inputTriggerMetricAcceptableHighValueLabel}</label>
                    </InlineHelp>
                </td> 
                <td className="p-1"><div className="d-flex">
                    <InputGroup id="input-acceptable-high-value" type="number" 
                        defaultValue={inputConfig.current?.triggerMetricConfig?.highValue} style={{width: "210px"}} 
                        onChange={(event) => {
                            inputConfig.current.triggerMetricConfig!.highValue = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                    <span ref={(element) => metricUnitLabels.current.push(element as HTMLSpanElement)} className="ml-2">{triggerMetricUnit}</span>
                </div></td>
            </tr>);
        }

        if (props?.runOnDemandRunbook) {
            inputsForm.push(<tr key="advanced-section-gap"><td className="pt-3"></td></tr>);
        }

        if (props.showTimeout || (props.showIncidentVariables && runbookConfig.triggerType !== InputType.WEBHOOK)) {
            inputsForm.push(<tr key="advanced-section">
                <td>
                    <div className="heading d-inline-block clickable display-9 font-weight-500"
                        onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
                        <Icon icon={showAdvancedOptions ? IconNames.CARET_DOWN : IconNames.CARET_RIGHT} />
                            {STRINGS.viewRunbooks.advancedSection}
                    </div>
                </td>
            </tr>);
        }

        if (props.showIncidentVariables && runbookConfig.triggerType !== InputType.WEBHOOK) {
            inputsForm.push(<tr key="incident-variables" className={showAdvancedOptions ? '' : 'd-none'}>
                <td className="p-1">
                    <InlineHelp 
                        helpMapping={HELP.triggerInputs?.inputIncidentVariables}
                    >
                        <label>{STRINGS.viewRunbooks.inputs.incidentVariables}</label>
                    </InlineHelp>
                </td>
                <td className="p-1"><div className="d-flex">
                    <TextArea id="input-http-request-body"
                        defaultValue={inputConfig.current.incidentVariables} style={{resize: "both", width: "360px", height: "120px"}}
                        onChange={(event) => {
                            inputConfig.current.incidentVariables = event.currentTarget.value;
                            if (props.onRunbookInputChange) {
                                props.onRunbookInputChange(
                                    generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                                    inputConfig.current
                                );
                            }
                        }}
                    />
                </div></td>
            </tr>);
        }

        if (props.onRunbookInputChange) {
            // The user used to have to select at least one item, but now items get auto selected.  Send out
            // a notification for the initial values, this can be improved later, by only doing it when necessary rather
            // than on every re-render
            props.onRunbookInputChange(
                generateRunbookInputsUsingSearch(runbookConfig, props.invocationType, inputConfig), 
                inputConfig.current
            );
        }
    }

    return <DataLoadFacade loading={(!props?.runOnDemandRunbook && !runbookConfig) || isLoadingRunbooks /*|| primaryEntityListQuery.loading || secondaryEntityListQuery.loading*/} fullScreen={false} data={true} error={false} 
        showContentsWhenLoading={false} transparent={false} className="runbook-inputs-load-facade"
    >
        <div className="d-flex">
            <table><tbody>
                {inputsForm}
                {props.showTimeout && <tr className={showAdvancedOptions ? '' : 'd-none'}>
                    <td className="p-1">
                        <InlineHelp 
                            helpMapping={HELP.triggerInputs?.inputTimeout}
                        >
                            <label>{STRINGS.viewRunbooks.inputTimeoutLabel}</label>
                        </InlineHelp>
                    </td> 
                    <td className="p-1"><InputGroup id="input-timeout" type="text" 
                        defaultValue={inputConfig.current.timeoutInMinutes + ""} style={{width: "360px"}} 
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 0 && value <= 60 && props.onTimeoutChanged) {
                                inputConfig.current.timeoutInMinutes = value;
                                props.onTimeoutChanged(value);
                            }
                        }}
                    /></td>
                </tr>}
            </tbody></table>
        </div>
    </DataLoadFacade>;
}

/** generates the runbook inputs object.  If there was an error creating the object it returns undefined.
 *  @param runbookConfig the RunbookConfig object with the full runbook configuration.
 *  @param invocationType the type of invocation: correlation engine, test, or on demand.
 *  @param inputConfig the input configuration object.
 *  @returns the RunbookInputs object that can be passed to the Runbook Orchestrator to start a runbook run. */
export function generateRunbookInputsUsingSearch(
    runbookConfig: RunbookConfig, invocationType: INVOCATION_TYPE, inputConfig: {current: InputConfig}
): RunbookInputs | undefined {
    try {
        let inputsJson: RunbookInputInputs | undefined;
        let objectJson: RunbookInputObject | undefined;
        let entityJson: RunbookInputEntity | undefined;
        let uuid: string | undefined = undefined;
        let metadata: RunbookMetadata | undefined = undefined;
        let primarySearchItem: any = inputConfig.current.primarySearchItem;
        let secondarySearchItem: any = inputConfig.current.secondarySearchItem;
        const onDemandRunbookInputNode = runbookConfig.nodes?.find(node => node.type === InputType.ON_DEMAND);
        let triggerType = runbookConfig.triggerType;
        let onDemandRunbookInputNodeKind;
        if (onDemandRunbookInputNode) {
            onDemandRunbookInputNodeKind = onDemandRunbookInputNode?.properties?.synthKeys?.[0]?.dataOceanId;
            if (onDemandRunbookInputNodeKind?.includes(InputType.LOCATION)) {
                triggerType = InputType.LOCATION;
            } else {
                triggerType = onDemandRunbookInputNodeKind;
            }
        }
        switch (triggerType) {
            case InputType.INTERFACE: {
                let deviceIp: string = "";
                let ifIndex: number = -1;
                let location = undefined;
                let ifAlias: string | null = null;
                let ifDescr: string | null = null;
                let name: string | undefined = undefined;
                if (typeof primarySearchItem === "string") {
                    // User has typed in a string, hopefully a deviceIp:ifIndex
                    const text: string = primarySearchItem;
                    if (text.includes(":")) {
                        deviceIp = text.substring(0, text.lastIndexOf(":"));
                        ifIndex = parseInt(text.substring(text.lastIndexOf(":") + 1, text.length));
                        name = deviceIp + ":" + ifIndex;
                    }
                } else {
                    // The user has selected one of the items in the list and the value should have everything in it
                    deviceIp = primarySearchItem?.network_interface?.ipaddr;
                    ifIndex = primarySearchItem?.network_interface?.ifindex;
                    name = primarySearchItem?.network_interface?.name;
                    location = primarySearchItem?.network_interface?.location;
                    ifAlias = primarySearchItem?.network_interface?.ifalias || null;
                    ifDescr = primarySearchItem?.network_interface?.ifdescr || null;
                }
                uuid = primarySearchItem?.network_interface?.uuid;
                metadata = { 
                    uuid, ipaddr: deviceIp, ifindex: ifIndex, name, location, ifalias: ifAlias, ifdescr: ifDescr
                    //elementType: attributes?.elementType, type: attributes?.type, 
                    //inbound_speed: attributes?.inbound_speed ? parseInt(attributes.inbound_speed) : undefined, 
                    //outbound_speed: attributes?.outbound_speed ? parseInt(attributes.outbound_speed) : undefined
                };
                if (!Number.isInteger(ifIndex)) {
                    throw new Error("bad ifindex input");
                }
                inputsJson = {
                    networkInterface: {
                        ipaddr: deviceIp,
                        ifindex: ifIndex,
                        ifalias: ifAlias,
                        ifdescr: ifDescr,
                        uuid
                    }
                };
                objectJson = {
                    name: `${deviceIp}:${ifIndex}`,
                    kind: "network_interface"
                };
                entityJson = {
                    kind: "network_interface",
                    attributes: {
                        ipaddr: deviceIp,
                        ifindex: ifIndex,
                        ifalias: ifAlias,
                        ifdescr: ifDescr,
                        name: name ? name : `${deviceIp}:${ifIndex}`,
                        uuid,
                        metadata
                    }
                }
                break;
            }
            case InputType.DEVICE: {
                let deviceIp = ""; 
                let location = undefined;
                let name = undefined;
                let hostname = undefined;
                if (typeof primarySearchItem === "string") {
                    // User has typed in a string, hopefully a deviceIp
                    deviceIp = primarySearchItem;
                } else {
                    // The user has selected one of the items in the list and the value should have everything in it
                    deviceIp = primarySearchItem?.network_device?.ipaddr;
                    name = primarySearchItem?.network_device?.name;
                    hostname = primarySearchItem?.network_device?.hostname;
                    location = primarySearchItem?.network_device?.location;
                }
                uuid = primarySearchItem?.network_device?.uuid;
                metadata = { 
                    uuid, ipaddr: deviceIp, name, location, hostname, 
                    //geo: attributes?.geo, elementType: attributes?.elementType, type: attributes?.type, 
                    //model: attributes?.model, serial_number: attributes?.serial_number,
                    //os_version: attributes?.os_version, vendor: attributes?.vendor
                };
                inputsJson = {
                    networkDevice: {
                        ipaddr: deviceIp,
                        uuid
                    }
                };
                objectJson = {
                    name: deviceIp,
                    kind: "network_device"
                };
                entityJson = {
                    kind: "network_device",
                    attributes: {
                        ipaddr: deviceIp,
                        name: name || deviceIp,
                        uuid,
                        metadata
                    }
                }
                break;
            }
            case InputType.APPLICATION_LOCATION: {
                let application: RunbookMetadataApplication | undefined = undefined;
                if (typeof primarySearchItem === "string") {
                    // User has typed in a string, hopefully a valid application
                    application = {name: primarySearchItem, uuid: ""};
                } else {
                    // The user has selected one of the items in the list and the value should have everything in it
                    application = {name: primarySearchItem.application.name, uuid: primarySearchItem.application.uuid};
                }

                let location: RunbookMetadataLocation | undefined = undefined;
                let locationType = "business";
                if (typeof secondarySearchItem === "string") {
                    // User has typed in a string, hopefully a valid location
                    location = {name: secondarySearchItem, uuid: ""};
                } else {
                    // The user has selected one of the items in the list and the value should have everything in it
                    if (secondarySearchItem.location.type) {
                        locationType = secondarySearchItem.location.type;
                    } else if (secondarySearchItem.location?.reportedBy?.length) {
                        locationType = secondarySearchItem.location.reportedBy[0] === "Aternity" ? "physical" : "business";
                    }
                    location = {
                        name: secondarySearchItem.location.name,
                        type: locationType,
                        uuid: secondarySearchItem.location.uuid
                    };
                }

                let entityKind: string = location?.name ? "application_location" : "application";

                // Calculate the metadata using the formula Bryan worked out with the metadata team
                uuid = "";
                if (application?.name && location?.name) {
                    let tenantId = "";
                    try {
                        tenantId = AuthService.getTenantId();
                    } catch (error) {
                        tenantId = "";
                    }
                    const identifier = [tenantId, application.name, location.name].join("_");
                    uuid = getUuidV5(identifier, NAMESPACE_MAP[entityKind]);
                }
                metadata = { uuid, application, location};
                inputsJson = {
                    application: {
                        name: application?.name || "",
                        uuid
                    }
                };
                objectJson = {
                    name: application?.name || "",
                    kind: entityKind
                };
                entityJson = {
                    kind: entityKind,
                    attributes: {
                        application: application?.name || "",
                        metadata
                    }
                }
                break;
            }
            case InputType.LOCATION: {
                let name: string | undefined = undefined;
                let locationType = "business";
                if (typeof primarySearchItem === "string") {
                    // User has typed in a string, hopefully a valid location
                    name = primarySearchItem;
                } else {
                    // The user has selected one of the items in the list and the value should have everything in it
                    if (primarySearchItem.location.type) {
                        locationType = primarySearchItem.location.type;
                    } else if (primarySearchItem.location?.reportedBy?.length) {
                        locationType = primarySearchItem.location.reportedBy[0] === "Aternity" ? "physical" : "business";
                    }
                    name = primarySearchItem.location.name;
                }
                uuid = primarySearchItem?.location?.uuid;
                metadata = { uuid, name, type: locationType };
                inputsJson = {
                    location: {
                        name: name || "",
                        uuid
                    }
                };
                objectJson = {
                    name: name || "",
                    kind: onDemandRunbookInputNodeKind ? onDemandRunbookInputNodeKind : "location"
                };
                entityJson = {
                    kind: onDemandRunbookInputNodeKind ? onDemandRunbookInputNodeKind : "location",
                    attributes: {
                        name: name,
                        uuid,
                        metadata
                    }
                }
                break;
            }
            case InputType.APPLICATION: {
                let name: string | undefined = undefined;
                if (typeof primarySearchItem === "string") {
                    // User has typed in a string, hopefully a valid location
                    name = primarySearchItem;
                } else {
                    // The user has selected one of the items in the list and the value should have everything in it
                    name = primarySearchItem?.application?.name;
                }
                uuid = primarySearchItem?.application?.uuid;
                metadata = { uuid, name };
                inputsJson = {
                    application: {
                        name: name || "",
                        uuid
                    }
                };
                objectJson = {
                    name: name || "",
                    kind: onDemandRunbookInputNodeKind ? onDemandRunbookInputNodeKind : "application"
                };
                entityJson = {
                    kind: onDemandRunbookInputNodeKind ? onDemandRunbookInputNodeKind : "application",
                    attributes: {
                        name: name,
                        uuid,
                        metadata
                    }
                }
                break;
            }
        }
        if (onDemandRunbookInputNode && !onDemandRunbookInputNodeKind) {
            metadata = {};
            inputsJson = {};
            objectJson = {
                name: "",
                kind: "none"
            };
            entityJson = {
                kind: "none",
                attributes: {
                    metadata
                }
            };
        } else if (onDemandRunbookInputNode && ["network_client", "network_server", "network_host"].includes(onDemandRunbookInputNodeKind)) {
            metadata = {
                ipaddr: primarySearchItem
            };
            inputsJson = {};
            objectJson = {
                name: "",
                kind: onDemandRunbookInputNodeKind
            };
            entityJson = {
                kind: onDemandRunbookInputNodeKind,
                attributes: {
                    ipaddr: primarySearchItem,
                    metadata
                }
            };
        }
        const jsonInput = getInput(
            runbookConfig.triggerType || InputType.INTERFACE,
            entityJson, inputsJson, objectJson, runbookConfig.id, `${inputConfig.current.endTime/1000}`, invocationType, 
            inputConfig.current.triggerMetricConfig, 
            runbookConfig.triggerType === InputType.WEBHOOK ? inputConfig.current.webhook: undefined,  
            inputConfig
        );
        return jsonInput;
    } catch (error) {
        return undefined;
    }
}

/** returns the document which specifies the inputs for the runbook orchestrator.
 *  @param triggerType the InputType that specifies what trigger is being used.
 *  @param entity the RunbookInputEntity with the inputs for runbook orchestrator.
 *  @param inputs the inputs object that is added to the inputs section.
 *  @param object the object object that is added to the object section.
 *  @param runbookId a String with the runbook id.
 *  @param endTime a String with the end time that should be used when running the runbook.  It is 
 *      in the format of seconds.nanoseconds.  If absent the runbook orchestrator should use now.
 *  @param invocationType the type of invocation: correlation engine, test, or on demand.
 *  @param triggerMetricConfig the object that contains the information about the trigger metric. If the trigger 
 *      metric is not supposed to be set this should be passed as undefined.
 *  @param webhookConfig the object that contains the inputs for the webhooks.
 *  @param inputConfig the input configuration object.
 *  @returns a JSON object with the runbook orchestrator inputs. */
function getInput(
    triggerType: InputType,
    entity: RunbookInputEntity | undefined, inputs: RunbookInputInputs | undefined, object: RunbookInputObject | undefined, 
    runbookId: string | undefined, endTime: string | undefined, invocationType: INVOCATION_TYPE, 
    triggerMetricConfig: TriggerMetricConfig | undefined, webhookConfig: WebhookConfig | undefined, inputConfig: {current: InputConfig}
): RunbookInputs {
    let tenantId = "";
    try {
        tenantId = AuthService.getTenantId();
    } catch (error) {
        tenantId = "";
    }
    let primaryIndicator: RunbookInputPrimaryIndicator | undefined = undefined;
    if (triggerMetricConfig && triggerMetricConfig.metric !== NO_TRIGGER_METRIC_VALUE) {
        primaryIndicator = {
            data: {
                indicator: {
                    metric:  triggerMetricConfig.metric,
                    details: {
                        actual_value: triggerMetricConfig.actualValue,
                        acceptable_low_value: triggerMetricConfig.lowValue,
                        acceptable_high_value: triggerMetricConfig.highValue
                    }
                }
            }
        };
    }

    const incidentId = getUuidV4();
    const userId = getUuidV4();

    const input: RunbookInputs = { 
        "utid": tenantId,
        "runbookId": runbookId || "",
        "variableOverrides": inputConfig.current?.variableOverrides || undefined,
        "incidentId": incidentId,
        "triggerId": getUuidV4(),
        "triggerMode": {
            "manualRun": true,
            "source": invocationType,
        },
        "detection": {
            "id": getUuidV4(),
            "entity": entity,
//            "inputs": inputs,
//            "object": object
            primaryIndicator
        },
        "duration": "900.000000000",
        "errors": []
    };

    if (inputConfig.current?.variableTypes) {
        input.variableTypes = inputConfig.current?.variableTypes;
    }

    if (inputConfig.current?.edgesAndAuthProfilesForScheduled) {
        input.edgesAndAuthProfilesForScheduled = inputConfig.current.edgesAndAuthProfilesForScheduled;
    }

    if (inputConfig.current.mimicMultipleDeviceOrApplication || inputConfig.current.mimicMultipleLocation) {
        if (triggerType === InputType.DEVICE) {
            // We have the multi-device down case
            input.detection.entity = {
                kind: ENTITY_KIND.LOCATION,
                attributes: {
                    metadata: entity?.attributes?.metadata?.location
                }
            };
        }
        // If this is checked we have an app/location indicator with the multi-application or multi-location case
        input.detection.indicators = [{
            isPrimary: true,
            entity,
            data: primaryIndicator?.data?.indicator,
            metric: primaryIndicator?.data?.indicator?.metric
        }];    
    }

    if (endTime && endTime !== "0") {
        input.endTime = endTime;
    }

    if (inputConfig.current.incidentVariables) {
        try {
            if (inputConfig.current?.incidentVariables?.trim()?.length) {
                input.variableOverrides = JSON.parse(inputConfig.current.incidentVariables);
            }
        } catch (error) {
            console.error(error);
            input.errors!.push(STRINGS.viewRunbooks.inputErrors.incidentVariablesParsing);
        }
    }

    if (entity?.kind && 
        ["network_client", "network_server", "network_host"].includes(entity.kind) && 
        (!inputConfig.current.primarySearchItem || (inputConfig.current.primarySearchItem && !validIP(inputConfig.current.primarySearchItem as string)))
    ) {
        input.errors!.push(STRINGS.viewRunbooks.inputErrors.primaryEntityIpAddressParsing);
    }

    // Handle the lifecycle triggers
    switch (triggerType) {
        case InputType.IMPACT_ANALYSIS_READY: {
            input.detection.entity = {
                kind: "ImpactAnalysisReady", 
                attributes: {
                    id: getUuidV4(), 
                    metadata: {
                        runbookTriggers: [{
                            id: getUuidV4(), runbookInstanceId: getUuidV4(), 
                            runbookStatus: inputConfig.current.runbookAnalysisConfig.status, 
                            createdAt: inputConfig.current.runbookAnalysisConfig.time !== 0 ?
                                new Date (inputConfig.current.runbookAnalysisConfig.time).toISOString() : 
                                new Date ().toISOString()
                        }]    
                    }
                }
            };
            input.detection.primaryIndicator = {data: {indicator: {metric: "ImpactAnalysisReady"}}};
            break;
        }
        case InputType.INCIDENT_STATUS_CHANGED: {
            input.detection.entity = {
                kind: "IncidentStatusChanged", 
                attributes: {
                    id: getUuidV4(), 
                    metadata: {
                        oldStatus: inputConfig.current.incidentStatusConfig.oldStatus,
                        status: inputConfig.current.incidentStatusConfig.newStatus,
                        lastUpdatedByUser: userId,
                        lastUpdatedAt: inputConfig.current.incidentStatusConfig.time !== 0 ?
                            new Date (inputConfig.current.incidentStatusConfig.time).toISOString() : 
                            new Date ().toISOString()
                    }
                }
            };
            input.detection.primaryIndicator = {data: {indicator: {metric: "IncidentStatusChanged"}}};
            break;
        }
        case InputType.INCIDENT_NOTE_ADDED: 
        case InputType.INCIDENT_NOTE_UPDATED: {
            input.detection.entity = {
                kind: triggerType === InputType.INCIDENT_NOTE_ADDED ? "IncidentNoteAdded" : "IncidentNoteUpdated", 
                attributes: {
                    id: getUuidV4(), 
                    metadata: {
                        type: "Standard", content: inputConfig.current.noteConfig.content || "", userId,
                        createdAt: inputConfig.current.noteConfig.time !== 0 ?
                            new Date (inputConfig.current.noteConfig.time).toISOString() : 
                            new Date ().toISOString()
                    }
                }
            };
            input.detection.primaryIndicator = {
                data: {
                    indicator: {metric: triggerType === InputType.INCIDENT_NOTE_ADDED ? "IncidentNoteAdded" : "IncidentNoteUpdated"}
                }
            };
            break;
        }
        case InputType.INCIDENT_INDICATORS_UPDATED: {
            input.detection.entity = {
                kind: "IncidentIndicatorsUpdated", 
                attributes: {
                    id: getUuidV4()
                }
            };
            if (inputConfig.current.indicatorsConfig.count !== 0) {
                input.detection.indicators = Array(inputConfig.current.indicatorsConfig.count);
                for (let index = 0; index < input.detection.indicators.length; index++) {
                    input.detection.indicators[index] = {
                        incidentId, sourceId: getUuidV4(), kind: "too_high", metric: "throughput",
                        isPrimary: false,
                        entity: {
                            kind: ENTITY_KIND.LOCATION,
                            attributes: {
                                elementType: "VNES_GROUP",
                                element_type: "VNES_GROUP",
                                geo: {},
                                uuid: "baf0bb32-6d76-5bd0-b845-fb54e1e4bcd3",
                                name: "Branch_Tokyo"
                            }
                        }
                    };
                    input.detection.entity.attributes.earliestIndicator = new Date().toISOString();
                    input.detection.entity.attributes.latestIndicator = new Date().toISOString();
                }
            }
            input.detection.primaryIndicator = {data: {indicator: {metric: "IncidentIndicatorsUpdated"}}};
            break;
        }
        case InputType.INCIDENT_ONGOING_CHANGED: {
            input.detection.entity = {
                kind: "IncidentOngoingChanged", 
                attributes: {
                    id: getUuidV4(), 
                    metadata: {
                        isOngoing: false,
                        endTime: inputConfig.current.ongoingStatusConfig.time !== 0 ?
                            new Date (inputConfig.current.ongoingStatusConfig.time).toISOString() : 
                            new Date ().toISOString()
                    }
                }
            };
            input.detection.primaryIndicator = {data: {indicator: {metric: "IncidentOngoingChanged"}}};
            break;
        } 
    }

    if (webhookConfig) {
        input.detection.entity = {kind: "webhook", attributes: {requestBody: {}, requestHeaders: {}, requestQueryParameters: {}}};
        input.detection.primaryIndicator = {data: {indicator: {metric: "webhook"}}}

        // Set the body/payload
        try {
            if (webhookConfig?.requestBody?.trim()?.length > 0) {
                input.detection!.entity!.attributes.requestBody = JSON.parse(webhookConfig.requestBody);
                // Request body can be anything
                //if (!isCorrectHttpRequestSchema(input.detection!.entity!.attributes.requestBody)) {
                //    input.errors!.push(STRINGS.viewRunbooks.inputErrors.requestBodySchema);
                //}
            }
        } catch (error) {
            console.log("Runbook Inputs: could not parse request body");
            input.errors!.push(STRINGS.viewRunbooks.inputErrors.requestBodyParsing);
        }

        // Set the URL
        input.detection!.entity!.attributes.requestPath = webhookConfig.requestUrl;
        
        // Set the headers
        try {
            if (webhookConfig?.requestHeaders?.trim()?.length > 0) {
                input.detection!.entity!.attributes.requestHeaders = JSON.parse(webhookConfig.requestHeaders);
                if (!isCorrectHttpRequestSchema(input.detection!.entity!.attributes.requestHeaders)) {
                    input.errors!.push(STRINGS.viewRunbooks.inputErrors.requestHeadersSchema);
                }
            }
        } catch (error) {
            console.log("Runbook Inputs: could not parse request headers");
            input.errors!.push(STRINGS.viewRunbooks.inputErrors.requestHeadersParsing);
        }
        
        // Set the query parameters
        try {
            if (webhookConfig?.requestParams?.trim()?.length > 0) {
                input.detection!.entity!.attributes.requestQueryParameters = JSON.parse(webhookConfig.requestParams);
                if (!isCorrectHttpRequestSchema(input.detection!.entity!.attributes.requestQueryParameters)) {
                    input.errors!.push(STRINGS.viewRunbooks.inputErrors.requestQueryParametersSchema);
                }
            }
        } catch (error) {
            console.log("Runbook Inputs: could not parse request query parameters");
            input.errors!.push(STRINGS.viewRunbooks.inputErrors.requestQueryParametersParsing);
        }
    }

    if (inputConfig.current?.vantagePointConfig?.vantagePoint?.id) {
        input.detection!.entity!.attributes.source_id = inputConfig.current.vantagePointConfig.vantagePoint.id;
    }

    return input;
}

/** checks the json to make sure the http request has the correct JSON schema.
 *  @param json the JSON with the http request schema to be validated.
 *  @returns a boolean value which is true if the schema is correct, or false otherwise. */
function isCorrectHttpRequestSchema(json: any): boolean {
    if (json && Object.keys(json).length > 0) {
        for (const key in json) {
            if (!Array.isArray(json[key])) {
                return false;
            }
        }
    }
    return true;
}

/** returns a String with the entity name
 *  @param entity the entity that is stored in the ADX indicators table.
 *  @param runbookConfig the configuration of the runbook.
 *  @returns a String with the entity name. */
export function getEntityName(entity: any, runbookConfig: RunbookConfig): string {
    const attributes: any = getAttributesObject(entity);
    let name = STRINGS.viewRunbooks.unknownEntityName;
    switch (runbookConfig.triggerType) {
        case InputType.INTERFACE:
            name = getEntityAttribute("ipaddr", entity) + ":" + getEntityAttribute("ifindex", entity);
            break;
        case InputType.DEVICE:
            name = getEntityAttribute("ipaddr", entity);
            break;
        case InputType.APPLICATION_LOCATION:
            name = getEntityAttribute("application", entity);
            if (name && typeof name === "object") {
                name = name.name;
            } else if (!name) {
                name = getEntityAttribute("name", entity);
            }
            if (attributes.location && !attributes.network_server?.ipaddr) {
                name += " (" + attributes?.location?.name + ")";
            }
            if (attributes.network_server?.ipaddr) {
                name += " (" + attributes?.network_server?.ipaddr + ")";
            }
            break;
        case InputType.LOCATION:
            name = getEntityAttribute("name", entity);
            break;
        case InputType.APPLICATION:
            name = getEntityAttribute("name", entity);
            break;
    }
    return name;
}

/** returns an array of strings with the entity kinds that can be used to supply the trigger.
 *  @param runbookConfig the configuration of the runbook.
 *  @returns an array of Strings with the entity kinds that can supply the trigger. */
export function getPrimaryEntityKindsForTrigger(runbookConfig: RunbookConfig): Array<string> {
    switch (runbookConfig.triggerType) {
        case InputType.INTERFACE:
            return ["network_interface"];
        case InputType.DEVICE:
            return ["network_device"];
        case InputType.APPLICATION_LOCATION:
            return ["application"];
        case InputType.LOCATION:
            return ["location"];
        case InputType.APPLICATION:
            return ["application"];
        }
    return [];
}

/** returns an array of strings with the entity kinds that can be used to supply the trigger.
 *  @param runbookConfig the configuration of the runbook.
 *  @returns an array of Strings with the entity kinds that can supply the trigger. */
export function getSecondaryEntityKindsForTrigger(runbookConfig: RunbookConfig): Array<string> | undefined{
    switch (runbookConfig.triggerType) {
        case InputType.INTERFACE:
            return undefined;
        case InputType.DEVICE:
            return undefined;
        case InputType.APPLICATION_LOCATION:
            return ["location"];
        case InputType.LOCATION:
            return undefined;
        case InputType.APPLICATION:
            return undefined;
    }
    return [];
}

/** checks whether or net there is a decision branch in the runbook and whether that decision branch
 *      has a condition based on the trigger metric.
 *  @param runbookConfig the configuration of the runbook.
 *  @returns true if there is a decision branch that checks the trigger metric, false otherwise. */
function hasTriggerMetricCondition(runbookConfig: RunbookConfig): boolean {
    if (runbookConfig && runbookConfig.nodes?.length) {
        for (const node of runbookConfig.nodes) {
            if (decisionNodes.includes(node.type) && node.properties) {
                // need to check the expressions
                const expressions = node.properties[DECISION_NODE_EDIT_PROPS.OUTPUT_CASES];
                if (expressionHasTriggerMetric(expressions)) {
                    return true;
                }
            }
        }
    }
    return false;
}

/** checks whether or net there is a visualization node connected to the trigger.
 *  @param runbookConfig the configuration of the runbook.
 *  @returns true if there is a visualization node connected to the trigger, false otherwise. */
function hasVisualizationConnectedToTrigger(runbookConfig: RunbookConfig): boolean {
    if (runbookConfig && runbookConfig.nodes?.length) {
        for (const node of runbookConfig.nodes) {
            if (tableNodes.includes(node.type)) {
                // need to check if its data parent is a trigger
                const parent = getFirstParentOfType(node, runbookConfig.nodes, [...triggerNodes, ...dataOceanNodes, ...transformNodes, ...aggregatorNodes]);
                if (parent && triggerNodes.includes(parent.type)) {
                    return true;
                }
            }
        }
    }
    return false;
}

/** recursively traverse the expressions and their conditions to find any conditions on trigger metrics.
 *  @param expressions the expressions to check for metrics and keys.
 *  @returns a boolean value, true if the expression has a trigger metric condition, false otherwise. */
function expressionHasTriggerMetric(expressions: any): boolean {
    if (expressions?.length) {
        for (const expression of expressions) {
            const conditions = expression?.expression?.conditions;
            if (conditionHasTriggerMetric(conditions)) {
                return true;
            }
        }
    }
    return false;
}

/** recursively traverse the conditions to see if any contain trigger metrics.
 *  @param conditions the conditions to check.
 *  @returns a boolean value, true if the expression has a trigger metric condition, false otherwise. */
 function conditionHasTriggerMetric(conditions: any): boolean {
    if (conditions?.length) {
        for (const condition of conditions) {
            if (condition.key) {
                const key = condition.key;
                switch (condition.category) {
                    case "input.metric": {
                        const operator = condition.op;
                        const triggerOperators: Array<string> = TriggerBaselineOperators;
                        if (triggerOperators.includes(operator)) {
                            return true;
                        }
                        break;
                    }
                    case "trigger":
                        // We either have $INDICATOR.KEY.metric or $INDICATOR.KEY.[some metric name]
                        // It would be nice to make this logic configured from the constants in either the decision 
                        // branch or the logic node editor.
                        if (
                            key && (
                                (key.startsWith("$PRIMARY_INDICATOR.KEY") && key !== "$PRIMARY_INDICATOR.KEY.kind") ||
                                (key.startsWith("$INDICATOR.KEY") && key !== "$INDICATOR.KEY.kind") || 
                                (key.startsWith("$PRIMARY_INDICATOR.DATA")) || 
                                (key.startsWith("$INDICATOR.DATA"))
                            )
                        ) {
                            return true;
                        }
                        break;
                    /* For now we removed direct access to the metric and details.
                    case "entity": {
                        const keyId = key.replace(TRIGGER_PREFIX, '');
                        const triggerKeys: Array<string> = [
                            "primaryIndicator.data.indicator.metric",
                            "primaryIndicator.data.indicator.details.actual_value",
                            "primaryIndicator.data.indicator.details.acceptable_low_value",
                            "primaryIndicator.data.indicator.details.acceptable_high_value"
                        ];
                        if (triggerKeys.includes(keyId)) {
                            return true;
                        }
                        break;
                    }
                    */
                }
            }
            if (condition.conditions && conditionHasTriggerMetric(condition.conditions)) {
                return true;
            }
        }
    }
    return false;
}

