/** This module contains the component for creating the list of runbooks.
 *  @module
 */
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
    Button, ButtonGroup, Intent, Menu, MenuItem, PopoverPosition, Position, Switch
} from '@blueprintjs/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AuthService } from '@tir-ui/azure-auth';
import {
    IconNames, Icon, Table, useLoading, useStateSafePromise, AutocompleteColumnFilterControl,
    CheckboxColumnFilterControl, DateRangeFilterControl, dateRangeFilterFunction, MultiSelectFilterControl
} from '@tir-ui/react-components';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { RunbookConfig, RunbookNode } from 'utils/services/RunbookApiService';
import {
    runbookService, getRunbookMetaData, commentNodes,
    canEditRunbook, canEditRunbooks, isDataOceanNode,
    subflowNodes
} from 'utils/runbooks/RunbookUtils';
import { InputType, Variant } from 'components/common/graph/types/GraphTypes';
import { getUuidV4 } from 'utils/unique-ids/UniqueIds';
import { TableColumnDef } from '@tir-ui/react-components';
import { BasicDialog, DialogState, updateDialogState } from 'components/common/basic-dialog/BasicDialog';
import { getQueryParam, getURL, setQueryParam, useQueryParams } from 'utils/hooks/useQueryParams';
import { getURLPath } from 'config';
import { Classes, Popover2, Popover2InteractionKind, Tooltip2 } from '@blueprintjs/popover2';
import { ReactFlowProvider } from 'react-flow-renderer';
import ReactFlowGraph from 'components/common/graph/react-flow/ReactFlowGraph';
import {
    createSubflowNodes, getGraphDefFromRunbookConfig, updateGraphDefWithErrors
} from 'pages/create-runbook/views/create-runbook/CreateRunbookView';
import { PARAM_NAME } from 'components/enums/QueryParams';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils';
import { ElapsedTimeFormatter } from 'reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter';
import { sortColumnWithTimeData } from 'reporting-infrastructure/utils/commonUtils';
import { Tab, TabbedSubPages } from 'components/common/layout/tabbed-sub-pages/TabbedSubPages';
import {
    NODE_OPTION_VALUE_AP, TRIGGER_OPTION_VALUE, TRIGGER_OPTION_VALUE_OLD
} from 'components/common/graph/editors/data-ocean/DataOceanFilters';
import { CustomProperty } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip';
import {
    GLOBAL_SCOPE, INCIDENT_SCOPE, INCIDENT_SCOPE_BUILTIN_VARIABLES, RUNBOOK_SCOPE_BUILTIN_VARIABLES,
    RUNTIME_SCOPE, VariableCollection, getRuntimeBuiltinVariables, removeTypename
} from 'utils/runbooks/VariablesUtils';
import { IntegrationLibraryService } from 'utils/services/IntegrationLibraryApiService';
import { MappingConfigService } from 'utils/services/MappingConfigApiService';
import { VariableContextByScope } from 'utils/runbooks/RunbookContext.class';
import { isEqual } from 'lodash';
import IncidentRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/node_library.json';
import LifecycleRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/lifecycle_node_library.json';
import SubflowRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/subflow_node_library.json';
import OnDemandRunbookNodeLibrary from 'pages/create-runbook/views/create-runbook/on_demand_node_library.json';
import ExternalRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library_external.json";
import RunbooksContainingSubflowTable from 'pages/create-runbook/views/runbooks-containing-subflow/RunbooksContainingSubflowTable';
import { NodeLibrary, NodeLibrarySpec } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { saveAs } from 'file-saver';
import { useQuery } from 'utils/hooks/useQuery';
import { Query } from 'reporting-infrastructure/data-hub';
import { loader } from 'graphql.macro';
import { RunbookIntegrationDetails } from 'pages/integrations/types/IntegrationTypes';
import { useCustomProperties } from 'utils/hooks/useCustomProperties';
import { useUserPreferences } from "utils/hooks";
import { runRunbookOnDemand } from "pages/runbook-invocations/views/runbook-invocations-list/RunbookInvocationsUtils";
import { APP_ICONS } from 'components/sdwan/enums';
import { STRINGS } from 'app-strings';
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";
import { Version } from 'utils/Version.class';
import { AdditionalInfoOptions } from 'utils/runbooks/RunbookValidationUtils';
import { ImportRunbookModal, ImportRunbookModalActions } from 'pages/runbook-list/modals/ImportRunbookModal/ImportRunbookModal';
import './RunbookList.scss';

// If this value is true a new runbook will be saved to the database when the new button is pressed.  If this
// value is false it will launch the editor without creating a new runbook and the user can then leave the
// editor without saving the runbook.
const saveOnCreateNewRunbook = false;

// If true get rid of the individual columns with icons that handle actions and show the more menu
const showMoreMenu = true;

// If true show the more popup when the user hovers over the ..., otherwise they have to click on it
const morePopupOnHover = false;

/** this interface defines the list of properties passed into the React Component. */
interface RunbookListViewProps {
    /** the runbook variant, incident or lifecycle. */
    variant: Variant;
}

/** this is the node library to use when evaluating subflows. */
const subflowNodeLibrary = new NodeLibrary(
    SubflowRunbookNodeLibrary as NodeLibrarySpec
);

/** Renders the runbook list view.
 *  @returns JSX with the runbook list view component.*/
export function RunbookListView(props: RunbookListViewProps): JSX.Element {
    const { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.devel] });
    // Determines whether debug information should be displayed
    const showDevelControls = params[PARAM_NAME.devel] === 'true';

    const [operationCount, setOperationCount] = useState<number>(0);
    const userPreferences = useUserPreferences({listenOnlyTo: {onDemandRunbookInputs: {runbooks: {}}, subflows: {isMultipleOutputsEnabled: false}, runbookImportExportMethod:{useSimpleImportExportRunbook: false}}});
    const useApiImportExportRunbook = Boolean(userPreferences?.runbookImportExportMethod?.useSimpleImportExportRunbook !== true);
    const [runRunbookDialogState, setRunRunbookDialogState] = useState<any>({ showDialog: false, title: "Run runbook on-demand", loading: false, dialogContent: null, dialogFooter: null });
    const history = useHistory();
    const [runbooksList, setRunbooksList] = useState<Array<RunbookConfig>>();
    const error = useRef(false);
    const deleteId = useRef('');
    const [executeSafely] = useStateSafePromise();
    const userName = AuthService.getUserAccount()?.getUsername() || '';
    const runbookImportModalRef = useRef();

    let InitNodeLibrary: NodeLibrarySpec;
    switch (props.variant) {
        case Variant.INCIDENT:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.LIFECYCLE:
            InitNodeLibrary = LifecycleRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.SUBFLOW:
            InitNodeLibrary = SubflowRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.ON_DEMAND:
            InitNodeLibrary = OnDemandRunbookNodeLibrary as NodeLibrarySpec;
            break;
        case Variant.EXTERNAL:
            InitNodeLibrary = ExternalRunbookNodeLibrary as NodeLibrarySpec;
            break;
        default:
            InitNodeLibrary = IncidentRunbookNodeLibrary as NodeLibrarySpec;
    }
    const nodeLibrary = useRef<NodeLibrary>(
        new NodeLibrary(InitNodeLibrary as NodeLibrarySpec)
    );

    const [subflows, setNodeRedSubflows] = useState<Array<RunbookNode>>([]);

    const incidentVariablesQuery = useQuery({
        name: 'IncidentVariables',
        query: new Query(
            loader('../../../../utils/hooks/incident-variables-query.graphql')
        ),
    });

    const customPropertiesQuery = useCustomProperties({});
    const customProperties = useMemo(() => {
        return customPropertiesQuery.data || [];
    }, [customPropertiesQuery.data]);

    // this is only needed for node red however, we cannot show the graph for
    // node red because we do not have the whole flow.
    useEffect(
        () => {
            async function fetchMyAPI() {
                let newSubflows: Array<RunbookNode> = [];
                try {
                    const subflowVariant = Variant.SUBFLOW;
                    const retFlows = await runbookService.getRunbooks(subflowVariant, true);
                    if (retFlows) {
                        for (const flow of retFlows) {
                            newSubflows.push(flow);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
                newSubflows = createSubflowNodes(
                    newSubflows,
                    subflowNodeLibrary,
                    integrationsCache.current
                );
                setNodeRedSubflows(newSubflows);
            }
            fetchMyAPI();

            incidentVariablesQuery.run();
            /*****************************************************************************************************************************************/
            /* This needs to be a async query                                                                                                        */
            /*****************************************************************************************************************************************/
            //setNodeRedSubflows(createSubflowNodes(SubFlows as RunbookConfig[], subflowNodeLibrary));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

     /** Retrieve the integrations list. */
    const [integrations, setIntegrations] = useState<RunbookIntegrationDetails[] | undefined>(undefined);
    const integrationsCache = useRef<RunbookIntegrationDetails[] | undefined>();
    useEffect(() => {
        const integrationsPromise = new Promise<RunbookIntegrationDetails[]>(async (resolve, reject) => {
            try {
                const newIntegrations = await IntegrationLibraryService.getRunbookIntegrations();
                resolve(newIntegrations as RunbookIntegrationDetails[]);    
            } catch (error) {
                reject(error);
            }
        });
        executeSafely(integrationsPromise).then(
            (integrations) => {
                integrationsCache.current = integrations;
                setIntegrations(integrations);
            }, 
            () => {
                integrationsCache.current = integrations;
                setIntegrations([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getRunbookRenders = useCallback(
        (runbook: RunbookConfig, operationCount) => {
            const {
                name, description, trigger, createdTime, lastUpdatedTime, createdByUser, lastUpdatedUser
            } = getRunbookMetaData(runbook);
            const enabled: boolean = Boolean(runbook.isReady);
            let isInvalid: boolean = !runbook.isValidated;
            let oldSubflows: boolean = false;
            const moreMenuItems: Array<JSX.Element> = [];
            const graphDef = getGraphDefFromRunbookConfig(
                nodeLibrary.current, runbook, subflows, integrationsCache.current
            );
            try {
                const variables: VariableContextByScope = {
                    [RUNTIME_SCOPE]: getRuntimeVariables(runbook, runbook.variant || Variant.INCIDENT),
                    [INCIDENT_SCOPE]:
                        runbook.triggerType === InputType.WEBHOOK
                            ? { primitiveVariables: [], structuredVariables: [] }
                            : {
                                    primitiveVariables: [
                                        ...(removeTypename(
                                            incidentVariablesQuery?.data
                                        )?.incidentVariables?.variables
                                            ?.primitiveVariables || []),
                                        ...INCIDENT_SCOPE_BUILTIN_VARIABLES,
                                    ],
                                    structuredVariables: [
                                        ...(removeTypename(
                                            incidentVariablesQuery?.data
                                        )?.incidentVariables?.variables
                                            ?.structuredVariables || []),
                                    ],
                              },
                    [GLOBAL_SCOPE]: { primitiveVariables: [], structuredVariables: [] },
                };
                updateGraphDefWithErrors(
                    nodeLibrary.current, graphDef, runbook, variables, customProperties, subflows, props.variant
                );
                oldSubflows = graphDef.additionalInformation === AdditionalInfoOptions.OLD_SUBFLOW_WARNING;
                if (!isInvalid) {
                    if (graphDef.errors) {
                        isInvalid = true;
                    } else if (graphDef.nodes) {
                        for (const node of graphDef.nodes) {
                            if (node.errors) {
                                isInvalid = true;
                                break;
                            }
                        }
                    }
                }
            } catch (error) {
                isInvalid = true;
            }
            moreMenuItems.push(
                <MenuItem
                    disabled={!canEditRunbook(runbook)} text={STRINGS.runbooks.more.editMenuItem}
                    active={false} key={'edit'}
                    onClick={() =>
                        history.push(
                            getURL(getURLPath('create-runbook'), {
                                [PARAM_NAME.rbConfigId]: runbook.id,
                                [PARAM_NAME.rbConfigNm]: runbook.name,
                                [PARAM_NAME.variant]: props.variant,
                            })
                        )
                    }
                />
            );
            moreMenuItems.push(
                <MenuItem
                    disabled={false} text={STRINGS.runbooks.more.exportMenuItem}
                    active={false} key={'export'}
                    onClick={() =>
                        exportContent(runbook.id || "", setDialogState, props.variant, useApiImportExportRunbook)
                    }
                />
            );
            moreMenuItems.push(
                <MenuItem
                    disabled={!canEditRunbooks()} text={STRINGS.runbooks.more.duplicateMenuItem}
                    active={false} key={'duplicate'}
                    onClick={() =>
                        duplicateRunbook(runbook.id || "", props.variant, setDialogState, operationCount, setOperationCount)
                    }
                />
            );
            moreMenuItems.push(
                <MenuItem
                    disabled={!canEditRunbook(runbook)} text={STRINGS.runbooks.more.deleteMenuItem}
                    active={false} key={'delete'}
                    onClick={() => {
                        deleteId.current = runbook.id || "";
                        handleDelete(
                            deleteId.current, enabled, setDialogState, operationCount,
                            setOperationCount, runbook, props.variant, history
                        );
                    }}
                />
            );
            (props.variant === Variant.SUBFLOW && runbook?.otherVersions?.length) && moreMenuItems.push(<Menu.Divider />);
            (props.variant === Variant.SUBFLOW && runbook?.otherVersions?.length) && moreMenuItems.push(
                <MenuItem
                    disabled={!canEditRunbook(runbook)} text={STRINGS.runbooks.more.editVersionMenuItem}
                    active={false} key={'edit-versions'}
                    children={runbook.otherVersions.map(versionInfo => <MenuItem
                        disabled={!canEditRunbook(runbook)} text={Version.parse(versionInfo.version || "").toString()}
                        active={false} key={'edit-version-' + versionInfo.version}
                        onClick={() =>
                            history.push(
                                getURL(getURLPath('create-runbook'), {
                                    [PARAM_NAME.rbConfigId]: versionInfo.id,
                                    [PARAM_NAME.rbConfigNm]: versionInfo.name,
                                    [PARAM_NAME.variant]: props.variant,
                                })
                            )
                        }
                    />)}
                 />
            );
            (props.variant === Variant.SUBFLOW && runbook?.otherVersions?.length) && moreMenuItems.push(
                <MenuItem
                    disabled={!canEditRunbook(runbook)} text={STRINGS.runbooks.more.deleteVersionMenuItem}
                    active={false} key={'delete-versions'}
                    children={runbook.otherVersions.map(versionInfo => <MenuItem
                        disabled={!canEditRunbook(runbook)} text={Version.parse(versionInfo.version || "").toString()}
                        active={false} key={'edit-version-' + versionInfo.version}
                        onClick={() => {
                            deleteId.current = versionInfo.id || "";
                            handleDelete(
                                deleteId.current, enabled, setDialogState, operationCount,
                                setOperationCount, runbook, props.variant, history
                            );
                        }}
                    />)}
                 />
            );
            props.variant !== Variant.SUBFLOW &&
                moreMenuItems.push(
                    <MenuItem
                        disabled={false} text={STRINGS.runbooks.more.testMenuItem}
                        active={false} key={'test'}
                        onClick={() =>
                            history.push(
                                getURL(getURLPath('view-runbook'), {
                                    [PARAM_NAME.rbConfigId]: runbook.id,
                                    [PARAM_NAME.rbConfigNm]: runbook.name,
                                    [PARAM_NAME.variant]: runbook.variant,
                                })
                            )
                        }
                    />
                );
            (props.variant === Variant.INCIDENT || props.variant === Variant.EXTERNAL || props.variant === Variant.ON_DEMAND) && 
                moreMenuItems.push(
                    <MenuItem
                        disabled={false} text={STRINGS.runbooks.more.previewMenuItem}
                        active={false} key={'preview'}
                        onClick={() =>
                            history.push(
                                getURL(getURLPath('view-runbook'), {
                                    [PARAM_NAME.rbConfigId]: runbook.id,
                                    [PARAM_NAME.rbConfigNm]: runbook.name,
                                    [PARAM_NAME.variant]: runbook.variant,
                                    [PARAM_NAME.rbViewMode]: 'preview',
                                })
                            )
                        }
                    />
                );
            props.variant === Variant.ON_DEMAND && 
                moreMenuItems.push(
                    <MenuItem
                        disabled={false} text={STRINGS.runbooks.more.manageSchedulesItem}
                        active={false} key={'schedules'}
                        onClick={() =>
                            history.push(
                                getURL(getURLPath('runbook-schedules'), {
                                    searchType: SEARCH_TYPE.runbookschedules
                                })
                            )
                        }
                    />
            );
            
            return {
                ...runbook,
                operationCount,
                name,
                enabled,
                createdTime,
                createdByUser,
                description,
                trigger,
                factory: runbook.isFactory,
                lastUpdatedTime,
                lastUpdatedBy:
                    lastUpdatedUser !== ''
                        ? lastUpdatedUser
                        : STRINGS.runbooks.systemText,
                edit: (
                    <Button
                        aria-label="runbook-edit-button"
                        icon={IconNames.EDIT}
                        minimal
                        className="runbook-action-icon"
                        disabled={!canEditRunbook(runbook)}
                        onClick={(e) => {
                            history.push(
                                getURL(getURLPath('create-runbook'), {
                                    [PARAM_NAME.rbConfigId]: runbook.id,
                                    [PARAM_NAME.rbConfigNm]: runbook.name,
                                    [PARAM_NAME.variant]: props.variant,
                                })
                            );
                        }}
                    />
                ),
                duplicate: (
                    <Button
                        aria-label="runbook-duplicate-button"
                        icon={IconNames.DUPLICATE}
                        minimal
                        className="runbook-action-icon"
                        disabled={!canEditRunbook(runbook)}
                        onClick={async (e) => {
                            duplicateRunbook(
                                runbook.id || "",
                                props.variant,
                                setDialogState,
                                operationCount,
                                setOperationCount
                            );
                        }}
                    />
                ),
                delete: (
                    <Button
                        aria-label="runbook-delete-button"
                        icon={IconNames.DELETE}
                        minimal
                        className="runbook-action-icon"
                        disabled={!canEditRunbook(runbook)}
                        onClick={(e) => {
                            deleteId.current = runbook.id || "";
                            handleDelete(
                                deleteId.current,
                                enabled,
                                setDialogState,
                                operationCount,
                                setOperationCount,
                                runbook,
                                props.variant,
                                history
                            );
                        }}
                    />
                ),
                errors: isInvalid,
                oldSubflows: oldSubflows,
                more: (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Popover2
                            position={Position.BOTTOM_RIGHT}
                            interactionKind={
                                morePopupOnHover
                                    ? Popover2InteractionKind.HOVER
                                    : Popover2InteractionKind.CLICK
                            }
                            content={<Menu>{moreMenuItems}</Menu>}
                        >
                            <Button
                                aria-label="runbook-more-button"
                                icon={IconNames.MORE}
                                minimal
                                className="runbook-action-icon"
                                disabled={false}
                                onClick={(e) => {}}
                            />
                        </Popover2>
                    </div>
                ),
                subComponent: (
                    <div className="template-container d-inline-block w-100">
                        <div className="runbook-actions-holder mt-1">
                            <ButtonGroup>
                                <Button
                                    small
                                    aria-label="runbook-edit-button"
                                    icon={IconNames.EDIT}
                                    className="runbook-action-icon"
                                    disabled={
                                        !(
                                            canEditRunbooks() &&
                                            canEditRunbook(runbook)
                                        )
                                    }
                                    onClick={(e) => {
                                        history.push(
                                            getURL(
                                                getURLPath('create-runbook'),
                                                {
                                                    [PARAM_NAME.rbConfigId]: runbook.id,
                                                    [PARAM_NAME.rbConfigNm]: runbook.name,
                                                    [PARAM_NAME.variant]: props.variant,
                                                }
                                            )
                                        );
                                    }}
                                    text={STRINGS.runbooks.more.editMenuItem}
                                />
                                <Button
                                    small
                                    aria-label="runbook-export-button"
                                    icon={IconNames.EXPORT}
                                    onClick={(e) => {
                                        exportContent(
                                            runbook.id || "",
                                            setDialogState,
                                            props.variant,
                                            useApiImportExportRunbook
                                        );
                                    }}
                                    text={STRINGS.runbooks.more.exportMenuItem}
                                />
                                <Button
                                    small
                                    aria-label="runbook-duplicate-button"
                                    icon={IconNames.DUPLICATE}
                                    disabled={!canEditRunbooks()}
                                    onClick={(e) => {
                                        duplicateRunbook(
                                            runbook.id || "",
                                            props.variant,
                                            setDialogState,
                                            operationCount,
                                            setOperationCount
                                        );
                                    }}
                                    text={
                                        STRINGS.runbooks.more.duplicateMenuItem
                                    }
                                />
                                <Button
                                    small
                                    aria-label="runbook-delete-button"
                                    icon={IconNames.TRASH}
                                    disabled={
                                        !(
                                            canEditRunbooks() &&
                                            canEditRunbook(runbook)
                                        )
                                    }
                                    onClick={(e) => {
                                        deleteId.current = runbook.id || "";
                                        handleDelete(
                                            deleteId.current,
                                            enabled,
                                            setDialogState,
                                            operationCount,
                                            setOperationCount,
                                            runbook,
                                            props.variant,
                                            history
                                        );
                                    }}
                                    text={STRINGS.runbooks.more.deleteMenuItem}
                                />
                            </ButtonGroup>
                        </div>
                        {description && (
                            <div className="p-3">{description}</div>
                        )}
                        <div className="overflow-auto">
                            <ReactFlowProvider>
                                <ReactFlowGraph
                                    nodeLibrary={nodeLibrary.current}
                                    graphDef={graphDef}
                                    subflows={subflows}
                                    static={true}
                                    showToolbar={false}
                                    showErrors={true}
                                    fitContainerHeightToContent
                                    fitContainerWidthToContent
                                    centerOnRender
                                    integrations={integrationsCache.current}
                                    variant={props.variant}
                                />
                            </ReactFlowProvider>
                        </div>
                    </div>
                ),
                action: (
                    <div className="d-flex justify-content-end">
                        <Button type="button" intent={Intent.PRIMARY} aria-label="run-runbook" className="mr-2" 
                            onClick={(e) => { 
                                runRunbookOnDemand(setRunRunbookDialogState, runbook.id || "", userPreferences?.onDemandRunbookInputs, history);
                                e.stopPropagation(); }
                            }
                            text={STRINGS.runbooks.more.runRunbook}
                        />
                    </div>
                )
            };
        },
        [subflows, props.variant, incidentVariablesQuery?.data, customProperties, history, userPreferences?.onDemandRunbookInputs, useApiImportExportRunbook]
    );

    const setDOUtilsInitialized = useState(false)[1];

    const [rawRunbooks, setRawRunbooks] = useState<RunbookNode[]>([]);
    const fetchRunbooks = useCallback(
        () => {
            return executeSafely(async () => {
                try {
                    await DataOceanUtils.init();
                    setDOUtilsInitialized(true);
                    const runbookVariant =
                        props.variant === Variant.SUBFLOW
                            ? Variant.SUBFLOW
                            : props.variant;
                    const runbooks: any = await runbookService.getRunbooks(runbookVariant);
                    setRawRunbooks(runbooks);
                } catch (ex) {
                    error.current = true;
                    console.error(ex);
                    setRawRunbooks([]);
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [operationCount]
    );
    const [loading] = useLoading(fetchRunbooks);

    useEffect(() => {
        try {
            let filteredRunbooks: any[] = rawRunbooks.filter((item) => {
                return ![Variant.INCIDENT, Variant.EXTERNAL].includes(item.variant) || 
                    (props.variant === Variant.EXTERNAL && item.triggerType === InputType.WEBHOOK) ||
                    (props.variant === Variant.INCIDENT && item.triggerType !== InputType.WEBHOOK);
            });
            setRunbooksList(
                filteredRunbooks.map((runbook) =>
                    getRunbookRenders(runbook, operationCount)
                )
            );
        } catch (ex) {
            error.current = true;
            console.error(ex);
            setRunbooksList([]);
        }
    }, [operationCount, getRunbookRenders, rawRunbooks, props.variant]);

    let columns: Array<TableColumnDef> = [];
    if (props.variant === Variant.ON_DEMAND) {
        columns = [
            {
                id: 'name',
                Header: STRINGS.runbooks.runbookTextForVariantUc[props.variant],
                accessor: 'name',
                sortable: true,
                showFilter: true,
                multiValueFilter: true,
                style: { maxWidth: '250px', overflow: 'hidden' },
                Cell: (row) => (
                    <WrapInTooltip tooltip={row.value}>
                        <span>
                            {row.value}
                            {row?.row?.original?.isScheduled && <Icon icon={APP_ICONS.TIMELINE_EVENTS} className="ml-2" />}
                        </span>
                    </WrapInTooltip>
                )
            },
            {
                id: 'description',
                Header: STRINGS.runbooks.columns.description,
                accessor: 'description',
                sortable: true,
                showFilter: true,
                style: { maxWidth: '200px' },
                headerClassName: 'text-nowrap d-none d-xl-table-cell',
                className: 'd-none d-xl-table-cell',
            },
            {
                id: 'factory',
                Header: STRINGS.runbooks.columns.factory,
                accessor: 'factory',
                sortable: true,
                sortFunction: (a, b) => {
                    const factoryA = a.factory;
                    const factoryB = b.factory;
                    return factoryA && !factoryB
                        ? 1
                        : factoryB && !factoryA
                        ? -1
                        : 0;
                },
                showFilter: true,
                filterControl: CheckboxColumnFilterControl,
                Cell: (tableInstance, cellModel) => {
                    return tableInstance.value ? (
                        <Icon iconSize={16} icon={IconNames.TICK} />
                    ) : (
                        <span />
                    );
                },
            },
            {
                id: 'createdByUser',
                Header: STRINGS.runbooks.columns.createdBy,
                accessor: 'createdByUser',
                sortable: true,
                showFilter: true,
                style: {
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                filterControl: AutocompleteColumnFilterControl,
                headerClassName:
                    'text-nowrap d-none d-xl-table-cell special-responsive-column',
                className:
                    'display-9 d-none d-xl-table-cell special-responsive-column',
            },
            {
                id: 'lastUpdatedTime',
                Header: STRINGS.runbooks.columns.lastUpdateTime,
                accessor: 'lastUpdatedTime',
                sortable: true,
                showFilter: true,
                filterControl: DateRangeFilterControl,
                filterFunction: dateRangeFilterFunction,
                sortFunction: sortColumnWithTimeData,
                multiValueFilter: true,
                sortDescFirst: true,
                formatter: (row) =>
                    row.lastUpdatedTime ? (
                        <ElapsedTimeFormatter
                            time={row.lastUpdatedTime}
                            showOriginal
                            suffix={STRINGS.incidents.elapsedSuffix}
                        />
                    ) : (
                        ''
                    ),
                headerClassName: 'text-nowrap d-none d-lg-table-cell',
                className: 'display-9 d-none d-lg-table-cell',
            },
            {
                Header: '',
                id: 'action',
                accessor: 'action',
                headerClassName: 'w-min-0-5',
            },
            {
                Header: '',
                id: 'more',
                accessor: 'more',
                headerClassName: 'w-min-0-5',
                style: { minWidth: '24px' },
            },
        ];
    } else {
        columns = [
            {
                id: 'name',
                Header: STRINGS.runbooks.runbookTextForVariantUc[props.variant],
                accessor: 'name',
                sortable: true,
                showFilter: true,
                multiValueFilter: true,
                style: { maxWidth: '250px', overflow: 'hidden' },
                Cell: (row) => (
                    <WrapInTooltip tooltip={row.value}>
                        <span>{row.value}</span>
                    </WrapInTooltip>
                ),
            },
            {
                id: 'description',
                Header: STRINGS.runbooks.columns.description,
                accessor: 'description',
                sortable: true,
                showFilter: true,
                style: { maxWidth: '200px', overflow: 'hidden' },
                headerClassName: 'text-nowrap d-none d-xl-table-cell',
                className: 'd-none d-xl-table-cell',
                Cell: (row) => (
                    <WrapInTooltip tooltip={row.value}>
                        <span className="display-10 d-inline-block text-jusitfy">
                            {row.value}
                        </span>
                    </WrapInTooltip>
                ),
            },
            {
                id: 'trigger',
                Header: STRINGS.runbooks.columns.trigger,
                accessor: 'trigger',
                sortable: true,
                showFilter: true,
                filterControl: MultiSelectFilterControl,
                multiValueFilter: true,
                className: 'display-9',
            },
            {
                id: 'factory',
                Header: STRINGS.runbooks.columns.factory,
                accessor: 'factory',
                sortable: true,
                sortFunction: (a, b) => {
                    const factoryA = a.factory;
                    const factoryB = b.factory;
                    return factoryA && !factoryB
                        ? 1
                        : factoryB && !factoryA
                        ? -1
                        : 0;
                },
                showFilter: true,
                filterControl: CheckboxColumnFilterControl,
                Cell: (tableInstance, cellModel) => {
                    return tableInstance.value ? (
                        <Icon iconSize={16} icon={IconNames.TICK} />
                    ) : (
                        <span />
                    );
                },
            },
            {
                id: 'createdByUser',
                Header: STRINGS.runbooks.columns.createdBy,
                accessor: 'createdByUser',
                sortable: true,
                showFilter: true,
                style: {
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                filterControl: AutocompleteColumnFilterControl,
                headerClassName:
                    'text-nowrap d-none d-xl-table-cell special-responsive-column',
                className:
                    'display-9 d-none d-xl-table-cell special-responsive-column',
            },
            // {
            //     id: "createdTime",
            //     Header: STRINGS.runbooks.columns.createdTime,
            //     accessor: "createdTime",
            //     sortable: true,
            //     showFilter: true,
            //     filterControl: DateRangeFilterControl,
            //     filterFunction: dateRangeFilterFunction,
            //     multiValueFilter: true,
            //     formatter: row => (row.createdTime? <ElapsedTimeFormatter time={row.createdTime} showOriginal suffix={STRINGS.incidents.elapsedSuffix}/> : ""),
            //     sortFunction: sortColumnWithTimeData,
            //     sortDescFirst: true,
            //     headerClassName: "text-nowrap d-none d-xl-table-cell special-responsive-column",
            //     className: "display-9 d-none d-xl-table-cell special-responsive-column",
            // },
            {
                id: 'lastUpdatedBy',
                Header: STRINGS.runbooks.columns.lastUpdateBy,
                accessor: 'lastUpdatedBy',
                sortable: true,
                showFilter: true,
                style: {
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                filterControl: AutocompleteColumnFilterControl,
                headerClassName: 'text-nowrap d-none d-xl-table-cell',
                className: 'display-9 d-none d-xl-table-cell',
            },
            {
                id: 'lastUpdatedTime',
                Header: STRINGS.runbooks.columns.lastUpdateTime,
                accessor: 'lastUpdatedTime',
                sortable: true,
                showFilter: true,
                filterControl: DateRangeFilterControl,
                filterFunction: dateRangeFilterFunction,
                sortFunction: sortColumnWithTimeData,
                multiValueFilter: true,
                sortDescFirst: true,
                formatter: (row) =>
                    row.lastUpdatedTime ? (
                        <ElapsedTimeFormatter
                            time={row.lastUpdatedTime}
                            showOriginal
                            suffix={STRINGS.incidents.elapsedSuffix}
                        />
                    ) : (
                        ''
                    ),
                headerClassName: 'text-nowrap d-none d-lg-table-cell',
                className: 'display-9 d-none d-lg-table-cell',
            },
            {
                id: 'enabled',
                Header: () => (
                    <span>
                        Allow
                        <br />
                        Automation
                    </span>
                ),
                accessor: 'enabled',
                sortable: true,
                sortFunction: (a, b) => {
                    const enabledA = a.isReady;
                    const enabledB = b.isReady;
                    return enabledA && !enabledB
                        ? 1
                        : enabledB && !enabledA
                        ? -1
                        : 0;
                },
                formatter: (row) => {
                    return (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Switch
                                checked={row.enabled}
                                disabled={
                                    !canEditRunbooks() ||
                                    (row.errors && !row.isReady)
                                }
                                className="mb-0"
                                innerLabel={
                                    row.enabled
                                        ? STRINGS.runbooks.onText
                                        : STRINGS.runbooks.offText
                                }
                                onChange={() =>
                                    setRunbookEnabled(
                                        row.id,
                                        !row.enabled,
                                        setDialogState,
                                        row.operationCount,
                                        setOperationCount,
                                        props.variant
                                    )
                                }
                            />
                        </div>
                    );
                },
            },
            ...(props.variant === Variant.SUBFLOW ? [{
                id: 'version',
                Header: STRINGS.runbooks.columns.version,
                accessor: 'version',
                formatter: (row) => {
                    const version: Version = Version.parse(row.version);
                    return version.toString();
                },
            }] : []),
            {
                id: 'errors',
                Header: '',
                accessor: 'errors',
                formatter: (row) =>
                            <>
                                {row.oldSubflows ? 
                                    <Tooltip2 className={Classes.TOOLTIP2_INDICATOR + " border-0 ml-2"} intent="primary" 
                                        content={
                                            <div style={{maxWidth: "200px"}}>{STRINGS.runbooks.subflowVersionWarning}</div>
                                        } interactionKind="hover" position={PopoverPosition.RIGHT}
                                    >
                                        <Icon
                                            aria-label="runbook-old-subflow-button"
                                            icon={IconNames.WARNING_SIGN}
                                            intent={Intent.WARNING}
                                        />
                                    </Tooltip2>
                                : 
                                    <></>
                                }
                                {row.errors ? 
                                    <Icon
                                        aria-label="runbook-error-button"
                                        icon={IconNames.ERROR}
                                        intent={Intent.DANGER}
                                    />
                                : 
                                    <></>
                                }
                            </>,
            },
        ];

        if (props.variant === Variant.SUBFLOW) {
            columns.splice(7, 1);
            columns.splice(2, 1);
        }

        if (!showMoreMenu) {
            columns.push({
                id: 'edit',
                Header: '',
                accessor: 'edit',
                style: { minWidth: '16px' },
                className: 'runbook-action-cell',
            });
            columns.push({
                id: 'duplicate',
                Header: '',
                accessor: 'duplicate',
                style: { minWidth: '16px' },
                className: 'runbook-action-cell',
            });
            columns.push({
                id: 'delete',
                Header: '',
                accessor: 'delete',
                style: { minWidth: '16px' },
                className: 'runbook-action-cell',
            });
        } else {
            columns.push({
                id: 'more',
                Header: '',
                accessor: 'more',
                style: { minWidth: '24px' },
            });
        }

        if (props.variant === Variant.LIFECYCLE) {
            columns = columns.filter(function (obj) {
                return obj.id !== 'factory';
            });
        }
    }

    const [dialogState, setDialogState] = useState<any>({
        showDialog: false, title: 'My Dialog', loading: false,
        dialogContent: null, dialogFooter: null,
    });

    const [tabId, setTabId] = useState<string>('all');
    const [selectedIdsByTab, setSelectedIdsByTab] = useState<{
        [x: string]: string[];
    }>({});

    const runbooksForEachTab: { [index: string]: RunbookConfig[] } = {
        all: [], scheduled: [], mine: [], automation: [],
        inerror: [], factory: [],
    };
    if (runbooksList) {
        for (const runbook of runbooksList) {
            runbooksForEachTab.all.push(runbook);
            if (
                runbook.createdByUser.toLowerCase() === userName.toLowerCase()
            ) {
                runbooksForEachTab.mine.push(runbook);
            }
            if (runbook.isReady) {
                runbooksForEachTab.automation.push(runbook);
            }
            if (runbook.errors) {
                runbooksForEachTab.inerror.push(runbook);
            }
            if (runbook.factory) {
                runbooksForEachTab.factory.push(runbook);
            }
            if (runbook.isScheduled) {
                runbooksForEachTab.scheduled.push(runbook);
            }
        }
    }

    const [filterParams, setFilterParams] = useState(
        (getQueryParam('filters') && JSON.parse(getQueryParam('filters'))) || []
    );

    function getTab({ title, runbooks, tabKey = 'all', showAlways = false }) {
        const sortByFromURL = getQueryParam('sortby');
        const [sortId, direction] = (
            sortByFromURL || 'lastUpdatedTime:desc'
        ).split(':');
        const showTab = showAlways || runbooks.length > 0;

        if (!showTab) {
            setQueryParam('tab', 'all');
        }
        const selectedItems: Array<string> = [];
        if (runbooks?.length) {
            const selectedIds = selectedIdsByTab[tabKey]
                ? selectedIdsByTab[tabKey]
                : [];
            for (let itemIndex = 0; itemIndex < runbooks.length; itemIndex++) {
                if (selectedIds.includes(runbooks[itemIndex].id || '')) {
                    selectedItems.push(itemIndex.toString());
                }
            }
        }
        const handleRunbookSelectionChange = (rows) => {
            //setSelectedId(rows?.length === 1 ? rows[0].id : null);
            //alert("handle selection change: " + ids.join(", "));
            const ids: string[] = rows?.map((row) => row.id) || [];
            const selectedIds = selectedIdsByTab[tabKey]
                ? selectedIdsByTab[tabKey]
                : [];
            if (
                ids.length !== selectedIds.length ||
                !isEqual(ids, selectedIds)
            ) {
                setSelectedIdsByTab({ ...selectedIdsByTab, [tabKey]: ids });
            }
        };
        return (
            showTab && (
                <Tab id={tabKey} title={title} count={runbooks.length}>
                    <Table
                        data-testid="RunbookList"
                        id="runbookList"
                        key={'runbookList-' + tabKey}
                        className="runbook-list-table display-9"
                        columns={columns}
                        columnDefinitionDefaults={{
                            headerClassName: 'text-nowrap',
                        }}
                        data={runbooks || []}
                        defaultPageSize={20}
                        //onRowClick={(e) => {setSelectedId(!e.ctrlKey ? e.id : null)}}
                        enableSelection={showDevelControls || !showMoreMenu}
                        selectOnRowClick={false}
                        onSelectionChange={(rows: Array<any>) => {
                            handleRunbookSelectionChange(rows);
                        }}
                        selectedRows={selectedItems}
                        sortBy={[{ id: sortId, desc: direction === 'desc' }]}
                        filterValues={filterParams.map((filter) => ({
                            column: filter.id,
                            value: filter.value,
                        }))}
                        onFiltersChange={(newFilterBy) => {
                            if (newFilterBy.length) {
                                setQueryParam('filters', newFilterBy);
                                setFilterParams(newFilterBy);
                            }
                        }}
                        onSortByChange={([newSortBy]) =>
                            setQueryParam(
                                'sortby',
                                newSortBy.id +
                                    ':' +
                                    (newSortBy.desc ? 'desc' : 'asc')
                            )
                        }
                        expandOnlyOneRow
                    />
                </Tab>
            )
        );
    }
    /** Handle Open Import Modal */
    function handleOpenImportModal(variant: Variant) {
		if (!runbookImportModalRef?.current) {
			return;
		}

		const modal  = runbookImportModalRef?.current as ImportRunbookModalActions;

		modal.setVariant(variant);
		modal.handleOpen();
	}

    /**
     * 
     */
    /* istanbul ignore next */
    function handleImportFileFromJSON() {
        importContent(
            nodeLibrary.current,
            setDialogState,
            operationCount,
            setOperationCount,
            subflows,
            removeTypename(
                incidentVariablesQuery?.data
            ),
            customProperties,
            props.variant
        );
    }

    return (
        <>
            <BasicDialog
                dialogState={dialogState}
                onClose={() =>
                    setDialogState(updateDialogState(dialogState, false, false, []))
                }
                className="export-subflow-dialog"
            />
            <BasicDialog 
                dialogState={runRunbookDialogState} 
                onClose={() => setRunRunbookDialogState(updateDialogState(runRunbookDialogState, false, false, []))} 
                className="runbook-on-demand-inputs-dialog" 
            />
            <ImportRunbookModal 
                ref={runbookImportModalRef} 
                refreshData = {() => { setOperationCount(operationCount + 1); }}
            />
            <DataLoadFacade 
                loading={loading} data={runbooksList} error={error.current} showContentsWhenLoading={true}
            >
                <>
                    <div className="runbooklist-toolbar">
                        <ButtonGroup>
                        <Button
                            aria-label="runbook-new-button"
                            icon={IconNames.ADD}
                            // minimal
                            // className="mr-2"
                            onClick={(e) => {
                                createNewRunbook(
                                    history,
                                    setDialogState,
                                    props.variant
                                );
                            }}
                            text={STRINGS.runbooks.newBtnText}
                        />
                        <Button
                            aria-label="runbook-import_button"
                            icon={IconNames.IMPORT}
                            // minimal
                            // className="mr-2"
                            onClick={(e) => {
                                useApiImportExportRunbook ?
                                    handleOpenImportModal(props.variant):
                                    handleImportFileFromJSON();
                            }}
                            text={STRINGS.runbooks.importBtnText}
                            disabled={!canEditRunbooks()}
                        />
                        {!showMoreMenu && (
                            <Button
                                aria-label="runbook-export-button"
                                icon={IconNames.EXPORT}
                                // minimal
                                disabled={selectedIdsByTab[tabId]?.length !== 1}
                                // className="mr-2"
                                onClick={(e) => {
                                    if (selectedIdsByTab[tabId]?.length) {
                                        exportContent(
                                            selectedIdsByTab[tabId][0],
                                            setDialogState,
                                            props.variant,
                                            useApiImportExportRunbook
                                        );
                                    }
                                }}
                            />
                        )}
                        {showDevelControls && (
                            <Button
                                aria-label="runbook-delete-button"
                                icon={IconNames.DELETE}
                                // minimal
                                // className="mr-2"
                                onClick={(e) => {
                                    if (selectedIdsByTab[tabId]?.length) {
                                        handleMultiDelete(
                                            selectedIdsByTab[tabId],
                                            false,
                                            setDialogState,
                                            operationCount,
                                            setOperationCount,
                                            setSelectedIdsByTab,
                                            props.variant
                                        );
                                    }
                                }}
                                text={STRINGS.runbooks.deleteBtnText}
                                disabled={
                                    !canEditRunbooks() ||
                                    !Boolean(selectedIdsByTab[tabId]?.length)
                                }
                            />
                        )}
                        </ButtonGroup>
                    </div>
                    <TabbedSubPages
                        className="runbooklist-tabs"
                        userPrefsStateKey="rb_tab"
                        notifyActiveTab={(tabId: any) => {
                            if (showDevelControls) {
                                setTabId(tabId);
                            }
                        }}
                    >
                        {getTab({
                            title:
                                props.variant === Variant.INCIDENT || props.variant === Variant.EXTERNAL
                                    ? STRINGS.runbooks.tabNames.allIncidentRunbooks
                                    : props.variant === Variant.LIFECYCLE
                                        ? STRINGS.runbooks.tabNames.allLifecycleRunbooks
                                        : props.variant === Variant.ON_DEMAND
                                            ? STRINGS.runbooks.tabNames.allOnDemandRunbooks
                                            : STRINGS.runbooks.tabNames.allSubflowRunbooks,
                            tabKey: 'all',
                            runbooks: runbooksForEachTab.all,
                            showAlways: true,
                        })}
                        {props.variant !== Variant.SUBFLOW &&
                            props.variant !== Variant.ON_DEMAND &&
                            getTab({
                                title: STRINGS.runbooks.tabNames.automation,
                                tabKey: 'active',
                                runbooks: runbooksForEachTab.automation,
                                showAlways: true,
                            })}
                        {runbooksForEachTab.mine.length <
                            runbooksForEachTab.all.length &&
                            getTab({
                                title: STRINGS.runbooks.tabNames.mine,
                                tabKey: 'mine',
                                runbooks: runbooksForEachTab.mine,
                            })}
                        {runbooksForEachTab.inerror.length <
                            runbooksForEachTab.all.length &&
                            props.variant !== Variant.ON_DEMAND &&
                            getTab({
                                title: STRINGS.runbooks.tabNames.inerror,
                                tabKey: 'inerror',
                                runbooks: runbooksForEachTab.inerror,
                            })}
                        {runbooksForEachTab.factory.length <
                            runbooksForEachTab.all.length &&
                            props.variant !== Variant.LIFECYCLE &&
                            getTab({
                                title: STRINGS.runbooks.tabNames.factory,
                                tabKey: 'factory',
                                runbooks: runbooksForEachTab.factory,
                            })}
                        {props.variant === Variant.ON_DEMAND && getTab({
                            title: STRINGS.runbooks.tabNames.scheduled,
                            tabKey: 'scheduled',
                            runbooks: runbooksForEachTab.scheduled,
                            showAlways: true
                        })}
                    </TabbedSubPages>
                </>
            </DataLoadFacade>
        </>
    );
}

/** creates a new runbook and opens the runbook editor with the new runbook selected.
 *  @param history the history object that we can append the URL to open the create runbook page.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param variant the runbook variant: incident or lifecycle.
 *  @returns a Promise with empty result. */
export const createNewRunbook = async (
    history: any, setDialogState: Function, variant: Variant
): Promise<void> => {
    if (!saveOnCreateNewRunbook) {
        // Go to the runbook editor and just open a brand new runbook
        history.push(
            getURL(getURLPath('create-runbook'), {
                [PARAM_NAME.rbConfigId]: 0,
                [PARAM_NAME.rbConfigNm]: 'New',
                [PARAM_NAME.variant]: variant,
            })
        );
    } else {
        /* istanbul ignore next */
        const newDialogState: any = {
            showDialog: true,
            loading: true,
            title: STRINGS.formatString(STRINGS.runbooks.createDialog.title, {
                variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
            }),
        };
        newDialogState.dialogContent = (
            <div className="mb-3">
                <span>
                    {STRINGS.formatString(
                        STRINGS.runbooks.createDialog.bodyText,
                        {
                            variant:
                                STRINGS.runbooks.runbookTextForVariant[variant],
                        }
                    )}
                </span>
            </div>
        );
        newDialogState.dialogFooter = (
            <Button
                active={true}
                outlined={true}
                onClick={async (evt) => {
                    setDialogState(
                        updateDialogState(newDialogState, false, false, [])
                    );
                }}
                text={STRINGS.runbooks.okBtnText}
            />
        );
        setDialogState(newDialogState);

        try {
            // Get the current list of runbook names
            const usedNames: Array<string> = [];
            const runbooks = await runbookService.getRunbooks(variant === Variant.EXTERNAL ? Variant.INCIDENT : variant);
            for (const savedRunbook of runbooks) {
                if (savedRunbook.name) {
                    usedNames.push(savedRunbook.name as string);
                }
                if (savedRunbook.otherVersions?.length) {
                    for (const versionInfo of savedRunbook.otherVersions) {
                        if (versionInfo.name) {
                            usedNames.push(versionInfo.name as string);                        
                        }
                    }
                } 
            }

            // Make sure the name is unique
            const nameRoot = STRINGS.formatString(
                STRINGS.runbooks.createDialog.newRunbookName,
                { variant: STRINGS.runbooks.runbookTextForVariantUc[variant] }
            );
            let nameIndex = 1;
            let name = nameRoot;
            while (usedNames.includes(name)) {
                name = nameRoot + ' ' + nameIndex++;
            }

            const newRunbook: RunbookConfig = {
                name: name,
                description: STRINGS.formatString(
                    STRINGS.runbooks.createDialog.newRunbookDesc,
                    { variant: STRINGS.runbooks.runbookTextForVariant[variant] }
                ),
                triggerType: InputType.DEVICE,
                nodes: [],
            };
            newRunbook.nodes!.push({
                id: getUuidV4(),
                label: STRINGS.runbooks.createDialog.newRunbookComment,
                type: commentNodes[0],
                properties: {},
                wires: [],
            });

            // We want all new runbooks to be inactive
            newRunbook.isReady = false;

            // Save the new runbook
            const savedRunbook = await runbookService.saveRunbook(newRunbook);

            setDialogState(updateDialogState(newDialogState, false, false, []));
            history.push(
                getURL(getURLPath('create-runbook'), {
                    [PARAM_NAME.rbConfigId]: savedRunbook.id,
                    [PARAM_NAME.rbConfigNm]: savedRunbook.name,
                    [PARAM_NAME.variant]: variant,
                })
            );
        } catch (error) {
            setDialogState(
                updateDialogState(newDialogState, true, false, [
                    STRINGS.formatString(
                        STRINGS.runbooks.createDialog.errorText,
                        {
                            variant:
                                STRINGS.runbooks.runbookTextForVariant[variant],
                        }
                    ),
                ])
            );
        }
    }
};

/** creates a duplicate of the runbook with the specified id and then reloads the list of runbooks.
 *  @param id a String with the id of the runbook.
 *  @param variant the Variant of the runbook that is being duplicated.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param operationCount an integer with the current operation count.  This number needs to be incremented to
 *      re-render the page.
 *  @param setOperationCount the settor function for the operation count.  Invoke this function with the new
 *      operation count to re-render the component.*/
async function duplicateRunbook(
    id: string, variant: Variant = Variant.INCIDENT, setDialogState: Function,
    operationCount: number, setOperationCount: Function
): Promise<void> {
    const newDialogState: any = {
        showDialog: true,
        loading: true,
        title: STRINGS.formatString(STRINGS.runbooks.duplicateDialog.title, {
            variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
        }),
    };
    newDialogState.dialogContent = (
        <div className="mb-3">
            <span>
                {STRINGS.formatString(
                    STRINGS.runbooks.duplicateDialog.bodyText,
                    { variant: STRINGS.runbooks.runbookTextForVariant[variant] }
                )}
            </span>
        </div>
    );
    newDialogState.dialogFooter = (
        <Button
            active={true}
            outlined={true}
            onClick={async (evt) => {
                setDialogState(
                    updateDialogState(newDialogState, false, false, [])
                );
            }}
            text={STRINGS.runbooks.okBtnText}
        />
    );
    setDialogState(newDialogState);

    try {
        const runbook = await runbookService.getRunbook(id);

        const newRunbook = JSON.parse(JSON.stringify(runbook));
        let name = 'Copy of ' + newRunbook.name;
        newRunbook.isFactory = false;
        delete newRunbook.factoryResourceName;
        delete newRunbook.id;
        delete newRunbook.version;
        delete newRunbook.seriesId;

        // Get the current list of runbook names
        const usedNames: Array<string> = [];
        const runbooks = await runbookService.getRunbooks(variant === Variant.EXTERNAL ? Variant.INCIDENT : variant);
        for (const savedRunbook of runbooks) {
            if (savedRunbook.name) {
                usedNames.push(savedRunbook.name as string);
            }
            if (savedRunbook.otherVersions?.length) {
                for (const versionInfo of savedRunbook.otherVersions) {
                    if (versionInfo.name) {
                        usedNames.push(versionInfo.name as string);                        
                    }
                }
            } 
        }

        // Make sure the name is unique
        const nameRoot = name;
        let nameIndex = 1;
        while (usedNames.includes(name)) {
            name = nameRoot + ' ' + nameIndex++;
        }
        newRunbook.name = name;

        // Update all node ids
        const newIdByOldId: Record<string, string> = {};
        for (const node of newRunbook.nodes) {
            const oldId = node.id;
            node.id = getUuidV4();
            newIdByOldId[oldId] = node.id;
        }

        // Update all wires
        for (const node of newRunbook.nodes) {
            if (node.wires) {
                for (const wire of node.wires) {
                    if (Array.isArray(wire)) {
                        for (let index = 0; index < wire.length; index++) {
                            wire[index] = newIdByOldId[wire[index]];
                        }
                    }
                }
            }
        }

        // Update filters on DO object
        updateDoFilters(newRunbook, newIdByOldId);

        // We want all duplicated runbooks to be inactive
        newRunbook.isReady = false;

        // Save the new runbook
        await runbookService.saveRunbook(newRunbook);
        setOperationCount(operationCount + 1);
        setDialogState(updateDialogState(newDialogState, false, false, []));
    } catch (error) {
        let errors: string[] = getErrorsFromResponse(
            error,
            STRINGS.formatString(STRINGS.runbooks.duplicateDialog.errorText, {
                variant: STRINGS.runbooks.runbookTextForVariant[variant],
            })
        );
        setDialogState(updateDialogState(newDialogState, true, false, errors));
    }
}

/** handler for the delete button pressed event.
 *  @param deleteId a String with the id of the runbook that is to be deleted.
 *  @param enabled a boolean value, true if the runbook is currently enabled.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param operationCount a unique id which if modified causes the component to re-render.
 *  @param setOperationCount the function from useState that is used to set the operation count.
 *  @param runbook the object value for the runbook that is to be deleted.
 *  @param variant the runbook variant that is being edited. 
 *  @param history the history object that can be used to push new URLs/states into the browser history. */
async function handleDelete(
    deleteId: string, enabled: boolean, setDialogState: (dialogState: DialogState) => void,
    operationCount: number, setOperationCount: (count: number) => void,
    runbook: any, variant: Variant, history: any,
): Promise<void> {
    // We no longer want to show the enabled warning because you can delete a runbook that is in the isReady state.
    // we might want to check if this is the active mapping and then warn the user.
    const isRunbookMapped = await getRunbookMappingStatus(deleteId);
    const showEnabledWarning: boolean = false;
    const isRunbookScheduled = runbook?.isScheduled;
    const noop = "#";

    const newDialogState: any = {
        showDialog: true,
        title: STRINGS.formatString(STRINGS.runbooks.deleteDialog.title, {
            variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
        }),
    };
    newDialogState.dialogContent = (
        <div className="mb-3">
            <span>
                {isRunbookMapped
                    ? STRINGS.formatString(
                            STRINGS.runbooks.deleteDialog.mappedBodyText,
                            {
                                variant:
                                    STRINGS.runbooks.runbookTextForVariant[
                                        variant
                                    ],
                            }
                      )
                    : enabled && showEnabledWarning
                    ? STRINGS.formatString(
                            STRINGS.runbooks.deleteDialog.enabledBodyText,
                            {
                                variant:
                                    STRINGS.runbooks.runbookTextForVariant[
                                        variant
                                    ],
                            }
                        )
                        : isRunbookScheduled ?
                            <>
                                {STRINGS.runbooks.deleteDialog.bodyTextScheduledRunbook1}
                                {' '}
                                <a href={noop} onClick={(e) => {
                                    e.preventDefault();
                                    history.push(
                                        getURL(getURLPath('runbook-schedules'), {
                                            searchType: SEARCH_TYPE.runbookschedules
                                        })
                                    )
                                }}>
                                    {STRINGS.runbooks.deleteDialog.bodyTextScheduledRunbook2}
                                </a>
                                {' '}
                                {STRINGS.runbooks.deleteDialog.bodyTextScheduledRunbook3}
                            </> :
                            STRINGS.formatString(
                                STRINGS.runbooks.deleteDialog.bodyTextWithRunbookName,
                                {
                                    variant:
                                        STRINGS.runbooks.runbookTextForVariant[
                                            variant
                                        ],
                                    runbookName: runbook.name
                                }
                            )}
            </span>
        </div>
    );
    let subflowIsUsedInIncidentRunbooks = false;
    let subflowIsUsedInLifecycleRunbooks = false;
    const dialogFooter = (deleteButtonText) => {
        return (
            <>
                {!isRunbookMapped && (
                    <Button
                        active={true}
                        outlined={true}
                        onClick={async (evt) => {
                            let errors: Array<string> = [];
                            if (deleteId !== '') {
                                setDialogState(
                                    updateDialogState(
                                        newDialogState,
                                        true,
                                        true,
                                        []
                                    )
                                );
                                try {
                                    await runbookService.deleteRunbook(
                                        deleteId
                                    );
                                    setOperationCount(operationCount + 1);
                                } catch (error) {
                                    errors = [
                                        STRINGS.formatString(
                                            STRINGS.runbooks.deleteDialog
                                                .generalDeleteError,
                                            {
                                                variant:
                                                    STRINGS.runbooks
                                                        .runbookTextForVariant[
                                                        variant
                                                    ],
                                            }
                                        ),
                                    ];
                                }
                            }
                            setDialogState(
                                updateDialogState(
                                    newDialogState,
                                    errors?.length > 0,
                                    false,
                                    errors || []
                                )
                            );
                        }}
                        disabled={isRunbookScheduled}
                        text={deleteButtonText}
                    />
                )}
                <Button
                    active={true}
                    outlined={true}
                    onClick={(evt) => {
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        );
                    }}
                    text={STRINGS.runbooks.cancelBtnText}
                />
            </>
        );
    };
    if (runbook?.variant === Variant.SUBFLOW) {
        const runbooksThatUseTheSubflow: RunbookNode[] = [];

        Promise.allSettled([
            runbookService.getRunbooks(Variant.INCIDENT),
            //runbookService.getRunbooks(Variant.EXTERNAL),
            runbookService.getRunbooks(Variant.LIFECYCLE),
        ]).then((results) => {
            if (deleteId !== '') {
                if (
                    results[0].status === 'fulfilled' &&
                    results[0].value?.length
                ) {
                    for (const runbook of results[0].value) {
                        if (runbook?.nodes) {
                            for (const node of runbook.nodes) {
                                if (
                                    node?.properties?.configurationId ===
                                    deleteId
                                ) {
                                    subflowIsUsedInIncidentRunbooks = true;
                                    runbooksThatUseTheSubflow.push(runbook);
                                }
                            }
                        }
                    }
                }
                if (
                    results[1].status === 'fulfilled' &&
                    results[1].value?.length &&
                    !subflowIsUsedInIncidentRunbooks
                ) {
                    for (const runbook of results[1].value) {
                        if (runbook?.nodes) {
                            for (const node of runbook.nodes) {
                                if (
                                    node?.properties?.configurationId ===
                                    deleteId
                                ) {
                                    subflowIsUsedInLifecycleRunbooks = true;
                                    runbooksThatUseTheSubflow.push(runbook);
                                }
                            }
                        }
                    }
                }
                if (
                    subflowIsUsedInIncidentRunbooks ||
                    subflowIsUsedInLifecycleRunbooks
                ) {
                    newDialogState.dialogContent = (
                        <div className="mb-3">
                            <p>
                                {
                                    STRINGS.runbooks.deleteDialog
                                        .bodyTextSubflowUsedInRunbooks1
                                }
                            </p>
                            <p>
                                {
                                    STRINGS.runbooks.deleteDialog
                                        .bodyTextSubflowUsedInRunbooks2
                                }
                            </p>
                            <RunbooksContainingSubflowTable
                                runbooks={runbooksThatUseTheSubflow}
                            />
                        </div>
                    );
                    newDialogState.dialogFooter = dialogFooter(
                        STRINGS.runbooks.deleteDialog
                            .btnTextSubflowUsedInRunbooks
                    );
                }
                setDialogState(
                    updateDialogState(newDialogState, true, false, [])
                );
            }
        });
    }
    if (!subflowIsUsedInIncidentRunbooks && !subflowIsUsedInLifecycleRunbooks) {
        newDialogState.dialogFooter = dialogFooter(
            STRINGS.runbooks.deleteDialog.btnText
        );
    }
    if (runbook?.variant === Variant.SUBFLOW) {
        setDialogState(updateDialogState(newDialogState, true, true, []));
    } else {
        setDialogState(newDialogState);
    }
}

/** handler for the delete button pressed event.
 *  @param deleteIds an array of Strings with the ids of the runbooks that are to be deleted.
 *  @param enabled a boolean value, true if the runbook is currently enabled.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param operationCount a unique id which if modified causes the component to re-render.
 *  @param setOperationCount the function from useState that is used to set the operation count.
 *  @param setSelectedIdsByTab sets the selected ids by tab state.
 *  @param variant the runbook Variant that is being edited. */
async function handleMultiDelete(
    deleteIds: string[], enabled: boolean, setDialogState: (dialogState: DialogState) => void,
    operationCount: number, setOperationCount: (count: number) => void,
    setSelectedIdsByTab: (idMap: { [x: string]: string[] }) => void, variant: Variant
): Promise<void> {
    if (deleteIds?.length) {
        // We no longer want to show the enabled warning because you can delete a runbook that is in the isReady state.
        // we might want to check if this is the active mapping and then warn the user.
        const isRunbookMapped = await getRunbookMappingStatus(deleteIds[0]);
        const showEnabledWarning: boolean = false;
        const newDialogState: any = {
            showDialog: true,
            title: STRINGS.formatString(STRINGS.runbooks.deleteDialog.title, {
                variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
            }),
        };
        newDialogState.dialogContent = (
            <div className="mb-3">
                <span>
                    {isRunbookMapped
                        ? STRINGS.formatString(
                                STRINGS.runbooks.deleteDialog.mappedBodyText,
                                {
                                    variant:
                                        STRINGS.runbooks.runbookTextForVariant[
                                            variant
                                        ],
                                }
                          )
                        : enabled && showEnabledWarning
                        ? STRINGS.formatString(
                                STRINGS.runbooks.deleteDialog.enabledBodyText,
                                {
                                    variant:
                                        STRINGS.runbooks.runbookTextForVariant[
                                            variant
                                        ],
                                }
                          )
                        : STRINGS.formatString(
                                STRINGS.runbooks.deleteDialog.multiBodyText,
                                {
                                    variant:
                                        STRINGS.runbooks.runbookTextForVariant[
                                            variant
                                        ],
                                }
                          )}
                </span>
            </div>
        );
        newDialogState.dialogFooter = (
            <>
                {!isRunbookMapped && (
                    <Button
                        active={true}
                        outlined={true}
                        onClick={async (evt) => {
                            let errors: Array<string> = [];
                            if (deleteIds[0] !== '') {
                                setDialogState(
                                    updateDialogState(newDialogState, true, true, [])
                                );
                                try {
                                    await runbookService.deleteRunbooks(deleteIds);
                                    setOperationCount(operationCount + 1);
                                    if (setSelectedIdsByTab) {
                                        setSelectedIdsByTab({});
                                    }
                                } catch (error) {
                                    errors = [
                                        STRINGS.formatString(
                                            STRINGS.runbooks.deleteDialog.generalDeleteError,
                                            {
                                                variant:
                                                    STRINGS.runbooks.runbookTextForVariant[
                                                        variant
                                                    ],
                                            }
                                        ),
                                    ];
                                }
                            }
                            setDialogState(
                                updateDialogState(newDialogState, errors?.length > 0, false, errors || [])
                            );
                        }}
                        text={STRINGS.runbooks.deleteDialog.btnText}
                    />
                )}
                <Button
                    active={true}
                    outlined={true}
                    onClick={(evt) => {
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        );
                    }}
                    text={STRINGS.runbooks.cancelBtnText}
                />
            </>
        );
        setDialogState(newDialogState);
    }
}

/** sets the runbook enabled flag on the runbook and saves it to the back-end.
 *  @param id a String with the id of the runbook.
 *  @param enabled the new value of the enabled flag.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param operationCount an integer with the current operation count.  This number needs to be incremented to
 *      re-render the page.
 *  @param setOperationCount the settor function for the operation count.  Invoke this function with the new
 *      operation count to re-render the component.
 *  @param variant the Variant of runbook that is being edited. */
async function setRunbookEnabled(
    id: string, enabled: boolean, setDialogState: Function, operationCount: number, 
    setOperationCount: Function, variant: Variant
): Promise<void> {
    const isRunbookMapped = await getRunbookMappingStatus(id);
    const newDialogState: any = {
        showDialog: true,
        loading: enabled,
        title: STRINGS.runbooks.activateDialog.title,
    };
    newDialogState.dialogContent = (
        <div className="mb-3">
            <span>
                {enabled
                    ? STRINGS.runbooks.activateDialog.bodyText
                    : STRINGS.formatString(
                            STRINGS.runbooks.activateDialog.disabledBodyText,
                            {
                                variant: STRINGS.runbooks.runbookTextForVariant[variant],
                            }
                      )}
            </span>
        </div>
    );
    newDialogState.dialogFooter = (
        <>
            <Button
                active={true}
                outlined={true}
                onClick={async (evt) => {
                    if (!enabled) {
                        try {
                            setDialogState(
                                updateDialogState(newDialogState, true, true, [])
                            );
                            await setEnabledFlag(id, enabled);
                            setOperationCount(operationCount + 1);
                            setDialogState(
                                updateDialogState(newDialogState, false, false, [])
                            );
                        } catch (error) {
                            const errorMsg =
                                (error as any)?.response?.status === 412
                                    ? STRINGS.formatString(
                                            STRINGS.runbooks.activateDialog.etagErrorText,
                                            {
                                                variant:
                                                    STRINGS.runbooks.runbookTextForVariant[
                                                        variant
                                                    ],
                                            }
                                      )
                                    : STRINGS.formatString(
                                            STRINGS.runbooks.activateDialog.generalErrorText,
                                            {
                                                variant:
                                                    STRINGS.runbooks.runbookTextForVariant[
                                                        variant
                                                    ],
                                            }
                                      );
                            setDialogState(
                                updateDialogState(newDialogState, true, false, [errorMsg])
                            );
                        }
                    } else {
                        // If the user is trying to enable a runbook you could get here if an error occurs trying to
                        // get the active count.  Allow them to try it again
                        setRunbookEnabled(
                            id, enabled, setDialogState, operationCount, setOperationCount, variant
                        );
                    }
                }}
                text={STRINGS.runbooks.okBtnText}
            />
            {!enabled && (
                <Button
                    active={true}
                    outlined={true}
                    onClick={async (evt) => {
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        );
                    }}
                    text={STRINGS.runbooks.cancelBtnText}
                />
            )}
        </>
    );
    setDialogState(newDialogState);

    try {
        const afterDialogState = updateDialogState(newDialogState, true, false, []);
        afterDialogState.dialogContent = (
            <div className="mb-3">
                <span>
                    {enabled
                        ? STRINGS.formatString(
                                STRINGS.runbooks.activateDialog.enabledBodyText,
                                {
                                    variant:
                                        STRINGS.runbooks.runbookTextForVariant[
                                            variant
                                        ],
                                }
                          )
                        : !isRunbookMapped
                        ? STRINGS.formatString(
                                STRINGS.runbooks.activateDialog.disabledBodyText,
                                {
                                    variant:
                                        STRINGS.runbooks.runbookTextForVariant[
                                            variant
                                        ],
                                }
                          )
                        : STRINGS.formatString(
                                STRINGS.runbooks.activateDialog.mappedDisablingBodyText,
                                {
                                    variant:
                                        STRINGS.runbooks.runbookTextForVariant[
                                            variant
                                        ],
                                }
                          )}
                </span>
            </div>
        );
        afterDialogState.dialogFooter = (
            <>
                {(!isRunbookMapped || (isRunbookMapped && enabled)) && (
                    <Button
                        active={true}
                        outlined={true}
                        onClick={async (evt) => {
                            try {
                                setDialogState(
                                    updateDialogState(newDialogState, true, true, [])
                                );
                                await setEnabledFlag(id, enabled);
                                setOperationCount(operationCount + 1);
                                setDialogState(
                                    updateDialogState(newDialogState, false, false, [])
                                );
                            } catch (error) {
                                const errorMsg =
                                    (error as any)?.response?.status === 412
                                        ? STRINGS.formatString(
                                                STRINGS.runbooks.activateDialog.etagErrorText,
                                                {
                                                    variant:
                                                        STRINGS.runbooks.runbookTextForVariant[
                                                            variant
                                                        ],
                                                }
                                          )
                                        : STRINGS.formatString(
                                                STRINGS.runbooks.activateDialog.generalErrorText,
                                                {
                                                    variant:
                                                        STRINGS.runbooks.runbookTextForVariant[
                                                            variant
                                                        ],
                                                }
                                          );
                                setDialogState(
                                    updateDialogState(newDialogState, true, false, [errorMsg])
                                );
                            }
                        }}
                        text={STRINGS.runbooks.okBtnText}
                    />
                )}
                <Button active={true} outlined={true}
                    onClick={async (evt) => {
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        );
                    }}
                    text={STRINGS.runbooks.cancelBtnText}
                />
            </>
        );
        setDialogState(afterDialogState);
    } catch (error) {
        const errorMsg =
            (error as any)?.response?.status === 412
                ? STRINGS.formatString(
                        STRINGS.runbooks.activateDialog.etagErrorText,
                        {
                            variant: STRINGS.runbooks.runbookTextForVariant[variant],
                        }
                  )
                : STRINGS.formatString(
                        STRINGS.runbooks.activateDialog.generalErrorText,
                        {
                            variant: STRINGS.runbooks.runbookTextForVariant[variant],
                        }
                  );
        setDialogState(
            updateDialogState(newDialogState, true, false, [errorMsg])
        );
    }
}

/** check if runbook belongs to any active mapping.
 *  @param id a String with the id of the runbook.
 *  @returns boolean true/false */
async function getRunbookMappingStatus(id: string): Promise<boolean> {
    let isRunbookMapped = false;
    await MappingConfigService.getMappings().then(
        (resolve) => {
            resolve.forEach((mapping) => {
                if (mapping.runbookId === id) {
                    isRunbookMapped = true;
                }
            });
        },
        (reject) => {
            console.error(reject.message);
        }
    );
    return Promise.resolve(isRunbookMapped);
}

/** sets the enabled flag.
 *  @param id a String with the id of the runbook.
 *  @param enabled the new value of the enabled flag.
 *  @returns a Promise that resolves to the id of the updated runbook. */
async function setEnabledFlag(id: string, enabled: boolean): Promise<string> {
    const updatedRunbook: RunbookConfig | undefined =
        await runbookService.getRunbook(id);

    // Save the new runbook
    if (updatedRunbook) {
        const currentEnabled = updatedRunbook.isReady;
        if (currentEnabled !== enabled) {
            //const runbookToSave = {id: updatedRunbook.id};
            //runbookToSave.isReady = enabled;
            updatedRunbook.isReady = enabled;
            const runbookId = await runbookService.updateRunbook(
                id,
                updatedRunbook,
                updatedRunbook.eTag || 'unknown-etag'
            );
            return Promise.resolve(runbookId);
        } else {
            // The active state already has been set, possibly by another user.
            return Promise.resolve(updatedRunbook.id || '');
        }
    } else {
        return Promise.reject('Error saving runbook');
    }
}

/** Creates a popup that imports the nodes using the node-red format into the graph.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param operationCount an integer with the current operation count.  This number needs to be incremented to
 *      re-render the page.
 *  @param setOperationCount the settor function for the operation count.  Invoke this function with the new
 *      operation count to re-render the component.
 *  @param subFlows the list of subflows or an empty array if there are no subflows.
 *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
 *  @param variant the runbook variant: incident or lifecycle. */
/* istanbul ignore next */
export function importContent(
    nodeLibrary: NodeLibrary, setDialogState: Function, operationCount: number, setOperationCount: Function,
    subflows: Array<RunbookNode>, incidentVariables, customProperties: CustomProperty[], variant: Variant
): void {
    const newDialogState: any = {
        showDialog: true,
        title: STRINGS.formatString(STRINGS.runbooks.importDialog.title, {
            variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
        }),
    };
    newDialogState.dialogContent = (
        <>
            <div className="mb-3">
                <span>
                    {STRINGS.formatString(STRINGS.runbooks.importDialog.text, {
                        variant: STRINGS.runbooks.runbookTextForVariant[variant],
                    })}
                </span>
            </div>
            <input id="runbook-file" type="file" />
        </>
    );
    newDialogState.dialogFooter = (
        <Button
            active={true}
            outlined={true}
            onClick={async (evt) => {
                const fileList = (
                    document.getElementById('runbook-file') as any
                ).files;
                if (fileList && fileList.length === 1) {
                    const fileReader = new FileReader();
                    fileReader.onload = async function () {
                        const text: string = fileReader.result as string;
                        if (text && text.length > 0) {
                            let errors: Array<string> = [];
                            try {
                                setDialogState(
                                    updateDialogState(newDialogState, true, true, [])
                                );
                                const runbookExportFormat = JSON.parse(text);

                                // Check the node types, do not allow someone to copy in a node type from another runbook
                                if (runbookExportFormat?.nodes?.length) {
                                    const nodeTypes: string[] = nodeLibrary.getNodeTypes();
                                    if (variant !== Variant.SUBFLOW) {
                                        nodeTypes.push(...subflowNodes);
                                    }
                                    const unsupportedTypes: string[] = [];
                                    for (const node of runbookExportFormat.nodes) {
                                        if (!nodeTypes.includes(node.type)) {
                                            if (!unsupportedTypes.includes(node.type)) {
                                                unsupportedTypes.push(node.type);
                                            }
                                        }
                                    }
                                    if (unsupportedTypes.length) {
                                        errors.push(
                                            STRINGS.formatString(
                                                STRINGS.runbooks.importDialog.invalidTypeErrorText,
                                                {
                                                    variant: STRINGS.runbooks.runbookTextForVariant[
                                                            variant
                                                        ],
                                                    types: unsupportedTypes.join(', '),
                                                }
                                            )
                                        );
                                    }
                                }

                                if (
                                    errors.length === 0 &&
                                    (
                                        runbookExportFormat?.variant === variant ||
                                        (variant === Variant.EXTERNAL && runbookExportFormat?.variant === Variant.INCIDENT)
                                    )
                                ) {
                                    await saveImportedRunbook(
                                        nodeLibrary, runbookExportFormat, subflows,
                                        incidentVariables, customProperties, variant
                                    );
                                    setOperationCount(operationCount + 1);
                                } else if (errors.length === 0) {
                                    errors = [
                                        STRINGS.formatString(
                                            STRINGS.runbooks.importDialog.wrongVariantErrorText,
                                            {
                                                variant:
                                                    STRINGS.runbooks.runbookTextForVariant[
                                                        variant
                                                    ],
                                            }
                                        ),
                                    ];
                                }
                            } catch (error) {
                                errors = getErrorsFromResponse(
                                    error,
                                    STRINGS.formatString(
                                        STRINGS.runbooks.importDialog.errorText,
                                        {
                                            variant:
                                                STRINGS.runbooks.runbookTextForVariant[
                                                    variant
                                                ],
                                        }
                                    ) +
                                        '<br/>' +
                                        error
                                );
                            }
                            setDialogState(
                                updateDialogState(newDialogState, errors?.length > 0, false, errors || [])
                            );
                        }
                    };
                    fileReader.readAsText(fileList[0]);
                }
            }}
            text={STRINGS.runbooks.importDialog.btnText}
        />
    );
    setDialogState(newDialogState);
}

/** extract any error
 *  @param error the error object from the catch.
 *  @param defaultError the detault error to return if there is no known error code in the response.
 *  @returns a String array with any errors parsed from the response object, or the default error message. */
function getErrorsFromResponse(error: any, defaultError): string[] {
    let errors: Array<string> = [];
    const errorMsg = error?.response?.data?.code
        ? STRINGS.formatString(
                STRINGS.runbookEditor.errors.codes[error?.response?.data?.code],
                error?.response?.data?.innererror || {}
          )
        : defaultError;
    errors.push(errorMsg);
    return errors;
}

/** saves a new runbook from the contents of the import file.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param runbookExportFormat the JSON object with the contents of the import file.
 *  @param subFlows the list of subflows or an empty array if there are no subflows.
 *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
 *  @param variant the runbook variant: incident or lifecycle.
 *  @returns a Promise which resolves to the runbook that was saved. */
/* istanbul ignore next */
async function saveImportedRunbook(
    nodeLibrary: NodeLibrary, runbookExportFormat: any, subflows: Array<RunbookNode>,
    incidentVariables, customProperties: CustomProperty[], variant: Variant
): Promise<RunbookConfig> {
    try {
        let name: string = '';
        let triggerType: InputType | undefined;
        let description: string = '';
        let disabled: boolean | null = null;
        let nodes: Array<RunbookNode> = [];
        let runtimeVariables: VariableCollection = { primitiveVariables: [], structuredVariables: [] };
        let runbookVariant: Variant = Variant.INCIDENT;

        // Nodered had two formats, the export format and the runbook format.  The export format has a list of nodes and there
        // is a node with the type=tab that has the information about the runbook, while the runbook format has an object with the
        // runbook attributes and then a nodes attribute with the list of nodes.  We are trying to support both here.
        if (runbookExportFormat && Array.isArray(runbookExportFormat)) {
            for (const node of runbookExportFormat) {
                if (node.type === 'tab') {
                    name = node.name || '';
                    description = node.description || '';
                    disabled =
                        node.disabled !== null && node.disabled !== undefined
                            ? node.disabled
                            : false;
                } else {
                    nodes.push(node);
                }
            }
        } else {
            name = runbookExportFormat.name || '';
            description = runbookExportFormat.description || '';
            triggerType = runbookExportFormat.triggerType;
            nodes = runbookExportFormat.nodes ? runbookExportFormat.nodes : [];
            runbookVariant = runbookExportFormat.variant ? runbookExportFormat.variant : Variant.INCIDENT;
            runtimeVariables = runbookExportFormat[getRuntimeVariablesKey(runbookVariant)]
                ? runbookExportFormat[getRuntimeVariablesKey(runbookVariant)]
                : { primitiveVariables: [], structuredVariables: [] };
        }

        // Get the current list of runbook names
        const usedNames: Array<string> = [];
        const runbooks = await runbookService.getRunbooks(variant === Variant.EXTERNAL ? Variant.INCIDENT : variant);
        for (const savedRunbook of runbooks) {
            if (savedRunbook.name) {
                usedNames.push(savedRunbook.name as string);
            }
            if (savedRunbook.otherVersions?.length) {
                for (const versionInfo of savedRunbook.otherVersions) {
                    if (versionInfo.name) {
                        usedNames.push(versionInfo.name as string);                        
                    }
                }
            } 
        }

        // Make sure the name is unique
        const nameRoot = name;
        let nameIndex = 1;
        while (usedNames.includes(name)) {
            name = nameRoot + ' ' + nameIndex++;
        }

        const newRunbook: RunbookConfig = {
            name: name,
            description: description,
            triggerType: triggerType,
            [getRuntimeVariablesKey(runbookVariant)]: runtimeVariables,
            nodes,
        };
        if (disabled !== null) {
            newRunbook.isReady = disabled;
        }
        delete newRunbook.id;

        // Update all node ids
        const newIdByOldId: Record<string, string> = {};
        for (const node of newRunbook.nodes!) {
            const oldId = node.id;
            node.id = getUuidV4();
            newIdByOldId[oldId] = node.id;
        }

        // Update all wires
        for (const node of newRunbook.nodes!) {
            if (node.wires) {
                for (const wire of node.wires) {
                    if (Array.isArray(wire)) {
                        for (let index = 0; index < wire.length; index++) {
                            wire[index] = newIdByOldId[wire[index]];
                        }
                    }
                }
            }
        }

        // Update filters on DO object
        updateDoFilters(newRunbook, newIdByOldId);

        // We want all imported runbooks to be inactive
        newRunbook.isReady = false;

        const graphDef = getGraphDefFromRunbookConfig(nodeLibrary, newRunbook, subflows);
        const variables: VariableContextByScope = {
            [RUNTIME_SCOPE]: getRuntimeVariables(newRunbook, runbookVariant),
            [INCIDENT_SCOPE]: {
                primitiveVariables: [
                    ...(incidentVariables?.incidentVariables?.variables
                        ?.primitiveVariables || []),
                    ...INCIDENT_SCOPE_BUILTIN_VARIABLES,
                ],
                structuredVariables: [
                    ...(incidentVariables?.incidentVariables?.variables
                        ?.structuredVariables || []),
                ],
            },
            [GLOBAL_SCOPE]: { primitiveVariables: [], structuredVariables: [] },
        };
        updateGraphDefWithErrors(
            nodeLibrary, graphDef, newRunbook, variables, customProperties, subflows, variant
        );
        let hasError = false;
        if (graphDef) {
            if (graphDef.errors) {
                hasError = true;
            }
            if (!hasError && graphDef.nodes) {
                for (const node of graphDef.nodes) {
                    if (node.errors) {
                        hasError = true;
                        break;
                    }
                }
            }
        }
        newRunbook.isValidated = !hasError;
        newRunbook.variant = runbookVariant;

        // Save the new runbook
        const dupeRunbook = await runbookService.saveRunbook(newRunbook);
        return Promise.resolve(dupeRunbook as RunbookConfig);
    } catch (error) {
        return Promise.reject(error);
    }
}

/** exports the currently selected runbook to the clipboard and displays it in a text area
 *      in a dialog.
 *  @param id a String with the id of the runbook to export.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. 
 *  @param variant the runbook Variant that we are extracting the variables from. */
async function exportContent(id: string, setDialogState: Function, variant: Variant, useApiImportExportRunbook: boolean): Promise<void> {
    const newDialogState: any = {
        showDialog: true,
        loading: true,
        title: STRINGS.formatString(STRINGS.runbooks.exportDialog.title, {
            variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
        }),
    };
    newDialogState.dialogContent = (
        <div className="mb-3">
            <span>
                {STRINGS.formatString(STRINGS.runbooks.exportDialog.preText, {
                    variant: STRINGS.runbooks.runbookTextForVariant[variant],
                })}
            </span>
        </div>
    );
    newDialogState.dialogFooter = undefined;
    setDialogState(newDialogState);

    try {
        const getRunbookDetails = useApiImportExportRunbook ?
                        runbookService.getRunbookWithDependenciesForExport(id):
                        runbookService.getRunbook(id);

        const runbook = await getRunbookDetails;

        const newDialogState: any = {
            showDialog: true,
            title: STRINGS.formatString(STRINGS.runbooks.exportDialog.title, {
                variant: STRINGS.runbooks.runbookTextForVariantUc[variant],
            }),
        };
        newDialogState.dialogContent = (
            <>
                <div className="mb-3">
                    <span>
                        {STRINGS.formatString(
                            STRINGS.runbooks.exportDialog.text,
                            {
                                variant: STRINGS.runbooks.runbookTextForVariant[variant],
                            }
                        )}
                    </span>
                </div>
                <textarea 
                    defaultValue={JSON.stringify(runbook, null, 4)}
                    style={{ minWidth: '470px', minHeight: '350px', resize: "both" }}
                    disabled
                />
            </>
        );
        newDialogState.dialogFooter = (
            <>
                <Button active={true} outlined={true} icon={IconNames.DOWNLOAD}
                    text={STRINGS.runbooks.exportDialog.downloadBtnText}
                    onClick={() => {
                        const runbookName: string = useApiImportExportRunbook ? 
                                                        (runbook)?.data?.runbook?.name || 'Runbook':
                                                        (runbook as any)?.name || 'Runbook';     
                        saveAs(
                            new Blob([JSON.stringify(runbook, null, 4)], {
                                type: 'text/plain;charset=utf-8',
                            }),
                            `${runbookName}.txt`
                        );
                        setDialogState(
                            updateDialogState(newDialogState, false, false, [])
                        );
                    }}
                />
                <CopyToClipboard text={JSON.stringify(runbook)}>
                    <Button active={true} outlined={true} text={STRINGS.runbooks.exportDialog.btnText}
                        icon={IconNames.DUPLICATE}
                        onClick={() => {
                            setDialogState(
                                updateDialogState(newDialogState, false, false, [])
                            );
                        }}
                    />
                </CopyToClipboard>
            </>
        );
        setDialogState(newDialogState);
    } catch (error) {
        const afterDialogState: any = updateDialogState(
            newDialogState, true, false,
            [
                STRINGS.formatString(STRINGS.runbooks.exportDialog.errorText, {
                    variant: STRINGS.runbooks.runbookTextForVariant[variant],
                }),
            ]
        );
        setDialogState(afterDialogState);
    }
}

/** This function updates the filters property of the DO node on imported and duplicated runbooks.  The filters property
 *      contains a variable of the format $node:id.filter which has the node id embedded in it.  Since the ids are changing
 *      during import/export and duplication, we need to update the ids in this token.  This is not ideal but there is no
 *      way around it.
 *  @param newRunbook the RunbookConfig object with the runbook configuration to be imported or duplicated.
 *  @param newIdByOldId the map of new ids by old ids. */
function updateDoFilters(newRunbook: RunbookConfig, newIdByOldId: Record<string, string>): void {
    for (const node of newRunbook.nodes!) {
        if (isDataOceanNode(node)) {
            const nodePropObject = node.properties;
            const filtersValue = nodePropObject['filters'];
            if (filtersValue) {
                for (const filterKey in filtersValue) {
                    if (
                        filtersValue[filterKey] &&
                        filtersValue[filterKey]?.length > 0 &&
                        typeof filtersValue[filterKey][0] === 'string'
                    ) {
                        if (
                            filtersValue[filterKey][0] ===
                            TRIGGER_OPTION_VALUE_OLD
                        ) {
                            // RO deprecated $runbook and prefers $trigger now
                            filtersValue[filterKey][0] = TRIGGER_OPTION_VALUE;
                        } else if (
                            filtersValue[filterKey][0].startsWith(
                                NODE_OPTION_VALUE_AP + ':'
                            )
                        ) {
                            // We want to update all the ids in the $node:id.filter tokens
                            const filterValue = filtersValue[filterKey][0];
                            const filterName = filterValue.substring(
                                filterValue.lastIndexOf('.') + 1,
                                filterValue.length
                            );
                            const nodeId = filterValue.substring(
                                filterValue.indexOf(':') + 1,
                                filterValue.lastIndexOf('.')
                            );
                            filtersValue[
                                filterKey
                            ][0] = `${NODE_OPTION_VALUE_AP}:${newIdByOldId[nodeId]}.${filterName}`;
                        }
                    }
                }
            }
        }
    }
}

/** generates the map of variables by scope.
 *  @param runbook the RunbookConfig with the runbook whose variable scope is requested.
 *  @param variant the runbook Variant that we are extracting the variables from.
 *  @returns an object with the variables by scope. */
function getRuntimeVariables(runbook: RunbookConfig, variant: Variant) {
    const runtimeVariableKey: string = getRuntimeVariablesKey(variant);
    return {
        primitiveVariables: [
            ...(runbook?.[runtimeVariableKey]?.primitiveVariables || []),
            ...RUNBOOK_SCOPE_BUILTIN_VARIABLES,
            ...getRuntimeBuiltinVariables(runbook.triggerType),
        ],
        structuredVariables: [
            ...(runbook?.[runtimeVariableKey]?.structuredVariables || []),
        ],
    };
}

/** returns the key to use for the runtime variables.
 *  @param variant the runbook Variant that we are extracting the variables from.
 *  @returns a String with the key for the runtime variables for the specified variant. */
function getRuntimeVariablesKey(variant: Variant): string {
    return variant === Variant.SUBFLOW
        ? 'subflowVariables'
        : 'runtimeVariables';
}
