import React, { useState, useEffect, useCallback } from "react";
import { parseTimeFromDAL } from "utils/hooks";
import { useStateSafePromise } from "@tir-ui/react-components";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { TimeChart, TimeChartDatum } from "components/common/time-chart/TimeChart";
import { ChartToolbarControls, LineStyle } from "components/common/chart-base/ChartToolbar";
import { STRINGS } from "app-strings";
import { AWSMetricRequest, CloudIMAWSMetrics, CloudIMAWSMetricsDatapoints, CloudIMAWSMetricsError, CloudIMService } from "utils/services/CloudIMApiService";

export interface NetworkOutTimeSeriesViewProps {
    id: string;
    type: string;
    startTime?: Date;
    endTime?: Date;
    period?: number;
    dataSourceId: string;
    region: string;
}

const NetworkOutTimeSeriesView = ({
    id,
    type,
    startTime = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endTime = new Date(),
    period = 3600,
    dataSourceId,
    region,
}: NetworkOutTimeSeriesViewProps) => {
    const [executeSafely] = useStateSafePromise();
    const [data, setData] = useState<CloudIMAWSMetricsDatapoints[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>();

    const formattedStartTime = new Date(startTime.setUTCSeconds(0, 0)).toISOString();
    const formattedEndTime = new Date(endTime.setUTCSeconds(0, 0)).toISOString();

    const fetchData = useCallback(() => {
        setLoading(true);
        if (type && id && dataSourceId && region) {
            const request: AWSMetricRequest = {
                AWSCloudWatchRequest: {
                    MetricName: "NetworkOut",
                    Dimensions: [{
                        Name: type,
                        Value: id
                    }],
                    StartTime: formattedStartTime,
                    EndTime: formattedEndTime,
                    Period: period,
                    Statistics: [
                        "Average",
                        "Maximum",
                    ]
                },
                DataSourceId: dataSourceId,
                Region: region,
            }

            return executeSafely(CloudIMService.getAWSMetrics(request))
                .then((response: CloudIMAWSMetrics | CloudIMAWSMetricsError) => {
                    if ('Error' in response) {
                        throw new Error(response.Error.Code + ": " + response.Error.Message);
                    }

                    setData(response.GetMetricStatisticsResponse.GetMetricStatisticsResult.Datapoints);
                    setLoading(false);
                    setError(undefined);
                })
                .catch((error) => {
                    setData([]);
                    setLoading(false);
                    setError(error);
                });
        } else {
            setData([]);
            setLoading(false);
            setError(undefined);
        }
    }, [executeSafely, type, id, formattedStartTime, formattedEndTime, period, dataSourceId, region]);

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    const tsKeyData: Array<TimeChartDatum> = [
        {
            groupName: STRINGS.cloudim.topology.modal.metrics.average, groupId: STRINGS.cloudim.topology.modal.metrics.average,
            metricName: STRINGS.cloudim.topology.modal.metrics.networkOut, metricId: STRINGS.cloudim.topology.modal.metrics.networkOut,
            unit: new Unit("bytes"),
            data: []
        },
        {
            groupName: STRINGS.cloudim.topology.modal.metrics.max, groupId: STRINGS.cloudim.topology.modal.metrics.max,
            metricName: STRINGS.cloudim.topology.modal.metrics.networkOut, metricId: STRINGS.cloudim.topology.modal.metrics.networkOut,
            unit: new Unit("bytes"),
            data: []
        },
    ];

    if (data && data.length > 0) {
        data.forEach(function (o, index) {
            let timeStamp = parseTimeFromDAL(o.Timestamp)?.getTime();
            let average = o.Average;
            let max = o.Maximum;
            tsKeyData[0].data!.push({ x: timeStamp as any, y: average });
            tsKeyData[1].data!.push({ x: timeStamp as any, y: max });
        }, {});
    }

    const chartOptions: Highcharts.Options = {
        legend: {
            enabled: true,
            align: "center",
            verticalAlign: "bottom",
            floating: false,
        },
    };

    return (
        <DataLoadFacade loading={loading} error={error} data={data}>
            <TimeChart
                primaryData={tsKeyData}
                settings={{ style: LineStyle.line }}
                height="300px"
                showChartSubtitle={false}
                options={chartOptions}
                controls={[ChartToolbarControls.fullScreen, ChartToolbarControls.settings]}
                enableFullScreen={true}
                fullScreenTitle={STRINGS.cloudim.topology.modal.metrics.networkOut}
                transparent={true}
            />
        </DataLoadFacade>
    );
};

export default NetworkOutTimeSeriesView;
