/** This module contains the component for displaying the list of facets.
 *  @module
 */
import React, { useRef, useState, useEffect } from "react";
import { ColumnChooser, Icon, IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { Facet, FacetValue, SearchRequest } from "utils/services/SearchApiService";
import { FacetSection } from "./FacetSection";
import { FacetSettings } from "./FacetSectionSettings";
import { isEmpty } from "lodash";
import { AnchorButton, Button, HTMLSelect, InputGroup, Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { SearchQueryPreference, UserPreferences } from "utils/services/UserPrefsTypes";
import { setUserPreferences, useUserPreferences, TIME_RANGE } from "utils/hooks";
import { TIME_DURATION } from "utils/stores/GlobalTimeStore";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { SEARCH_TYPE } from "pages/incident-search/IncidentSearchPage";
import { getHiddenProps } from "utils/hooks/useCustomProperties";

/** a constant that specifies whether we should show the apply and clear buttons in which case we batch the
 *  facet changes (true) or if we do not batch the facet changes and remove the apply and clear buttons (false). */
const DELAY_FACET_SELECTIONS: boolean = true;

/** this list contains all the facet categories that we created and did not come from search. */
const ARTIFICIAL_FACET_CATEGORIES: string[] = ["incident/duration"];

/** This interface defines the properties passed into the facet view React component.*/
interface FacetProps {
    /** the classname to apply to the div for the component. */
    className?: string;
    /** the list of facets. */
    facets: Record<string, Array<Facet>>;
    /** the explorer type which is being used */
    searchType: SEARCH_TYPE;
    /** the list of custom properties. */
    customProperties: Array<CustomProperty>
    /** a dictionary with the facet limits per category. */
    limitsByFacetCategory: Record<string, number>;
    /** the default facet limit. */
    defaultLimit: number;
    /** the current search request with all the filters including the facets. */
    searchRequest: SearchRequest | undefined;
    /** the search request object to use to do a search with the current time range and search string but no facets. */
    searchRequestWithNoFacets: SearchRequest | undefined;
    /** the handler for facet selection changes. */
    onFacetSelected?: (facetValue: FacetValue, facetCategory: string, selected: boolean) => void;
    /** the handler for facet category selections. */
    onFacetCategorySelected?: (facetCategory: string, selected: boolean) => void;
    /** the handler for facet settings changes. */
    onFacetSettingsChanged: (facetCategory: string, facetSettings: FacetSettings) => void;
    /** the handler for facet settings changes. */
    onFilterSelectionMade: (selections: any) => void;
    /** the handler for load query events. */
    loadQuery?: (queryName: string) => void;
    /** the handler for query save requests. */
    querySaveRequested?: (queryName: string) => void;
    /** the handler for query delete requests. */
    queryDeleteRequested?: (queryName: string) => void;
    /** the array of search queries, if any. */
    searchQueries?: SearchQueryPreference[];
    /** a boolean value, which if true specifies that the query control should be displayed, if false or omitted do not display the control. */
    showQueryControl?: boolean;
    /** the current search string if any. */
    searchText: string;
    /** the current search time or undefined if there is no search time. */
    timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined;
    /** a boolean value, which if true specifies we should sort selected facets at the top.  If false sort by count and alphabetically only. */
    sortSelectedFacetsFirst?: boolean;
    clearAllFilters: () => void;
}

/** Renders the facet view.
 *  @param props the properties passed in.
 *  @returns JSX with the facet view component.*/
const FacetView = (props: FacetProps) => {
    const sections = useRef<Array<any>>([]);
    const [facetCache, setFacetCache] = useState<Record<string, Array<Facet>>>({});
    const [isSaveOpen, setIsSaveOpen] = useState<boolean>(false);
    const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
    const [queryName, setQueryName] = useState<string>("");
    const columnChooser = useRef();
    const handleOpenFacetsChooser = () => {
        if (columnChooser.current) {
            // @ts-ignore
            columnChooser.current.handleOpen();
          }
    };
    const userPreferences = useUserPreferences({listenOnlyTo: {
        explorer_facets: {
            [props.searchType]: ""
        },
        search: {savedQueries: [], showAdvancedSystemProperties: false}
    }});
    // const [isApplied, setIsApplied] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);

    useEffect(
        () => {
            // Every time the facets change in the parent update the facet cache here
            const newFacetCache = JSON.parse(JSON.stringify(props.facets));
            setFacetCache(newFacetCache);
        },
        [props.facets]
    );

    // We need to catch search text and time range changes so we can enable the save button 
    const prevSearchText = useRef<string>(props.searchText);
    const previousTimeRange = useRef<Partial<TIME_RANGE & TIME_DURATION> | undefined>(props.timeRange);
    useEffect(
        () => {
            if (
                prevSearchText.current !== props.searchText ||
                previousTimeRange.current?.startTime !== props.timeRange?.startTime ||
                previousTimeRange.current?.endTime !== props.timeRange?.endTime ||
                previousTimeRange.current?.duration !== props.timeRange?.duration
            ) {
                // setIsApplied(true);
                prevSearchText.current = props.searchText;
                previousTimeRange.current= props.timeRange;
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.searchText, props.timeRange?.startTime, props.timeRange?.endTime, props.timeRange?.duration]
    );

    const facetListJsx: Array<JSX.Element> = [];
    const facetCategories: Array<string> = getSortedFacetCategories();
    const [selectedFacetCategoriesIds, setSelectedFacetCategoriesIds] = useState<Array<string>>([]);
    const preparedFacetCategories = prepareFacetCategories(userPreferences);

    useEffect(() => {
        const userFacets = getUserFacets();

        setSelectedFacetCategoriesIds(
            [...preparedFacetCategories
                .filter(facet => userFacets.includes(facet.id))
                .map(el => el.id)]
        );

        function getUserFacets() {
            const preferencesFacets = userPreferences?.explorer_facets?.[props.searchType];
            const defaultFacets = preferencesFacets 
                ? JSON.parse(preferencesFacets) 
                : facetCategories.map(el => el.toLocaleLowerCase());
                                  
            return defaultFacets;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facetCache])

    for (const facetCategory of facetCategories) {   
        const key = facetCategory.toLowerCase().replace(/ /g, '_');
        const relatedCustomProperty = props.customProperties.find(customProperty => {
            return facetCategory === customProperty.name
        });
        const facetCategoryId = relatedCustomProperty ? `cp_${relatedCustomProperty.id}` : key;

        if (!(selectedFacetCategoriesIds.includes(facetCategoryId))) {
            continue;
        }

        facetListJsx.push(
            <FacetSection
                facets={facetCache[facetCategory]}
                facetCategory={facetCategory}
                isFacetCategoryArtificial={Boolean(ARTIFICIAL_FACET_CATEGORIES.includes(facetCategory))}
                facetCategoryLimit={props.limitsByFacetCategory[facetCategory] || props.defaultLimit}
                searchRequest={props.searchRequest} searchRequestWithNoFacets={props.searchRequestWithNoFacets}
                sortSelectedFacetsFirst={props.sortSelectedFacetsFirst}
                onFacetSelected={(facetValue: FacetValue, facetCategory: string, selected: boolean) => {
                    if (DELAY_FACET_SELECTIONS) {
                        const newFacetCache = JSON.parse(JSON.stringify(facetCache));
                        if (newFacetCache[facetCategory]) {
                            const facets = newFacetCache[facetCategory];
                            for (const facet of facets) {
                                if (facet.value === facetValue) {
                                    facet.selected = selected;
                                    break;
                                }
                            }
                        }
                        setFacetCache(newFacetCache);
                    } else {
                        if (props.onFacetSelected) {
                            props.onFacetSelected(facetValue, facetCategory, selected);
                        }
                    }
                } }
                onFacetCategorySelected={(facetCategory: string, selected: boolean) => {
                    if (DELAY_FACET_SELECTIONS) {
                        const newFacetCache = JSON.parse(JSON.stringify(facetCache));
                        if (newFacetCache[facetCategory]) {
                            const facets = newFacetCache[facetCategory];
                            for (const facet of facets) {
                                facet.selected = selected;
                            }
                        }
                        setFacetCache(newFacetCache);
                    } else {
                        if (props.onFacetCategorySelected) {
                            props.onFacetCategorySelected(facetCategory, selected);
                        }
                    }
                } }
                onFacetSettingsChanged={(facetCategory: string, facetSettings: FacetSettings) => {
                    if (props.onFacetSettingsChanged) {
                        props.onFacetSettingsChanged(facetCategory, facetSettings);
                    }
                } }
                ref={(element) => sections.current.push(element)}
                key={facetCategory} />
        );
    }

    return <div className={(props.className ? " " + props.className : "")} >
        <div className="facet-view-header">
            <div className="d-flex justify-content-between pt-3 display-7">
                <div>{STRINGS.incidentSearch.facetView.title}</div>
                <Popover2 
                    position={Position.LEFT_TOP} 
                    isOpen={isMoreOpen} 
                    content={<div className="w-min-1-5 p-2 pl-3">
                        <div onClick={() => {
                            for (const section of sections.current) {
                                section.expandSection();
                            }
                            setIsMoreOpen(!isMoreOpen);
                        }} className={"text-nowrap py-1"} style={{ cursor: "pointer" }}
                        >
                            <Icon icon={IconNames.EXPAND_ALL} className={"mr-2"} style={{ verticalAlign: "baseline", cursor: "pointer" }} size={14} />
                            <span>{STRINGS.incidentSearch.facetView.menu.expand}</span>
                        </div>
                        <div onClick={() => {
                            for (const section of sections.current) {
                                section.collapseSection();
                            }
                            setIsMoreOpen(!isMoreOpen);
                        }} className={"text-nowrap py-1"} style={{ cursor: "pointer" }}
                        >
                            <Icon icon={IconNames.COLLAPSE_ALL} className={"mr-2"} style={{ verticalAlign: "baseline", cursor: "pointer" }} size={14} />
                            <span>{STRINGS.incidentSearch.facetView.menu.collapse}</span>
                        </div>
                        <div onClick={() => {
                            setIsMoreOpen(!isMoreOpen);
                            handleOpenFacetsChooser();
                        }} className={"text-nowrap py-1 d-flex align-items-center"} style={{ cursor: "pointer" }}
                        >
                            <Icon icon={IconNames.PANEL_TABLE} className={"mr-2"} style={{ verticalAlign: "baseline", cursor: "pointer" }} size={14} />
                            <span>{STRINGS.incidentSearch.facetView.menu.customize}</span>
                        </div>
                    </div>}>
                    <WrapInTooltip tooltip={STRINGS.incidentSearch.filterControl.moreTooltip} >
                        <span className="save-query-more" style={{cursor: "pointer"}}
                            onClick={() => setIsMoreOpen(!isMoreOpen)} 
                        >
                            <Icon icon={IconNames.MORE} style={{verticalAlign: "baseline", cursor: "pointer"}} size={16} />
                        </span>
                    </WrapInTooltip>
                </Popover2>
            </div>

            {selectedFacetCategoriesIds.length > 0 &&
                <ColumnChooser
                    ref={columnChooser}
                    columns={preparedFacetCategories}
                    selectedIds={selectedFacetCategoriesIds}
                    handleColumnsUpdate={(facets) => {
                        setSelectedFacetCategoriesIds([...facets]);
                        setUserPreferences({
                            explorer_facets: {
                                [props.searchType]: JSON.stringify(facets),
                            },
                        });
                    }}
                    enableDragging={false}
                    isFacet={true}
                    title={STRINGS.incidentSearch.facetsChooser.title}
                    lhsTitle={STRINGS.incidentSearch.facetsChooser.availableFacets}
                    rhsTitle={STRINGS.incidentSearch.facetsChooser.selectedFacets}
                />
            }

            {/* start here - SAVE QUERY */}
            {props.showQueryControl && <div className="pt-3 pb-4">
                <label className="d-block display-9 font-weight-normal">Saved queries</label>
                <HTMLSelect
                    className="save-query-load"
                    data-testid="saved-query-selector"
                    options={[
                        {label: STRINGS.incidentSearch.filterControl.noneSelectedOption, value: "none"}]
                        .concat((props.searchQueries || [])
                        .map((query: any) => {return {label: query.name, value: query.name}})
                    )}
                    onChange={(e) => {
                        setQueryName(e.currentTarget.value !== "none" ? e.currentTarget.value : "");
                        setCanDelete(e.currentTarget.value !== "none");
                        if (e.currentTarget.value !== "none" && props.loadQuery) {
                            props.loadQuery(e.currentTarget.value);
                        }
                    }}
                >
                </HTMLSelect>
                <WrapInTooltip tooltip={STRINGS.incidentSearch.filterControl.deleteTooltip} >
                    <AnchorButton 
                        icon={IconNames.TRASH} className="ml-2 align-middle save-query-delete" style={{verticalAlign: "baseline", cursor: !canDelete ? "not-allowed" : "pointer"}} minimal 
                        disabled={!canDelete}
                        onClick={() => {
                            if (props.queryDeleteRequested) {
                                props.queryDeleteRequested(queryName);
                            }
                            setCanDelete(false);
                        }}
                    />
                </WrapInTooltip>
                <Popover2 position={Position.LEFT_TOP} 
                    isOpen={isSaveOpen}
                    content={<>
                        <div className={"p-2" + (props.className ? " " + props.className : "")} >
                            <label className="mr-2">Query name:</label>
                            <InputGroup
                                name="query-name"
                                onChange={e => setQueryName(e.currentTarget.value)}
                                value={queryName}
                                disabled={false}
                            />
                        </div>
                        <div className="d-flex justify-content-between w-100 p-2">
                            <Button onClick={() => {
                                setIsSaveOpen(false);
                                setQueryName("");
                            }}>{STRINGS.incidentSearch.facetCTA.cancelQuery}</Button>
                            <AnchorButton intent="primary"
                                text={STRINGS.incidentSearch.facetCTA.saveQuery}
                                className="display-9"
                                disabled={queryName.trim().length === 0}
                                onClick={() => {
                                    if (props.querySaveRequested) {
                                        props.querySaveRequested(queryName);
                                    }
                                    setIsSaveOpen(false);
                                    setQueryName("");
                                }}
                                data-testid="save-query-button"
                            />
                        </div>
                    </>} 
                >
                    <WrapInTooltip tooltip={STRINGS.incidentSearch.filterControl.saveTooltip} >
                        <AnchorButton 
                            icon={IconNames.FLOPPY_DISK} className="save-query-cta" style={{verticalAlign: "baseline", cursor: "pointer"}} minimal 
                            onClick={() =>  setIsSaveOpen(!isSaveOpen)}
                        />
                    </WrapInTooltip>
                </Popover2>
            </div>}
        </div>
        <div data-testid="FacetList-container" className={"font-weight-normal py-5"} >
            {facetListJsx}
        </div>
        {/* Sticky Apply Filters */}
        {DELAY_FACET_SELECTIONS && !isEmpty(facetCache) && <div className="facet-cta d-flex justify-content-between">
            <Button outlined={true} intent="none" icon={IconNames.TRASH} text={STRINGS.incidentSearch.facetCTA.clear} onClick={() => {
                if (props.onFilterSelectionMade) {
                    props.onFilterSelectionMade({});
                }
                // We have a case where if we add a filter and then clear it without applying it, the component does not
                // re-render and the cache does not update, so clear the cache to handle this case
                const newFacetCache = JSON.parse(JSON.stringify(facetCache));
                for (const facetCategory in newFacetCache) {
                    for (const facet of newFacetCache[facetCategory]) {
                        facet.selected = false;
                    }
                }
                setFacetCache(newFacetCache);
                // NOTE: careful eyes will see that the `<FilterView />`
                // component uses a similar operation above for clearing facets but
                // within the `<FacetView />` we generate the `facetCache`
                // within the component by feeding in the `facets` from the
                // `<IncidentSearchPage />`. Tried to rework the
                // `clearAllFiltersHandler()` to include a facetCache as a param
                // but got issues in the `<FilterView />` since that does not
                // generate a facetCache similar to this component. Extracting
                // it out to use the parent level component introduced type
                // issues that took longer than antcipated to try to fix so
                // opting for a "duplicated" operation. Profiling the component
                // does not trigger a rerender outside of refreshing the page;
                // but if performance degrades it may be possible to tune up
                // this event handler to be more efficient (not creating a new
                // `facetCache` within `<FacetView />`, using the parent's
                // cache).
                props.clearAllFilters()
            }} />
            <Button
                intent="primary"
                icon={IconNames.FILTER}
                onClick={() => {
                    if (props.onFilterSelectionMade) {
                        const selections: Record<string, Array<FacetValue>> = {};
                        for (const facetCategory in facetCache) {
                            for (const facet of facetCache[facetCategory]) {
                                if (facet.selected) {
                                    if (!selections[facetCategory]) {
                                        selections[facetCategory] = [];
                                    }
                                    selections[facetCategory].push(facet.value);
                                }
                            }
                        }
                        props.onFilterSelectionMade(selections);
                    }
                }}
                text={STRINGS.incidentSearch.facetCTA.apply}
            />
        </div>}


    </div>;

    /**
     * Get the list of facet categories sorted by weight
     * 
     * @returns {Array} Sorted facet categories
     */
    function getSortedFacetCategories() {
        const facetCategories: Array<string> = Object.keys(facetCache);
        facetCategories.sort((catA: string, catB: string) => {
            const nameA = STRINGS.incidentSearch.facetView.facets[catA]?.name || catA;
            const nameB = STRINGS.incidentSearch.facetView.facets[catB]?.name || catB;
            const orderA = STRINGS.incidentSearch.facetView.facets[catA]?.order ||
                STRINGS.incidentSearch.facetView.facets[catA]?.order === 0
                ? STRINGS.incidentSearch.facetView.facets[catA]?.order
                : 100;
            const orderB = STRINGS.incidentSearch.facetView.facets[catB]?.order ||
                STRINGS.incidentSearch.facetView.facets[catB]?.order === 0
                ? STRINGS.incidentSearch.facetView.facets[catB]?.order
                : 100;
            // make undefined have an order of 100
            if (orderA < orderB) {
                return -1;
            }
            if (orderA > orderB) {
                return 1;
            }
            if (orderA === 100 && orderB === 100) {
                return nameA.localeCompare(nameB);
            }
            return nameA.localeCompare(nameB);
        });
        return facetCategories;
    }

    /** Replace name with id for custom properties related facets, and add 
     *      required object properties for the FacetsChooser component
     *  @param userPreferences the UserPreference object with the search preferences that have the 
     *      showAdvancedSystemProperties setting that specifies whether some of the system custom 
     *      properties are hidden because they are under development.
     *  @returns Array of facets for the facet chooser. */
    function prepareFacetCategories(userPreferences: UserPreferences) {
        return facetCategories.map((category => {
            const key = category.toLowerCase().replace(/ /g, '_');
            const customPropertyRelatedToFacet = (props.customProperties.find(el => el.name === category));
            const id = customPropertyRelatedToFacet ? `cp_${customPropertyRelatedToFacet.id}` : key;
            const label = STRINGS.incidentSearch.facetView.facets[category]?.name ||
                category[0] + category.slice(1);
            const facetCategory = {
                id,
                key,
                label,
                isSelected: false
            };

            return facetCategory;
        })).filter(category => !getHiddenProps(userPreferences).includes(category.label));
    }
};

export default FacetView;
