/** This module contains an editor for the graph properties.
 *  @module
 */
import React, { Component } from 'react';
import { NodeDef, NodeWiresSetting, NodeProperty, RunbookInfo, GraphDef, Variant } from './types/GraphTypes';
import { UniversalNode } from './UniversalNode';
import { SimpleNodeEditor } from './editors/simple/SimpleNodeEditor';
import SwitchNodeEditor from './editors/switch/SwitchNodeEditor';
import { DataOceanNodeEditor } from "./editors/data-ocean/DataOceanNodeEditor";
import { TableNodeEditor } from "./editors/table/TableNodeEditor";
import { Button, Callout, Checkbox, InputGroup, Intent, TextArea } from '@blueprintjs/core';
import { STRINGS } from "app-strings";
import { ScrollableErrorList } from "../error/ScrollableErrorList";
import { dataOceanNodes } from "../../../utils/runbooks/RunbookUtils";
import { DataOceanUtils } from "./editors/data-ocean/DataOceanUtils";
import { LogicNodeEditor } from './editors/logical/LogicNodeEditor';
import { AggregatorNodeEditor} from './editors/aggregator/AggregatorNodeEditor';
import { NodeLibrary, NodeLibraryNode, NodeLibrarySubType } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { DecisionNodeEditor } from './editors/decision/DecisionNodeEditor';
import { NodeUtils } from 'utils/runbooks/NodeUtil';
import { TransformNodeEditor } from './editors/transform/TransformNodeEditor';
import { HttpNodeEditor } from './editors/http/HttpNodeEditor';
import { TriggerNodeEditor } from './editors/trigger/TriggerNodeEditor';
import { InputNodeEditor } from './editors/input/InputNodeEditor';
import { SetSimpleVariablesEditor } from './editors/set-variables-simple/SetSimpleVariablesEditor';
import { SetComplexVariableEditor } from './editors/set-variable-complex/SetComplexVariableEditor';
import { SubflowInputNodeEditor } from './editors/subflow-input/SubflowInputNodeEditor';
import { SubflowNodeEditor } from './editors/subflow/SubflowNodeEditor';
import { OnDemandInputNodeEditor } from './editors/on-demand-input/OnDemandInputNodeEditor';
import { RunbookContext } from '../../../utils/runbooks/RunbookContext.class';
import { startCase, toLower } from 'lodash';
import { RunbookNode } from 'utils/services/RunbookApiService';
import { AiNodeEditor } from './editors/ai/AiNodeEditor';
import './NodeEditorPanel.scss';

/*Node type to editor mapping*/
const NodeEditors = {
    'data_ocean': DataOceanNodeEditor,
    'switch': SwitchNodeEditor,
    'logic': LogicNodeEditor,
    'decision': DecisionNodeEditor,
    'aggregator': AggregatorNodeEditor,
    'rvbd_ui_table': TableNodeEditor,
    'transform': TransformNodeEditor,
    'http': HttpNodeEditor,
    'trigger': TriggerNodeEditor,
    'input': InputNodeEditor,
    'set_primitive_variables': SetSimpleVariablesEditor,
    'set_structured_variable': SetComplexVariableEditor,
    'subflow_input': SubflowInputNodeEditor,
    'subflow': SubflowNodeEditor,
    'on_demand_input': OnDemandInputNodeEditor,
    'ai': AiNodeEditor
};

/** This interface defines the properties passed into the node editor React component.*/
interface NodeEditorPanelProps {
    /** the selected node. */
    selectedNode?: UniversalNode;
    /** the libaray node that describes the editable properites. */
    libraryNode?: NodeLibraryNode;
    /** the array of global nodes. */
    globalNodes: Array<NodeDef>;
    /** a callback that is called when the user finishes editing the node and the node properties are updated. */
    onNodeEdited?: (node: UniversalNode) => void;
    /** Currently active runbook info*/
    activeRunbook: RunbookInfo;
    /** information about all nodes and their parent child relationships*/
    graphDef: GraphDef;
    /** a reference to the NodeLibrary. */
    nodeLibrary: NodeLibrary;
    /** Callback to be called when user attempts to cancel out of the panel */
    onCancel?: () => void;
    /** a boolean value which specifies whether node errors are currently visible. */
    showNodeErrors?: boolean;
    /** the runbook variant that is currently being edited. */
    variant: Variant;
    /** the array of RunbookNode objects with the list of subflows. */
    subflows: RunbookNode[];
    /** runtime variables changes handler */
    onRuntimeOrSubflowVariableEdited?: (updatedVariablesList) => void;
}

/** This interface defines the state of the editor panel. */
interface NodeEditorPanelState {
    /** an array of string with the current set of error messages.*/
    errorMessages: Array<string>;
    valueChanged: boolean;
    saveAndCloseBtnDisabled: boolean;
}

/** this class defines a react component for editing the properties in a node. */
export default class NodeEditorPanel extends Component<NodeEditorPanelProps, NodeEditorPanelState> {
    /** the input from the name text box. */
    private nameInput: any;
    /** the input from the info text box. */
    private infoInput: any;
    /** a boolean value that specifices whether debug is enabled. */
    private debugInput: any;
    /** a reference to the editor. */
    private editor: any;
    /** a unique integer used as keys for the controls. */
    private controlId: number = 1;
    /** the type of this node. */
    private type: string | null;
    /** a boolean value which specifies whether node errors are currently visible. */
    private showNodeErrors: boolean;
 
    /** the constructor for the class
     *  @param props the properties passed in to the component.*/
    constructor(props: NodeEditorPanelProps) {
        super(props);

        this.nameInput = React.createRef();
        this.infoInput = React.createRef();
        this.debugInput = React.createRef();
        this.editor = React.createRef();
        this.state = {
            errorMessages: [],
            valueChanged: false,
            saveAndCloseBtnDisabled: false,
        };

        this.nameInput.current = { name: "" };
        this.infoInput.current = { info: "" };
        this.debugInput.current = { debug: false };
        
        this.type = this.props.selectedNode?.getType() || null;
        this.showNodeErrors = this.props.showNodeErrors || false;
    }

    componentDidMount() {
        this.nameInput.current.value = (this.props.selectedNode ? this.props.selectedNode.getName() || "" : "");
        this.infoInput.current.value = (this.props.selectedNode ? this.props.selectedNode.getInfo() || "" : "");
        this.debugInput.current.checked = (this.props.selectedNode ? this.props.selectedNode.getProperty("debug") || false : false);
    }

    /** Renders the node editor panel. .
     *  @returns JSX with the node editor display panel.*/
    render(): JSX.Element {
        if (this.type) {
            // Checks if there are any configuration errors. If yes then display the errors and avoid further rendering.
            // We can add multiple configuration checks in the `checkNodeError` function to avoid UI from crashing due to
            // those invalid configuration. Currently it checks only for DataOceanNode
            const subType = this.props.libraryNode?.hasOwnProperty("subType") ? (this.props.libraryNode as NodeLibrarySubType).subType : "";
            let inputMustBeConnected = Boolean(this.props.libraryNode?.uiAttrs?.connectInputToEdit);
            if (inputMustBeConnected) {
                if (!NodeUtils.getParentNode(this.props.selectedNode?.getId() || "", this.props.graphDef)) {
                    return <div className="mt-5 pt-5 display-8 text-center">{STRINGS.runbookEditor.nodeEditor.connectInputMsg}</div>;
                }
            }
            const nodeErrors = checkForNodeErrors(this.type, this.props.selectedNode);
            if (nodeErrors && nodeErrors.length) {
                return <ScrollableErrorList items={nodeErrors}/>
            }
            const nodeStrings = NodeLibrary.getNodeResourceStrings(this.type, subType);
            const {data: nodeData}: any = this.props.selectedNode?.node || {};
            const nodeType = nodeData?.type;
            let subflowOutputType: string | undefined;
            if (nodeType === "subflow_output" && nodeData) {
                const nodeId = this.props.selectedNode?.getId();
                if (nodeId) {
                    nodeData.id = this.props.selectedNode?.getId();
                    const context = new RunbookContext(
                        nodeData, this.props.graphDef, DataOceanUtils.dataOceanMetaData
                    );
                    subflowOutputType = getSubflowOutputType(this.props.graphDef, context);
                }
            }

            const nodeTopHint = nodeStrings.editorHint;

            return <div className="node-editor-content display-8 pb-3" onChange={() => {
                    if (!this.state.valueChanged) {
                        this.setState({
                            ...this.state,
                            valueChanged: true,
                        });
                    }
                }}>
                { nodeTopHint && <Callout intent={Intent.PRIMARY} className="mb-4 display-9">{nodeTopHint}</Callout> }
                <table><tbody>
                <tr>
                    <td className="p-1" colSpan={2}>
                        <label className='mb-0 pb-1'>{STRINGS.runbookEditor.nodeEditor.nameLabel}</label>
                        <InputGroup id="node-name-entry" name="node-name-entry" key="node-name-entry" type="text"
                        defaultValue={this.nameInput?.current?.value} inputRef={this.nameInput} className="editor-input-standard"
                    /></td>
                </tr>
                {subflowOutputType && <tr>
                    <td className="p-1" colSpan={2}><p>{STRINGS.runbookEditor.nodeEditor.subflowOutputTypeLabel} {subflowOutputType}</p></td>
                </tr>}
                {
                    ((nodeType === "subflow" && this.props.libraryNode?.subflowDescription) || 
                    this.props.libraryNode?.uiAttrs?.showDescriptionField) &&
                    <tr>
                        <td className="p-1">
                            {nodeType !== "subflow" && <>
                            <label className='mb-0 pb-1'>{STRINGS.runbookEditor.nodeEditor.infoLabel}</label><br />
                            <TextArea fill={true} id="node-info-entry" name="node-info-entry" key="node-info-entry"
                                defaultValue={this.infoInput?.current?.value} inputRef={(element) => {this.infoInput.current = element;}}
                                className="editor-input-textarea mb-3" rows={5}
                            /></>}{nodeType === "subflow" && <Callout intent={Intent.PRIMARY} className="subflowDescription">{this.props.libraryNode?.subflowDescription}</Callout>}</td>
                    </tr>
                }
                {
                    this.props.libraryNode?.uiAttrs?.showDebug &&
                    <tr>
                        <td className="p-1" colSpan={2}><Checkbox type="checkbox" id="node-debug-entry" name="node-debug-entry"
                            label={STRINGS.runbookEditor.nodeEditor.debugLabel}
                            defaultChecked={Boolean(this.debugInput?.current?.checked)}
                            onChange={(event: any) => {/*this.properties.current[prop.name] = event.target.checked;*/}}
                            inputRef={(element) => {this.debugInput.current = element;}}
                            //checked={this.state[prop.name]}
                            //onChange={(event: any) => this.setState({ [prop.name]: event.target.checked })}
                        /></td>
                    </tr>
                }
                {this.getNodeEditor(this.type)}
                </tbody>
                    <tfoot>
                        { this.state.errorMessages.length ?
                            <tr>
                                <td colSpan={2}>
                                    <ScrollableErrorList items={this.state.errorMessages}/>
                                    <br/>
                                </td>
                            </tr>
                        :
                            null
                        }
                        <tr>
                            <td colSpan={2} className="pt-4">
                                {
                                    this.props.onCancel &&
                                    <Button
                                        className="mr-2"
                                        aria-label="cancel"
                                        type="button"
                                        onClick={() => {
                                            if (this.props.onCancel) {
                                                this.props.onCancel();
                                            }
                                        }}
                                    >
                                        { STRINGS.runbookEditor.nodeEditor.cancelBtnText }
                                    </Button>
                                }
                                <Button
                                    intent={Intent.SUCCESS}
                                    aria-label="submit"
                                    disabled={!this.state.valueChanged || this.state.saveAndCloseBtnDisabled}
                                    type="submit"
                                    onClick={() => this.saveChanges()}
                                >
                                    { STRINGS.runbookEditor.nodeEditor.doneBtnText }
                                </Button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>;
        } else {
            console.error("Unable to configure node because node type is not found");
            return <div className="text-center">{STRINGS.ERRORS.defaultMessage}</div>;
        }
    }

    /** saves the changes from the node editor. */
    public saveChanges(): void {
        this.setState({errorMessages: []});
        let errorMessages = new Array<any>();
        if(this.editor.current) {
            if (this.editor.current.validate) {
                errorMessages = this.editor.current.validate();
            }
            if (errorMessages && errorMessages.length) {
                this.setState({errorMessages: errorMessages});
            } else {
                this.editor.current.updateNode();
            }
        }

        if (!errorMessages.length) {
            this.updateNode();
            if(this.props.onNodeEdited && this.props.selectedNode) {
                this.props.onNodeEdited(this.props.selectedNode);
            }
        }
    }

    /** returns the correct editor for the specified type.
     *  @param type the node type.
     *  @returns the react element with the node.*/
    private getNodeEditor(type: string): JSX.Element {
        const editorProps = {
            selectedNode: this.props.selectedNode,
            libraryNode: this.props.libraryNode,
            globalNodes: this.props.globalNodes,
            activeRunbook: this.props.activeRunbook,
            graphDef: this.props.graphDef,
            nodeLibrary: this.props.nodeLibrary,
            variant: this.props.variant,
            subflows: this.props.subflows,
            saveAndCloseBtnDisable: (buttonDisabled) =>
            this.setState({
                ...this.state,
                saveAndCloseBtnDisabled: buttonDisabled,
            }),
            onRuntimeOrSubflowVariableEdited: this.props.onRuntimeOrSubflowVariableEdited,
            handleChange: () =>
                this.setState({
                    ...this.state,
                    valueChanged: true,
                })
        }
        let EditorComponent = SimpleNodeEditor;
        if (type) {
            EditorComponent = NodeEditors[type] || EditorComponent;
        }
        return <EditorComponent {...editorProps} ref={this.editor}/>
    }

    /** updates the selected node with the current form values. */
    private updateNode (): void {
        const nameValue = this.nameInput.current.value;
        if (nameValue !== this.props?.selectedNode?.getName() && this.props.selectedNode) {
            this.props.selectedNode.setName(nameValue);
        }

        const infoValue = this.infoInput.current.value;
        if (infoValue !== this.props?.selectedNode?.getInfo() && this.props.selectedNode) {
            this.props.selectedNode.setInfo(infoValue);
        }

        const debugValue = this.debugInput.current.checked;
        if (debugValue !== this.props?.selectedNode?.getProperty("debug") && this.props.selectedNode) {
            this.props.selectedNode.setProperty("debug", debugValue);
        }

        const errorMessages = this.state.errorMessages;
        if (this.showNodeErrors && this.props.selectedNode
            && JSON.stringify(errorMessages) !== JSON.stringify(this.props.selectedNode?.getData()?.['errors']) && this.props.selectedNode?.getData()?.['errors']?.length > 0) {
            this.props.selectedNode.setData('errors', errorMessages);
        }

        // Update the node
        if (this.props.selectedNode) {
            const NodeDef: NodeDef = {
                id: this.props.selectedNode.getId() || "",
                name: nameValue,
                type: this.props.selectedNode.getType() || "",
                info: infoValue,
                color: this.props.selectedNode.getColor() || "",
                wires: this.props.selectedNode.getWires() || { direction: "none" } as NodeWiresSetting,
            };
            if (this.props.libraryNode && this.props.libraryNode.properties) {
                NodeDef.properties = [];
                for (const prop of this.props.libraryNode.properties) {
                    const nodePropValue = this.props.selectedNode.getProperty(prop.name);
                    if (nodePropValue !== null && nodePropValue !== undefined) {
                        NodeDef.properties.push({ key: prop.name, value: nodePropValue })
                    }
                }
            }
            if (this.props.libraryNode && this.props.libraryNode.env) {
                NodeDef.env = this.props.selectedNode.getEnvSettings();
            }
            const passThruProperties: Array<NodeProperty> | null | undefined = this.props.selectedNode.getPassThroughProperties();
            if (passThruProperties) {
                NodeDef.passThruProperties = ([] as Array<NodeProperty>).concat(passThruProperties);
            }
        }
    }
}

/*** Checks if the node has valid configuration. If no it returns a list of error messages
 *  @param type the node type.
 *  @param selectedNode the currently selected node.
 *  @returns any errors found in the node's configuration. */
 const checkForNodeErrors = (type, selectedNode): Array<string> => {
    const errors = new Array<string>();
    // check if node is a data ocean node and has a valid objType
    if (type && dataOceanNodes.includes(type) && !DataOceanUtils.isValidDataOceanObjectType(selectedNode.getProperty("objType")) ) {
        errors.push(STRINGS.runbookEditor.errors.doNode.invalidDataOceanNode);
    }
    return errors;
};

/*** Returns the subflow output type
 *  @param graphDef the GraphDef object with the graph data.
 *  @param context the node runbook context.
 *  @returns the subflow output type. */
export function getSubflowOutputType(graphDef: GraphDef, context: RunbookContext) {   
    let subflowOutputType: string | undefined;
    let getSubflowOutputFormatFromSubflowInput = false;
    let subflowOutputContextLast: any;
    if (context?.nodeContexts.length) {
        subflowOutputContextLast = context.nodeContexts[context.nodeContexts.length-1];
        const subflowOutputContextReversed = context.nodeContexts.reverse();
        loopSubflowOutputContextReversed: for (const subflowOutputContext of subflowOutputContextReversed) {
            const nodeId = subflowOutputContext.source.id;
            for (const graphDefNode of graphDef.nodes) {
                if (graphDefNode.id === nodeId && (["aggregator", "transform", "http", "data_ocean"].includes(graphDefNode.type))) {
                    if (graphDefNode.type === "aggregator") {
                        if (graphDefNode?.properties) {
                            const groupBy: any = graphDefNode.properties.find(item => item.key === 'groupBy');
                            subflowOutputType = groupBy?.value?.length ? 'Custom aggregation by ' + groupBy.value.join(', ') : 'Custom aggregation';
                        }
                    } else if (graphDefNode.type === "transform") {
                        if (graphDefNode?.properties) {
                            let useVariableDefinition: any = graphDefNode.properties.find(item => item.key === 'useVariableDefinition');
                            subflowOutputType = useVariableDefinition?.value ? useVariableDefinition.value.replace('subflow.','') : 'Custom';
                        }
                    } else if (graphDefNode.type === "http") {
                        subflowOutputType = graphDefNode.type;
                    } else {
                        subflowOutputType = subflowOutputContext?.keys[0];
                        if (subflowOutputType === "network_interface") {
                            subflowOutputType = "interface";
                        } else if (subflowOutputType === "network_device" || subflowOutputType === "cpu_util_type" || subflowOutputType === "memory_type" || subflowOutputType === "disk_path") {
                            subflowOutputType = "device";
                        }
                        if (subflowOutputType) {
                            subflowOutputType = `${startCase(toLower(subflowOutputType.replace(/_/g, ' ')))}(s)`;
                        }
                    }
                    break loopSubflowOutputContextReversed;
                }
            }
        }
    }
    if (!subflowOutputType) {
        subflowOutputType = context?.triggerContext?.keys[0];
        getSubflowOutputFormatFromSubflowInput = true;
        if (subflowOutputType === "network_interface") {
            subflowOutputType = "interface";
            subflowOutputType = `${startCase(toLower(subflowOutputType.replaceAll('_', ' ')))}(s)`;
        } else if (subflowOutputType === "network_device" || subflowOutputType === "cpu_util_type" || subflowOutputType === "memory_type" || subflowOutputType === "disk_path") {
            subflowOutputType = "device";
            subflowOutputType = `${startCase(toLower(subflowOutputType.replaceAll('_', ' ')))}(s)`;
        } else if (subflowOutputType === "json_input" || subflowOutputType === "json") {
            subflowOutputType = "JSON";
        }
    }
    if (subflowOutputType) {
        if (subflowOutputContextLast?.isTimeseries || (getSubflowOutputFormatFromSubflowInput && context?.triggerContext?.isTimeseries)) {
            subflowOutputType += ' (Time series)';
        } else if ((subflowOutputContextLast && 'isTimeseries' in subflowOutputContextLast) || (context?.triggerContext && 'isTimeseries' in context.triggerContext)) {
            subflowOutputType += ' (Summarized)';
        }
    }
    return subflowOutputType;
}
