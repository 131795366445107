/** This module contains the CloudIMService that can be used to query the CloudIM APIs
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';

// The URL for the API server.
export const CLOUDIM_BASE_URL = '/api/affogato/';

export type AWSMetricRequest = {
    AWSCloudWatchRequest: {
        MetricName: string,
        Dimensions: [{
            Name: string,
            Value: string
        }],
        StartTime: string,
        EndTime: string,
        Period: number,
        Statistics: string[]
    },
    DataSourceId: string,
    Region: string,
}

export type CloudIMAWSMetricsDatapoints = {
    Average: number,
    Maximum: number,
    Minimum: number,
    Sum: number,
    Timestamp: number,
    Unit: string,
}

export type CloudIMAWSMetrics = {
    GetMetricStatisticsResponse: {
        GetMetricStatisticsResult: {
            Datapoints: CloudIMAWSMetricsDatapoints[],
            Label: string,
        },
        ResponseMetadata: {
            RequestId: string,
        }
    }
}

export type CloudIMAWSMetricsError = {
    Error: {
        Code: string,
        Message: string,
        Type: string,
    },
    RequestId: string,
}

/** this class defines the CloudIMApiService. */
class CloudIMApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        super(CLOUDIM_BASE_URL);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/`;
        } else {
            return this.baseApiUri;
        }
    }

    /** returns all AWS Metrics.
     *  @returns a Promise which resolves to the returned metrics. */
    getAWSMetrics(requestBody: AWSMetricRequest): Promise<CloudIMAWSMetrics | CloudIMAWSMetricsError> {
        const utid = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.post<CloudIMAWSMetrics | CloudIMAWSMetricsError>(`cloudim/1.0/tenants/${utid}/metrics`, requestBody).then(
                (results) => {
                    resolve(results);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }
}

/** a constant with an instance of the CloudIMApiService. */
const CloudIMService = new CloudIMApiService();
export { CloudIMService };
