import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    ErrorToaster,
    IconNames,
    LoadingOverlay,
    useStateSafePromise,
} from "@tir-ui/react-components";
import { LangEN, HELP, STRINGS } from "app-strings";
import {
    IconName,
    InputGroup,
    Tab,
} from "@blueprintjs/core";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { DEFAULT_TAB_URL_STATE_KEY, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { IntegrationConfigModalTabs, IntegrationConfigurationModal } from "./modals/IntegrationConfigurationModal";

import "./IntegrationsLibraryPage.scss";
import {
    AvailableIntegration,
    AvailableIntegrationDetails,
    InstalledIntegration,
    INTEGRATION_PROCESS_VARIANT,
    IntegrationAvatarVariants,
    IntegrationIconsList,
    IntegrationProcess,
} from "./types/IntegrationTypes";
import { IntegrationErrorModal } from "./modals/IntegrationErrorModal";
import { getQueryParam } from "utils/hooks/useQueryParams";
import IntegrationCard, { IntegrationCardData } from "./components/IntegrationCard";
import { useQuery } from "utils/hooks";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/data-hub";
import { DURATION, durationToRoundedTimeRange } from "utils/stores/GlobalTimeStore";
import { PARAM_NAME } from "components/enums/QueryParams";

type IntegrationsLibraryTabs = "installed" | "available";

/** Renders the device list page.
 *  @param props the properties passed in.
 *  @returns JSX with the device list page.*/
const IntegrationsLibraryPage = (): JSX.Element => {
    const urlSelectedTab: IntegrationsLibraryTabs = getQueryParam(DEFAULT_TAB_URL_STATE_KEY) === "available" ? "available" : "installed";
    const [activeTab, setActiveTab] =
        useState<IntegrationsLibraryTabs>(urlSelectedTab);
    const [searchTerm, setSearchTerm] = useState(getQueryParam(PARAM_NAME.searchText) || '');
    const translations: LangEN['INTEGRATIONS_PAGE'] = STRINGS.INTEGRATIONS_PAGE;
    const [availableIntegrations, setAvailableIntegrations] = useState<
        Array<AvailableIntegration>
    >([]);
    const [rawAvailableIntegrations, setRawAvailableIntegrations] = useState<
        Array<AvailableIntegration>
    >([]);
    const [installedIntegrations, setInstalledIntegrations] = useState<
        Array<InstalledIntegration>
    >([]);
    const integrations: Array<IntegrationCardData> = useMemo(() => {
        return getIntegrationsAsCards(
            availableIntegrations,
            installedIntegrations
        );
    }, [availableIntegrations, installedIntegrations]);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery({
        name: 'EdgeConfig',
        query: new Query(loader('../edge-configuration/queries/edge-config.graphql')),
        queryVariables: {
            ...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
        },
    });

    const integrationConfigModalRef = useRef(null);
    const integrationErrorModalRef = useRef(null);

    const [executeSafely] = useStateSafePromise();

    const fetchInstalledIntegrations = useCallback(async () => {
        try {
            const result: any = await executeSafely(
                IntegrationLibraryService.getInstalledIntegrations()
            );

            return result;
        } catch (error) {
            console.error(error);
            ErrorToaster({ message: translations.unableToRetrieveIntegrations });
        }
    }, [executeSafely, translations.unableToRetrieveIntegrations]);

    const fetchAvailableInstallations = useCallback(async () => {
        try {
            const result: any = await executeSafely(
                IntegrationLibraryService.getAvailableIntegrations()
            );

            return result;
        } catch (error) {
            console.error(error);
        }
    }, [executeSafely]);

    const fetchIntegrationDetails = useCallback(
        async (
            id: string | undefined,
            version: string | undefined,
        ) => {
            try {
                const result = await executeSafely(IntegrationLibraryService.getAvailableIntegration(id, version));

                return result;
            } catch (error) {
                console.error(error);
                throw new Error(translations.integrationDetailsModal.errors.failedToLoadIntegration)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [executeSafely]
    );

    /** Load the integrations data */
    useEffect(() => {
        fetchIntegrations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Get the integrations list
     */
    async function fetchIntegrations() {
        try {
            setLoading(true);
            const [available, installed] = await Promise.all([
                fetchAvailableInstallations(),
                fetchInstalledIntegrations(),
            ]);

            setRawAvailableIntegrations(available);
            setInstalledIntegrations(installed);

            // If there are no installed integrations, move user to available tab
            if (installed?.length === 0) {
                setActiveTab("available");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(
        () => {
            const filteredAvailable = (rawAvailableIntegrations || []).filter(integration => data?.edges?.length !== 0 || integration.id !== "Nexthink");
            setAvailableIntegrations(filteredAvailable);
        }, [rawAvailableIntegrations, data]
    );

    /**
     *
     * @param availableIntegrations List of available integrations
     * @param installedIntegrations List of installed integrations
     *
     * @returns {Array<IntegrationCardData>} A list of mapped integrations for the use in the UI cards
     */
    function getIntegrationsAsCards(
        availableIntegrations: Array<AvailableIntegration>,
        installedIntegrations: Array<InstalledIntegration>
    ): Array<IntegrationCardData> {
        let integrations: Array<IntegrationCardData> = [];

        if (!availableIntegrations || availableIntegrations.length === 0) {
            return [];
        }

        for (let i = 0; i < availableIntegrations.length; i++) {
            const availableIntegration = availableIntegrations[i];
            const matchingInstalledIntegration = (installedIntegrations || []).find(
                (installedIntegration) =>
                    installedIntegration.integrationId ===
                    availableIntegration.id
            );

            // The integration is not installed
            if (!matchingInstalledIntegration) {
                integrations.push({
                    id: availableIntegration.id,
                    name: availableIntegration.name,
                    version: availableIntegration.version,
                    description: availableIntegration.description.short, // The short description in the API
                    icons: availableIntegration.branding.icons,
                    isBeta: availableIntegration.isBeta, // Flag returned from the API
                    isUpgradable: false, // We need to check if there is a matching available integration with a larger version
                    isInstalled: false, // We need to check if this is an installed integration
                    status: null,
                });

                continue;
            }

            // There is an update available
            const hasAnUpdate =
                matchingInstalledIntegration?.installedVersion !==
                availableIntegration.version;

            if (hasAnUpdate) {
                // Installed Card
                integrations.push({
                    id: matchingInstalledIntegration.integrationId,
                    name: matchingInstalledIntegration.name,
                    version: matchingInstalledIntegration.installedVersion,
                    description: matchingInstalledIntegration.description.short, // The short description in the API
                    icons: matchingInstalledIntegration.branding.icons,
                    isBeta: matchingInstalledIntegration.isBeta, // Flag returned from the API
                    isUpgradable: true, // We need to check if there is a matching available integration with a larger version
                    upgradableToVersion: availableIntegration.version,
                    isInstalled: true, // Flag to know which card represents and installed integration
                    status: matchingInstalledIntegration.status,
                });

                continue;
            }

            // The integration is installed with no update
            integrations.push({
                id: matchingInstalledIntegration.integrationId,
                name: matchingInstalledIntegration.name,
                version: matchingInstalledIntegration.installedVersion,
                description: matchingInstalledIntegration.description.short, // The short description in the API
                icons: matchingInstalledIntegration.branding.icons,
                isBeta: matchingInstalledIntegration.isBeta, // Flag returned from the API
                isUpgradable: false, // We need to check if there is a matching available integration with a larger version
                isInstalled: true,  // Flag to know which card represents and installed integration
                status: matchingInstalledIntegration.status,
            });
        }

        return integrations;
    }

    /**
     * Render the list of integrations
     *
     * @param integrations Array<Integrations>
     * @param variant IntegrationLibraryTabs
     *
     * @returns {JSX.Element}
     */
    function renderIntegrationsList(
        integrations: Array<IntegrationCardData>,
        variant: IntegrationsLibraryTabs
    ) {
        const integrationsByVariant = integrations.filter((integration) => {
            return integration.isInstalled === (variant === "installed");
        });

        const filteredIntegrations = integrationsByVariant.filter(
            (integration) => {
                return `${integration.name.toLowerCase()} ${integration.description.toLowerCase()} ${integration.id.toLowerCase()}`.includes(searchTerm.toLowerCase());
            }
        );

        // No integrations retrieved from API
        if (integrationsByVariant.length === 0) {
            return (
                <div className="font-size-lg py-2">
                    {variant === "installed"
                        ? translations.noInstalledIntegrations
                        : translations.noAvailableIntegrations}
                </div>
            );
        }

        // No integrations found during the search
        if (filteredIntegrations.length === 0) {
            return (
                <div className="font-size-lg py-2">
                    {translations.noIntegrationsFound}
                </div>
            );
        }

        return (
            <div className="integrations-library__cards">
                {filteredIntegrations.map((integration) => {
                    return <IntegrationCard 
                                key={integration.id}
                                availableIntegrations={availableIntegrations} 
                                integration={integration} 
                                handleOpenIntegrationConfigurationModal={openIntegrationConfigurationModal} 
                                handleOpenIntegrationErrorModal={openIntegrationErrorModal}
                            />
                    })
                }
            </div>
        );
    }

    /**
     * Open the Integration Config/Install Modal
     */
    async function openIntegrationConfigurationModal(
        integration: IntegrationCardData,
        isUpgradable: boolean,
        isInstalled: boolean,
        preselectedTab?: IntegrationConfigModalTabs
    ) {
        const modal = integrationConfigModalRef?.current;

        try {
            const integrationDetails = await fetchIntegrationDetails(
                integration?.id,
                integration.version
            );

            /*  
                Branding, Description and Links can change in an upgraded version of the integration
                thus we need to re-use them as they were when the integration was installed first 
            */
            const installedIntegrationDetails = (installedIntegrations || []).find(el => el.integrationId === integration.id);

            if (isInstalled && (!installedIntegrationDetails || !integrationDetails)) {
                return;
            }

            // @ts-ignore
            modal.setIsInstalled(isInstalled);

            // @ts-ignore
            modal.setIsUpgradable(isUpgradable);

            if (isInstalled && installedIntegrationDetails) {
                if (integration.upgradableToVersion) {
                    // @ts-ignore
                    modal.setUpgradableToVersion(integration.upgradableToVersion)
                }
                // @ts-ignore
                modal.setIntegrationDetails({
                    ...integrationDetails, 
                    name: installedIntegrationDetails.name,
                    branding: { ...installedIntegrationDetails.branding },
                    description: { ...installedIntegrationDetails.description },
                    links: installedIntegrationDetails?.links || [],
                } as AvailableIntegrationDetails);
            } else {
                // @ts-ignore
                modal.setIntegrationDetails(integrationDetails);
            }

            if (preselectedTab) {
                // @ts-ignore
                modal.setPreselectedTab(preselectedTab)
            }

            // @ts-ignore
            modal.handleOpen();           
        } catch (error) {
            ErrorToaster({ message: translations.integrationDetailsModal.errors.failedToLoadIntegration });
        }
    }

    /**
     * Refresh the integrations list
     */
    async function refreshIntegrationsList() {
        await fetchIntegrations();
    }

    /** Handle Process Finished (Install/Uninstall/Update/Upgrade Integration) */
    async function handleProcessFinished(process: IntegrationProcess, integrationId: string) {
        // Reset the tab if it was installed/upgraded now
        const shouldResetTab = process === INTEGRATION_PROCESS_VARIANT.install ||
            process === INTEGRATION_PROCESS_VARIANT.upgrade;

        if (shouldResetTab) {
            setActiveTab("installed");
        }

        await refreshIntegrationsList();

        const integrationDetails = integrations?.find(el => el.id === integrationId);
    
        if (process === INTEGRATION_PROCESS_VARIANT.upgrade && integrationDetails) {
            openIntegrationConfigurationModal(integrationDetails, false, true, 'configuration');
        }

        setLoading(false);
    };

    /**
     * Open the Install/Uninstall Error Details Modal
     */
    function openIntegrationErrorModal (
        integrationId: string,
        integrationName: string
    ) {
        const modal = integrationErrorModalRef?.current;

        // @ts-ignore
        modal.toggleOpen();

        // @ts-ignore
        modal.setIntegrationName(integrationName);
        // @ts-ignore
        modal.setIntegrationId(integrationId);
    }

    /**
     * The errors are handled inside each modal, so no need to handle them here also
     *
     * @param error
     */
    function handleErrors(error) {
        console.error(error);
    }

    return (
        <PageWithHeader
            name="IntegrationsLibraryPage"
            title={translations.title}
            icon={IconNames.CUBE_ADD}
            addPadding={true}
            showTimeBar={false}
            helpInfo={HELP.integrationsLibrary}
            className="integrations-library"
        >
            <LoadingOverlay visible={loading} />
            <div className="integrations-library__search-bar mb-4">
                <InputGroup
                    placeholder={
                        translations.searchBox.placeholder
                    }
                    type="search"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.currentTarget.value);
                    }}
                    leftIcon={IconNames.SEARCH as IconName}
                />
                <span className="small">
                    {translations.searchBox.helpText}
                </span>
            </div>

            <TabbedSubPages
                selectedTabId={activeTab}
                onTabChange={setActiveTab}
                renderActiveTabPanelOnly={false}
            >
                <Tab
                    id="installed"
                    title={translations.tabs.installed}
                >
                    {renderIntegrationsList(integrations, "installed")}
                </Tab>
                <Tab
                    id="available"
                    title={translations.tabs.available}
                >
                    {renderIntegrationsList(integrations, "available")}
                </Tab>
            </TabbedSubPages>

            {/* Configuration/Install Modal */}
            <IntegrationConfigurationModal
                ref={integrationConfigModalRef}
                handleErrors={handleErrors}
                onProcessFinished={handleProcessFinished}
            />

            {/* Display error details for install/uninstall process */}
            <IntegrationErrorModal 
                ref={integrationErrorModalRef}                 
                handleErrors={handleErrors}
            />
        </PageWithHeader>
    );
};

/**
 * Get SVG element from string
 *
 * @param {IntegrationIconsList} iconsList
 * @param {string} integrationName
 * @param {IntegrationAvatarVariants} type - variant of the icon
 * @param {string} bgColor - optional parameter for the bg color of the span
 * @returns
 */
export function getIntegrationIcon(
    iconsList: IntegrationIconsList | undefined,
    integrationName: string,
    type: IntegrationAvatarVariants | "badge", // UI only type
    bgColor?: string
) {
    let svg: string | undefined = undefined;
    
    if (iconsList) {
        const typeVariant = type === "badge" ? "avatar" : type; // we need to convert type to avatar for badge variant, as badge is not an actual type of icon from BE

        svg = iconsList?.find((el) => el.type === typeVariant)?.svg;
    }

    return getIntegrationIconFromSvg(svg, integrationName, type, bgColor);
}

/**
 * Get SVG element from string
 *
 * @param {string} svg the svg to be used to generate the icon
 * @param {string} integrationName
 * @param {IntegrationAvatarVariants} type - variant of the icon
 * @param {string} bgColor - optional parameter for the bg color of the span
 * @returns
 */
export function getIntegrationIconFromSvg(
    svg: string | undefined,
    integrationName: string,
    type: IntegrationAvatarVariants | "badge", // UI only type
    bgColor?: string
) {
    const renderFallbackIcon = () => (
        <span className="text-xl">{integrationName}</span>
    );

    if (!svg) {
        return <>{renderFallbackIcon()}</>;
    }

    /**
     * For the avatar type we return the svg inside the icon div
     */
    if (type === "avatar") {
        return (
            <span
                className="integrations-library__card-logo integrations-library__card-logo--avatar icon"
                style={{ backgroundColor: bgColor || "transparent" }}
            >
                <img src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        svg
                    )}`}
                    alt={integrationName}
                />
            </span>
        );
    }

    /**
     * For the node panel we return a square badge version of the icon
     */
    if (type === "badge") {
        return (
            <span
                className="integrations-library__card-logo integrations-library__card-logo--badge icon"
                style={{ backgroundColor: bgColor || "transparent" }}
            >
                <img src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        svg
                    )}`}
                    alt={integrationName}
                />
            </span>
        );
    }

    const element = (
        <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
            alt={integrationName}
        />
    );

    return element;
}


export default IntegrationsLibraryPage;
