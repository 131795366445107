import React, { useState } from "react";
import { Classes, Dialog, Tab, Tabs } from "@blueprintjs/core";
import { Icon, IconName } from "@tir-ui/react-components";
import { AWS_TYPES_TO_LABEL_MAP, AWSSupportedTypes, NETIM_TYPES_TO_LABEL_MAP } from "utils/cloudim/TopologyUtils";
import { PROVIDER_TYPES, TIME_FORMAT } from "components/enums";
import { STRINGS } from "app-strings";
import { getElapsedTimeInfo } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters";
import AttributesTab from "./tabs/attributes/AttributesTab";
import MetricsTab from "./tabs/metrics/MetricsTab";

import './PropertiesModal.scss';

export interface PropertiesModalProps {
    icon: string | IconName;
    provider: PROVIDER_TYPES;
    data: any;
    onClose: () => void;
}

const PropertiesModal = ({ icon, provider, data, onClose }: PropertiesModalProps) => {
    const [modalOpen, setModalOpen] = useState(true);

    function handleClose () {
        setModalOpen(false);
        if (onClose) {
            onClose();
        }
    }

    let type: string = "";
    let metrics: boolean = false;
    let headerSublabel: string = "";

    if (provider === PROVIDER_TYPES.AWS) {
        type = AWS_TYPES_TO_LABEL_MAP[data.type];
    } else if (provider === PROVIDER_TYPES.ONPREM) {
        type = NETIM_TYPES_TO_LABEL_MAP[data.type];
    }

    if (data.type === AWSSupportedTypes.COMPUTEINSTANCE) {
        metrics = true;
        if (data.attributes?.InstanceType) {
            headerSublabel = `${STRINGS.cloudim.topology.modal.subLabels.instanceType} ${data.attributes?.InstanceType}`;
        }
    }

    return (
        <Dialog
            className="propertiesModal"
            isOpen={modalOpen}
            isCloseButtonShown={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            autoFocus={true}
            enforceFocus={true}
            usePortal={true}
            title={type + " " + STRINGS.cloudim.topology.modal.properties}
            aria-labelledby={type + " " + STRINGS.cloudim.topology.modal.properties}
            onClose={() => handleClose()}
        >
            <div className={Classes.DIALOG_BODY}>
                <div className="header">
                    <div className="profile">
                        <div className={"icon" + (provider ? " " + provider : "")} title={type}>
                            <Icon iconSize={40} icon={icon} />
                        </div>
                        <div className="details">
                            <span>
                                {data.label}
                            </span>
                            <span className="text-secondary display-9 font-weight-500">
                                {type}
                            </span>
                        </div>
                    </div>
                    <div className="subLabels">
                        {headerSublabel &&
                            <span>
                                {headerSublabel}
                            </span>
                        }
                        {/* Modifying from UTC to local time */}
                        {data?.timestamp &&
                            <span title={`${formatToLocalTimestamp(new Date(data.timestamp + "Z"), TIME_FORMAT.DISPLAY_TIME_FORMAT)}`}>
                                {`${STRINGS.cloudim.topology.modal.lastUpdated} ${getElapsedTimeInfo(new Date(data.timestamp + "Z"), "standard")} ${STRINGS.cloudim.topology.modal.timeAgo}`}
                            </span>
                        }
                    </div>
                </div>
                <hr />
                <div className="body">
                    {metrics ?
                        <Tabs>
                            <Tab id={STRINGS.cloudim.topology.modal.tabs.metrics} title={STRINGS.cloudim.topology.modal.tabs.metrics} panel={<MetricsTab type={data.type} attributes={data.attributes} />} />
                            <Tab id={STRINGS.cloudim.topology.modal.tabs.attributes} title={STRINGS.cloudim.topology.modal.tabs.attributes} panel={<AttributesTab attributes={data.attributes} />} />
                        </Tabs>
                        :
                        <AttributesTab attributes={data.attributes} />
                    }
                </div>
            </div>
        </Dialog>
    );
};

export default PropertiesModal;
