/** This module contains the wrapper for the react-flow graph.
 *  @module
 */
import React, { useState, useRef, useEffect, useCallback, DragEvent } from "react";
import ReactFlow, {
    removeElements, addEdge, isNode, NodeExtent, Connection, Edge, ArrowHeadType,
    Position, OnLoadParams, Elements, Node, ConnectionLineType, useUpdateNodeInternals,
    useStoreActions, useStoreState, FlowElement, isEdge, XYPosition
} from 'react-flow-renderer';
import dagre from 'dagre';
import { 
    DIRECTION, GraphDef, RunbookInfo, Variant, NodeWiresSetting, VARIANTS_WITH_PREVIEW_IN_GRAPH, 
    VARIANTS_WITH_VERSIONING, VARIANTS_WITH_TEST_IN_GRAPH 
} from "../types/GraphTypes";
import GraphToolbar, { ToolbarAction } from "../GraphToolbar";
import DragAndDropPanel from "../DragAndDropPanel";
import { isEqual, cloneDeep } from "lodash";
import { RunbookNode } from "utils/services/RunbookApiService";
import { getUuidV4 } from "utils/unique-ids/UniqueIds";
import DefaultNode from './nodes/default/DefaultNode';
import SubflowNode, { DEFAULT_SUBFLOW_COLOR } from './nodes/subflow/SubflowNode';
import SwitchNode from './nodes/switch/SwitchNode';
import LogicalNode from './nodes/logical/LogicalNode';
import DecisionNode from "./nodes/decision/DecisionNode";
import classNames from "classnames";
import { getUserPreferences, setUserPreferences } from "utils/stores/UserPreferencesStore";
import { NODE_CONTROLS_ACTIONS } from "./nodes/BaseNodeContent";
import { ConnectionExclusions, canConnect } from "utils/runbooks/RunbookValidationUtils";
import { Icon, ErrorToaster } from "@tir-ui/react-components";
import { NodeLibrary, NodeLibrarySubType, NodeLibraryNode, SubTypeDefault, NodeLibraryCategory } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { STRINGS } from "app-strings";
import { MenuItem } from "@blueprintjs/core";
import { APP_ICONS, SDWAN_ICONS } from "components/sdwan/enums";
import AggregatorNode from "./nodes/aggregator/AggregatorNode";
import { getTriggerTypes } from "utils/stores/GlobalEventMappingsStore";
import { DataOceanUtils } from "../editors/data-ocean/DataOceanUtils";
import { getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { IS_EMBEDDED } from "components/enums/QueryParams";
import { deduplicateSubflows, isVariablesNode, subflowNodes } from "utils/runbooks/RunbookUtils";
import SkeletonNode from "./nodes/skeleton/SkeletonNode";
import { isArray } from "highcharts";
import { skeletonNodes as skeletonNodesDef} from "utils/runbooks/RunbookUtils";
import { checkConnectionToSubflow } from "utils/runbooks/RunbookValidationUtils";
import { subflowOrderingInNodePalette } from "utils/runbooks/RunbookUtils";
import { RunbookIntegrationDetails } from "pages/integrations/types/IntegrationTypes";
import { SubflowsPreference } from "utils/services/UserPrefsTypes";
import './ReactFlowGraph.scss';

// Get the environment from the runConfig file
const { ENV } = window["runConfig"] || {};

// Determine if the user has a Mac to set the keys
const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

// The default edge type to use wherever an edge is created
const defaultEdgeType: ConnectionLineType = ConnectionLineType.Bezier;

// The default edge style to use whenever an edge is created
const defaultEdgeStyle = { stroke: '#aaa', strokeWidth: '3px' };

// This indirectly controls edge length, choose 50 for shorter edges and 100 for longer edges
const defaultRankSep = 100;

// the default arrow type which is one of ArrowHeadType.ArrowClosed, ArrowHeadType.Arrow, undefined
const defaultArrowHeadType = ArrowHeadType.ArrowClosed;

/** The default layout direction, LR for horizontal, TB for vertical.*/
export let defaultDirection = "LR";

const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
};

const nodeExtent: NodeExtent = [
    [0, 0],
    [1000, 1000],
];

/** This interface defines the properties passed into the react-flow graph React component.*/
export interface ReactFlowGraphProps {
    /** the GraphDef object with the graph nodes in it.*/
    graphDef: GraphDef;
    /** the handler for the graph component created event. */
    onGraphComponentCreated?: (reactFlowInstance: OnLoadParams) => void;
    /** callback handler for when reset button is clicked in GraphToolbar */
    onReset?: () => void;
    /** the handler for item deletion events. */
    onDeleteItems?: (items: Elements, deleteElementHandler?: Function) => void;
    /** the handler for node added events. */
    onNodeAdded?: (node: Node, isCopy: boolean) => void;
    /** the handler for edge added events. */
    onEdgeAdded?: (source: string, target: string, sourcePort: string | null | undefined, targetPort: string | null | undefined, elements?: Elements | undefined) => void;
    /** the handler for node selection events. */
    onNodeSelectionChanged?: (nodes: Node[]) => void;
    /** the handler for when edit action is performed in quick controls */
    onNodeEdited?: (node: Node) => void;
    /** the handler for node(s) moved events. */
    onNodesMoved?: (node: Array<Node>) => void;
    /** the handler for runtime variables changes */
    onRuntimeOrSubflowVariableEdited?: (updatedVariablesList) => void;
    /** the handler for incident variables changes */
    onIncidentVariableEdited?: (updatedVariablesList) => void;
    /** notifies any listeners about a toolbar action. */
    notifyToolbarAction?: (type: string, value: any) => void;
    /** the nodelibary that contains all the nodes that can be used in the graph. */
    nodeLibrary: NodeLibrary;
    /** the list of runbooks */
    runbooks?: Array<RunbookInfo>;
    /** the subflows that can be used to build a new flow. */
    subflows?: Array<RunbookNode>;
    /** the current active runbook. */
    activeRunbook?: RunbookInfo;
    /** the node that has been updated. */
    updateNode?: Node | null;
    /** a unique index so that the same node can change multiple times. */
    updateIndex?: number;
    /** Should the toolbar be displayed? */
    showToolbar?: boolean;
    /** CSS Style class to apply */
    className?: string;
    /** If set to true, the graph will be displayed as a non-editable static graph.
     * Layout and toolbar controls will not be displayed */
    static?: boolean;
    /** A flag to indicate if the runbook is in a modified state. This will be used to
     * enable/disable the save and reset buttons in GraphToolbar */
    runbookModified?: boolean;
    /** IDs of the nodes to programatically show as selected */
    selectedNodes?: string[];
    /** When passed as true, the container element will have a calculated width
     * style applied based on how much space is needed to show the passed graph flow */
    fitContainerWidthToContent?: boolean;
    /** When passed as true, the container element will have a calculated height
     * style applied based on how much space is needed to show the passed graph flow */
    fitContainerHeightToContent?: boolean;
    /** Pass this as true if you want the graph to be auto-centered on render */
    centerOnRender?: boolean;
    /** Pass categories that should be shown as open by default when rendering */
    defaultExpandedCategories?: string[];
    /** a boolean value, true if the errors should be displayed, false otherwise. */
    showErrors?: boolean;
    /** if true the undo control should be enabled, if false it should be disabled. */
    undoAvailable?: boolean;
    /** if true the redo control should be enabled, if false it should be disabled. */
    redoAvailable?: boolean;
    /** a boolean value, true if multi-select is enabled. */
    multiSelect?: boolean;
    /** if this is true debug information and debug controls should be shown. */
    isDebug?: boolean;
    /** if this is true development controls should be shown. */
    isDevel?: boolean;
    /** if true hide the editor, if false, display it.   When hidden all subcomponents that utilize a popover need 
     *  to have the popover hidden as well. */
    editorHidden?: boolean;
    /** the runbook variant, incident or lifecycle. */
    variant: Variant;
    /** the exclusion list for auto-connect. */
    exclusions?: ConnectionExclusions;
    /** an optional set of integrations that is used to categorize the integration subflows. */
    integrations?: RunbookIntegrationDetails[];
    /** an optional boolean which is true if the graph is rendered inside the runbook path traversal view. */
    runbookPathTraversalView?: boolean;
    /** an array containing the nodes traversed during runbook execution. */
    nodeRunStatus?: any;
    /** a handler which opens the node info dialog in the runbook path traversal view. */
    openTraversedNodeInfoDialog?: Function;
    /** an object containing information related to the output of the executed runbook. */
    runbookOutput?: any;
}

/** Renders the react-flow graph component.
 *  @param props the properties passed in.  These properties contain some of the meta data necessary to 
 *      draw the graph including the runbooks, and the nodes and edges that should be drawn in the graph.
 *  @returns JSX with the react flow graph component.*/
const ReactFlowGraph = ({ showToolbar = true, ...props }: ReactFlowGraphProps): JSX.Element => {
    const updateNodeInternals = useUpdateNodeInternals();
    const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
    // Destructure the props array so they can be added as dependencies to useEffect.
    const { onNodeAdded, updateNode, updateIndex, onEdgeAdded } = props;
    const layoutDirection = useRef<string>(defaultDirection);
    const [showResetViewButton, setShowResetViewButton] = useState<boolean>(false);
    const [showFitToScreenButton, setShowFitToScreenButton] = useState<boolean>(true);
    const [autoConnectNodes, setAutoConnectNodes] = useState<boolean>(true);
    const [subflowsPreference, setSubflowsPreference] = useState<SubflowsPreference>({});
    const [useSimpleImportPreference, setUseSimpleImportPreference] = useState<boolean>(false);
    const [autoLayout, setAutoLayout] = useState<boolean>(false);
    const isNewRunbook = props.activeRunbook?.id === '0';
    const mostRecentNodeWithOutput = useRef<Node>();
    const mostRecentCreatedNode = useRef<Node>();
    const connectionStartingFromNode = useRef<{ nodeId, handleId, nodeElement? }>();
    const SKELETON_NODE_PROP_KEYS = {
        parent: 'parentNode',
        sourceHandle: 'sourceHandle'
    };

    // Set initial state of autoConnectNodes from user preferences
    useEffect(() => {
        getUserPreferences().then(({ rbe_autoConnect, subflows, runbookImportExportMethod }) => {
            // if autoConnect from user preferences was undefined, default to true
            const autoConnectFromUserPref = (rbe_autoConnect === "off") ? false : true;
            if (autoConnectFromUserPref !== autoConnectNodes) {
                setAutoConnectNodes(autoConnectFromUserPref);
            }
            setSubflowsPreference(subflows || {});
            setUseSimpleImportPreference(Boolean(runbookImportExportMethod?.useSimpleImportExportRunbook === true))
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Programatically sync back selected node if provided from parent component
    const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);
    const storeNodes = useStoreState((store) => store.nodes);
    const setStoreElements = useStoreActions(actions => actions.setElements);
    useEffect(() => {
        if (props.selectedNodes) {
            if (props.selectedNodes.length === 0 && selectedNodes.current.length !== 0) {
                setSelectedElements([]);
            } else if (props.selectedNodes.length > 0) {
                // If current selected nodes count has a mismatch with passed parameter then there's definitely a change
                let changeDetected = props.selectedNodes.length !== selectedNodes.current.length;
                if (changeDetected === false) {
                    // If the counts matched, then try to see if the IDs of the nodes are the same
                    const nodesFromProps = props.selectedNodes.sort().join("-");
                    const currentSelectedNodes = selectedNodes.current.map(node => node.id).sort().join("-");
                    if (nodesFromProps !== currentSelectedNodes) {
                        changeDetected = true;
                    }
                }
                if (changeDetected) {
                    setSelectedElements(props.selectedNodes.map(nodeID => {
                        const selectedNodeFromStore = storeNodes.find(node => node.id === nodeID);
                        return {
                            id: selectedNodeFromStore?.id,
                            type: selectedNodeFromStore?.type
                        };
                    }));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedNodes]);

    // When a node is edited, its name might change as well as its edge handles so we need
    // to let react-flow know that its internal properties have changed and in the case of 
    // handle chnages we need to call updateNodeInternals 
    useEffect(() => {
        let elemsUpdated = false;
        const updatedElements:Elements = [];
        const removedEdges:Elements = [];

        const edgesMap = {};
        for (const edge of props.graphDef.edges) {
            const fromNode = edge.fromNode;
            const port = edge.fromPort || "portless";
            const toNode = edge.toNode;
            edgesMap[fromNode + ":" + port + "-to-" + toNode] = edge;
        }

        for (const el of elements) {
            if (updateNode?.id === el.id) {
                // it's important that you create a new object here
                // in order to notify react flow about the change
                el.data = { ...updateNode.data };
                updatedElements.push(el);
                elemsUpdated = true;
            // If this element is a wire (aka edge)
            } else if ((el as any).source !== undefined) {
                const edge = el as Edge;
                const fromNode = edge.source;
                const port = edge.sourceHandle || "portless";
                const toNode = edge.target;

                // add decision node output labels to decision node react flow edges
                if (updateNode?.id === fromNode && updateNode?.data?.type === "decision") {
                    let label = "";
                    const properties = updateNode.data?.properties;
                    if (properties?.length && edge.sourceHandle) {
                        const outputs = properties.find(property => property.key === "outputs");
                        if (outputs?.value?.length) {
                            label = outputs.value[edge.sourceHandle]?.label;
                        }
                        if (!label && outputs.value[edge.sourceHandle]?.id === "default") {
                            label = "Default";
                        }
                    }
                    (el as Edge).label = "";
                    if (label) {
                        (el as Edge).label = label;
                    }
                }
                if (edgesMap[fromNode + ":" + port + "-to-" + toNode]) {
                    updatedElements.push(el);
                    delete edgesMap[fromNode + ":" + port + "-to-" + toNode];
                } else {
                    // If an existing wire is not in current graphDef anymore, then it's removed
                    removedEdges.push(el);
                }
            } else {
                updatedElements.push(el);
            }
        }
        // This condition indicates there are edges in definition that aren't
        // already present or have been moved to a different port
        if (Object.keys(edgesMap).length > 0) {
            for (const key in edgesMap) {
                const edge = edgesMap[key];
                const existingEdgeToBeMoved = removedEdges.find((e:any) => {
                    return e.source === edge.fromNode && e.target === edge.toNode && e.sourceHandle !== edge.fromPort;
                });
                if (existingEdgeToBeMoved) {
                    updatedElements.push({
                        ...existingEdgeToBeMoved,
                        sourceHandle: edge.fromPort,
                    });
                }
            }
        }
        if (elemsUpdated) {
            setElements(updatedElements);
        }
        if (updateNode) {
            updateNodeInternals(updateNode.id);
        }
        if (removedEdges.length > 0) {
            setStoreElements(updatedElements);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNode, updateIndex, updateNodeInternals]);

    // Center the view when ReactFlowGraph mounts
    useEffect(() => {
        if (props.centerOnRender) {
            reactFlowInstance?.fitView();
            if ((reactFlowInstance?.toObject().zoom || 0) > 1) {
                reactFlowInstance?.zoomTo(1);
            }
        }
    }, [reactFlowInstance, props.centerOnRender]);

    const elementsRef = useRef<Elements>();
    const onQuickControlClicked = useCallback(function (action, nodeID, options?: any) {
        const nodeInAction = elementsRef.current?.find(element => element.id === nodeID) as Node;
        if (nodeInAction) {
            // @ts-ignore
            const elementsToRemove = getElementsToRemove(nodeInAction);

            if (action === NODE_CONTROLS_ACTIONS.edit) {
                if (props.onNodeEdited) {
                    props.onNodeEdited(nodeInAction);
                }
            } else {
                if (action === NODE_CONTROLS_ACTIONS.duplicate) {
                    const newPosition = calculateNewNodePosition({
                        position: { ...nodeInAction.position },
                        horizontal: isHorizontalLayout(),
                        elements: elementsRef.current,
                    });
                    onCreateNewNode([{
                        nodeDef: {
                            type: nodeInAction.type,
                            data: cloneDeep(nodeInAction.data),
                        },
                        position: newPosition
                    }], [], true, true);
                } else if (action === NODE_CONTROLS_ACTIONS.delete) {
                    onElementsRemove(elementsToRemove);
                } else if (action === NODE_CONTROLS_ACTIONS.showAddMenu) {
                    let topLevelMenuItems: Array<JSX.Element> = [];

                    if (props.nodeLibrary && elementsRef.current) {
                        topLevelMenuItems = getAddNodeMenu(
                            props.nodeLibrary,
                            nodeInAction, props.graphDef, elementsRef.current,
                            onCreateNewNode, isHorizontalLayout, setSelectedElements,
                            IS_EMBEDDED, props.exclusions, options, props.subflows,
                            subflowsPreference, props.integrations
                        );
                    }
                    setSelectedElements([]);
                    return topLevelMenuItems;
                }
                // selectedNodes.current = [];
                setSelectedElements([]);
                // if (props.onNodeSelectionChanged) {
                //     props.onNodeSelectionChanged(selectedNodes.current);
                // }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onNodeEdited, props.nodeLibrary, props.graphDef, props.exclusions]);

    const previousGraphDef = useRef<GraphDef | null>(null);
    const passedElements = getElements(props.nodeLibrary, props.graphDef, props.integrations, props.subflows, props.nodeRunStatus).map(element => {
        element.data = element.data || {};
        if (isNode(element)) {
            element.data.quickControlsCallback = onQuickControlClicked;
        }
        return element;
    });

    const [elements, setElements] = useState<Elements>(passedElements);
    elementsRef.current = elements;
    // @ts-ignore
    const edges = elementsRef.current.filter(el => (el?.source)) as Edge[];

    // Calculate max width and height necessary to show all the contents of this flow graph.
    // This will be calculated only when fitContainerWidthToContent or fitContainerHeightToContent prop is true
    const maxWidthAndHeight: { width: number, height: number } | undefined = React.useMemo(() => {
        let xMax = 0,
            yMax = 0,
            // Use min values of X and Y to trim any white space offset on the left and top of the runbook flow
            xMin = -1,
            yMin = -1;
        if (props.fitContainerWidthToContent || props.fitContainerHeightToContent) {
            for (const elem of elements) {
                const elemCopy: any = elem;
                if (elemCopy.position?.x && elemCopy.position?.y) {
                    if (elemCopy.position?.x > xMax) {
                        xMax = elemCopy.position?.x;
                    }
                    if (elemCopy.position?.y > yMax) {
                        yMax = elemCopy.position?.y;
                    }
                    if (xMin === -1 || elemCopy.position.x < xMin) {
                        xMin = elemCopy.position.x;
                    }
                    if (yMin === -1 || elemCopy.position.y < yMin) {
                        yMin = elemCopy.position.y;
                    }
                }
            }
        }
        if (xMax && yMax) {
            return {
                // Offset width by an additional 150px
                width: xMax - xMin + 150,
                // Offset height by an additional 100px
                height: yMax - yMin + 100,
            };
        } else {
            return undefined;
        }
    }, [elements, props.fitContainerWidthToContent, props.fitContainerHeightToContent]);
    const isHorizontalLayout = (): boolean => {
        return (layoutDirection.current === "LR");
    }
    const onConnect = useCallback((params: Connection | Edge<any>, updateDefaults: boolean = true, isConnectToSkeletonNode = false) => {
        (params as Edge).type = defaultEdgeType;
        (params as Edge).style = defaultEdgeStyle;
        (params as Edge).arrowHeadType = defaultArrowHeadType;

        /***
         *  We need to set some default properties in the child node when a connection is made. These
         *  properties need to be set on the elements object of react-flow instance. Some of these property
         *  values depend on the parent node or a grand parent node of the same type or the trigger node.
         *  So these properties can only be set after the edges are updated in the graph definition which is done in the
         *  `onReactFlowEdgeAdded`. The below function is passed as a parameter which will be called at the end of
         * `onReactFlowEdgeAdded` function by passing the elements object
         *  */

        setElements((els: any): any => {

            let hasDuplicateEdge = false;
        
            els.forEach((element: Edge) => {
                if (
                    element.source !== undefined &&
                    compareEdgesPropertyValues(element.source, params.source) && 
                    compareEdgesPropertyValues(element.target, params.target) && 
                    compareEdgesPropertyValues(element.sourceHandle, params.sourceHandle) && 
                    compareEdgesPropertyValues(element.targetHandle, params.targetHandle)
                ) {
                    hasDuplicateEdge = true;
                }
            });
        
            if (!hasDuplicateEdge || !isConnectToSkeletonNode) {
                const elements = addEdge(params, els);
        
                if (onEdgeAdded && params.source && params.target) {
                    edgeUpdate.current = {edge: params, updateDefaults};
                }
        
                return elements;
            }
        
            return els;
        });
    }, [onEdgeAdded]);

    // We need to store the edge update and invoke it after the react-flow model has updated.  React
    // complains if you do a set state call in the handler while the react-flow function is rendering,
    // so save up the notification and do it after the render.  Note Ajay's comment above we are going
    // in to the models and updating some properties to set defaults based on the node connections so 
    // that is why this notification is more complicated than the others.
    const edgeUpdate = useRef<any>(undefined);
    useEffect(
        () => {
            if (edgeUpdate.current) {
                const edge = edgeUpdate.current.edge;
                if (onEdgeAdded && edge.source && edge.target) {
                    onEdgeAdded(
                        edge.source, edge.target, edge.sourceHandle, edge.targetHandle, edgeUpdate.current.updateDefaults ? elements : undefined
                    );
                }
                edgeUpdate.current = undefined
            }
        }, 
        [elements, onEdgeAdded]
    );

    const onElementsRemove = (elementsToRemove: Elements): any => {

        const deleteElementHandler = (handler: Function): void => {
            setElements((els: any): any => {
                handler(els);
                return removeElements((elementsToRemove as any), els);
            });
        };

        if (props.onDeleteItems) {
            props.onDeleteItems(elementsToRemove, deleteElementHandler);
        }
    };
    const onLayout = (direction: string, pElements?: Elements, setPosition: boolean = false) => {
        layoutDirection.current = direction;
        const isHorizontal = isHorizontalLayout();
        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));
        dagreGraph.setGraph({ rankdir: direction, nodesep: 50, edgesep: 20, ranksep: defaultRankSep });

        const elems = pElements || elements;

        elems.forEach((el: any) => {
            if (isNode(el)) {
                dagreGraph.setNode(el.id, { width: 150, height: 50 });
            } else {
                dagreGraph.setEdge(el.source, el.target);
            }
        });

        dagre.layout(dagreGraph);

        const layoutedElements = elems.map((el: any) => {
            if (isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = undefined;
                el.targetPosition = isHorizontal ? Position.Left : Position.Top;
                el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
                if (setPosition) {
                    el.position = { x: nodeWithPosition.x, y: nodeWithPosition.y };
                }
            }
            return el;
        });
        nodeExtent[1] = [dagreGraph.graph().width, dagreGraph.graph().height];

        setElements((layoutedElements as any));

        if (setPosition && props.onNodesMoved) {
            const nodes: Array<Node> = [];
            for (const element of layoutedElements) {
                nodes.push(element);
            }
            if (nodes.length > 0) {
                props.onNodesMoved(nodes);
            }
        }
    };
    const onLoad = (reactFlowInstance: OnLoadParams) => {
        setReactFlowInstance(reactFlowInstance);
        //reactFlowInstance.fitView();
        onLayout(layoutDirection.current, undefined, autoLayout);
        if (props.onGraphComponentCreated) {
            props.onGraphComponentCreated(reactFlowInstance);
        }
    };

    const onDrop = (event: DragEvent) => {
        event.preventDefault();

        if (reactFlowInstance) {
            const { node, offsets }: any = JSON.parse(event.dataTransfer.getData('application/reactflow'));
            let offsetX = 0,
                offsetY = 0;
            if (flowContainer.current) {
                const { top, left } = flowContainer.current.getBoundingClientRect();
                offsetX = left;
                offsetY = top;
            }
            if (offsets?.x !== undefined && offsets?.y !== undefined) {
                offsetX += offsets.x;
                offsetY += offsets.y;
            }

            onCreateNewNode([
                {
                    nodeDef: node,
                    position: reactFlowInstance.project({ x: event.clientX - offsetX, y: event.clientY - offsetY }),
                }
            ]);
        }
        // This class will get added when a drag starts in the drag and drop panel. There doesn't seem to be a
        // solid way of figuring out the completion of drag when user drops it in the work area. Hence adding
        // this line over here to remove that class.
        document.body.classList.remove("drag-in-progress");
    };

    const onCreateNewNode = useCallback((
        nodeDefs: Array<any>, edges: Array<Edge> = [], useAutoConnect: boolean = true, selectNewElements: boolean = false, updateDefaults: boolean = false
    ): boolean => {
        const addedNodes: Array<Node> = [];
        const addedEdges: Array<Edge | Connection> = [];
        const newNodeIdByOrigId: Record<string, string> = {};

        let removedSkeletonData = {
            parent: null,
            sourceHandle: '0',
            position: {x: 0, y: 0}
        }

        // Check if it was created from skeleton node context menu
        if (selectedNodes.current.length === 1) {
            const selectedNode = selectedNodes.current[0];

            if (selectedNode?.data && skeletonNodesDef.includes(selectedNode?.type || '')) {
                const parentProp = selectedNode.data.properties.find((el) => el.key === SKELETON_NODE_PROP_KEYS.parent);
                const sourceHandleProp = selectedNode.data.properties.find((el) => el.key === SKELETON_NODE_PROP_KEYS.sourceHandle);

                removedSkeletonData = {
                    parent: parentProp?.value,
                    sourceHandle: sourceHandleProp?.value,
                    position: selectedNode.position
                }

                removeSkeletonNode(selectedNode);
            }
        }

        for (const nodeDefItem of nodeDefs) {
            const nodeDef = nodeDefItem.nodeDef;
            const passedPos = nodeDefItem.position;
            
            if (!nodeDef?.data) {
                continue
            }

            nodeDef.data.editedByUser = false;
            if (
                elementsRef.current && nodeDef && nodeDef.type &&
                passedPos && passedPos.x !== null && passedPos.x !== undefined &&
                passedPos.y !== null && passedPos.y !== undefined
            ) {
                const type = nodeDef.type;
                const newNode: Node = {
                    id: getUuidV4(),
                    type,
                    position: passedPos,
                    data: {
                        ...nodeDef.data,
                        quickControlsCallback: onQuickControlClicked,
                    }
                };
                mostRecentCreatedNode.current = newNode;
                addedNodes.push(newNode);
                if (nodeDef.id) {
                    newNodeIdByOrigId[nodeDef.id] = newNode.id;
                }

                // Make sure the edge ids are up to date
                for (const edge of edges) {
                    if (edge.source === nodeDef.id) {
                        edge.source = newNode.id;
                    } else if (edge.target === nodeDef.id) {
                        edge.target = newNode.id;
                    }
                }

                newNode.style = {};
                newNode.style.background = newNode.data.color;
                // Make sure the name is unique
                let name = nodeDef.data.label;
                let nameExists = false;
                const names: Array<string> = [];
                const elements = elementsRef.current;
                if (elements && elements.length > 0) {
                    for (const element of elements) {
                        if (element?.data?.label) {
                            names.push(element.data.label);
                            if (element.data.label === name) {
                                nameExists = true;
                            }
                        }
                    }
                }

                if (nameExists) {
                    let [nameOnly, subText] = splitName(name);
                    if (nameOnly.includes(" ")) {
                        const strs = nameOnly.split(" ");
                        if (!Number.isNaN(parseInt(strs[strs.length - 1]))) {
                            nameOnly = nameOnly.substring(0, nameOnly.lastIndexOf(" "));
                        }
                    }
                    let index = 1;
                    while (nameExists) {
                        const newName = nameOnly + " " + index + (subText ? " " + subText : "");
                        if (names.includes(newName)) {
                            index++;
                        } else {
                            nameExists = false;
                            name = newName;
                        }
                    }
                }
                newNode.data.label = name;

                // Handle environment variables for subflows
                //if (nodeDef.data.type.startsWith("subflow:")) {
                if (subflowNodes.includes(nodeDef.data.type)) {
                    newNode.data.env = [];
                }

                const isHorizontal = isHorizontalLayout();
                newNode.targetPosition = isHorizontal ? Position.Left : Position.Top;
                newNode.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
                newNode.connectable = true;
                newNode.type = getReactFlowNodeType(nodeDef.data);

                if (onNodeAdded) {
                    onNodeAdded(newNode, selectNewElements);
                }
                mostRecentCreatedNode.current = newNode;
                mostRecentNodeWithOutput.current = newNode;
                setElements((es) => es.concat(newNode));

                // Identify if we should auto-connect the newly added node
                if (!skeletonNodesDef.includes(newNode.type) && useAutoConnect && autoConnectNodes && isNodeWithInputs(newNode)) {
                    const isHorizontal = isHorizontalLayout();
                    const autoConnectSource = identifyNodeToConnectFrom(elements, newNode, isHorizontal);
                    if (autoConnectSource) {
                        const sourceHandle = identifyHandleToConnectFrom(autoConnectSource, newNode, edges, isHorizontal);
                        const connectError = canConnect(
                            autoConnectSource.data.type, autoConnectSource.data.subType, autoConnectSource.id, 
                            autoConnectSource.data.properties, sourceHandle === null ? undefined : sourceHandle,
                            newNode.data.type, newNode.data.subType, newNode.id, newNode.data.properties, newNode.data.wires,
                            props.graphDef, props.exclusions
                        );
                        
                        // no automatic connection between nodes and placeholders (skeleton) nodes
                        if (!connectError && !skeletonNodesDef.includes(autoConnectSource.data.type)) {
                            onConnect({
                                source: autoConnectSource.id,
                                target: newNode.id,
                                sourceHandle: typeof sourceHandle === "number" ? String(sourceHandle) : sourceHandle,
                                targetHandle: null,
                            }, true);
                        } else {
                            ErrorToaster({
                                message:STRINGS.formatString(STRINGS.runbookEditor.errors.connectError, connectError)
                            });
                        }
                    }
                }
            }
        }

        if (selectNewElements || edges.length > 0) {
            // If this is copy and paste check and see if any of the edges have both the source and target nodes available
            for (let edgeIndex = edges.length - 1; edgeIndex >= 0; edgeIndex--) {
                let sourceNode: Node | undefined;
                let targetNode: Node | undefined;
                for (const element of addedNodes) {
                    if (isNode(element) && element.id === edges[edgeIndex].source) {
                        sourceNode = element;
                    } else if (isNode(element) && element.id === edges[edgeIndex].target) {
                        targetNode = element;
                    }
                }
                /* This prevents internal edges from being added when pasting to a new graph, this was added to prevent the 
                   edge from being added twice when we automatically create the edges based on the existing graph
                   below.  Instead keep track of what was created here from the list of edges in the paste and 
                   don't create them a second time below.
                if (sourceNode && targetNode) {
                    // We are automatically hooking up any pasted nodes that were connected before so 
                    // skip this case here.  However, if we remove the automatic hooking up of the 
                    // edges within the pasted nodes, remove this continue.
                    continue;
                }
                */
                if (!sourceNode && targetNode) {
                    // Check for a edge from an existing node to one of the new nodes.  We will add 
                    // edges for incoming connections.  We will not add edges for outgoing connections
                    // to nodes that already exist and are not part of the set of new nodes.
                    for (const element of elements) {
                        if (isNode(element) && element.id === edges[edgeIndex].source) {
                            sourceNode = element;
                        }
                    }
                }
                if (sourceNode && targetNode) {
                    const newEdge: Edge | Connection = {
                        source: sourceNode.id,
                        target: targetNode.id,
                        sourceHandle: edges[edgeIndex].sourceHandle || null,
                        targetHandle: edges[edgeIndex].targetHandle || null,
                        animated: skeletonNodesDef.includes(targetNode?.type || '')
                    };
                    
                    onConnect(newEdge, updateDefaults);
                    edges.splice(edgeIndex, 1);
                    addedEdges.push(newEdge);
                }
            }

            // Automatically re-connect any nodes that were connected before the paste.  We have 
            // also added edges that were in the data passed to onCreateNode, so we need to make
            // sure we don't add any edges twice.
            for (const element of elements) {
                if (
                    (element as Edge).source && newNodeIdByOrigId[(element as Edge).source] &&
                    (element as Edge).target && newNodeIdByOrigId[(element as Edge).target]
                ) {
                    const newEdge: Edge | Connection = {
                        source: newNodeIdByOrigId[(element as Edge).source],
                        target: newNodeIdByOrigId[(element as Edge).target],
                        sourceHandle: (element as Edge).sourceHandle || null,
                        targetHandle: (element as Edge).targetHandle || null
                    };

                    // Check to see if we already added this edge based on the edges supplied as 
                    // parameters to this function
                    const alreadyAddedEdge = addedEdges.some((item) => {
                        return compareEdgesPropertyValues(item.source, newEdge.source) && compareEdgesPropertyValues(item.target, newEdge.target) &&
                        compareEdgesPropertyValues(item.sourceHandle, newEdge.sourceHandle) && compareEdgesPropertyValues(item.targetHandle, newEdge.targetHandle);
                    });
                    if (!alreadyAddedEdge) {
                        onConnect(newEdge, updateDefaults);
                        addedEdges.push(newEdge);
                    }
                }
            }

            if (selectNewElements) {
                let selectedElements: Elements<any> = [];
                selectedElements = selectedElements.concat(addedNodes);
                for (const edge of addedEdges) {
                    if (reactFlowInstance) {
                        for (const element of reactFlowInstance.getElements()) {
                            if (
                                (element as Edge).source === edge.source && (element as Edge).target === edge.target &&
                                (element as Edge).sourceHandle === edge.sourceHandle && (element as Edge).targetHandle === edge.targetHandle
                            ) {
                                selectedElements.push(element);
                            }
                        }
                    }
                }
                setSelectedElements(selectedElements);
            }
        }

        const createdNode = {...mostRecentCreatedNode.current} as Node;
        
        if (removedSkeletonData?.parent && !skeletonNodesDef.includes(createdNode?.type || '')) {
            setElements((els) =>
            // Place new node in place of the removed skeleton node
            els.map((el) => {
                    if (el.id === createdNode.id && removedSkeletonData?.position) {
                        (el as Node).position = { ...removedSkeletonData.position };
                    }
                    return el;
                })
            );
            addConnectionToSkeletonParentNode(
                removedSkeletonData?.parent,
                createdNode.id,
                removedSkeletonData?.sourceHandle,
            );
        }

        return addedNodes.length > 0;
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        onNodeAdded, autoConnectNodes, onConnect, elements, onQuickControlClicked, setSelectedElements, 
        reactFlowInstance, props.graphDef, props.exclusions
    ]);

    const lastClick = useRef<any>(null);
    const selectedNodes = useRef<Array<Node>>([]);
    const selectedEdges = useRef<Array<Edge>>([]);
    const onSelectionChange = (elements: Elements<any> | null) => {
        selectedNodes.current = [];
        selectedEdges.current = [];
        if (elements) {
            for (const element of elements) {
                if (isNode(element) && element.id && element.type) {
                    selectedNodes.current.push(element as Node);
                } else if (isEdge(element)) {
                    selectedEdges.current.push(element as Edge);
                }
            }
        }
        if (selectedNodes.current.length > 1) {
            flowContainer.current?.classList.add("multiple-nodes-selected");
        } else {
            flowContainer.current?.classList.remove("multiple-nodes-selected");
        }
        if (props.onNodeSelectionChanged) {
            props.onNodeSelectionChanged(selectedNodes.current);
        }
    };

    const onNodeDoubleClicked = (event, node: Node) => {
        if (!skeletonNodesDef.includes(node.type || '') && props.onNodeEdited) {
            props.onNodeEdited(node);
        }
    };

    const warningNodeIds = useRef<Array<string>>([]);
    const errorNodeIds = useRef<Array<string>>([]);
    const nodeWarnings = useRef<Array<string>>([]);
    const nodeErrors = useRef<Array<string>>([]);
    if (!isEqual(props.graphDef, previousGraphDef.current)) {
        previousGraphDef.current = props.graphDef;
        if (!props.graphDef.silent) {
            warningNodeIds.current = [];
            errorNodeIds.current = [];
            nodeWarnings.current = [];
            nodeErrors.current = [];
            onLayout(layoutDirection.current, passedElements, autoLayout);
        }
        props.graphDef.silent = false;
    }

    // Handle nodes that have errors, collect the errors up from the graph def
    // and then update the errorNodeIds if and only if they changed.  If they changed
    // the useEffect hook will be invoked and that will update the graph
    let warnings: Array<string> = [];
    let errors: Array<string> = [];
    const newNodeWarnings: Array<string> = [];
    const newNodeErrors: Array<string> = [];
    if (props.graphDef) {
        warningNodeIds.current = [];
        errorNodeIds.current = [];
        if (props.graphDef.warnings) {
            warnings = warnings.concat(props.graphDef.warnings);
        }
        if (props.graphDef.errors) {
            errors = errors.concat(props.graphDef.errors);
        }
        if (props.graphDef.nodes) {
            for (const node of props.graphDef.nodes) {
                if (node.warnings) {
                    warningNodeIds.current.push(node.id);
                    for (const warning of node.warnings) {
                        newNodeWarnings.push(node.name + ": " + warning);
                        warnings.push(node.name + ": " + warning);
                    }
                }
                if (node.errors) {
                    errorNodeIds.current.push(node.id);
                    for (const error of node.errors) {
                        newNodeErrors.push(node.name + ": " + error);
                        errors.push(node.name + ": " + error);
                    }
                }
            }
        }
        newNodeWarnings.sort();
        newNodeErrors.sort();
    }
    if (!isEqual(nodeWarnings.current, newNodeWarnings)) {
        nodeWarnings.current = newNodeWarnings;
    }
    if (!isEqual(nodeErrors.current, newNodeErrors)) {
        nodeErrors.current = newNodeErrors;
    }
    useEffect(
        () => {
            setElements((els) =>
                els.map((el) => {
                    // it's important that you create a new object here
                    // in order to notify react flow about the change
                    if (el.data) {
                        el.data = { ...el.data };
                        if (
                            props.showErrors && (
                                (errorNodeIds.current && errorNodeIds.current.includes(el.id)) ||
                                (warningNodeIds.current && warningNodeIds.current.includes(el.id))
                            )
                        ) {
                            for (const node of props.graphDef.nodes) {
                                if (node.id === el.id && (node.warnings || node.errors)) {
                                    el.className = "node-warnings-and-errors";
                                    if (node.warnings) {
                                        const warnings: Array<string> = [];
                                        el.data.warnings = warnings.concat(node.warnings);
                                    }
                                    if (node.errors) {
                                        const errors: Array<string> = [];
                                        el.data.errors = errors.concat(node.errors);
                                    }
                                }
                            }
                        } else {
                            delete el.data.warnings;
                            delete el.data.errors;
                            el.className = "";
                        }
                    }
                    return el;
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps 
        [nodeWarnings.current, nodeErrors.current, props.showErrors]
    );

    // We need to always have a reference to the latest onQuickControlClicked
    useEffect(
        () => {
            setElements((els) => {
                return els.map((el) => {
                    if (el.data) {
                        el.data = { ...el.data };
                        el.data.quickControlsCallback = onQuickControlClicked;
                    }
                    return el;
                });
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps 
        [onQuickControlClicked]
    );

    const flowContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onCopy = (): any => {
            lastClick.current = null;
            if (reactFlowInstance && selectedNodes.current.length > 0) {
                const clipboardData: any = { nodes: [], edges: [] };
                for (const node of selectedNodes.current) {
                    if (skeletonNodesDef.includes(node?.type || '')) {
                        continue;
                    }

                    // Position the copied node to the right and below the current node
                    const position = { x: node.position.x + 25, y: node.position.y + 25 };
                    clipboardData.nodes.push({ nodeDef: { type: node.type, data: node.data, id: node.id }, position });
                }
                for (const edge of selectedEdges.current) {
                    clipboardData.edges.push(edge);
                }
                return clipboardData;
            }
            return null;
        };

        const copyhandler = (event) => {
/*
            const elementFocusedOnKeyPress = event.target?.tagName;
            // Prevent events from being triggered when other elements are focused
            if (!['BODY'].includes(elementFocusedOnKeyPress)) {
                return;
            }
*/

            const selection = document.getSelection();
            if (!selection || !selection.toString()) {
                const data = onCopy();
                if (data !== null && data !== undefined) {
                    event.clipboardData.setData('text/json', JSON.stringify(data));
                    event.preventDefault();
                }
            }
        };
        const pastehandler = (event) => {
/*
            const elementFocusedOnKeyPress = event.target?.tagName;
            // Prevent events from being triggered when other elements are focused
            if (!['BODY'].includes(elementFocusedOnKeyPress)) {
                return;
            }
*/
            
            let data = (event.clipboardData || (window as any).clipboardData).getData('text/json');
            if (data && data.length > 0) {
                try {
                    data = JSON.parse(data);

                    if (data?.nodes) {
                        // Check to make sure the nodes are compatible
                        const nodeTypes: string[] = props.nodeLibrary.getNodeTypes();
                        if (props.variant !== Variant.SUBFLOW) {
                            nodeTypes.push(...subflowNodes)
                        }
                        const unsupportedTypes: string[] = [];
                        for (const node of data.nodes) {
                            if (!nodeTypes.includes(node.nodeDef.data.type)) {
                                if (!unsupportedTypes.includes(node.nodeDef.data.type)) {
                                    unsupportedTypes.push(node.nodeDef.data.type);
                                }
                            }
                        }
                        if (unsupportedTypes.length) {
                            ErrorToaster({
                                message:STRINGS.formatString(STRINGS.runbookEditor.errors.pasteError, {types: unsupportedTypes.join(", ")})
                            });
                            throw new Error("unsupported nodes in the pasted content!");
                        }
                    }

                    if (lastClick.current && flowContainer.current) {
                        // If the user clicks on a location within the graph, try to paste all the contents at that location
                        const { top, left } = flowContainer.current.getBoundingClientRect();
                        let clickPosX = lastClick.current.clientX - left;
                        let clickPosY = lastClick.current.clientY - top;

                        // Calculate the position of the upper left corner of all the selected nodes 
                        let minX = Number.MAX_SAFE_INTEGER;
                        let minY = Number.MAX_SAFE_INTEGER;
                        for (const node of data.nodes) {
                            minX = Math.min(minX, node.position.x);
                            minY = Math.min(minY, node.position.y);
                        }

                        // Translate all the selected nodes such that their upper left corner of the box surrounding all the 
                        // nodes will be at the click position.
                        for (const node of data.nodes) {
                            node.position.x = node.position.x + (clickPosX - minX);
                            node.position.y = node.position.y + (clickPosY - minY);
                            node.position = reactFlowInstance?.project(node.position);
                        }
                    }
                    const result: boolean = onCreateNewNode(data.nodes, data.edges || [], false, true);
                    if (result) {
                        event.preventDefault();
                    }
                } catch (error) {
                    console.log("error during paste");
                }
            }
        };

        document.addEventListener('copy', copyhandler);
        document.addEventListener('paste', pastehandler);
        return () => {
            document.removeEventListener('copy', copyhandler);
            document.removeEventListener('paste', pastehandler);
        }
    }, [reactFlowInstance, onCreateNewNode, props.nodeLibrary, props.variant]);

    const [isVariablePopoverOpen, setIsVariablePopoverOpen] = useState<boolean>(false)

    function updateElementProps(
        nodeID?: string,
        nodeOverrides?: { style?: Object, data?: Object },
        edgeOverrides?: {
            nodeAsSource?: { style?: Object },
            nodeAsTarget?: { style?: Object },
        }
    ) {
        const { nodeAsSource, nodeAsTarget } = edgeOverrides || {};
        if (nodeID && (nodeOverrides || edgeOverrides)) {
            setElements(oldElements => {
                return oldElements.map((element: any) => {
                    if (nodeOverrides && element.id === nodeID) {
                        if (nodeOverrides.data) {
                            element.data = { ...(element.data || {}), ...nodeOverrides.data };
                        }
                        if (nodeOverrides.style) {
                            element.style = { ...(element.style || {}), ...nodeOverrides.style };
                        }
                    }
                    if (nodeAsSource && element.source === nodeID) {
                        if (nodeAsSource.style) {
                            element.style = { ...(element.style || {}), ...nodeAsSource.style };
                        }
                    }
                    if (nodeAsTarget && element.target === nodeID) {
                        if (nodeAsTarget.style) {
                            element.style = { ...(element.style || {}), ...nodeAsTarget.style };
                        }
                    }
                    return element;
                });
            });
            updateNodeInternals(nodeID);
        }
    }

    function onNodeDragStart(event, node) {
        flowContainer.current?.classList.add("drag-in-progress");
        updateElementProps(node.id, {
            // Dragging property can be used by the custom nodes to know when it's being dragged.
            // Currently, this is being used to prevent tooltip from being displayed.
            data: { ...(node.data || {}), dragging: true },
            // Setting zIndex of node to be higher when dragging so that it doesn't go below other nodes.
            style: { zIndex: 100 },
        });
    }

    /* istanbul ignore next */
    function onNodeDragEnd(event, node) {
        removeDragFlags(node);
        mostRecentNodeWithOutput.current = node;
        // Update the element list with the new position
        setElements((els) =>
            els.map((el) => {
                // it's important that you create a new object here
                // in order to notify react flow about the change
                if (el.id === node.id) {
                    (el as Node).position = { ...node.position };
                }
                return el;
            })
        );
        if (props.onNodesMoved) {
            props.onNodesMoved([node]);
        }
    }

    function removeDragFlags(node) {
        flowContainer.current?.classList.remove("drag-in-progress");
        updateElementProps(node.id, {
            data: { ...(node.data || {}), dragging: false },
            style: { zIndex: "unset" }
        });
    }

    /* istanbul ignore next */
    function keepEdgeColorOnNodeHoverInTraversalView() {
        if (props.runbookPathTraversalView) {
            setElements((oldElements) => {
                const newStyle = { stroke: "#007bff", strokeWidth: "4px" };
                
                // the runbook output nodeRunStatus object does not contain the traversed visualization nodes
                // we will use custom logic to figure out which visualization nodes where traversed
                const outputNodes: any = [];
    
                oldElements.forEach((element) => {
                    if (element.type === "output") {
                        outputNodes.push(element);
                    }
                });
    
                const outputNodesIdAndSource: Array<{
                    id: string;
                    source: string;
                }> = [];
    
                outputNodes.forEach((node) => {
                    for (const element of oldElements) {
                        if ((element as any).target && (element as any).target === node.id) {
                            let canPush = true;
                            for (const element2 of oldElements) {
                                if (
                                    (element2 as any).source === (element as any).source &&
                                    (element2 as any).source !== (element as any).target &&
                                    props.nodeRunStatus?.find((item) => item.id === (element2 as any).target)
                                ) {
                                    canPush = false;
                                }
                                if (canPush &&
                                    !props.nodeRunStatus?.find((item) => item.id === oldElements.find(item => item.id === (element as any).source)?.id)
                                ) {
                                    canPush = false;
                                }
                            }
                            if (canPush) {
                                // visualization node was traversed
                                outputNodesIdAndSource.push({
                                    id: node.id,
                                    source: (element as any).source,
                                });
                            }
                            break;
                        }
                    }
                });
    
                return oldElements.map((element: any) => {
                    if ((element as any).source !== undefined) {
                        element.style =
                            props.nodeRunStatus?.find(
                                (item) => item.id === element.target,
                            ) ||
                            outputNodesIdAndSource?.find(
                                (item) =>
                                    item.id === element.target &&
                                    props.nodeRunStatus?.find(
                                        (node) => node.id === element.source,
                                    ),
                            )
                                ? newStyle
                                : defaultEdgeStyle;
                    }
                    return element;
                });
            });
        }
    }

    function onMouseEnterNode(event, node) {
        updateElementProps(node.id,
            // If connection is in progress, then connectionStartingFromNode.current will have a value.
            // Pass the connecting flag as true to the node on which user is hovering over to disable tooltips.
            connectionStartingFromNode.current ? { data: { ...(node.data || {}), connecting: true } } : {},
            {
                // Highlighting edges that end in and start from this node
                nodeAsSource: { style: { stroke: "#2c9124" } },
                nodeAsTarget: { style: { stroke: "#2c9124" } },
            }
        );
        keepEdgeColorOnNodeHoverInTraversalView();
    }

    function onMouseLeaveNode(event, node) {
        updateElementProps(node.id, {
            data: {
                ...(node.data || {}),
                dragging: false,
                connecting: false,
            }
        }, {
            nodeAsSource: { style: { stroke: "" } },
            nodeAsTarget: { style: { stroke: "" } },
        });
        // When a node is clicked, ReactFlow is calling onDragStart but it doesn't call onDragEnd.
        // So, the flags that indicate that a drag is in progress don't get reset. To circumvent that,
        // we're also calling the method here
        removeDragFlags(node);
        keepEdgeColorOnNodeHoverInTraversalView();
    }

    function onSelectionDragStop(event, nodes: Array<Node>) {
        // Update the element list with the new position
        setElements((els) =>
            els.map((el) => {
                // it's important that you create a new object here
                // in order to notify react flow about the change
                for (const node of nodes) {
                    if (el.id === node.id) {
                        (el as Node).position = { ...node.position };
                    }    
                }
                return el;
            })
        );
        if (props.onNodesMoved) {
            props.onNodesMoved(nodes);
        }
    }

    function onConnectStart(e) {
        flowContainer.current?.classList.add("connection-in-progress");
        // When user starts performing a connection
        var target = e.target as Element;
        if (target) {
            // Find the node ID and the handle ID and keep it in a reference
            const nodeId = target.getAttribute("data-nodeid");
            if (nodeId) {
                const handleId = target.getAttribute("data-handleid");
                target.parentElement?.classList.add("connection-in-progress-source");
                connectionStartingFromNode.current = {
                    nodeId,
                    handleId,
                    nodeElement: target.parentElement,
                };
            }
        }
    };

    const onConnectEnd = useCallback(e => {
        // When user lets go of a connection that they attempted to make
        var target = e.target as Element;
        // If user did not end the connection in a handle, then the target will not be
        // a react flow handle and won't have the react-flow__handle class
        if (!target.classList.contains("react-flow__handle")) {
            // Keep walking up the DOM tree and see if the target was within a valid react flow node
            while (target && target !== document.body) {
                // If we found a react flow node DOM element
                if (target.classList.contains("react-flow__node")) {
                    // Read the node's ID and find the node from elements array
                    const targetNodeId = target.getAttribute("data-id");
                    const targetNode = elements.find(element => element.id === targetNodeId);
                    // If this is a node that has connectable inputs
                    if (targetNode && !skeletonNodesDef.includes(targetNode.type || '') && isNodeWithInputs(targetNode)) {
                        // Perform a connection from the node from which user started the connection to this target node
                        if (connectionStartingFromNode.current && connectionStartingFromNode.current.nodeId !== targetNodeId) {
                            onConnect({
                                source: connectionStartingFromNode.current.nodeId,
                                target: targetNodeId,
                                sourceHandle: typeof connectionStartingFromNode.current.handleId === "number" ? String(connectionStartingFromNode.current.handleId) : null,
                                targetHandle: null,
                            });
                        }
                    }
                    break;
                    // Walk up the DOM tree
                } else if (target.parentElement) {
                    target = target.parentElement;
                } else {
                    break;
                }
            }
        }
        flowContainer.current?.classList.remove("connection-in-progress");
        if (connectionStartingFromNode.current?.nodeElement) {
            connectionStartingFromNode.current?.nodeElement.classList.remove("connection-in-progress-source");
        }
        connectionStartingFromNode.current = undefined;
    }, [elements, onConnect]);

    /* Add skeleton nodes for nodes with empty source handles */
    const nodesWithEmptySourceHandles = useRef<any[]>([]);
    useEffect(() => {
        if (nodesWithEmptySourceHandles.current.length > 0) {
            nodesWithEmptySourceHandles.current.forEach(entry => {
                if (!props.runbookPathTraversalView) {
                    addSkeletonNodes(
                        entry.node as Node,
                        entry.sourceHandles,
                        subflowsPreference
                    );
                }
            });

            nodesWithEmptySourceHandles.current.length = 0;
        } else {
            cleanupSkeletonNodes(edges);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onEdgeAdded, onNodeAdded, updateIndex, onElementsRemove])

    /* Keep track of nodes with empty source handles */
    useEffect(() => {
        if (!props.static && isArray(elements)) {
            nodesWithEmptySourceHandles.current = [
                ...nodesWithEmptySourceHandles.current,
                ...retrieveNodesWithEmptySourceHandles(),
            ]; 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.static, elements, onNodeAdded, onEdgeAdded])

    // add decision node output labels to decision node react flow edges
    const previousElementsLength = useRef(0);
    useEffect(() => {
        if (elements.length && elements.length !== previousElementsLength.current) {
            previousElementsLength.current = elements.length;
            setElements(oldElements => {
                return oldElements.map((element: any, index) => {
                    // if the element is a react flow edge
                    if ((element as any).source !== undefined) {
                        const edge = element as Edge;
                        // find decision nodes connected to this edge
                        for (let j=0;j<oldElements.length;j++) {
                            if (oldElements[j].id === edge.source && oldElements[j]?.data?.type === "decision") {
                                let label: string | undefined;
                                const properties = oldElements[j].data?.properties;
                                if (properties?.length) {
                                    const outputs = properties.find(property => property.key === "outputs");
                                    if (outputs?.value?.length) {
                                        label = outputs.value[(edge as any).sourceHandle]?.label;
                                    }
                                    if (!label && outputs.value[(edge as any).sourceHandle]?.id === "default") {
                                        label = "Default";
                                    }
                                }
                                (oldElements[index] as Edge).label = "";
                                if (label) {
                                    (oldElements[index] as Edge).label = label;
                                }
                            }
                        }
                    }
                    return element;
                });
            });
        }
    }, [elements]);

    // logic for the runbook path traversal feature which applies styles to the traversed/untraversed nodes and edges
    const previousElementsJSON = useRef("");
    useEffect(() => {
        if (elements.length && JSON.stringify(elements) !== previousElementsJSON.current && props.runbookPathTraversalView) {
            setElements(oldElements => {
                const newElements = oldElements.map((element: any, _index) => {  
                    const outputNodes: Elements = [];

                    // the runbook output nodeRunStatus object does not contain the traversed visualization nodes
                    // we will use custom logic to figure out which visualization nodes where traversed
                    const outputNodesIdAndSource: Array<{
                        id: string;
                        source: string;
                    }> = [];

                    oldElements.forEach((element) => {
                        if (element.type === "output") {
                            outputNodes.push(element);
                        }
                    });
        
                    outputNodes.forEach((node) => {
                        for (const element of oldElements) {
                            if ((element as any).target && (element as any).target === node.id) {
                                let canPush = true;
                                for (const element2 of oldElements) {
                                    if (
                                        (element2 as any).source === (element as any).source &&
                                        (element2 as any).source !== (element as any).target &&
                                        props.nodeRunStatus?.find((item) => item.id === (element2 as any).target)
                                    ) {
                                        canPush = false;
                                    }
                                    if (canPush &&
                                        !props.nodeRunStatus?.find((item) => item.id === oldElements.find(item => item.id === (element as any).source)?.id)
                                    ) {
                                        canPush = false;
                                    }
                                }
                                if (canPush) {
                                    // visualization node was traversed
                                    outputNodesIdAndSource.push({
                                        id: node.id,
                                        source: (element as any).source,
                                    });
                                }
                            }
                        }
                    });

                    if (element.target && !outputNodesIdAndSource?.find(node => node.id === element.target) && !props.nodeRunStatus?.find((item) => item.id === (element as any).target)) {
                        // add 0.5 opacity to the edges which were not traversed
                        element.style = {...element.style, opacity: 0.5};
                    }

                    const dataSets = props.runbookOutput?.datasets;

                    const nodeDataSet = dataSets?.find(dataset => dataset.id?.includes(element.id));

                    if (nodeDataSet?.debug) {
                        element.className = `${element.className ? element.className : ''} has-debug`;
                    }

                    const traversedNode = props.nodeRunStatus?.find((item) => item.id === (element as any).id);
                    
                    if (
                        !element.source && 
                        !traversedNode &&
                        !outputNodesIdAndSource.find(outputNode => outputNode.id === (element as any).id)
                    ) {
                        element.className = `${element.className ? element.className : ''} not-traversed`;
                    }

                    if (!traversedNode) return element;

                    if (traversedNode.status === "SUCCEEDED") element.className = `${element.className ? element.className : ''} node-succeeded`;
                    if (traversedNode.status === "FAILED") element.className = `${element.className ? element.className : ''} node-failed`;
                    
                    return element;
                });

                previousElementsJSON.current = JSON.stringify(newElements);

                return newElements;
            });
        }
    }, [elements, props.nodeRunStatus, props.runbookOutput?.datasets, props.runbookPathTraversalView]);

    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        // allow scrolling over the runbook path traversal view

        let scrollTimeout;
    
        const handleScroll = () => {
            setIsScrolling(true);
    
            clearTimeout(scrollTimeout);
    
            scrollTimeout = setTimeout(() => {
                setIsScrolling(false);
            }, 700);
        };
    
        window.addEventListener("wheel", handleScroll);
    
        return () => {
            window.removeEventListener("wheel", handleScroll);
            clearTimeout(scrollTimeout);
        };
    }, []);

    return (<div
        className={classNames({
            "react-flow-component d-flex flex-column": true,
            "h-100": !props.static,
            "graph-static position-relative": props.static,
            "h-100 h-min-5": props.static && (!props.fitContainerHeightToContent || !maxWidthAndHeight),
            "w-100 w-min-5": props.static && (!props.fitContainerHeightToContent || !maxWidthAndHeight),
            ...(props.className ? { [props.className]: true } : {})
        })}
        style={{
            width: props.fitContainerWidthToContent && maxWidthAndHeight?.width ? maxWidthAndHeight.width + "px" : "",
            height: props.fitContainerHeightToContent && maxWidthAndHeight?.height ? maxWidthAndHeight.height + "px" : "",
        }}
    >
        {showToolbar && (!props.runbookPathTraversalView ? <div className="toolbar bg-white w-100 position-relative">
            <GraphToolbar
                notifyToolbarAction={(action: ToolbarAction, value: any) => {
                    switch (action) {
                        case ToolbarAction.ZOOM_IN:
                            reactFlowInstance?.zoomIn();
                            break;
                        case ToolbarAction.ZOOM_OUT:
                            reactFlowInstance?.zoomOut();
                            break;
                        case ToolbarAction.ZOOM_FIT_TO_SCREEN:
                            reactFlowInstance?.fitView();
                            setShowResetViewButton(true);
                            setShowFitToScreenButton(false);
                            break;
                        case ToolbarAction.ZOOM_RESET:
                            reactFlowInstance?.zoomTo(1);
                            setShowResetViewButton(false);
                            setShowFitToScreenButton(true);
                            break;
                        case ToolbarAction.RESET_RUNBOOK:
                            if (props.onReset) {
                                props.onReset();
                            }
                            reactFlowInstance?.zoomTo(1);
                            setShowResetViewButton(false);
                            setShowFitToScreenButton(true);
                            break;
                        case ToolbarAction.LAYOUT_VERTICAL:
                            onLayout('TB', undefined, true);
                            break;
                        case ToolbarAction.LAYOUT_HORIZONTAL:
                            onLayout('LR', undefined, true);
                            break;
                        case ToolbarAction.LAYOUT:
                            onLayout(layoutDirection.current, undefined, true);
                            break;
                        case ToolbarAction.AUTO_CONNECT:
                            setAutoConnectNodes(value);
                            setUserPreferences({ rbe_autoConnect: value ? "" : "off" });
                            break;
                        case ToolbarAction.AUTO_LAYOUT:
                            setAutoLayout(value);
                            if (value) {
                                onLayout(layoutDirection.current, undefined, true);
                            }
                            break;
                        case ToolbarAction.VARIABLES:
                            setIsVariablePopoverOpen(!isVariablePopoverOpen);
                            break;    
                    }
                    if (props.notifyToolbarAction) {
                        props.notifyToolbarAction(action, value);
                    }
                }}
                searchChange={evt => { alert("search change");/*this.onSearchQueryChanged(evt.target.value);*/ }}
                runbooks={props.runbooks} activeRunbook={props.activeRunbook}
                disableNew={elements.length === 0}
                disableSave={!props.runbookModified}
                disableExport={useSimpleImportPreference ? false : props.runbookModified || isNewRunbook}
                showReset
                disableReset={!props.runbookModified}
                showZoomControls
                showResetView={showResetViewButton}
                showFitToScreen={showFitToScreenButton}
                showAutoConnectNodesControl={true}
                autoConnectNodes={autoConnectNodes}
                showRefreshLayoutControl={true}
                autoLayout={autoLayout}
                showLayoutDirectionControls={props.isDevel}
                showExportControl={true}
                showImportControl={props.isDevel}
                showClearControl={props.isDevel}
                showDeleteControl={props.isDevel}
                showSelectRunbookControl={props.isDevel}
                warnings={warnings}
                errors={errors}
                showErrors={true}
                undoAvailable={props.undoAvailable}
                redoAvailable={props.redoAvailable}
                showUndoRedoControls={true}
                multiSelectActivated={props.multiSelect}
                showDevel={props.isDevel}
                showTestControl={VARIANTS_WITH_TEST_IN_GRAPH.includes(props.variant)}
                showPreviewControl={VARIANTS_WITH_PREVIEW_IN_GRAPH.includes(props.variant)}
                showVersion={VARIANTS_WITH_VERSIONING.includes(props.variant)}
            />
        </div> : <div className="toolbar bg-white w-100 position-relative">
        <GraphToolbar
                notifyToolbarAction={(action: ToolbarAction, value: any) => {
                    switch (action) {
                        case ToolbarAction.ZOOM_IN:
                            reactFlowInstance?.zoomIn();
                            break;
                        case ToolbarAction.ZOOM_OUT:
                            reactFlowInstance?.zoomOut();
                            break;
                        case ToolbarAction.ZOOM_FIT_TO_SCREEN:
                            reactFlowInstance?.fitView();
                            setShowResetViewButton(true);
                            setShowFitToScreenButton(false);
                            break;
                    }
                    if (props.notifyToolbarAction) {
                        props.notifyToolbarAction(action, value);
                    }
                }}
                runbooks={props.runbooks} 
                activeRunbook={props.activeRunbook}
                disableNew={true}
                disableSave={true}
                disableExport={props.runbookModified || isNewRunbook}
                showReset={false}
                showZoomControls
                showResetView={false}
                showFitToScreen={true}
                showAutoConnectNodesControl={false}
                autoConnectNodes={false}
                showRefreshLayoutControl={false}
                autoLayout={false}
                showLayoutDirectionControls={false}
                showExportControl={false}
                showImportControl={false}
                showClearControl={false}
                showDeleteControl={false}
                showSelectRunbookControl={false}
                showErrors={false}
                undoAvailable={false}
                redoAvailable={false}
                showUndoRedoControls={false}
                multiSelectActivated={false}
                showDevel={props.isDevel}
                showTestControl={false}
                showPreviewControl={false}
                showVersion={false}
            />
        </div>)}

        <div className={"graph-component-container d-flex flex-grow-1 overflow-auto" + (props.runbookPathTraversalView ? " position-relative" : "")}>
            {isScrolling && props.runbookPathTraversalView && <div className="runbook-traversal-view-scroll-overlay"></div>}
                {
                    props.nodeLibrary && !props.static && !props.runbookPathTraversalView &&
                    <DragAndDropPanel
                        className="bg-white shadow w-max-2-5 p-2 h-100"
                        variant={props.variant}
                        nodeLibrary={props.nodeLibrary}
                        subflows={props.subflows || []}
                        graphDef={props.graphDef}
                        defaultExpandedCategories={props.defaultExpandedCategories}
                        onNodeDoubleClicked={node => {
                            const newPosition = calculateNewNodePosition({
                                nearToNode: mostRecentNodeWithOutput.current,
                                horizontal: isHorizontalLayout(),
                                elements: elements,
                            });
                            
                            onCreateNewNode([{
                                nodeDef: node,
                                position: newPosition
                            }]);
                        }}
                        editorHidden={props.editorHidden}
                        runbookTriggerType={props.activeRunbook?.triggerType}
                        onRuntimeOrSubflowVariableEdited={(event) => {
                            if (props.onRuntimeOrSubflowVariableEdited) {
                                props.onRuntimeOrSubflowVariableEdited(event);
                            }
                            setIsVariablePopoverOpen(false);
                        }}
                        onIncidentVariableEdited={(event) => {
                            if (props.onIncidentVariableEdited) {
                                props.onIncidentVariableEdited(event);
                            }
                            setIsVariablePopoverOpen(false);
                        }}
                        onVariablePopoverClosed={(open: boolean) => {
                            setIsVariablePopoverOpen(open);
                        }}
                        isVariablePopoverOpen={isVariablePopoverOpen}
                        integrations={props.integrations}
                    />
                }
                <div className="layoutflow flex-grow-1" ref={flowContainer}>
                    <ReactFlow
                        zoomOnScroll={!props.static && !props.runbookPathTraversalView}
                        nodesDraggable={!props.static && !props.runbookPathTraversalView}
                        nodesConnectable={!props.static}
                        elementsSelectable={!props.static}
                        elements={elements as Elements<any>}
                        onConnectStart={onConnectStart}
                        onConnectEnd={onConnectEnd}
                        onConnect={onConnect}
                        connectionLineStyle={defaultEdgeStyle}
                        connectionLineType={defaultEdgeType}
                        onElementsRemove={(elementsToRemove) => {
                            onElementsRemove(elementsToRemove);
                        }}
                        //nodeExtent={nodeExtent}
                        nodeTypes={{
                            input: DefaultNode,
                            output: DefaultNode,
                            default: DefaultNode,
                            comment: DefaultNode,
                            subflow: SubflowNode,
                            switch: SwitchNode,
                            logic: LogicalNode,
                            aggregate: AggregatorNode,
                            decision: DecisionNode,
                            skeleton: SkeletonNode,
                        }}
                        onLoad={onLoad}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onNodeDragStart={onNodeDragStart}
                        onNodeDragStop={onNodeDragEnd}
                        onNodeMouseEnter={onMouseEnterNode}
                        onNodeMouseLeave={onMouseLeaveNode}
                        onNodeDoubleClick={onNodeDoubleClicked}
                        onSelectionDragStop={onSelectionDragStop}
                        selectNodesOnDrag={false}
                        onSelectionChange={onSelectionChange}
                        onClick={(event) => {
                            lastClick.current = { clientX: event.clientX, clientY: event.clientY };
                        }}
                        deleteKeyCode={isMac ? "Backspace" : "Delete"}
                        multiSelectionKeyCode={isMac ? "Meta" : "Control"}
                        snapToGrid={true}
                        snapGrid={[1, 20]}
                        onElementClick={(_event, element) => {
                            if (
                                element && 
                                element.id && 
                                element.data && 
                                props.openTraversedNodeInfoDialog &&
                                !element.data.type?.includes("rvbd_ui") && 
                                props.runbookPathTraversalView
                            ) {
                                props.openTraversedNodeInfoDialog(element);
                            }
                        }}
                    >
                        {/* <Controls style={{position: "absolute", "bottom": 70}} showInteractive={!props.static}/> */}
                    </ReactFlow>
                </div>
        </div>
    </div>);

    /**
     * Add skeleton nodes to the chart with the related connections
     * 
     * @param forNode 
     * @param sourceHandles
     */
    function addSkeletonNodes(
        parentNode: Node,
        sourceHandles: number[] | null[],
        subflowsPreference: SubflowsPreference
    ) {
        if (!sourceHandles) {
            sourceHandles = [null]
        }

        let alreadyExists = edges?.find((el) => el.source === parentNode.id);

        if (alreadyExists) {
            return;
        }

        let nodesToAdd: any[] = [];
        let edgesToAdd: any[] = [];

        sourceHandles.forEach(sourceHandle => {
            const skeletonNodeProps = [
                { 
                    key: SKELETON_NODE_PROP_KEYS.parent, 
                    value: parentNode
                },
                {
                    key: SKELETON_NODE_PROP_KEYS.sourceHandle,
                    value: sourceHandle,
                },
            ];
            const skeletonNodeOptions =  {
                showOutputs: false,
                output: sourceHandle || 0,
            }
            const skeletonNodeId = `skeletonFor-${parentNode.id}:${sourceHandle}`;
            const skeletonNodeDef = {
                id: skeletonNodeId,
                type: "skeleton",
                data: {
                    type: "skeleton",
                    label: skeletonNodeId,
                    wires: {
                        direction: DIRECTION.IN,
                    },
                    icon: "",
                    properties: skeletonNodeProps,
                    editedByUser: true,
                    handleGetMenuItems: () => {
                        return getAddNodeMenu(
                            props.nodeLibrary, parentNode, props.graphDef,
                            elementsRef.current as FlowElement[], onCreateNewNode, 
                            isHorizontalLayout, setSelectedElements, IS_EMBEDDED,
                            props.exclusions, skeletonNodeOptions, props.subflows,
                            subflowsPreference, props.integrations
                        );
                    },
                },
            };

            const skeletonNodePosition = calculateNewNodePosition({
                nearToNode: parentNode,
                position: { x: parentNode.position.x + 200, y: parentNode.position.y + 50 * sourceHandle },
                horizontal: isHorizontalLayout(),
                elements: elements,
                includeSkeleton: true
            });

            const edge: Edge = {
                id: "reactFlowEdge",
                source: parentNode?.id,
                target: skeletonNodeId,
                sourceHandle:
                    typeof sourceHandle === "number"
                        ? String(sourceHandle)
                        : null,
                targetHandle: null,
                animated: true,
            };

            if (!["set_primitive_variables", "set_structured_variable"].includes(parentNode.data?.type)) {
                nodesToAdd.push({
                    nodeDef: skeletonNodeDef,
                    position: skeletonNodePosition,
                })

                edgesToAdd.push(edge);
            }
        });

        return onCreateNewNode(nodesToAdd, edgesToAdd, false, false, true);
    }

    /**
     * Retrieve all the nodes with unconnected source handles
     */
    function retrieveNodesWithEmptySourceHandles(): any[] {
        let unconnectedNodes: any[] = [];

        const nodesWithOutputs = elements?.filter(el => (!skeletonNodesDef.includes(el?.type || ''))).filter(isNodeWithOutputs);

        if (nodesWithOutputs) {
            nodesWithOutputs.forEach((node) => {
                let firstHandleWithNoOutput: number | null = null;
                const outputsInfo = node?.data?.properties?.find(
                    (el) => el.key === "outputs"
                );

                // Don't add skeleton node for variables in a subflow
                if (props.variant === Variant.SUBFLOW && isVariablesNode(node?.data as RunbookNode)) {
                    return;
                }

                // If the node has multiple handles (more output nodes), and some are not connected
                if (outputsInfo) {
                    const maxOutputs = outputsInfo?.value.length;
                    // @ts-ignore
                    const currentOutputs = edges.filter((el) => el.source === node?.id);

                    const handles = currentOutputs
                        .map((el) => Number(el.sourceHandle || 0))
                        .sort();

                    const unconnectedOutputHandles: number[] = getUnconnectedOutputHandles(
                        maxOutputs,
                        handles
                    );

                    if (unconnectedOutputHandles.length > 0) {
                        unconnectedNodes.push({
                            node: node,
                            sourceHandles: unconnectedOutputHandles
                        });
                    }
                }

                // If the node is not connected, we add a placeholder node
                else {
                    const isNotConnected = !edges.some(
                        (edge) => edge.source === node.id
                    );

                    if (isNotConnected) {
                        unconnectedNodes.push({
                            node: node,
                            sourceHandles: [firstHandleWithNoOutput]
                        });
                    }
                }
            });
        }

        return unconnectedNodes;
    }
    
    /**
     * Remove unconnected skeleton nodes
     * 
     * @param connections - edges in the node
     */
    function cleanupSkeletonNodes(connections: Edge[]) {
        const skeletonNodes = elements.filter((el) => skeletonNodesDef.includes(el.type || ''));
        const unconnectedSkeletonNodes = skeletonNodes.filter((node) => !connections.find((connection) => connection?.target === node.id)) as Node[];
        cleanConnectedNodes(skeletonNodes, connections);

        unconnectedSkeletonNodes.forEach(node => {
            removeSkeletonNode(node);
        });
    }
    
    /**
     * Remove skeleton nodes from already connected nodes
     * 
     * @param skeletonNodes 
     * @param connections 
     */
    function cleanConnectedNodes(skeletonNodes: FlowElement<any>[], connections: Edge<any>[]) {
        const connectedSkeletonNodes = skeletonNodes.filter((node) => connections.find((connection) => connection?.target === node.id)) as Node[];

        connectedSkeletonNodes.forEach(node => {
            const connectionToSourceNode = connections.find((el) => el.target === node.id) as Edge;

            if (!connectionToSourceNode) {
                return;
            }

            const allConnectionsToSourceNode = connections.filter((el) => el.source === connectionToSourceNode.source && el.sourceHandle === connectionToSourceNode.sourceHandle);

            if (allConnectionsToSourceNode.length < 2) {
                return;
            }

            /*
            If there are two connections to the same source and source handle, that means we need to remove
            the skeleton node
            */
            removeSkeletonNode(node);
        });
    }

    /**
     * 
     * @param maxOutputs Maximum number of outputs in a node
     * @param handles Current outputs for a node
     * 
     * @returns Array
     */
    function getUnconnectedOutputHandles(maxOutputs: any, handles: number[]) {
        let unconnectedHandles: number[] = [];

        for (var i = 0; i < maxOutputs; i++) {
            if (handles.indexOf(i) === -1) {
                unconnectedHandles.push(i);
            }
        }

        return unconnectedHandles;
    }

    /**
     * Add a connection from the parent of the placeholder to the newly created node
     * 
     * @param skeletonParentNode 
     * @param createdNodeId 
     * @param skeletonParentSourceHandle
     */
    function addConnectionToSkeletonParentNode(
        skeletonParentNode: Node,
        createdNodeId: string,
        skeletonParentSourceHandle: string
    ) {
        const edge = {
            source: skeletonParentNode.id as string,
            target: createdNodeId as string,
            sourceHandle: skeletonParentSourceHandle,
            targetHandle: null,
        };

        onConnect(edge, true, true);
    }

    /**
     * Remove the skeleton node
     * 
     * @param selectedNode 
     * @param elementsRef 
     */
    function removeSkeletonNode(selectedNode: Node) {
        // @ts-ignore
        const links = elementsRef.current?.filter(element => element.source === selectedNode.id || element.target === selectedNode.id);
        const elementsToRemove = new Array<any>(selectedNode).concat(links);

        onElementsRemove(elementsToRemove);
    }

    /**
     * Get a list of elements attached to the node in action
     * 
     * @param nodeInAction 
     * 
     * @returns Array of elements to be removed from the graph
     */
    function getElementsToRemove(nodeInAction: Node<any>) {
        // @ts-ignore
        const links = elementsRef.current?.filter(element => element.source === nodeInAction.id || element.target === nodeInAction.id) || [];
        // @ts-ignore
        const connectionLinks = links?.filter(el => { return el.target || el.source; });
        const skeletonNodes = elementsRef.current?.filter((el) => skeletonNodesDef.includes(el.type || ''));

        let skeletonNodesToRemove: FlowElement[] = [];

        if (connectionLinks && skeletonNodes) {
            connectionLinks.forEach(connection => {
                const connectionNodesToRemove = skeletonNodes.filter(
                    (el) => {
                        // @ts-ignore
                        const isLinked = el.id === connection.target || el.id === connection.source;

                        return isLinked;
                    }
                );

                if (connectionNodesToRemove.length) {
                    skeletonNodesToRemove = [...skeletonNodesToRemove, ...connectionNodesToRemove];
                }
            });
        }

        const elementsToRemove = [nodeInAction, ...links, ...skeletonNodesToRemove];
        return elementsToRemove;
    }
};

export default ReactFlowGraph;

export function getReactFlowNodeType (node: any)  {
    let type = "default";
    if (node?.type === "decision") {
        type = "decision";
    } else if (node?.type === "switch") {
        type = "switch";
    } else if (node?.type === "logic") {
        type = "logic";
    } else if (node?.type === "skeleton") {
        type = "skeleton";
    //} else if (node?.type?.startsWith("subflow:")) {
    } else if (subflowNodes.includes(node?.type)) {
        type = "subflow";
    } else {
        switch (node?.wires?.direction) {
            case DIRECTION.IN:
                type = "output";
                break;
            case DIRECTION.OUT:
                type = "input";
                break;
            case DIRECTION.BOTH:
                type = "default";
                break;
            case DIRECTION.NONE:
                type = "comment";
                break;
        }
    }
    return type;
}

/** outputs the react-flow elements based on the GraphDef object.
 *  @param nodeLibrary a reference to the NodeLibrary with the nodes that are currently displayed in the runbook editor.
 *  @param graphDef the GraphDef object with the graph data.
 *  @returns the Elements with the graph nodes and edges.*/
function getElements(
    nodeLibrary: NodeLibrary, 
    graphDef: GraphDef, 
    integrations: Array<RunbookIntegrationDetails> | undefined, 
    subflows: Array<RunbookNode> | undefined,
    nodeRunStatus
): Elements {
    const elements: Elements = [];
    for (const node of graphDef.nodes) {
        const type = getReactFlowNodeType(node);
        const connectable = (node?.wires?.direction === DIRECTION.NONE || !node?.wires?.direction ? false : true);
        const nodeEl: Node<any> = {
            id: node.id,
            type,
            connectable,
            data: {
                editedByUser: node.editedByUser,
                label: node.name,
                type: node.type,
                info: node.info,
                color: node.color,
                wires: node.wires,
                icon: node.icon,
            },
            position: {
                x: node.x !== null && node.x !== undefined ? node.x : 0,
                y: node.y !== null && node.y !== undefined ? node.y : 0
            },
        };
        // Handle the i18n keys for our own runbooks
        if (node.i18nNameKey) {
            nodeEl.data.i18nNameKey = node.i18nNameKey;
        }
        if (node.i18nInfoKey) {
            nodeEl.data.i18nInfoKey = node.i18nInfoKey;
        }

        nodeEl.style = {};
        nodeEl.style.background = node.color;

        if (node.properties) {
            nodeEl.data.properties = node.properties;
        }
        if (node.passThruProperties) {
            nodeEl.data.passThruProperties = node.passThruProperties;
        }
        if (node.env) {
            nodeEl.data.env = node.env;
        }

        // Handle subflows
        //if (node.type.startsWith("subflow:")) {
        if (subflowNodes.includes(node.type)) {
            nodeEl.type = "subflow";
            const subflowIdProp = (node?.properties || []).find((el) => el.key === "configurationId");
            const nodeSubflow = subflows?.find(el => el.id === subflowIdProp?.value);
            const integrationConfiguration = integrations?.find(el => el.id === nodeSubflow?.integrationId);
            if (nodeSubflow && integrationConfiguration) {
                nodeEl.data.integrationInfo = {
                    id: nodeSubflow?.integrationId, 
                    icon: integrationConfiguration?.branding?.icons?.find(icon => icon.type === "avatar")?.svg,
                    name: integrationConfiguration?.name || "",
                    primaryColor: integrationConfiguration?.branding?.primaryColor || DEFAULT_SUBFLOW_COLOR,
                    secondaryColor: integrationConfiguration?.branding?.secondaryColor
                };
            }    
        }

        // Handle switches
        if (node.type === "switch") {
            nodeEl.type = "switch";
        }

        // Handle logical nodes
        if (node.type === "logic") {
            nodeEl.type = "logic";
        }

        const nodeFromLibrary = nodeLibrary.getNodeUsingProperties(node.type, node.properties);

        if (nodeFromLibrary && nodeFromLibrary.subType) {
            nodeEl.data.subType = nodeFromLibrary.subType;
        }
    
        elements.push(nodeEl);
    }

    const newStyle = { stroke: '#007bff', strokeWidth: '4px' };

    const outputNodes: Elements = [];

    elements.forEach(element => {
        if (element.type === "output") {
            outputNodes.push(element);
        }
    });

    const outputNodesIdAndSource: Array<{ id: string, source: string }> = [];
    
    outputNodes.forEach((node) => {
        for (const edge of graphDef.edges) {
            if (edge.toNode === node.id) {
                let canPush = true;
                for (const edge2 of graphDef.edges) {
                    if (
                        edge2.fromNode === edge.fromNode &&
                        edge2.toNode !== edge.toNode &&
                        nodeRunStatus?.find((item) => item.id === edge2.toNode)
                    ) {
                        canPush = false;
                    }
                }
                // if (
                //     !nodeRunStatus?.find((item) => item.id === graphDef.nodes.find(item => item.id === node.source)?.id) &&
                //     graphDef.nodes.find(item => item.id === node.source)?.type !== "decision"
                // ) {
                //     canPush = false;
                // }
                if (canPush) {
                    outputNodesIdAndSource.push({
                        id: node.id,
                        source: edge.fromNode,
                    });
                }
                break;
            }
        }
    });

    let elIndex = 1;
    for (const edge of graphDef.edges) {
        const edgeDefn: Edge<any> = {
            id: "" + elIndex++,
            source: edge.fromNode,
            target: edge.toNode,
            type: defaultEdgeType,
            style:
                nodeRunStatus?.find((item) => item.id === edge.toNode) ||
                outputNodesIdAndSource?.find(
                    (item) =>
                        item.id === edge.toNode &&
                        item.source === edge.fromNode &&
                        nodeRunStatus?.find((node) => node.id === edge.fromNode),
                )
                    ? newStyle
                    : defaultEdgeStyle,
            arrowHeadType: defaultArrowHeadType,
            animated: false,
        };
        if (edge.fromPort) {
            edgeDefn.sourceHandle = edge.fromPort;
        }
        if (edge.toPort) {
            edgeDefn.targetHandle = edge.toPort;
        }
        elements.push(edgeDefn);
    }

    return elements;
}

/** This function splits the name string into main name and subText */
export function splitName(name: string) {
    const splitIndex = name.indexOf("(");
    return [
        (splitIndex > 0 ? name.substr(0, splitIndex).trim() : name),
        (splitIndex > 0 ? name.substr(splitIndex).trim() : "")
    ];
}

/** Utility function to check if the passed flow element is a node with connectable inputs */
function isNodeWithInputs(node: FlowElement): boolean {
    return node?.data?.wires?.direction === DIRECTION.IN || node?.data?.wires?.direction === DIRECTION.BOTH;
}

/** Utility function to check if the passed flow element is a node with connectable outputs */
function isNodeWithOutputs(node: FlowElement): boolean {
    return node?.data?.wires?.direction === DIRECTION.OUT || node?.data?.wires?.direction === DIRECTION.BOTH;
}

// TBD: If necessary, add logic to calculate actual height and/or width of nodes instead of using these constants.
// It probably won't be needed 90% of the times
const NODE_APPROX_WIDTH = 150;
const NODE_APPROX_HEIGHT = 30;

/** Logic that can be used to identify the most appropriate node from an array of nodes to which another node should be connected to.
 *  @param applicableSourceNodes An array of nodes that will be compared against to identify the one to connect to
 *  @param nodeToConnectTo The target node to which the connection is to be established
 *  @param horizontal A boolean flag indicating if current layout is in horizontal or vertical format so that calculations can be done appropriately
 *  @returns the handle node. */
function identifyNodeToConnectFrom(elements: Array<FlowElement>, nodeToConnectTo: Node, horizontal: Boolean): Node | undefined {
    const calculateDistanceBetweenTwoNodes = (sourceNode, targetNode) => {
        const xDifference = targetNode.position.x - (sourceNode.position.x + (horizontal ? NODE_APPROX_WIDTH : 0));
        const yDifference = targetNode.position.y - (sourceNode.position.y + (horizontal ? NODE_APPROX_HEIGHT : 0));
        const distanceFromNode = Math.sqrt(Math.pow(xDifference, 2) + Math.pow(yDifference, 2));
        return Math.ceil(distanceFromNode);
    }
    let nodeToConnectFrom;
    // Filter out nodes without outputs and all the edge elements and typecast the output to 'Node'
    const applicableSourceNodes = elements.filter(isNodeWithOutputs).map(nodeOrEdgeAsPerTS => nodeOrEdgeAsPerTS as Node);
    if (applicableSourceNodes.length > 0) {
        // Filter down the list to only nodes that are to the left of/above the node to connect to based on layout direction.
        // Also remove the target node if it exists in the list
        const connectableNodes = applicableSourceNodes.filter(sourceNode => (
            sourceNode.id !== nodeToConnectTo.id && (
                horizontal ?
                    ((sourceNode.position.x + NODE_APPROX_WIDTH) < nodeToConnectTo.position.x) :
                    ((sourceNode.position.y + NODE_APPROX_HEIGHT) < nodeToConnectTo.position.y)
            )
        )
        );
        // If there are still nodes left, then sort them based on which node is the closest and return the first one
        if (connectableNodes.length > 0) {
            connectableNodes.sort((sourceNodeA, sourceNodeB) => {
                const distanceA = calculateDistanceBetweenTwoNodes(sourceNodeA, nodeToConnectTo);
                const distanceB = calculateDistanceBetweenTwoNodes(sourceNodeB, nodeToConnectTo);
                return distanceA - distanceB;
            });
            nodeToConnectFrom = connectableNodes[0];
        }
    }

    return nodeToConnectFrom;
}

/** identifies the handles to connect from based on the specified nodes.
 *  @param fromNode the from node.
 *  @param toNode the two node.
 *  @param horizontal a boolean, true if the layout is horizontal, false if it is vertical.
 *  @returns a number with the handle to connect from or null. */
function identifyHandleToConnectFrom(fromNode: Node, toNode: Node, edges: FlowElement[], horizontal: Boolean): null | number {
    let handleIndex: null | number = null;

    const countOfHandles = fromNode.data?.wires?.out?.length || fromNode.data?.wires?.outputsCount || 1;

    if (countOfHandles > 1) {
        const approxDimension = horizontal ? NODE_APPROX_HEIGHT : NODE_APPROX_WIDTH;
        const relativePosition = horizontal ?
            ((fromNode.position.y + (approxDimension / 2)) - (toNode.position.y + (approxDimension / 2))) :
            ((fromNode.position.x + (approxDimension / 2)) - (toNode.position.x + (approxDimension / 2)));

        // TBD: This logic only connects to the first or last output port. Enhance this logic when nodes with
        // more than 2 outputs are supported to calculate an even more precise output port.
        if (relativePosition < 0) {
            handleIndex = countOfHandles - 1;
        } else {
            handleIndex = 0;
        }
    }
    return handleIndex;
}

/** calculates a position for the node based on a set of parameters.
 *  @param param0 
 *  @returns the new node position that should be used when placing the node on the graph. */
function calculateNewNodePosition(
    { node, nearToNode, position, horizontal = true, elements, includeSkeleton }:
        { node?: Node, nearToNode?: Node, position?: XYPosition, horizontal?: Boolean, elements?: Array<FlowElement>, includeSkeleton?: boolean }
): { x: number, y: number } {
    const bufferWidth = Math.ceil(NODE_APPROX_WIDTH / 2);
    const bufferHeight = horizontal ? NODE_APPROX_HEIGHT : (NODE_APPROX_HEIGHT * 2);
    let newPosition = { x: Math.ceil(NODE_APPROX_WIDTH / 2), y: Math.ceil(NODE_APPROX_WIDTH / 2) };
    if (position) {
        newPosition = position;
    } else if (nearToNode?.position) {
        newPosition = { ...nearToNode.position };
        if (isNodeWithOutputs(nearToNode) && (!node || isNodeWithInputs(node))) {
            if (horizontal) {
                newPosition.x += NODE_APPROX_WIDTH + bufferWidth;
            } else {
                newPosition.y += NODE_APPROX_HEIGHT + bufferHeight;
            }
        }
    }
    if (elements && isArray(elements)) {
        let hasCollision;
        do {
            hasCollision = false;

            for (const element of elements) {
                if (isNode(element) && (includeSkeleton || element.data?.type !== "skeleton")) {
                    // Are nodes colliding (or are too close)?
                    if (
                        (newPosition.x + NODE_APPROX_WIDTH) >= (element.position.x - 10) && newPosition.x <= (element.position.x + NODE_APPROX_WIDTH + 10) &&
                        (newPosition.y + NODE_APPROX_HEIGHT) >= (element.position.y - 10) && newPosition.y <= (element.position.y + NODE_APPROX_HEIGHT + 10)
                    ) {
                        if (horizontal) {
                            newPosition.y += bufferHeight;
                        } else {
                            newPosition.x += bufferWidth;
                        }
                        hasCollision = true;
                        break;
                    }
                }
            }
        } while (hasCollision);
    }
    return newPosition;
}

/** returns the top level menu items for the add node menu and all its descendants.
 *  @param nodeInAction the node that the user invoked the quick control menu on.
 *  @param nodeLibrary the node library that describes the full set of nodes.
 *  @param graphDef the GraphDef with the current state of the graph.
 *  @param elements the current set of elements in the react-flow graph.
 *  @param onCreateNewNode the function to create a new node.
 *  @param isHorizontalLayout function that returns true if the layout is horizontal and false if it is vertical.
 *  @param setSelectedElements 
 *  @param isEmbedded a boolean value, true if the ui is embedded, false if not.
 *  @param exclusions the rules for which nodes can be connected.
 *  @param options the options passed in.
 *  @param subflows the subflow runbooks list.
 *  @param subflowsPreference the subflows preferences that specify whether the integrations should be pulled out
 *      into a parent menu in the add menu.
 *  @param integrations .
 *  @returns the top level menu items in the add node menu. */
export function getAddNodeMenu(
    library: NodeLibrary, nodeInAction: Node, graphDef: GraphDef, elements: Elements, onCreateNewNode: Function, 
    isHorizontalLayout: () => boolean, setSelectedElements: Function, isEmbedded: boolean, exclusions?: ConnectionExclusions, 
    options?: any, subflows?: any, subflowsPreference?: SubflowsPreference, integrations?: RunbookIntegrationDetails[]
): Array<JSX.Element> {
    let topLevelMenuItems: Array<JSX.Element> = [];

    if (library && library.getNodeSpecification().categories) {
        const outputLabels = nodeInAction?.data?.wires?.outputLabels || [];

        const decisionOutputs = nodeInAction?.data?.properties?.find(prop => prop.key === "outputs")?.value;
        //const subflowOutputs = nodeInAction?.data?.properties?.find(props=> props.key === "out")?.value;
        const subflowOutputs = nodeInAction?.data?.wires?.out;
        
        const numOutputs = decisionOutputs?.length || subflowOutputs?.length || nodeInAction?.data?.wires?.outputsCount || 1;
        for (let output = 0; output < numOutputs; output++) {

            if (!options.showOutputs && output !== options.output) {
                continue;
            }

            let categoryMenuItems: Array<JSX.Element> = [];

            for (const libraryCategory of library.getNodeSpecification().categories!) {
                if (libraryCategory.name === "config") {
                    // As far as I can tell the user must never add config nodes to the visible graph
                    continue;
                } else if (
                    libraryCategory.embed !== undefined &&
                    ((libraryCategory.embed && libraryCategory.embed !== isEmbedded) || (!libraryCategory.embed && isEmbedded === true))
                ) {
                    continue;
                } else if (
                    (libraryCategory.env !== undefined && !libraryCategory.env.includes(ENV)) || 
                    libraryCategory?.deprecated === true
                ) {
                    continue;
                } else if (libraryCategory.triggerTypes !== undefined) {
                    let triggerSupported = true;
                    for (const triggerType of libraryCategory.triggerTypes) {
                        if (!getTriggerTypes().includes(triggerType)) {
                            triggerSupported = false;
                            break;
                        }
                    }
                    if (!triggerSupported) {
                        continue;
                    }                        
                }

                const categoryDisplayName = STRINGS.runbookEditor.nodeLibrary.categories[libraryCategory.name] ?
                    STRINGS.runbookEditor.nodeLibrary.categories[libraryCategory.name] : libraryCategory.name;

                const menuItems: Array<JSX.Element> = [];
                if (libraryCategory.nodes) {
                    for (const rawLibraryNode of libraryCategory.nodes) {
                        // Get the library node from the library node cache.  Note that the subflow nodes
                        // are not cached so we continue if there is no cached node otherwise the subflows are 
                        // not displayed and cannot be added to the graph
                        let libraryNode = library.getNode(rawLibraryNode.type, undefined, libraryCategory.name);
                        libraryNode = libraryNode ? libraryNode : rawLibraryNode;

                        const subTypes: Array<NodeLibrarySubType> = libraryNode.subTypes ? libraryNode.subTypes : [{ subType: "", defaults: [] as Array<SubTypeDefault> }];
                        for (const subTypeConfig of subTypes) {
                            const { subType } = subTypeConfig;
                            if (subType === "divider" || rawLibraryNode.type === "divider") {
                                continue;
                            }

                            const finalNodeConfig: any = library.getNode(
                                rawLibraryNode.type, (subType === "" ? undefined : subType), libraryCategory.name
                            ) || rawLibraryNode;

                            let triggerSupported = true;
                            if (finalNodeConfig.uiAttrs?.triggerTypes) {
                                for (const triggerType of finalNodeConfig.uiAttrs.triggerTypes) {
                                    if (!getTriggerTypes().includes(triggerType)) {
                                        triggerSupported = false;
                                        break;
                                    }
                                }                        
                            }

                            let doSupported = true;
                            if (finalNodeConfig.properties) {
                                for (const property of finalNodeConfig.properties) {
                                    if (property.name === "objType") {
                                        const objType = property.default;
                                        doSupported = DataOceanUtils.isObjectTypeSupported(objType, getTypes());
                                        break;
                                    }
                                }    
                            }
                        
                            if (
                                finalNodeConfig && finalNodeConfig.uiAttrs?.deprecated !== true && triggerSupported && doSupported &&
                                (!finalNodeConfig.uiAttrs?.env || finalNodeConfig.uiAttrs?.env.includes(ENV)) &&
                                (finalNodeConfig.uiAttrs?.embed === undefined || ((finalNodeConfig.uiAttrs?.embed && (finalNodeConfig.uiAttrs?.embed === isEmbedded || isEmbedded === undefined)) || (!finalNodeConfig.uiAttrs?.embed && isEmbedded !== true)))
                            ) {
                                const resources = NodeLibrary.getNodeResourceStrings(libraryNode.type, subType);

                                // Logic to render the node's name
                                let name = libraryNode.subflowName || resources.name || libraryNode.type;
                                //const [nodeName, subText] = splitName(name);
                                //const nameElement = subText ? <div>{nodeName}<div className="display-10">{subText}</div></div> : name;
                                const properties: Array<{ key: string, value: any }> = [];
                                if (finalNodeConfig.properties) {
                                    for (const property of finalNodeConfig.properties) {
                                        let defaultValue = property.default ? JSON.parse(JSON.stringify(property.default)) : property.default;
                                        if (finalNodeConfig.defaults) {
                                            for (const subTypeDefault of finalNodeConfig.defaults) {
                                                if (subTypeDefault.name === property.name && subTypeDefault.default !== null && subTypeDefault.default !== undefined) {
                                                    defaultValue = subTypeDefault.default ? JSON.parse(JSON.stringify(subTypeDefault.default)) : subTypeDefault.default;
                                                    break;
                                                }
                                            }
                                        }

                                        if (defaultValue !== null && defaultValue !== undefined) {
                                            properties.push({ key: property.name, value: defaultValue });
                                        }
                                    }
                                }

                                const connectError: string | undefined = canConnect(
                                    nodeInAction.data.type, nodeInAction.data.subType, nodeInAction.id, nodeInAction.data.properties, output,
                                    libraryNode.type, subType, "", properties, finalNodeConfig.wires, 
                                    graphDef, exclusions
                                );
                                if (!connectError) {
                                    const icon = finalNodeConfig.icon || "";
                                    const newPosition = calculateNewNodePosition({
                                        position: { x: nodeInAction.position.x + 200, y: nodeInAction.position.y },
                                        horizontal: isHorizontalLayout(),
                                        elements: elements,
                                    });
                                    menuItems.push(<MenuItem text={name} key={name} 
                                        icon={<Icon icon={SDWAN_ICONS[icon] || APP_ICONS[icon] || icon} className={icon === "AZURE_MONITOR" ? "ic-azure-monitor" : ""}/>} onClick={(e) => {
                                        const edge: Edge = {
                                            id: "", source: nodeInAction.id, target: "newNodeId",
                                            sourceHandle: numOutputs > 1 ? String(output) : null, targetHandle: null
                                        };
                                        onCreateNewNode([{
                                            nodeDef: {
                                                id: "newNodeId",
                                                type: libraryNode!.type,
                                                data: {
                                                    type: finalNodeConfig.type,
                                                    subType: subType,
                                                    label: name,
                                                    info: "",
                                                    color: finalNodeConfig.color,
                                                    wires: finalNodeConfig.wires,
                                                    icon: icon,
                                                    properties: properties
                                                }
                                            },
                                            position: newPosition
                                        }], [edge] as any, false, false, true);

                                        setSelectedElements([]);
                                    }} />);
                                }
                            }
                        }
                    }
                }

                if (menuItems && menuItems.length > 0) {
                    categoryMenuItems.push(<MenuItem text={categoryDisplayName} key={libraryCategory.name} >{menuItems}</MenuItem>);
                }
            }

            if (subflows?.length) {
                const dedupSubflows = deduplicateSubflows(subflows);
                const { builtInSubflows, nonBuiltInSubflows } = subflowOrderingInNodePalette(dedupSubflows);
                const sortedSubflows = [...builtInSubflows, ...nonBuiltInSubflows];
                const subflowCategories: Record<string, NodeLibraryCategory> = {};

                for (const subflow of sortedSubflows) {
                    const integrationId: string = subflow.integrationId || "";
                    const connectorIntegrationDetails: RunbookIntegrationDetails | undefined = (integrations || []).find(integration => integration.id === integrationId);
                    const connectorName: string = (integrations || []).find(integration => integration.id === integrationId)?.name || integrationId;
                    const subflowCategoryName: string = connectorName || subflow.category || STRINGS.runbookEditor.nodeLibrary.categories.subflows!;
                    if (!subflowCategories[subflowCategoryName]) {
                        subflowCategories[subflowCategoryName] = { 
                            name: subflowCategoryName, 
                            color: DEFAULT_SUBFLOW_COLOR, 
                            defaults: {
                                color: DEFAULT_SUBFLOW_COLOR,
                                wires: {
                                    direction: DIRECTION.NONE
                                }
                            },
                            nodes: [] 
                        };
                    }

                    let wires: NodeWiresSetting = {
                        direction: DIRECTION.NONE, in: subflow.in, inputLabels: subflow.inputLabels,
                        out: subflow.out, outputLabels: subflow.outputLabels
                    };
                    if ((subflow?.in?.length || 0) > 0 && (subflow?.out?.length || 0) > 0) {
                        wires.direction = DIRECTION.BOTH;
                    } else if ((subflow?.in?.length || 0) > 0) {
                        wires.direction = DIRECTION.IN;
                    } else if ((subflow?.out?.length || 0) > 0) {
                        wires.direction = DIRECTION.OUT;
                    }
                    const subflowNode: NodeLibraryNode = {
                        type: "subflow", icon: subflow.builtIn? "LOCK": subflow.icon,
                        subflowName: subflow.name || "unknown", subflowId: subflow.id, subflowBuiltIn: subflow.builtIn, 
                        wires, color: subflow.color || "", uiAttrs: {showDebug: true},
                        integrationId: subflow?.integrationId || "",
                        integrationInfo: {
                            id: integrationId, 
                            icon: connectorIntegrationDetails?.branding?.icons?.find(icon => icon.type === "avatar")?.svg,
                            name: connectorIntegrationDetails?.name || "",
                            primaryColor: connectorIntegrationDetails?.branding?.primaryColor || DEFAULT_SUBFLOW_COLOR,
                            secondaryColor: connectorIntegrationDetails?.branding?.secondaryColor
                        },
                        properties: [
                            {name: "debug", type: "boolean", label: "debug", default: false},
                            {name: "configurationId", label: "configurationId", type: "hidden", default: subflow.id},
                            {name: "in", label: "in", type: "hidden", default: (subflow.inputVariables || []).map(variable => {return {inner: variable.name, outer: ""}})},
                            {name: "out", label: "out", type: "hidden", default: (subflow.outputVariables || []).map(variable => {return {inner: variable.name, outer: ""}})}
                        ],
                        subflowDescription: subflow.info
                    };
                    subflowCategories[subflowCategoryName].nodes!.push(subflowNode);
                }
    
                const subflowCategoryArray: string[] = [];
                if (STRINGS.runbookEditor.nodeLibrary.categories.subflows in subflowCategories) {
                    subflowCategoryArray.push(STRINGS.runbookEditor.nodeLibrary.categories.subflows);
                }
                for (const catName in subflowCategories) {
                    if (catName === STRINGS.runbookEditor.nodeLibrary.categories.subflows) {
                        continue;
                    }
                    subflowCategoryArray.push(catName);
                }
                const integrationsMenuItems: Array<JSX.Element> = [];
                for (const catName of subflowCategoryArray) {
                    const libraryCategory = subflowCategories[catName];

                    const categoryDisplayName = STRINGS.runbookEditor.nodeLibrary.categories[libraryCategory.name] ?
                        STRINGS.runbookEditor.nodeLibrary.categories[libraryCategory.name] : libraryCategory.name;

                    const menuItems: Array<JSX.Element> = [];
                    if (libraryCategory.nodes) {
                        for (const rawLibraryNode of libraryCategory.nodes) {
                            let libraryNode = library.getNode(rawLibraryNode.type, undefined, libraryCategory.name);
                            libraryNode = libraryNode ? libraryNode : rawLibraryNode;

                            const finalNodeConfig: any = library.getNode(
                                rawLibraryNode.type, undefined, libraryCategory.name
                            ) || rawLibraryNode;

                            let triggerSupported = true;
                            if (finalNodeConfig.uiAttrs?.triggerTypes) {
                                for (const triggerType of finalNodeConfig.uiAttrs.triggerTypes) {
                                    if (!getTriggerTypes().includes(triggerType)) {
                                        triggerSupported = false;
                                        break;
                                    }
                                }                        
                            }

                            let doSupported = true;
                            if (finalNodeConfig.properties) {
                                for (const property of finalNodeConfig.properties) {
                                    if (property.name === "objType") {
                                        const objType = property.default;
                                        doSupported = DataOceanUtils.isObjectTypeSupported(objType, getTypes());
                                        break;
                                    }
                                }    
                            }
                        
                            if (
                                finalNodeConfig && finalNodeConfig.uiAttrs?.deprecated !== true && triggerSupported && doSupported &&
                                (!finalNodeConfig.uiAttrs?.env || finalNodeConfig.uiAttrs?.env.includes(ENV)) &&
                                (finalNodeConfig.uiAttrs?.embed === undefined || ((finalNodeConfig.uiAttrs?.embed && (finalNodeConfig.uiAttrs?.embed === isEmbedded || isEmbedded === undefined)) || (!finalNodeConfig.uiAttrs?.embed && isEmbedded !== true)))
                            ) {
                                let name = libraryNode?.subflowName;
                                const properties: Array<{ key: string, value: any }> = [];
                                if (finalNodeConfig.properties) {
                                    for (const property of finalNodeConfig.properties) {
                                        let defaultValue = property.default ? JSON.parse(JSON.stringify(property.default)) : property.default;
                                        if (finalNodeConfig.defaults) {
                                            for (const subTypeDefault of finalNodeConfig.defaults) {
                                                if (subTypeDefault.name === property.name && subTypeDefault.default !== null && subTypeDefault.default !== undefined) {
                                                    defaultValue = subTypeDefault.default ? JSON.parse(JSON.stringify(subTypeDefault.default)) : subTypeDefault.default;
                                                    break;
                                                }
                                            }
                                        }

                                        if (defaultValue !== null && defaultValue !== undefined) {
                                            properties.push({ key: property.name, value: defaultValue });
                                        }
                                    }
                                }
                                let connectError: string | boolean | undefined;
                                try {
                                    connectError = checkConnectionToSubflow(nodeInAction.data.type, nodeInAction.data.subType, nodeInAction.id, nodeInAction.data.properties, libraryNode?.type ?? "", "", "", properties, finalNodeConfig.wires, graphDef);
                                } catch {
                                    connectError = true;
                                }
                                if (!connectError) {
                                    const icon = finalNodeConfig.icon || "";
                                    const newPosition = calculateNewNodePosition({
                                        position: { x: nodeInAction.position.x + 200, y: nodeInAction.position.y },
                                        horizontal: isHorizontalLayout(),
                                        elements: elements,
                                    });
                                    const subflowMenuItem = <MenuItem text={name} key={name} 
                                        icon={<Icon icon={SDWAN_ICONS[icon] || APP_ICONS[icon] || icon} 
                                        className={icon === "AZURE_MONITOR" ? "ic-azure-monitor" : ""}/>} 
                                        onClick={(e) => {
                                            const edge: Edge = {
                                                id: "", source: nodeInAction.id, target: "newNodeId",
                                                sourceHandle: numOutputs > 1 ? String(output) : null, targetHandle: null
                                            };
                                            onCreateNewNode([{
                                                nodeDef: {
                                                    id: "newNodeId",
                                                    type: libraryNode!.type,
                                                    data: {
                                                        type: finalNodeConfig.type,
                                                        subType: "",
                                                        label: name,
                                                        info: "",
                                                        color: finalNodeConfig.color,
                                                        wires: finalNodeConfig.wires,
                                                        icon: icon,
                                                        integrationInfo: finalNodeConfig.integrationInfo,
                                                        properties: properties
                                                    }
                                                },
                                                position: newPosition
                                            }], [edge] as any, false, false, true);

                                            setSelectedElements([]);
                                        }}
                                    />;
                                    menuItems.push(subflowMenuItem);
                                }
                            }
                        }
                    }

                    if (menuItems && menuItems.length > 0) {
                        if (subflowsPreference?.showAddMenuIntegrationFolder && catName !== STRINGS.runbookEditor.nodeLibrary.categories.subflows) {
                            integrationsMenuItems.push(<MenuItem text={categoryDisplayName} key={libraryCategory.name} >{menuItems}</MenuItem>);
                        } else {
                            categoryMenuItems.push(<MenuItem text={categoryDisplayName} key={libraryCategory.name} >{menuItems}</MenuItem>);
                        }
                    }
                }
                if (integrationsMenuItems.length > 0) {
                    categoryMenuItems.push(<MenuItem text={"Integrations"} key={"integrations"} >{integrationsMenuItems}</MenuItem>);
                }
            }

            if (numOutputs > 1 && options.showOutputs) {
                const branchName = numOutputs === outputLabels.length ? outputLabels[output] : (STRINGS.runbookEditor.graphData.output + " " + String(output + 1));
                topLevelMenuItems.push(
                    <MenuItem text={branchName} key={branchName}>
                        {categoryMenuItems}
                    </MenuItem>
                );
            } else {
                topLevelMenuItems = categoryMenuItems;
            }
        }
    }
    return topLevelMenuItems;
}

/** returns true or false after comparing properties between two edges
 *  @param value1 property value for the first edge.
 *  @param value2 property value for the second edge
 *  @returns true or false after comparing properties between two edges. */
function compareEdgesPropertyValues(value1, value2) {
    if ((typeof value1 === 'string' || typeof value1 === 'number') && 
        (typeof value2 === 'string' || typeof value2 === 'number')) {
        // Use normal equality for strings and numbers
        // eslint-disable-next-line eqeqeq
        return value1 == value2;
    } else {
        // Use strict equality for other types (like null)
        return value1 === value2;
    }
}
