import { LangEN, STRINGS } from "app-strings";
import { Form, SelectField } from "components/common/form";
import { InstalledIntegration } from "pages/integrations/types/IntegrationTypes";
import React, { useEffect, useState } from "react";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import { ThirdPartyIntegrationService } from "utils/services/ThirdPartyIntegrationApiService";

interface Props {
    integrations: InstalledIntegration[];
    integrationId: string;
    requiresProfile: boolean;
    subflowId: string;
    connectorId: string;
    handleConnectorChange: (connectorId: string, event: React.ChangeEvent<HTMLSelectElement>) => any;
}

export default function ImportRunbookConnectorSelector({
    integrations,
    integrationId,
    requiresProfile,
    subflowId,
    connectorId,
    handleConnectorChange
}: Props) {
    const translations: LangEN["runbooks"]["importModal"] = STRINGS["runbooks"]["importModal"];
    const [loading, setLoading] =
        useState(true);
    const [options, setOptions] = useState<
        Array<{ label: string; value: string }>
    >([]);

    useEffect(() => {
        async function getConnectors() {
            setLoading(true);
            const matchingIntegration = integrations?.find((el) => el.integrationId === integrationId);
    
            if (!matchingIntegration?.integrationId) {
                return [];
            }

            const result = await IntegrationLibraryService.getConnectors(matchingIntegration.integrationId);

            const options = result.map((connector) => {
                return {
                    label: connector.name,
                    value: connector.connectorId,
                };
            });

            return options;
        }

        async function getProfiles() {
            setLoading(true);

            const result = await ThirdPartyIntegrationService.getRunbookAuthProfiles()
            const options = result.map((profile) => {
                return {
                    label: profile.name,
                    value: profile.id || profile.name,
                };
            });

            return options;
        }

        async function getOptions() {
            try {
                setLoading(true);
                const options = requiresProfile ? await getProfiles() : await getConnectors();
                
                setOptions(options);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        getOptions();
    }, [integrationId, integrations, integrations?.length, requiresProfile]);

    if (loading) {
        return <span>{translations.loading}</span>;
    }

    return (
        <Form
            loading={loading}
            initialValues={{ connector: connectorId }}
        >
            <SelectField
                data-testid="import-runbook_connector-select"
                name="connector"
                value={connectorId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    handleConnectorChange(subflowId, e)
                }}
            >
                <option key={translations.connectorSelector.noSelection} label={translations.connectorSelector.noSelection} value={undefined} />
                {options.map(connector => <option key={connector.label} value={connector.value}>{connector.label}</option>)}
            </SelectField>
        </Form>
    );
}
