/** This module contains constants and utilities that are used for runbook validation.
 *  @module
 */
import { STRINGS } from 'app-strings';
import { EdgeDef, GraphDef, InputType, NodeDef, NodeProperty, Variant } from 'components/common/graph/types/GraphTypes';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils';
import { 
    dataOceanNodes, 
    isTriggerNodeFromGraphDef, isDataOceanNodeFromGraphDef, isDataNodeFromGraphDef, isTagNodeFromGraphDef,
    isLogicalNodeFromGraphDef, isChartNodeFromGraphDef, isCommentNodeFromGraphDef, isPriorityNodeFromGraphDef,
    isValidTypeFromGraphDef, isCardChartNodeFromGraphDef, isTimeChartNodeFromGraphDef, isConnectionGraphNodeFromGraphDef,
    isDecisionNodeFromGraphDef, isAggregatorNodeFromGraphDef, getNodeFromGraphDef, getFirstParentOfTypeFromGraphDef, 
    getTriggerNodesFromGraphDef, getChartNodeFromGraphDef, getParentsFromGraphDef, getChildrenFromGraphDef,
    RunbookGraphNodeDef, generateGraphFromGraphDef, isGraphCyclicFromGraphDef, getProperty, RunbookGraphNode,
    getChartNode, isTriggerNode, getParents, isCommentNode, getChildren, isChartNode, isPriorityNode, isDataNode, 
    isDataOceanNode, getTriggerNodes, generateGraph, isGraphCyclic, isTimeChartNode, getFirstParentOfType,
    isLogicalNode, isDecisionNode, isTagNode, isConnectionGraphNode, isCardChartNode, isValidType, connectionGraphNodes, 
    isAggregatorNode, getFirstParentOfTypeInEachBranchFromGraphDef, getFirstParentOfTypeInEachBranch, 
    isTableNodeFromGraphDef, isMetricsEditorChartNodeFromGraphDef, isMetricEditorChartNodeFromGraphDef, 
    isHttpNodeFromGraphDef, isHttpNode, isVariablesNodeFromGraphDef, isVariablesNode, isTransformNode, 
    isTransformNodeFromGraphDef, isDebugNode, isDebugNodeFromGraphDef, transformNodes, isTableNode, isMetricsEditorChartNode,
    isMetricEditorChartNode, isSetSimpleVariablesNode, isSetComplexVariableNode, isSetSimpleVariablesNodeFromGraphDef,
    isSetComplexVariableNodeFromGraphDef, isSizeMetricEditorChartNodeFromGraphDef, isColorMetricEditorChartNodeFromGraphDef,
    isSizeMetricEditorChartNode, isColorMetricEditorChartNode, isXMetricEditorChartNode, isYMetricEditorChartNode, 
    isXMetricEditorChartNodeFromGraphDef, isYMetricEditorChartNodeFromGraphDef, isNoteNodeFromGraphDef, isSubflowNodeFromGraphDef, isSubflowNode, 
    isSubflowInputNodeFromGraphDef, isSubflowInputNode, isSubflowOutputNodeFromGraphDef, isSubflowOutputNode,
    isOnDemandInputNodeFromGraphDef,
    isAiNodeFromGraphDef
} from './RunbookUtils';
import { LogicNodeUtil } from 'components/common/graph/editors/logical/LogicNodeUtil';
import { RunbookConfig, RunbookNode } from 'utils/services/RunbookApiService';
import { AggregateNodeUtil } from 'components/common/graph/editors/aggregator/AggregatorNodeUtils';
import { isEqual } from 'lodash';
import { DecisionNodeUtil } from 'components/common/graph/editors/decision/DecisionNodeUtil';
import { TransformNodeUtils } from 'components/common/graph/editors/transform/TransformNodeUtils';
import { HttpNodeUtil } from 'components/common/graph/editors/http/HttpNodeUtil';
import { TriggerNodeUtils } from 'components/common/graph/editors/trigger/TriggerNodeUtils';
import { SetSimpleVariablesNodeUtils } from 'components/common/graph/editors/set-variables-simple/SetSimpleVariablesNodeUtils';
import { SetComplexVariableNodeUtils } from 'components/common/graph/editors/set-variable-complex/SetComplexVariableNodeUtils';
import { Context, RunbookContext, VariableContextByScope } from './RunbookContext.class';
import { NodeLibrary } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { DataOceanKey } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type';
import { IS_EMBEDDED } from 'components/enums/QueryParams';
import { CustomProperty } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { SubflowInputNodeUtils } from 'components/common/graph/editors/subflow-input/SubflowInputNodeUtils';
import { OnDemandInputNodeUtils } from 'components/common/graph/editors/on-demand-input/OnDemandInputNodeUtils';
import { SubflowNodeUtils } from 'components/common/graph/editors/subflow/SubflowNodeUtils';
import { AiNodeUtils } from 'components/common/graph/editors/ai/AiNodeUtils';

/** this interface defines the format of the object that is returned from a validation. */
export interface ValidationResult {
    /** the node id if the error is not for a node then this should not be included. */
    nodeId?: string;
    /** the name of the node, if the error occurred on a node. */
    nodeName?: string;
    /** the string with the error text. */
    text: string;
    /** aditional information. */
    additionalInfo?: AdditionalInfoOptions;
}

export interface ConnectionExclusions {
    [x: string]: ConnectionExclusion[];
}

export interface ConnectionExclusion {
    fromSubTypes?: string[];
    toTypes: string[];
    toSubTypes?: string[];
    function?: string;
    error?: ConnectionExclusionError;
}

export interface ConnectionExclusionError {
    nodeKey: string;
    errorKey: string;
}

export enum AdditionalInfoOptions {
    'INCIDENT_VARIABLE_ERROR',
    'OLD_SUBFLOW_WARNING'
}

/** validates the runbook nodes and returns any errors with the nodes.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param runbook the RunbookConfig object with the current contents of the runbook.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @param variables the map of variables by scope.
 *  @param customProperties the array of CustomProperty objects that has the custom properties for all the entity types.
 *  @param variant the runbook variant incident or lifecycle.
 *  @returns an object that contains the arrary of warnings and errors.*/
/* istanbul ignore next */
export function validateNodes(
    nodeLibrary: NodeLibrary, runbook: RunbookConfig, graphDef:GraphDef, variables: VariableContextByScope,
    customProperties: CustomProperty[], variant: Variant
): {warnings: Array<ValidationResult>, errors: Array<ValidationResult>} {
    const errors: Array<ValidationResult> = [];
    const warnings: Array<ValidationResult> = [];
    if (!runbook || !runbook.nodes || runbook.nodes.length === 0) {
        errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.noRunbookContent, {variant: STRINGS.runbookEditor.runbookTextForVariant[Variant.LIFECYCLE]})});
    }

    if (runbook && runbook.nodes && runbook.nodes.length > 500) {
        errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.runbookNodeCountExceeded, 500, STRINGS.runbookEditor.runbookTextForVariant[Variant.INCIDENT]) as string});
    }

    // The query node is clear with the new riverbed azure function back-end but it wasn't as clear with node red
    // so only check this for the new back-end for now.
    let allTerminatingDataNodesAndLogicalNodesHaveCharts: boolean = true;

    const triggerNodes = getTriggerNodes(runbook.nodes);

    let triggerType: InputType | undefined;

    //There should be one and only one trigger node
    if (triggerNodes?.length) {
        if (triggerNodes.length > 1) {
            errors.push({text: STRINGS.formatString(
                STRINGS.runbookEditor.errors.triggerNode.tooManyTriggerNodesError, 
                {triggerName: STRINGS.runbookEditor.triggerNameForVariant[variant]}
            )});
        } else {
            triggerType = triggerNodes[0].properties.triggerType as InputType;
        }
    } else {
        errors.push({text: STRINGS.formatString(
            STRINGS.runbookEditor.errors.triggerNode.noTriggerNodeError, 
            {triggerName: STRINGS.runbookEditor.triggerNameForVariant[variant]}
        )});
    }
    let dataOceanNodeCount = 0;
    let dataTransformAndSubflowNodeCount = 0;
    let impactAssessmentCount = 0;
    let chartNodeCount = 0;

    if (runbook && runbook.nodes) {
        let roots: Array<RunbookGraphNode> = generateGraph(runbook.nodes);
        let isCyclic = isGraphCyclic(roots);
        if (isCyclic) {
            // We don't allow cyclic graphs
            errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.noCyclicGraphs, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[variant]})});
        }

        for (const node of runbook.nodes) {
            const parents = getParents(node, runbook.nodes);
            const name = node.label || "";

            if (!isValidType(node)) {
                // We don't know what this node is, so let's flag it as an error and do no additional checking
                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeHasInvalidType});
                continue;
            }

            if (isTriggerNode(node) || isCommentNode(node)) {
                if (parents && parents.length > 0) {
                    // Trigger and comment nodes cannot have any parents
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeHasParent});
                }
            } else if (isSubflowOutputNode(node)) {
                // Subflow output should not have more than one parent, except when linked to a decision branch                
                if (parents.length > 1 && !parents.every(el => isDecisionNode(el))) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowOutputNode.subflowOutputNodeOneParent});
                }
            } else {
                if (!parents || parents.length === 0) {
                    // All other nodes must have a parent, except the comment node
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeDoesNotHaveParent});
                }
            }

            const children = getChildren(node, runbook.nodes);
            if (
                isCommentNode(node) || isChartNode(node) || isPriorityNode(node) || 
                isTagNode(node) || isSubflowOutputNode(node)
            ) {
                if (children && children.length > 0) {
                    // A comment, chart node, priority, or variables node cannot have children
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeShouldNotHaveChildren});
                }
            } else if (!isVariablesNode(node) && !isHttpNode(node) && !isSubflowNode(node)) {
                if (!children || children.length === 0) {
                    // All other nodes must have children
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeShouldHaveChildren});
                }
            }

            if (isTriggerNode(node)) {
                const triggerErrors: Array<string> = [];
                try {
                    TriggerNodeUtils.validateNode(node.id, triggerErrors, graphDef, variables);
                    for (const triggerError of triggerErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: triggerError});
                    }
                } catch (triggerErrors) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.triggerNode.unexpectedError});
                    console.error("Unexpected error in TriggerNodeUtil");
                }
            }

            if (isDataNode(node)) {
                // Validate the data ocean node and logical node
                if (isDataOceanNode(node)) {
                    // Counter to check if data ocean count is between 1-100(inclusive)
                    dataOceanNodeCount++;
                    dataTransformAndSubflowNodeCount++;
                    // A data ocean can have one and only one parent
                    const doErrors: Array<string> = [];
                    try {
                        DataOceanUtils.validateNode(
                            nodeLibrary, node, parents, runbook.nodes, 
                            triggerNodes && triggerNodes.length === 1 ? triggerNodes[0] : null, 
                            variables, doErrors
                        );
                        for (const doError of doErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: doError});
                        }
                    } catch (doError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.doNode.unexpectedError});
                        console.error("Unexpected error in DataOceanUils");
                    }
                }
                
                if (isLogicalNode(node)) {
                    const logicErrors: Array<string> = [];
                    try {
                        LogicNodeUtil.validateNode(node.id, logicErrors, graphDef, variables);
                        for (const logicError of logicErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: logicError});
                        }    
                    } catch (logicError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.logicNode.unexpectedError});
                        console.error("Unexpected error in LogicNodeUtil");
                    }
                }

                if (isDecisionNode(node)) {
                    const decisionErrors: Array<string> = [];
                    try {
                        DecisionNodeUtil.validateNode(node.id, decisionErrors, graphDef, variables, customProperties);
                        for (const decisionError of decisionErrors) {
                            if (decisionError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: decisionError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: decisionError});
                            }
                        }    
                    } catch (decisionError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.decisionNode.unexpectedError});
                        console.error("Unexpected error in DecisionNodeUtil");
                    }
                }

                if (isAggregatorNode(node)) {
                    const logicErrors: Array<string> = [];
                    try {
                        AggregateNodeUtil.validateNode(node.id, logicErrors, graphDef, variables);
                        for (const logicError of logicErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: logicError});
                        }
                    } catch (logicError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.aggregateNode.unexpectedError});
                        console.error("Unexpected error in AggregatorNodeUtil");
                    }
                }

                const chartNode = getChartNode(node, runbook.nodes);
                let hasDataChild: boolean = false;
                if (children && children.length > 0) {
                    for (const childNode of children) {
                        if (isDataNode(childNode)) {
                            hasDataChild = true;
                            break;
                        }
                    }
                }

                if (!hasDataChild) {
                    // If a data node (data ocean or logical node) does not have another data node attached to it, it needs to have
                    // a chart attached to it otherwise it's data will not be visible.
                    allTerminatingDataNodesAndLogicalNodesHaveCharts = allTerminatingDataNodesAndLogicalNodesHaveCharts && chartNode !== null && chartNode !== undefined;
                }
            }

            if (isPriorityNode(node)) {
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Priority nodes can only have one parent and that has to be a data node or trigger
                    if (parents.length !== 1) {
                        // We might want to relax this in the future, we could tag all the branches with the 
                        // same priority using one node
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.priorityNode.priorityNodeOneParent});
                    } else if (
                        !isHttpNode(parents[0]) && !isTriggerNode(parents[0]) && !isDataNode(parents[0]) && !isSubflowNode(parents[0]) && 
                        !isVariablesNode(parents[0])
                    ) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.priorityNode.priorityNodeNonDataOrTriggerParent});
                    }
                }
            }

            if (isHttpNode(node)) {
                const httpErrors: Array<string> = [];
                try {
                    HttpNodeUtil.validateNode(node.id, httpErrors, graphDef, variables);
                    for (const httpError of httpErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: httpError});
                    } 
                } catch (httpErrors) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.httpNode.unexpectedError});
                    console.error("Unexpected error in HttpNodeUtil");
                }
            }

            if (isTransformNode(node)) {
                dataTransformAndSubflowNodeCount++;
                // Check if all the subkeys in the node match the subkeys from DataOcean Metadata
                // Since this is not used I just ommented this out, but this should be included if we ever switch back to this function
                //const su = TransformNodeUtils.allSubkeysInMetadata(node);
                //if (subkeysMissingWarning) {
                    //    warnings.push({nodeId: node.id, nodeName: name, text: subkeysMissingWarning});
                //}
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Transform nodes can only have one parent and that has to be an http or decision branch node
                    if (parents.length !== 1) {
                        // We might want to relax this in the future
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.transformNodeOneParent});
                    } else if (
                        !isHttpNode(parents[0]) && !isDecisionNode(parents[0]) && 
                        !isTriggerNode(parents[0]) && !isVariablesNode(parents[0]) && 
                        !isDataOceanNode(parents[0]) && !isSubflowNode(parents[0])
                    ) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.transformNodeNonHttpOrDecisionOrTriggerParent});
                    }
                }
                const transformErrors: Array<string> = [];
                try {
                    TransformNodeUtils.validateNode(node.id, transformErrors, graphDef, variables, customProperties);
                    for (const transformError of transformErrors) {
                        if (transformError.includes("incident variable")) {
                            errors.push({nodeId: node.id, nodeName: name, text: transformError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                        } else {
                            errors.push({nodeId: node.id, nodeName: name, text: transformError});
                        }
                    }
                } catch (transformError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.unexpectedError});
                    console.error("Unexpected error in TransformNodeUtil");
                }
            }

            if (isSubflowNode(node)) {
                // We might want to check if the subflow node actually produces data
                dataTransformAndSubflowNodeCount++;
                const subflowErrors: Array<string> = [], subflowWarnings: Array<string> = [];
                try {
                    SubflowNodeUtils.validateNode(node.id, subflowWarnings, subflowErrors, graphDef, [], variables);
                    for (const subflowWarning of subflowWarnings) {
                        warnings.push({nodeId: node.id, nodeName: name, text: subflowWarning});
                    }
                    for (const subflowError of subflowErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: subflowError});
                    }
                } catch (subflowError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowNode.unexpectedError});
                    console.error("Unexpected error in SubflowNodeUtil");
                }
            }

            if (isSubflowInputNode(node)) {
                const subflowInputErrors: Array<string> = [];
                try {
                    SubflowInputNodeUtils.validateNode(node.id, subflowInputErrors, graphDef, variables);
                    for (const subflowError of subflowInputErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: subflowError});
                    } 
                } catch (subflowInputNodeError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowInputNode.unexpectedError});
                    console.error("Unexpected error in SubflowInputNodeUtils");
                }
            }

            if (isVariablesNode(node)) {
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Variable nodes can only have one parent and that has to be a data node or trigger
                    if (parents.length !== 1) {
                        // We might want to relax this in the future
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.variablesNodeOneParent});
                    } else if (isSetSimpleVariablesNode(node)) {
                        if (
                            !isTriggerNode(parents[0]) && !isDataOceanNode(parents[0]) && !isTransformNode(parents[0]) && 
                            !isDecisionNode(parents[0]) && !isHttpNode(parents[0]) && !isVariablesNode(parents[0]) && 
                            !isLogicalNode(parents[0]) && !isSubflowNode(parents[0]) && !isAggregatorNode(parents[0])
                        ) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.simpleVarsNodeSupportedParents});
                        }
                    } else if (isSetComplexVariableNode(node)) {
                        if (
                            !isDataOceanNode(parents[0]) && !isTransformNode(parents[0]) && 
                            !isDecisionNode(parents[0]) && !isVariablesNode(parents[0]) && 
                            !isLogicalNode(parents[0]) && !isSubflowNode(parents[0])
                        ) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.complexVarsNodeSupportedParents});
                        }
                    }
                }
                const variablesErrors: Array<string> = [];
                if (isSetSimpleVariablesNode(node)) {
                    try {
                        SetSimpleVariablesNodeUtils.validateNode(node.id, variablesErrors, graphDef, variables);
                        for (const variableError of variablesErrors) {
                            if (variableError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError});
                            }
                        }
                    } catch (variableError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.setSimpleVarsUnexpectedError});
                        console.error("Unexpected error in SetSimpleVariableNodeUtil");
                    }
                } else if (isSetComplexVariableNode(node)) {
                    try {
                        SetComplexVariableNodeUtils.validateNode(node.id, variablesErrors, graphDef, variables);
                        for (const variableError of variablesErrors) {
                            if (variableError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError});
                            }
                        }
                    } catch (variableError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.setComplexVarsUnexpectedError});
                        console.error("Unexpected error in SetComplexVariableNodeUtil");
                    }
                }

            }

            if (isChartNode(node)) {
                chartNodeCount++;

                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Charts can have only one parent and that has to be a data node
                    if (!isCardChartNode(node) && parents.length !== 1) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.chartNodeOneParent});
                    } else if (isCardChartNode(node) && ![1, 2].includes(parents.length)) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeOneOrTwoParents});
                    } else if (
                        !isDataNode(parents[0]) && !isTransformNode(parents[0]) && !isVariablesNode(parents[0]) &&
                        !isSubflowNode(parents[0])
                    ) {
                        if (isTableNode(node) && !isTriggerNode(parents[0])) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.tableNodeNonDataOrHttpParent});
                        } else if (!isTableNode(node)) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.chartNodeNonDataOrHttpParent});
                        }
                    }
                }

                const doOrTransformNode = getFirstParentOfType(node, runbook.nodes, [...dataOceanNodes, ...transformNodes]);
                if (!isCardChartNode(node) && !isDebugNode(node)) {
                    // Check to make sure time charts are hooked to time series data ocean queries and all other
                    // charts are hooked up to average queries
                    const isTimeChart = isTimeChartNode(node);
                    if (doOrTransformNode && doOrTransformNode.properties) {
                        const isTimeseries = Boolean(doOrTransformNode.properties.timeSeries) || Boolean(doOrTransformNode.properties.outputDataFormat === "timeseries");
                        if (isTimeChart !== isTimeseries) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode["chartNode" + (isTimeChart ? "TimeSeries" : "Summary") + "DataParent"]})
                        }
                    }
                }

                // Make sure cards are hooked up properly
                if (isCardChartNode(node)) {
                    if (doOrTransformNode && parents.length === 1) {
                        // We have a card with one parent make sure the query has metrics, we don't want completely 
                        // empty cards.
                        const metrics = doOrTransformNode.properties.metrics || doOrTransformNode.properties.synthMetrics;
                        if (!metrics?.length) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMissing});
                        }
                    } else if (parents.length === 2) {
                        // We have a card with two parents, make sure one is a summary query and one is a time query
                        const doNodes = getFirstParentOfTypeInEachBranch(node, runbook.nodes, dataOceanNodes);
                        if (doNodes?.length === 2) {
                            if (doNodes[0].properties.objType !== doNodes[1].properties.objType) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeQueryMismatch});
                            }
                            if (doNodes[0].properties.timeSeries === doNodes[1].properties.timeSeries) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeTimeSeriesMismatch});
                            }
                            const metrics1 = doNodes[0].properties.metrics;
                            const metrics2 = doNodes[1].properties.metrics;
                            if (!metrics1?.length || !metrics2?.length) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMissing});
                            } else {
                                if (metrics1?.length !== metrics2?.length || !isEqual(metrics1, metrics2)) {
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMismatch});
                                }    
                            }
                        }
                    }
                }

                // Check to make sure connection graphs are hooked to host pair data ocean queries
                const isConnectionGraph = isConnectionGraphNode(node);
                if (isConnectionGraph && doOrTransformNode && doOrTransformNode.properties) {
                    if (doOrTransformNode.properties.objType !== "network_client_server.traffic") {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.connectionGraphClientServerDataParent})
                    }
                    if (doOrTransformNode.properties.timeSeries) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.connectionGraphTimeSeriesDataParent})
                    }
                }

                const isTable = isTableNode(node);
                if (isTable) {
                    const columns = node.properties.columns;
                    if (!columns?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tableNode.noColumn})
                    }
/* This doesn't work properly when setDefaultNodePropertiesOnConnect.  That function 
is called before the state is fully updated so the sort column defaults do not get 
are not yet visible to the validation utilities and thus even though the sort 
column is correct it is shown as an error
                    const sortColumn = node.properties.sortColumn;
                    const sortOrder = node.properties.sortOrder;
                    if (!sortColumn || !sortOrder) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tableNode.noSortColumn})
                    }
*/
                }

                const isMetricsEditor = isMetricsEditorChartNode(node);
                if (isMetricsEditor) {
                    const metrics = node.properties.metrics;
                    if (!metrics?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noMetrics})
                    }
                }

                const isMetricEditor = isMetricEditorChartNode(node);
                if (isMetricEditor) {
                    const metric = node.properties.metric;
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noMetric})
                    }
                }

                const isSizeMetricEditor = isSizeMetricEditorChartNode(node);
                if (isSizeMetricEditor) {
                    const metric = node.properties.sizeMetric;
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noSizeMetric})
                    }
                }

                const isColorMetricEditor = isColorMetricEditorChartNode(node);
                if (isColorMetricEditor) {
                    const metric = node.properties.colorMetric;
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noColorMetric})
                    }
                }

                const isXMetricEditor = isXMetricEditorChartNode(node);
                if (isXMetricEditor) {
                    const metric = node.properties.xMetric;
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noXAxisMetric})
                    }
                }

                const isYMetricEditor = isYMetricEditorChartNode(node);
                if (isYMetricEditor) {
                    const metric = node.properties.yMetric;
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noYAxisMetric})
                    }
                }
            }

            if (isTagNode(node)) {
                // Tags can have only one parent and that has to be a data node.  We might want to allow
                // it to tag more than one node.
                impactAssessmentCount++;
                // Only check the parents, if there is at least one parent, if there are no parents, we caught that error above, 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    if (parents.length !== 1) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeOneParent});
                    } else if (isTriggerNode(parents[0])) {
                        if (parents[0].properties && node.properties) {
                            if (["network_interface", "network_device", "location"].includes(parents[0].properties.triggerType) && node.properties.impactType !== "location") {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeInterfaceOrDeviceOrLocationTriggerWithWrongTag});
                            } else if (["application"].includes(parents[0].properties.triggerType) && !["location", "application"].includes(node.properties.impactType)) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeApplicationTriggerWithWrongTag});
                            } else if (["webhook"].includes(parents[0].properties.triggerType)) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeWebhookTrigger});
                            }
                        }
                    } else if (!isDataNode(parents[0])) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeNonDataParent});
                    } else {
                        // The impact assessment node is connected to a data node, make sure it supports the type of tag
                        const doNodeOrXformNode = getFirstParentOfType(node, runbook.nodes, [...dataOceanNodes, ...transformNodes]);
                        if (doNodeOrXformNode && doNodeOrXformNode.properties) {
                            if (isDataOceanNode(doNodeOrXformNode)) {
                                // The impact is connected to a data ocean node.
                                if (node.properties.impactType === "location" && !DataOceanUtils.hasAnyKey(doNodeOrXformNode.properties.objType, ["location", "client_location", "server_location"])) {
                                    // Location tags must be connected to a DO node with the location key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongLocationTag})
                                } else if (node.properties.impactType === "application" && !DataOceanUtils.hasAnyKey(doNodeOrXformNode.properties.objType, ["application"])) {
                                    // Application tags must be connected to a DO node with the application key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongApplicationTag})
                                } else if (node.properties.impactType === "user" && !DataOceanUtils.hasAnyKey(doNodeOrXformNode.properties.objType, ["network_client", "user_device"])) {
                                    // User tags must be connected to a DO node with the network_client key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongUserTag})
                                }    
                            } else {
                                // The impact node is connected to a transform node
                                if (node.properties.impactType === "location" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, doNodeOrXformNode.properties.synthKeys, ["location", "client_location", "server_location"])) {
                                    // Location tags must be connected to a DO node with the location key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongLocationTag})
                                } else if (node.properties.impactType === "application" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, doNodeOrXformNode.properties.synthKeys, ["application"])) {
                                    // Application tags must be connected to a DO node with the application key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongApplicationTag})
                                } else if (node.properties.impactType === "user" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, doNodeOrXformNode.properties.synthKeys, ["network_client"])) {
                                    // User tags must be connected to a DO node with the network_client key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongUserTag})
                                }
                            }
                        }    
                    }
                }
            }
        }
    }

    if (!IS_EMBEDDED && triggerType !== InputType.WEBHOOK && variant !== Variant.SUBFLOW && dataTransformAndSubflowNodeCount < 1) {
        errors.push({text: STRINGS.runbookEditor.errors.doNode.noDataOrTransformNode});
    }

    if (dataOceanNodeCount > 100) {
        errors.push({text: STRINGS.runbookEditor.errors.doNode.dataOceanNodeCountExceeded});
    }

    if (triggerNodes?.length === 1 && variant !== Variant.SUBFLOW) {
        if (triggerNodes[0].properties.triggerType !== InputType.WEBHOOK && chartNodeCount < 1) {
            errors.push({text: STRINGS.runbookEditor.errors.chartNode.noChartNode});
        }
    }

    // This is no longer an error
    //if (!allTerminatingDataNodesAndLogicalNodesHaveCharts) {
    //    errors.push({text: STRINGS.runbookEditor.errors.chartNode.noChartNode});
    //}

    if (
        !IS_EMBEDDED && ![Variant.SUBFLOW, Variant.ON_DEMAND].includes(variant) && triggerType !== InputType.WEBHOOK && 
        impactAssessmentCount === 0
    ) {
        warnings.push({text: STRINGS.runbookEditor.warnings.noImpactAssessment});
    }

    return {warnings, errors};
}

/** validates the runbook nodes and returns any errors with the nodes.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @param variables the map of variables by scope.
 *  @param customProperties the array of CustomProperty objects with the custom properties for 
 *       all the entity types. 
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param variant the runbook variant incident or lifecycle.
 *  @returns an object that contains the arrary of warnings and errors.*/
export function validateNodesFromGraphDef(
    nodeLibrary: NodeLibrary, graphDef: GraphDef, variables: VariableContextByScope,
    customProperties: CustomProperty[], subflows: Array<RunbookNode> = [], variant: Variant
): {warnings: Array<ValidationResult>, errors: Array<ValidationResult>} {
    const errors: Array<ValidationResult> = [];
    const warnings: Array<ValidationResult> = [];
    
    // Do some basic checks
    if (!graphDef || !graphDef.nodes || graphDef.nodes.length === 0) {
        errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.noRunbookContent, {variant: STRINGS.runbookEditor.runbookTextForVariant[variant]})});
    }

    if (graphDef && graphDef.nodes && graphDef.nodes.length > 500) {
        errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.runbookNodeCountExceeded, 500, STRINGS.runbookEditor.runbookTextForVariant[variant]) as string});
    }

    switch (variant) {
        case Variant.INCIDENT:
        case Variant.EXTERNAL:
            validateIncidentNodesFromGraphDef(nodeLibrary, graphDef, variables, customProperties, subflows, variant, warnings, errors);
            break;
        case Variant.LIFECYCLE:
            validateLifecycleNodesFromGraphDef(nodeLibrary, graphDef, variables, customProperties, subflows, warnings, errors);
            break;
        default:
            validateIncidentNodesFromGraphDef(nodeLibrary, graphDef, variables, customProperties, subflows, variant, warnings, errors);
    }

    return {warnings, errors};
}

/** return an array of children nodes.
 *  @param node the node for which to get the children.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @returns an array of NodeDef objects which are the children nodes. */
export function getGraphDefNodeChildren(node: NodeDef | undefined, graphDef: GraphDef): Array<NodeDef> {
    let children: Array<NodeDef> = [];
    if (node) {
        for (const otherNode of graphDef.nodes) {
            if (otherNode.id !== node.id) {
                const parents = getParentsFromGraphDef(otherNode, graphDef);
                if (parents?.length) {
                    for (const parentNode of parents) {
                        if (parentNode.id === node.id) {
                            children.push(otherNode);
                        }
                    }
                }
            }
        }
    }
    return children;
}

/** counts the number of reachable subflow output nodes.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @returns the number of reachable subflow output nodes. */
export function countReachableSubflowOutputs(graphDef: GraphDef): number {
    const nodes = graphDef.nodes;
    const visitedNodes: Array<NodeDef> = [];
    const reachedSubflowOutputs: Array<NodeDef> = [];

    function dfs(nodeId) {
        if (visitedNodes.includes(nodeId)) {
            return 0;
        }

        visitedNodes.push(nodeId);

        const currentNode: NodeDef | undefined = nodes.find(node => node.id === nodeId);

        if (currentNode?.type === "subflow_output") {
            reachedSubflowOutputs.push(currentNode);
            return 1;
        }

        let reachableSubflowOutputs = 0;

        let branchTaken = false;

        const children: Array<NodeDef> = getGraphDefNodeChildren(currentNode, graphDef);

        if (children.length) {
            for (const childNode of children) {
                if (currentNode?.type === "decision" && branchTaken) {
                    continue;
                }

                if (currentNode?.type === "decision") {
                    branchTaken = true;
                }

                reachableSubflowOutputs += dfs(childNode.id);
            }
        }

        return reachableSubflowOutputs;
    }

    let totalReachableSubflowOutputs = 0;

    const subflowInputNode = nodes.find(node => node.type === "subflow_input");
    if (subflowInputNode) {
        totalReachableSubflowOutputs = dfs(subflowInputNode.id);
    }

    /* To cover the case when the multiple subflow outputs flag is on, the following checks if the reachable subflow nodes have different indexes. */
    const subflowOutputIndexes: Array<number> = [];

    for (const node of reachedSubflowOutputs) {
        const index = node.properties?.find(property => property.key === "index");
        if (typeof index?.value !== "undefined" && !subflowOutputIndexes.includes(index.value)) {
            subflowOutputIndexes.push(index.value);
        }
    }
    
    if (reachedSubflowOutputs.length > 1 && subflowOutputIndexes.length === reachedSubflowOutputs.length) {
        totalReachableSubflowOutputs = 1;
    }

    return totalReachableSubflowOutputs;
}

/** checks if subflow output nodes are connected to different types.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @returns a boolean which is true if subflow output nodes are connected to different types or false otherwise. */
function checkIfSubflowOutputsAreOnDifferentTypes(graphDef: GraphDef) {
    const contexts: Array<object> = [];
    for (const node of graphDef.nodes) {
        if (node.type === "subflow_output") {
            const context = new RunbookContext(
                node, graphDef, DataOceanUtils.dataOceanMetaData
            );
            if (context?.nodeContexts) {
                const nodeContextsReversed = context.nodeContexts.toReversed();
                loopNodeContextsReversed: for (const nodeContext of nodeContextsReversed) {
                    const nodeId = nodeContext.source.id;
                    for (const graphDefNode of graphDef.nodes) {
                        if (graphDefNode.id === nodeId && (graphDefNode.type === "data_ocean" || graphDefNode.type === "http")) {
                            contexts.push({
                                keys: nodeContext.keys,
                                metrics: nodeContext.metrics,
                                isTimeseries: nodeContext.isTimeseries
                            });
                            break loopNodeContextsReversed;
                        }
                    }
                }
            }
        }
    }
    if (contexts.length > 1) {
        const allContextsEqual = contexts => contexts.every(context => isEqual(context, contexts[0]));
        if (!allContextsEqual(contexts) && countReachableSubflowOutputs(graphDef) > 1) {
            return true;
        }
    }
    return false;
}

/** validates the runbook nodes and returns any errors with the nodes.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @param variables the map of variables by scope.
 *  @param customProperties the array of CustomProperty objects with the custom properties for 
 *       all the entity types. 
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param variant the runbook variant incident or lifecycle.
 *  @param warnings the array of ValidationResults with the warnings.
 *  @param errors the array of ValidationResults with the errors.
 *  @returns an object that contains the array of warnings and errors.*/
export function validateIncidentNodesFromGraphDef(
    nodeLibrary: NodeLibrary, graphDef: GraphDef, variables: VariableContextByScope,
    customProperties: CustomProperty[], subflows: Array<RunbookNode> = [], variant: Variant, 
    warnings: ValidationResult[], errors: ValidationResult[]
): void {
    // The query node is clear with the new riverbed azure function back-end but it wasn't as clear with node red
    // so only check this for the new back-end for now.
    let allTerminatingDataNodesAndLogicalNodesHaveCharts: boolean = true;

    const triggerNodes = getTriggerNodesFromGraphDef(graphDef);

    let triggerType: InputType | undefined;

    //There should be one and only one trigger node
    if (triggerNodes?.length) {
        if (triggerNodes.length > 1) {
            errors.push({text: STRINGS.formatString(
                STRINGS.runbookEditor.errors.triggerNode.tooManyTriggerNodesError, 
                {triggerName: STRINGS.runbookEditor.triggerNameForVariant[variant]}
            )});
        } else {
            triggerType = getProperty(triggerNodes[0], "triggerType") as InputType;
        }
    } else {
        errors.push({text: STRINGS.formatString(
            STRINGS.runbookEditor.errors.triggerNode.noTriggerNodeError, 
            {triggerName: STRINGS.runbookEditor.triggerNameForVariant[variant]}
        )});
    }
    let dataOceanNodeCount = 0;
    let dataTransformAndSubflowNodeCount = 0;
    let impactAssessmentCount = 0;
    let chartNodeCount = 0;

    if (graphDef && graphDef.nodes) {
        let roots: Array<RunbookGraphNodeDef> = generateGraphFromGraphDef(graphDef);
        let isCyclic = isGraphCyclicFromGraphDef(roots);
        if (isCyclic) {
            // We don't allow cyclic graphs
            errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.noCyclicGraphs, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[variant]})});
        }

        for (const node of graphDef.nodes) {
            const parents = getParentsFromGraphDef(node, graphDef);
            const name = node.name || "";

            if (!isValidTypeFromGraphDef(node)) {
                // We don't know what this node is, so let's flag it as an error and do no additional checking
                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeHasInvalidType});
                continue;
            }

            if (isTriggerNodeFromGraphDef(node) || isCommentNodeFromGraphDef(node)) {
                if (parents && parents.length > 0) {
                    // Trigger and comment nodes cannot have any parents
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeHasParent});
                }
            } else if (isSubflowOutputNodeFromGraphDef(node)) {
                // Add error that you cannot connect a subflow output to more than one branch except for decision branch
                if (countReachableSubflowOutputs(graphDef) > 1) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowOutputNode.subflowOutputNodeOneBranch});
                }
                // Add error that you cannot a subflow to more than one type
                if (checkIfSubflowOutputsAreOnDifferentTypes(graphDef)) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowOutputNode.subflowOutputNodeTwoTypes});
                }
                // Subflow output should not have more than one parent, except when linked to a decision branch                
                if (parents.length > 1 && !parents.every(el => isDecisionNodeFromGraphDef(el))) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowOutputNode.subflowOutputNodeOneParent});
                }
            } else {
                if (!parents || parents.length === 0) {
                    // All other nodes must have a parent, except the comment node
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeDoesNotHaveParent});
                }
            }

            const children = getChildrenFromGraphDef(node, graphDef);
            if (
                isCommentNodeFromGraphDef(node) || isChartNodeFromGraphDef(node) || isPriorityNodeFromGraphDef(node) || 
                isTagNodeFromGraphDef(node) || isSubflowOutputNodeFromGraphDef(node)
            ) {
                if (children && children.length > 0) {
                    // A comment, chart node, priority, or variables node cannot have children
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeShouldNotHaveChildren});
                }
            } else if (!isVariablesNodeFromGraphDef(node) && !isHttpNodeFromGraphDef(node) && !isSubflowNodeFromGraphDef(node)) {
                if (!children || children.length === 0) {
                    // All other nodes must have children
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeShouldHaveChildren});
                }
            }

            if (isTriggerNodeFromGraphDef(node)) {
                const triggerErrors: Array<string> = [];
                try {
                    TriggerNodeUtils.validateNode(node.id, triggerErrors, graphDef, variables);
                    for (const triggerError of triggerErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: triggerError});
                    }
                } catch (triggerErrors) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.triggerNode.unexpectedError});
                    console.error("Unexpected error in TriggerNodeUtil");
                }
            }

            if (isDataNodeFromGraphDef(node)) {
                // Validate the data ocean node and logical node
                if (isDataOceanNodeFromGraphDef(node)) {
                    // Counter to check if data ocean count is between 1-100(inclusive)
                    dataOceanNodeCount++;
                    dataTransformAndSubflowNodeCount++;
                    // A data ocean can have one and only one parent
                    const doErrors: Array<string> = [];
                    try {
                        DataOceanUtils.validateNodeFromGraphDef(
                            nodeLibrary, node, parents, graphDef, triggerNodes && triggerNodes.length === 1 ? triggerNodes[0] : null, 
                            variables, doErrors
                        );
                        for (const doError of doErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: doError});
                        }
                    } catch (doError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.doNode.unexpectedError});
                        console.error("Unexpected error in DataOceanUils");
                    }
                }
                
                if (isLogicalNodeFromGraphDef(node)) {
                    const logicErrors: Array<string> = [];
                    try {
                        LogicNodeUtil.validateNode(node.id, logicErrors, graphDef, variables);
                        for (const logicError of logicErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: logicError});
                        }    
                    } catch (logicError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.logicNode.unexpectedError});
                        console.error("Unexpected error in LogicNodeUtil");
                    }
                }

                if (isDecisionNodeFromGraphDef(node)) {
                    const decisionErrors: Array<string> = [];
                    try {
                        DecisionNodeUtil.validateNode(node.id, decisionErrors, graphDef, variables, customProperties);
                        for (const decisionError of decisionErrors) {
                            if (decisionError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: decisionError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: decisionError});
                            }
                        }    
                    } catch (decisionError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.decisionNode.unexpectedError});
                        console.error("Unexpected error in DecisionNodeUtil");
                    }
                }

                if (isAggregatorNodeFromGraphDef(node)) {
                    const logicErrors: Array<string> = [];
                    try {
                        AggregateNodeUtil.validateNode(node.id, logicErrors, graphDef, variables);
                        for (const logicError of logicErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: logicError});
                        }
                    } catch (logicError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.aggregateNode.unexpectedError});
                        console.error("Unexpected error in AggregatorNodeUtil");
                    }
                }

                const chartNode = getChartNodeFromGraphDef(node, graphDef);
                let hasDataChild: boolean = false;
                if (children && children.length > 0) {
                    for (const childNode of children) {
                        if (isDataNodeFromGraphDef(childNode)) {
                            hasDataChild = true;
                            break;
                        }
                    }
                }

                if (!hasDataChild) {
                    // If a data node (data ocean or logical node) does not have another data node attached to it, it needs to have
                    // a chart attached to it otherwise it's data will not be visible.
                    allTerminatingDataNodesAndLogicalNodesHaveCharts = allTerminatingDataNodesAndLogicalNodesHaveCharts && chartNode !== null && chartNode !== undefined;
                }
            }

            if (isPriorityNodeFromGraphDef(node)) {
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Priority nodes can only have one parent and that has to be a data node or trigger
                    if (parents.length !== 1) {
                        // We might want to relax this in the future, we could tag all the branches with the 
                        // same priority using one node
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.priorityNode.priorityNodeOneParent});
                    } else if (
                        !isHttpNodeFromGraphDef(parents[0]) && !isTriggerNodeFromGraphDef(parents[0]) && !isDataNodeFromGraphDef(parents[0]) && 
                        !isSubflowNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef((parents[0]))
                    ) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.priorityNode.priorityNodeNonDataOrTriggerParent});
                    }
                }
            }

            if (isHttpNodeFromGraphDef(node)) {
                const httpErrors: Array<string> = [];
                try {
                    HttpNodeUtil.validateNode(node.id, httpErrors, graphDef, variables);
                    for (const httpError of httpErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: httpError});
                    } 
                } catch (httpErrors) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.httpNode.unexpectedError});
                    console.error("Unexpected error in HttpNodeUtil");
                }
            }

            if (isTransformNodeFromGraphDef(node)) {
                dataTransformAndSubflowNodeCount++;
                // Check if all the subkeys in the node match the subkeys from DataOcean Metadata
                const subkeysMissingWarning = TransformNodeUtils.allSubkeysInMetadata(node);
                if (subkeysMissingWarning) {
                    warnings.push({nodeId: node.id, nodeName: name, text: subkeysMissingWarning});
                }
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Transform nodes can only have one parent and that has to be an http or decision branch node
                    if (parents.length !== 1) {
                        // We might want to relax this in the future
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.transformNodeOneParent});
                    } else if (
                        !isHttpNodeFromGraphDef(parents[0]) && !isDecisionNodeFromGraphDef(parents[0]) && 
                        !isTriggerNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) && 
                        !isDataOceanNodeFromGraphDef(parents[0]) && !isSubflowNodeFromGraphDef(parents[0])
                    ) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.transformNodeNonHttpOrDecisionOrTriggerParent});
                    }
                }
                const transformErrors: Array<string> = [];
                try {
                    TransformNodeUtils.validateNode(node.id, transformErrors, graphDef, variables, customProperties);
                    for (const transformError of transformErrors) {
                        if (transformError.includes("incident variable")) {
                            errors.push({nodeId: node.id, nodeName: name, text: transformError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                        } else {
                            errors.push({nodeId: node.id, nodeName: name, text: transformError});
                        }
                    }
                } catch (transformError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.unexpectedError});
                    console.error("Unexpected error in TransformNodeUtil");
                }
            }

            if (isSubflowNodeFromGraphDef(node)) {
                // We might want to check if the subflow node actually produces data
                dataTransformAndSubflowNodeCount++;
                const subflowErrors: Array<string> = [], subflowWarnings: Array<string> = [];
                try {
                    SubflowNodeUtils.validateNode(node.id, subflowWarnings, subflowErrors, graphDef, subflows, variables);
                    for (const subflowWarning of subflowWarnings) {
                        warnings.push({nodeId: node.id, nodeName: name, text: subflowWarning});
                    }
                    for (const subflowError of subflowErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: subflowError});
                    }
                } catch (subflowError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowNode.unexpectedError});
                    console.error("Unexpected error in SubflowNodeUtil");
                }
            }

            if (isSubflowInputNodeFromGraphDef(node)) {
                const subflowInputErrors: Array<string> = [];
                try {
                    SubflowInputNodeUtils.validateNode(node.id, subflowInputErrors, graphDef, variables);
                    for (const subflowError of subflowInputErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: subflowError});
                    } 
                } catch (subflowInputNodeError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowInputNode.unexpectedError});
                    console.error("Unexpected error in SubflowInputNodeUtils");
                }
            }

            if (isOnDemandInputNodeFromGraphDef(node)) {
                const onDemandInputErrors: Array<string> = [];
                try {
                    OnDemandInputNodeUtils.validateNode(node.id, onDemandInputErrors, graphDef, variables);
                    for (const onDemandError of onDemandInputErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: onDemandError});
                    } 
                } catch (subflowInputNodeError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.onDemandInputNode.unexpectedError});
                    console.error("Unexpected error in OnDemanInputNodeUtils");
                }
            }

            if (isAiNodeFromGraphDef(node)) {
                const aiInputErrors: Array<string> = [];
                try {
                    AiNodeUtils.validateNode(node.id, aiInputErrors, graphDef, variables);
                    for (const onDemandError of aiInputErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: onDemandError});
                    } 
                } catch (aiNodeError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.aiNode.unexpectedError});
                    console.error("Unexpected error in AiNodeUtils");
                }
            }

            if (isVariablesNodeFromGraphDef(node)) {
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Variable nodes can only have one parent and that has to be a data node or trigger
                    if (parents.length !== 1) {
                        // We might want to relax this in the future
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.variablesNodeOneParent});
                    } else if (isSetSimpleVariablesNodeFromGraphDef(node)) {
                        if (
                            !isTriggerNodeFromGraphDef(parents[0]) && !isDataOceanNodeFromGraphDef(parents[0]) && !isTransformNodeFromGraphDef(parents[0]) && 
                            !isDecisionNodeFromGraphDef(parents[0]) && !isHttpNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) && 
                            !isLogicalNodeFromGraphDef(parents[0]) && !isSubflowNodeFromGraphDef(parents[0]) && !isAggregatorNodeFromGraphDef(parents[0])
                        ) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.simpleVarsNodeSupportedParents});
                        }
                    } else if (isSetComplexVariableNode(node)) {
                        if (
                            !isDataOceanNodeFromGraphDef(parents[0]) && !isTransformNodeFromGraphDef(parents[0]) && 
                            !isDecisionNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) && 
                            !isLogicalNodeFromGraphDef(parents[0]) && !isSubflowNodeFromGraphDef(parents[0])
                        ) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.complexVarsNodeSupportedParents});
                        }
                    }
                }
                const variablesErrors: Array<string> = [];
                if (isSetSimpleVariablesNodeFromGraphDef(node)) {
                    try {
                        SetSimpleVariablesNodeUtils.validateNode(node.id, variablesErrors, graphDef, variables);
                        for (const variableError of variablesErrors) {
                            if (variableError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError});
                            }
                        }
                    } catch (variableError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.setSimpleVarsUnexpectedError});
                        console.error("Unexpected error in SetSimpleVariableNodeUtil");
                    }
                } else if (isSetComplexVariableNodeFromGraphDef(node)) {
                    try {
                        SetComplexVariableNodeUtils.validateNode(node.id, variablesErrors, graphDef, variables);
                        for (const variableError of variablesErrors) {
                            if (variableError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError});
                            }
                        }
                    } catch (variableError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.setComplexVarsUnexpectedError});
                        console.error("Unexpected error in SetComplexVariableNodeUtil");
                    }
                }

            }

            if (isChartNodeFromGraphDef(node)) {
                chartNodeCount++;

                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Charts can have only one parent and that has to be a data node
                    if (!isCardChartNodeFromGraphDef(node) && parents.length !== 1) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.chartNodeOneParent});
                    } else if (isCardChartNodeFromGraphDef(node) && ![1, 2].includes(parents.length)) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeOneOrTwoParents});
                    } else if (
                        !isDataNodeFromGraphDef(parents[0]) && !isTransformNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) &&
                        !isSubflowNodeFromGraphDef(parents[0])
                    ) {
                        if (isTableNodeFromGraphDef(node) && !isTriggerNodeFromGraphDef(parents[0])) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.tableNodeNonDataOrHttpParent});
                        } else if (!isTableNodeFromGraphDef(node)) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.chartNodeNonDataOrHttpParent});
                        }
                    }
                }

                const doOrTransformNode = getFirstParentOfTypeFromGraphDef(node, graphDef, [...dataOceanNodes, ...transformNodes]);
                if (!isCardChartNodeFromGraphDef(node) && !isDebugNodeFromGraphDef(node)) {
                    // Check to make sure time charts are hooked to time series data ocean queries and all other
                    // charts are hooked up to average queries
                    const isTimeChart = isTimeChartNodeFromGraphDef(node);
                    if (doOrTransformNode && doOrTransformNode.properties) {
                        const isTimeseries = Boolean(getProperty(doOrTransformNode, "timeSeries")) || Boolean(getProperty(doOrTransformNode, "outputDataFormat") === "timeseries");
                        if (isTimeChart !== isTimeseries) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode["chartNode" + (isTimeChart ? "TimeSeries" : "Summary") + "DataParent"]})
                        }
                    }
                }

                // Make sure cards are hooked up properly
                if (isCardChartNodeFromGraphDef(node)) {
                    if (doOrTransformNode && parents.length === 1) {
                        // We have a card with one parent make sure the query has metrics, we don't want completely 
                        // empty cards.
                        const metrics = getProperty(doOrTransformNode, "metrics") || getProperty(doOrTransformNode, "synthMetrics");
                        if (!metrics?.length) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMissing});
                        }
                    } else if (parents.length === 2) {
                        // We have a card with two parents, make sure one is a summary query and one is a time query
                        const doNodes = getFirstParentOfTypeInEachBranchFromGraphDef(node, graphDef, dataOceanNodes);
                        if (doNodes?.length === 2) {
                            if (getProperty(doNodes[0], "objType") !== getProperty(doNodes[1], "objType")) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeQueryMismatch});
                            }
                            if (getProperty(doNodes[0], "timeSeries") === getProperty(doNodes[1], "timeSeries")) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeTimeSeriesMismatch});
                            }
                            const metrics1 = getProperty(doNodes[0], "metrics");
                            const metrics2 = getProperty(doNodes[1], "metrics");
                            if (!metrics1?.length || !metrics2?.length) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMissing});
                            } else {
                                if (metrics1?.length !== metrics2?.length || !isEqual(metrics1, metrics2)) {
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMismatch});
                                }    
                            }
                        }
                    }
                }

                // Check to make sure connection graphs are hooked to host pair data ocean queries
                const isConnectionGraph = isConnectionGraphNodeFromGraphDef(node);
                if (isConnectionGraph && doOrTransformNode && doOrTransformNode.properties) {
                    if (getProperty(doOrTransformNode, "objType") !== "network_client_server.traffic") {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.connectionGraphClientServerDataParent})
                    }
                    if (getProperty(doOrTransformNode, "timeSeries")) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.connectionGraphTimeSeriesDataParent})
                    }
                }

                const isTable = isTableNodeFromGraphDef(node);
                if (isTable) {
                    const columns = getProperty(node, "columns");
                    if (!columns?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tableNode.noColumn})
                    }
/* This doesn't work properly when setDefaultNodePropertiesOnConnect.  That function 
is called before the state is fully updated so the sort column defaults do not get 
are not yet visible to the validation utilities and thus even though the sort 
column is correct it is shown as an error
                    const sortColumn = getProperty(node, "sortColumn");
                    const sortOrder = getProperty(node, "sortOrder");
                    if (!sortColumn || !sortOrder) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tableNode.noSortColumn})
                    }
*/
                }

                const isMetricsEditor = isMetricsEditorChartNodeFromGraphDef(node);
                if (isMetricsEditor) {
                    const metrics = getProperty(node, "metrics");
                    if (!metrics?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noMetrics})
                    }
                }

                const isMetricEditor = isMetricEditorChartNodeFromGraphDef(node);
                if (isMetricEditor) {
                    const metric = getProperty(node, "metric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noMetric})
                    }
                }

                const isSizeMetricEditor = isSizeMetricEditorChartNodeFromGraphDef(node);
                if (isSizeMetricEditor) {
                    const metric = getProperty(node, "sizeMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noSizeMetric})
                    }
                }

                const isColorMetricEditor = isColorMetricEditorChartNodeFromGraphDef(node);
                if (isColorMetricEditor) {
                    const metric = getProperty(node, "colorMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noColorMetric})
                    }
                }

                const isXMetricEditor = isXMetricEditorChartNodeFromGraphDef(node);
                if (isXMetricEditor) {
                    const metric = getProperty(node, "xMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noXAxisMetric})
                    }
                }

                const isYMetricEditor = isYMetricEditorChartNodeFromGraphDef(node);
                if (isYMetricEditor) {
                    const metric = getProperty(node, "yMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noYAxisMetric})
                    }
                }
            }

            if (isTagNodeFromGraphDef(node)) {
                // Tags can have only one parent and that has to be a data node.  We might want to allow
                // it to tag more than one node.
                impactAssessmentCount++;
                // Only check the parents, if there is at least one parent, if there are no parents, we caught that error above, 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    if (parents.length !== 1) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeOneParent});
                    } else if (isTriggerNodeFromGraphDef(parents[0])) {
                        if (parents[0].properties && node.properties) {
                            if (["network_interface", "network_device", "location"].includes(getProperty(parents[0], "triggerType")) && getProperty(node, "impactType") !== "location") {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeInterfaceOrDeviceOrLocationTriggerWithWrongTag});
                            } else if (["application"].includes(getProperty(parents[0], "triggerType")) && !["location", "application"].includes(getProperty(node, "impactType"))) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeApplicationTriggerWithWrongTag});
                            } else if (["webhook"].includes(getProperty(parents[0], "triggerType"))) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeWebhookTrigger});
                            }
                        }
                    } else if (!isDataNodeFromGraphDef(parents[0])) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeNonDataParent});
                    } else {
                        // The impact assessment node is connected to a data node, make sure it supports the type of tag
                        const doNodeOrXformNode = getFirstParentOfTypeFromGraphDef(node, graphDef, [...dataOceanNodes, ...transformNodes]);
                        if (doNodeOrXformNode && doNodeOrXformNode.properties) {
                            if (isDataOceanNodeFromGraphDef(doNodeOrXformNode)) {
                                // The impact is connected to a data ocean node.
                                if (getProperty(node, "impactType") === "location" && !DataOceanUtils.hasAnyKey(getProperty(doNodeOrXformNode, "objType"), ["location", "client_location", "server_location"])) {
                                    // Location tags must be connected to a DO node with the location key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongLocationTag})
                                } else if (getProperty(node, "impactType") === "application" && !DataOceanUtils.hasAnyKey(getProperty(doNodeOrXformNode, "objType"), ["application"])) {
                                    // Application tags must be connected to a DO node with the application key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongApplicationTag})
                                } else if (getProperty(node, "impactType") === "user" && !DataOceanUtils.hasAnyKey(getProperty(doNodeOrXformNode, "objType"), ["network_client", "user_device"])) {
                                    // User tags must be connected to a DO node with the network_client key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongUserTag})
                                }    
                            } else {
                                // The impact node is connected to a transform node
                                if (getProperty(node, "impactType") === "location" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, getProperty(doNodeOrXformNode, "synthKeys"), ["location", "client_location", "server_location"])) {
                                    // Location tags must be connected to a DO node with the location key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongLocationTag})
                                } else if (getProperty(node, "impactType") === "application" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, getProperty(doNodeOrXformNode, "synthKeys"), ["application"])) {
                                    // Application tags must be connected to a DO node with the application key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongApplicationTag})
                                } else if (getProperty(node, "impactType") === "user" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, getProperty(doNodeOrXformNode, "synthKeys"), ["network_client"])) {
                                    // User tags must be connected to a DO node with the network_client key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongUserTag})
                                }
                            }
                        }    
                    }
                }
            }
        }
    }

    if (!IS_EMBEDDED && triggerType !== InputType.WEBHOOK && variant !== Variant.SUBFLOW && dataTransformAndSubflowNodeCount < 1) {
        errors.push({text: STRINGS.runbookEditor.errors.doNode.noDataOrTransformNode});
    }

    if (dataOceanNodeCount > 100) {
        errors.push({text: STRINGS.runbookEditor.errors.doNode.dataOceanNodeCountExceeded});
    }

    if (triggerNodes?.length === 1 && variant !== Variant.SUBFLOW) {
        if (getProperty(triggerNodes[0], "triggerType") !== InputType.WEBHOOK && chartNodeCount < 1) {
            errors.push({text: STRINGS.runbookEditor.errors.chartNode.noChartNode});
        }
    }

    // This is no longer an error
    //if (!allTerminatingDataNodesAndLogicalNodesHaveCharts) {
    //    errors.push({text: STRINGS.runbookEditor.errors.chartNode.noChartNode});
    //}

    if (
        !IS_EMBEDDED && ![Variant.SUBFLOW, Variant.ON_DEMAND].includes(variant) && triggerType !== InputType.WEBHOOK && 
        impactAssessmentCount === 0
    ) {
        warnings.push({text: STRINGS.runbookEditor.warnings.noImpactAssessment});
    }
}

/** validates the runbook nodes and returns any errors with the nodes.
 *  @param nodeLibrary a reference to the NodeLibrary.
 *  @param graphDef the GraphDef object with the current graph definition.
 *  @param variables the map of variables by scope.
 *  @param customProperties the array of CustomProperty objects with the custom properties for 
 *       all the entity types. 
 *  @param subflows the array of RunbookNode objects with the list of subflows.
 *  @param warnings the array of ValidationResults with the warnings.
 *  @param errors the array of ValidationResults with the errors.
 *  @returns an object that contains the arrary of warnings and errors.*/
export function validateLifecycleNodesFromGraphDef(
    nodeLibrary: NodeLibrary, graphDef: GraphDef, variables: VariableContextByScope, customProperties: CustomProperty[],
    subflows: Array<RunbookNode> = [], warnings: ValidationResult[], errors: ValidationResult[]
): void {
    // The query node is clear with the new riverbed azure function back-end but it wasn't as clear with node red
    // so only check this for the new back-end for now.
    //let allTerminatingDataNodesAndLogicalNodesHaveCharts: boolean = true;

    const triggerNodes = getTriggerNodesFromGraphDef(graphDef);

    //let triggerType: InputType | undefined;

    //There should be one and only one trigger node
    if (triggerNodes?.length) {
        if (triggerNodes.length > 1) {
            errors.push({text: STRINGS.formatString(
                STRINGS.runbookEditor.errors.triggerNode.tooManyTriggerNodesError, 
                {triggerName: STRINGS.runbookEditor.triggerNameForVariant[Variant.LIFECYCLE]}
            )});
        //} else {
            //triggerType = getProperty(triggerNodes[0], "triggerType") as InputType;
        }
    } else {
        errors.push({text: STRINGS.formatString(
            STRINGS.runbookEditor.errors.triggerNode.noTriggerNodeError, 
            {triggerName: STRINGS.runbookEditor.triggerNameForVariant[Variant.LIFECYCLE]}
        )});
    }
    // Not needed for lifecycle runbooks
    //let dataOceanNodeCount = 0;
    //let dataTransformAndSubflowNodeCount = 0;
    //let impactAssessmentCount = 0;
    //let chartNodeCount = 0;

    if (graphDef && graphDef.nodes) {
        let roots: Array<RunbookGraphNodeDef> = generateGraphFromGraphDef(graphDef);
        let isCyclic = isGraphCyclicFromGraphDef(roots);
        if (isCyclic) {
            // We don't allow cyclic graphs
            errors.push({text: STRINGS.formatString(STRINGS.runbookEditor.errors.noCyclicGraphs, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[Variant.LIFECYCLE]})});
        }

        for (const node of graphDef.nodes) {
            const parents = getParentsFromGraphDef(node, graphDef);
            const name = node.name || "";

            if (!isValidTypeFromGraphDef(node)) {
                // We don't know what this node is, so let's flag it as an error and do no additional checking
                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeHasInvalidType});
                continue;
            }

            if (isTriggerNodeFromGraphDef(node) || isCommentNodeFromGraphDef(node)) {
                if (parents && parents.length > 0) {
                    // Trigger and comment nodes cannot have any parents
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeHasParent});
                }
            } else {
                if (!parents || parents.length === 0) {
                    // All other nodes must have a parent, except the comment node
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeDoesNotHaveParent});
                }
            }

            const children = getChildrenFromGraphDef(node, graphDef);
            if (
                isCommentNodeFromGraphDef(node) || isChartNodeFromGraphDef(node) || isPriorityNodeFromGraphDef(node) || 
                isTagNodeFromGraphDef(node) || isNoteNodeFromGraphDef(node)
            ) {
                if (children && children.length > 0) {
                    // A comment, chart node, priority, or variables node cannot have children
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeShouldNotHaveChildren});
                }
            } else if (!isVariablesNodeFromGraphDef(node) && !isHttpNodeFromGraphDef(node) && !isSubflowNodeFromGraphDef(node)) {
                if (!children || children.length === 0) {
                    // All other nodes must have children
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.nodeShouldHaveChildren});
                }
            }

            if (isTriggerNodeFromGraphDef(node)) {
                const triggerErrors: Array<string> = [];
                try {
                    TriggerNodeUtils.validateNode(node.id, triggerErrors, graphDef, variables);
                    for (const triggerError of triggerErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: triggerError});
                    }
                } catch (triggerErrors) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.triggerNode.unexpectedError});
                    console.error("Unexpected error in TriggerNodeUtil");
                }
            }

            if (isDataNodeFromGraphDef(node)) {
/* Not needed for lifecycle runbooks
                // Validate the data ocean node and logical node
                if (isDataOceanNodeFromGraphDef(node)) {
                    // Counter to check if data ocean count is between 1-100(inclusive)
                    dataOceanNodeCount++;
                    dataTransformAndSubflowNodeCount++;
                    // A data ocean can have one and only one parent
                    const doErrors: Array<string> = [];
                    try {
                        DataOceanUtils.validateNodeFromGraphDef(
                            nodeLibrary, node, parents, graphDef, triggerNodes && triggerNodes.length === 1 ? triggerNodes[0] : null, 
                            variables, doErrors
                        );
                        for (const doError of doErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: doError});
                        }
                    } catch (doError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.doNode.unexpectedError});
                        console.error("Unexpected error in DataOceanUils");
                    }
                }
*/
                
                if (isLogicalNodeFromGraphDef(node)) {
                    const logicErrors: Array<string> = [];
                    try {
                        LogicNodeUtil.validateNode(node.id, logicErrors, graphDef, variables);
                        for (const logicError of logicErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: logicError});
                        }    
                    } catch (logicError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.logicNode.unexpectedError});
                        console.error("Unexpected error in LogicNodeUtil");
                    }
                }

                if (isDecisionNodeFromGraphDef(node)) {
                    const decisionErrors: Array<string> = [];
                    try {
                        DecisionNodeUtil.validateNode(node.id, decisionErrors, graphDef, variables, customProperties);
                        for (const decisionError of decisionErrors) {
                            if (decisionError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: decisionError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: decisionError});
                            }
                        }    
                    } catch (decisionError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.decisionNode.unexpectedError});
                        console.error("Unexpected error in DecisionNodeUtil");
                    }
                }

/* Not needed for lifecycle runbooks
                if (isAggregatorNodeFromGraphDef(node)) {
                    const logicErrors: Array<string> = [];
                    try {
                        AggregateNodeUtil.validateNode(node.id, logicErrors, graphDef, variables);
                        for (const logicError of logicErrors) {
                            errors.push({nodeId: node.id, nodeName: name, text: logicError});
                        }
                    } catch (logicError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.aggregateNode.unexpectedError});
                        console.error("Unexpected error in AggregatorNodeUtil");
                    }
                }

                const chartNode = getChartNodeFromGraphDef(node, graphDef);
                let hasDataChild: boolean = false;
                if (children && children.length > 0) {
                    for (const childNode of children) {
                        if (isDataNodeFromGraphDef(childNode)) {
                            hasDataChild = true;
                            break;
                        }
                    }
                }

                if (!hasDataChild) {
                    // If a data node (data ocean or logical node) does not have another data node attached to it, it needs to have
                    // a chart attached to it otherwise it's data will not be visible.
                    allTerminatingDataNodesAndLogicalNodesHaveCharts = allTerminatingDataNodesAndLogicalNodesHaveCharts && chartNode !== null && chartNode !== undefined;
                }
*/
            }

/* Not needed for lifecycle runbooks
            if (isPriorityNodeFromGraphDef(node)) {
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Priority nodes can only have one parent and that has to be a data node or trigger
                    if (parents.length !== 1) {
                        // We might want to relax this in the future, we could tag all the branches with the 
                        // same priority using one node
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.priorityNode.priorityNodeOneParent});
                    } else if (
                        !isHttpNodeFromGraphDef(parents[0]) && !isTriggerNodeFromGraphDef(parents[0]) && !isDataNodeFromGraphDef(parents[0]) && 
                        !isSubflowNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef((parents[0]))
                    ) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.priorityNode.priorityNodeNonDataOrTriggerParent});
                    }
                }
            }
*/

            if (isHttpNodeFromGraphDef(node)) {
                const httpErrors: Array<string> = [];
                try {
                    HttpNodeUtil.validateNode(node.id, httpErrors, graphDef, variables);
                    for (const httpError of httpErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: httpError});
                    } 
                } catch (httpErrors) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.httpNode.unexpectedError});
                    console.error("Unexpected error in HttpNodeUtil");
                }
            }

            if (isTransformNodeFromGraphDef(node)) {
                // Not needed for lifecycle runbooks
                //dataTransformAndSubflowNodeCount++;
                // Check if all the subkeys in the node match the subkeys from DataOcean Metadata
                const subkeysMissingWarning = TransformNodeUtils.allSubkeysInMetadata(node);
                if (subkeysMissingWarning) {
                    warnings.push({nodeId: node.id, nodeName: name, text: subkeysMissingWarning});
                }
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Transform nodes can only have one parent and that has to be an http or decision branch node
                    if (parents.length !== 1) {
                        // We might want to relax this in the future
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.transformNodeOneParent});
                    } else if (
                        !isHttpNodeFromGraphDef(parents[0]) && !isDecisionNodeFromGraphDef(parents[0]) && 
                        !isTriggerNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) && 
                        !isDataOceanNodeFromGraphDef(parents[0]) && !isSubflowNodeFromGraphDef(parents[0])
                    ) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.transformNodeNonHttpOrDecisionOrTriggerParent});
                    }
                }
                const transformErrors: Array<string> = [];
                try {
                    TransformNodeUtils.validateNode(node.id, transformErrors, graphDef, variables, customProperties);
                    for (const transformError of transformErrors) {
                        if (transformError.includes("incident variable")) {
                            errors.push({nodeId: node.id, nodeName: name, text: transformError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                        } else {
                            errors.push({nodeId: node.id, nodeName: name, text: transformError});
                        }
                    }
                } catch (transformError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.transformNode.unexpectedError});
                    console.error("Unexpected error in TransformNodeUtil");
                }
            }

/*
            if (isSubflowNodeFromGraphDef(node)) {
                // We might want to check if the subflow node actually produces data
                dataTransformAndSubflowNodeCount++;
                const subflowErrors: Array<string> = [], subflowWarnings: Array<string> = [];
                try {
                    SubflowNodeUtils.validateNode(node.id, subflowWarnings, subflowErrors, graphDef, subflows, variables);
                    for (const subflowWarning of subflowWarnings) {
                        warnings.push({nodeId: node.id, nodeName: name, text: subflowWarning});
                    }
                    for (const subflowError of subflowErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: subflowError});
                    }
                } catch (subflowError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowNode.unexpectedError});
                    console.error("Unexpected error in SubflowNodeUtil");
                }
            }

            if (isSubflowInputNodeFromGraphDef(node)) {
                const subflowInputErrors: Array<string> = [];
                try {
                    SubflowInputNodeUtils.validateNode(node.id, subflowInputErrors, graphDef, variables);
                    for (const subflowError of subflowInputErrors) {
                        errors.push({nodeId: node.id, nodeName: name, text: subflowError});
                    } 
                } catch (subflowInputNodeError) {
                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.subflowInputNode.unexpectedError});
                    console.error("Unexpected error in SubflowInputNodeUtils");
                }
            }
*/

            if (isVariablesNodeFromGraphDef(node)) {
                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Variable nodes can only have one parent and that has to be a data node or trigger
                    if (parents.length !== 1) {
                        // We might want to relax this in the future
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.variablesNodeOneParent});
                    } else if (isSetSimpleVariablesNodeFromGraphDef(node)) {
                        if (
                            !isTriggerNodeFromGraphDef(parents[0]) && !isDataOceanNodeFromGraphDef(parents[0]) && !isTransformNodeFromGraphDef(parents[0]) && 
                            !isDecisionNodeFromGraphDef(parents[0]) && !isHttpNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) && 
                            !isLogicalNodeFromGraphDef(parents[0]) && !isSubflowNodeFromGraphDef(parents[0]) && !isAggregatorNodeFromGraphDef(parents[0])
                        ) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.simpleVarsNodeSupportedParents});
                        }
                    } else if (isSetComplexVariableNode(node)) {
                        if (
                            !isDataOceanNodeFromGraphDef(parents[0]) && !isTransformNodeFromGraphDef(parents[0]) && 
                            !isDecisionNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) && 
                            !isLogicalNodeFromGraphDef(parents[0]) && !isSubflowNodeFromGraphDef(parents[0])
                        ) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.complexVarsNodeSupportedParents});
                        }
                    }
                }
                const variablesErrors: Array<string> = [];
                if (isSetSimpleVariablesNodeFromGraphDef(node)) {
                    try {
                        SetSimpleVariablesNodeUtils.validateNode(node.id, variablesErrors, graphDef, variables);
                        for (const variableError of variablesErrors) {
                            if (variableError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError});
                            }
                        }
                    } catch (variableError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.setSimpleVarsUnexpectedError});
                        console.error("Unexpected error in SetSimpleVariableNodeUtil");
                    }
                } else if (isSetComplexVariableNodeFromGraphDef(node)) {
                    try {
                        SetComplexVariableNodeUtils.validateNode(node.id, variablesErrors, graphDef, variables);
                        for (const variableError of variablesErrors) {
                            if (variableError.includes("incident variable")) {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError, additionalInfo: AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR});
                            } else {
                                errors.push({nodeId: node.id, nodeName: name, text: variableError});
                            }
                        }
                    } catch (variableError) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.variablesNode.setComplexVarsUnexpectedError});
                        console.error("Unexpected error in SetComplexVariableNodeUtil");
                    }
                }

            }

/* Not needed for lifecycle runbooks
            if (isChartNodeFromGraphDef(node)) {
                chartNodeCount++;

                // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    // Charts can have only one parent and that has to be a data node
                    if (!isCardChartNodeFromGraphDef(node) && parents.length !== 1) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.chartNodeOneParent});
                    } else if (isCardChartNodeFromGraphDef(node) && ![1, 2].includes(parents.length)) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeOneOrTwoParents});
                    } else if (
                        !isDataNodeFromGraphDef(parents[0]) && !isTransformNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) &&
                        !isSubflowNodeFromGraphDef(parents[0])
                    ) {
                        if (isTableNodeFromGraphDef(node) && !isTriggerNodeFromGraphDef(parents[0])) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.tableNodeNonDataOrHttpParent});
                        } else if (!isTableNodeFromGraphDef(node)) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.chartNodeNonDataOrHttpParent});
                        }
                    }
                }

                const doOrTransformNode = getFirstParentOfTypeFromGraphDef(node, graphDef, [...dataOceanNodes, ...transformNodes]);
                if (!isCardChartNodeFromGraphDef(node) && !isDebugNodeFromGraphDef(node)) {
                    // Check to make sure time charts are hooked to time series data ocean queries and all other
                    // charts are hooked up to average queries
                    const isTimeChart = isTimeChartNodeFromGraphDef(node);
                    if (doOrTransformNode && doOrTransformNode.properties) {
                        const isTimeseries = Boolean(getProperty(doOrTransformNode, "timeSeries")) || Boolean(getProperty(doOrTransformNode, "outputDataFormat") === "timeseries");
                        if (isTimeChart !== isTimeseries) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode["chartNode" + (isTimeChart ? "TimeSeries" : "Summary") + "DataParent"]})
                        }
                    }
                }

                // Make sure cards are hooked up properly
                if (isCardChartNodeFromGraphDef(node)) {
                    if (doOrTransformNode && parents.length === 1) {
                        // We have a card with one parent make sure the query has metrics, we don't want completely 
                        // empty cards.
                        const metrics = getProperty(doOrTransformNode, "metrics") || getProperty(doOrTransformNode, "synthMetrics");
                        if (!metrics?.length) {
                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMissing});
                        }
                    } else if (parents.length === 2) {
                        // We have a card with two parents, make sure one is a summary query and one is a time query
                        const doNodes = getFirstParentOfTypeInEachBranchFromGraphDef(node, graphDef, dataOceanNodes);
                        if (doNodes?.length === 2) {
                            if (getProperty(doNodes[0], "objType") !== getProperty(doNodes[1], "objType")) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeQueryMismatch});
                            }
                            if (getProperty(doNodes[0], "timeSeries") === getProperty(doNodes[1], "timeSeries")) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeTimeSeriesMismatch});
                            }
                            const metrics1 = getProperty(doNodes[0], "metrics");
                            const metrics2 = getProperty(doNodes[1], "metrics");
                            if (!metrics1?.length || !metrics2?.length) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMissing});
                            } else {
                                if (metrics1?.length !== metrics2?.length || !isEqual(metrics1, metrics2)) {
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.cardNodeMetricMismatch});
                                }    
                            }
                        }
                    }
                }

                // Check to make sure connection graphs are hooked to host pair data ocean queries
                const isConnectionGraph = isConnectionGraphNodeFromGraphDef(node);
                if (isConnectionGraph && doOrTransformNode && doOrTransformNode.properties) {
                    if (getProperty(doOrTransformNode, "objType") !== "network_client_server.traffic") {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.connectionGraphClientServerDataParent})
                    }
                    if (getProperty(doOrTransformNode, "timeSeries")) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.connectionGraphTimeSeriesDataParent})
                    }
                }

                const isTable = isTableNodeFromGraphDef(node);
                if (isTable) {
                    const columns = getProperty(node, "columns");
                    if (!columns?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tableNode.noColumn})
                    }
// This doesn't work properly when setDefaultNodePropertiesOnConnect.  That function 
//   is called before the state is fully updated so the sort column defaults do not get 
//   are not yet visible to the validation utilities and thus even though the sort 
//   column is correct it is shown as an error
                    //                        const sortColumn = getProperty(node, "sortColumn");
                    //                        const sortOrder = getProperty(node, "sortOrder");
                    //                        if (!sortColumn || !sortOrder) {
                        //                            errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tableNode.noSortColumn})
                    //                        }
//
                }

                const isMetricsEditor = isMetricsEditorChartNodeFromGraphDef(node);
                if (isMetricsEditor) {
                    const metrics = getProperty(node, "metrics");
                    if (!metrics?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noMetrics})
                    }
                }

                const isMetricEditor = isMetricEditorChartNodeFromGraphDef(node);
                if (isMetricEditor) {
                    const metric = getProperty(node, "metric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noMetric})
                    }
                }

                const isSizeMetricEditor = isSizeMetricEditorChartNodeFromGraphDef(node);
                if (isSizeMetricEditor) {
                    const metric = getProperty(node, "sizeMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noSizeMetric})
                    }
                }

                const isColorMetricEditor = isColorMetricEditorChartNodeFromGraphDef(node);
                if (isColorMetricEditor) {
                    const metric = getProperty(node, "colorMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noColorMetric})
                    }
                }

                const isXMetricEditor = isXMetricEditorChartNodeFromGraphDef(node);
                if (isXMetricEditor) {
                    const metric = getProperty(node, "xMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noXAxisMetric})
                    }
                }

                const isYMetricEditor = isYMetricEditorChartNodeFromGraphDef(node);
                if (isYMetricEditor) {
                    const metric = getProperty(node, "yMetric");
                    if (!metric?.length) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.chartNode.noYAxisMetric})
                    }
                }
            }
*/

/* Not needed for lifecycle runbooks
            if (isTagNodeFromGraphDef(node)) {
                // Tags can have only one parent and that has to be a data node.  We might want to allow
                // it to tag more than one node.
                impactAssessmentCount++;
                // Only check the parents, if there is at least one parent, if there are no parents, we caught that error above, 
                // in the general parents check.
                if (parents && parents.length > 0) {
                    if (parents.length !== 1) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeOneParent});
                    } else if (isTriggerNodeFromGraphDef(parents[0])) {
                        if (parents[0].properties && node.properties) {
                            if (["network_interface", "network_device", "location"].includes(getProperty(parents[0], "triggerType")) && getProperty(node, "impactType") !== "location") {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeInterfaceOrDeviceOrLocationTriggerWithWrongTag});
                            } else if (["application"].includes(getProperty(parents[0], "triggerType")) && !["location", "application"].includes(getProperty(node, "impactType"))) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeApplicationTriggerWithWrongTag});
                            } else if (["webhook"].includes(getProperty(parents[0], "triggerType"))) {
                                errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeWebhookTrigger});
                            }
                        }
                    } else if (!isDataNodeFromGraphDef(parents[0])) {
                        errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeNonDataParent});
                    } else {
                        // The impact assessment node is connected to a data node, make sure it supports the type of tag
                        const doNodeOrXformNode = getFirstParentOfTypeFromGraphDef(node, graphDef, [...dataOceanNodes, ...transformNodes]);
                        if (doNodeOrXformNode && doNodeOrXformNode.properties) {
                            if (isDataOceanNodeFromGraphDef(doNodeOrXformNode)) {
                                // The impact is connected to a data ocean node.
                                if (getProperty(node, "impactType") === "location" && !DataOceanUtils.hasAnyKey(getProperty(doNodeOrXformNode, "objType"), ["location", "client_location", "server_location"])) {
                                    // Location tags must be connected to a DO node with the location key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongLocationTag})
                                } else if (getProperty(node, "impactType") === "application" && !DataOceanUtils.hasAnyKey(getProperty(doNodeOrXformNode, "objType"), ["application"])) {
                                    // Application tags must be connected to a DO node with the application key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongApplicationTag})
                                } else if (getProperty(node, "impactType") === "user" && !DataOceanUtils.hasAnyKey(getProperty(doNodeOrXformNode, "objType"), ["network_client", "user_device"])) {
                                    // User tags must be connected to a DO node with the network_client key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongUserTag})
                                }    
                            } else {
                                // The impact node is connected to a transform node
                                if (getProperty(node, "impactType") === "location" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, getProperty(doNodeOrXformNode, "synthKeys"), ["location", "client_location", "server_location"])) {
                                    // Location tags must be connected to a DO node with the location key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongLocationTag})
                                } else if (getProperty(node, "impactType") === "application" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, getProperty(doNodeOrXformNode, "synthKeys"), ["application"])) {
                                    // Application tags must be connected to a DO node with the application key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongApplicationTag})
                                } else if (getProperty(node, "impactType") === "user" && !TransformNodeUtils.hasAnyKey(DataOceanUtils.dataOceanMetaData, getProperty(doNodeOrXformNode, "synthKeys"), ["network_client"])) {
                                    // User tags must be connected to a DO node with the network_client key
                                    errors.push({nodeId: node.id, nodeName: name, text: STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongUserTag})
                                }
                            }
                        }    
                    }
                }
            }
            */
        }
    }

/* Not needed for lifecycle runbooks
    if (!IS_EMBEDDED && triggerType !== InputType.WEBHOOK && dataTransformAndSubflowNodeCount < 1) {
        errors.push({text: STRINGS.runbookEditor.errors.doNode.noDataOrTransformNode});
    }

    if (dataOceanNodeCount > 100) {
        errors.push({text: STRINGS.runbookEditor.errors.doNode.dataOceanNodeCountExceeded});
    }

    if (triggerNodes?.length === 1) {
        if (getProperty(triggerNodes[0], "triggerType") !== InputType.WEBHOOK && chartNodeCount < 1) {
            errors.push({text: STRINGS.runbookEditor.errors.chartNode.noChartNode});
        }
    }

    // This is no longer an error
    //if (!allTerminatingDataNodesAndLogicalNodesHaveCharts) {
    //    errors.push({text: STRINGS.runbookEditor.errors.chartNode.noChartNode});
    //}

    if (
        !IS_EMBEDDED && ![Variant.SUBFLOW, Variant.ON_DEMAND].includes(variant) && triggerType !== InputType.WEBHOOK && 
        impactAssessmentCount === 0
    ) {
        warnings.push({text: STRINGS.runbookEditor.warnings.noImpactAssessment});
    }
*/
}

/** returns true if the two nodes can be connected, false otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param fromHandle the index of the from handle we are connecting to.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
export function canConnect(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, fromHandle: number | undefined,
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, toWires: any,
    graphDef: GraphDef, exclusions?: ConnectionExclusions
): string | undefined {
    if (!exclusions) {
        // There is no exclusion rules defined so don't allow auto-connections
        return "error";
    }

    //if (fromType.startsWith("subflow")) {
        // Subflows have an id in the type so pull it out
        //fromType = fromType.substring(0, 7);
    //}

    //if (toType.startsWith("subflow")) {
        // Subflows have an id in the type so pull it out
        //toType = toType.substring(0, 7);
    //}

    // Use the exclusions file to see if the connection is covered by an exclusion
    const exclusionsForType: any = exclusions[fromType];
    if (exclusionsForType) {
        for (const exclusion of exclusionsForType) {
            if (exclusion.fromSubTypes && exclusion.fromSubTypes.length > 0 && exclusion.fromSubTypes[0] !== "*" && !exclusion.fromSubTypes.includes(fromSubType)) {
                // This rule does not apply
                continue;
            }

            if (exclusion.toTypes && exclusion.toTypes.length > 0 && exclusion.toTypes[0] !== "*" && !exclusion.toTypes.includes(toType)) {
                // This rule does not apply
                continue;
            }

            if (exclusion.toSubTypes && exclusion.toSubTypes.length > 0 && exclusion.toSubTypes[0] !== "*" && !exclusion.toSubTypes.includes(toSubType)) {
                // This rule does not apply
                continue;
            }

            // The rule applies
            let result: string | undefined;
            const validationFunc = exclusion.function;
            if (validationFunc) {
                switch (validationFunc) {
                    case "checkDataOceanToDataOcean":
                        result = checkDataOceanToDataOcean(fromType, fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
                        break;
                    case "checkDataOceanToChart":
                        result = checkDataOceanToChart(fromType, fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
                        break;
                    case "checkDataOceanToLogicToDataOcean":
                        result = checkDataOceanToLogicToDataOcean(fromType, fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
                        break;
                    case "checkDataOceanToLogicToChart":
                        result = checkDataOceanToLogicToChart(fromType, fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
                        break;
                    case "checkConnectionToTag":
                        result = checkConnectionToTag(fromType, fromSubType, fromId, fromProperties, fromHandle, toType, toSubType, toId, toProperties, graphDef);
                        break;
                    case "checkConnectionFromSubflowInputToDataOcean":
                        result = checkConnectionFromSubflowInputToDataOcean(fromType, fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
                        break;
                    case "checkConnectionToSubflow":
                        result = checkConnectionToSubflow(fromType, fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, toWires, graphDef);
                        break;
                    case "checkConnectionFromSubflowToDataOcean":
                        result = checkConnectionFromSubflowToDataOcean(fromType, fromSubType, fromId, fromProperties, fromHandle, toType, toSubType, toId, toProperties, graphDef);
                        break;
                    case "checkConnectionFromSubflowToChart":
                        result = checkConnectionFromSubflowToChart(fromType, fromSubType, fromId, fromProperties, fromHandle, toType, toSubType, toId, toProperties, graphDef);
                        break;
                }
                return result ? result : undefined;
            }

            const {nodeKey, errorKey} = exclusion.error || {nodeKey: "", errorKey: ""};
            return STRINGS.runbookEditor.errors[nodeKey] && STRINGS.runbookEditor.errors[nodeKey][errorKey] ? 
                STRINGS.runbookEditor.errors[nodeKey][errorKey] : "error";

        }

        // There are no exclusions, the connection is allowed
        return undefined;
    }

/*
    // This does the same thing as above, except right now the exclusions list does not detect if there
    // is a new node that we don't know about, this programatic version of what is above, handles cases
    // where a new subtype is added and prevents a connection, whereas the above does not prevent a 
    // connection to some new subtype that has been added without updating the exclusions file.
    if (triggerNodes.includes(fromType)) {
        return canConnectToTriggerNode(fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    } else if (dataOceanNodes.includes(fromType)) {
        return canConnectToDataOceanNode(fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    } else if (logicalNodes.includes(fromType)) {
        return canConnectToLogicalNode(fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    }
*/

    // If we don't have a function to check the type then say that a connection is allowed
    return STRINGS.runbookEditor.errors.nodeShouldNotHaveChildren;
}

/** returns true if the specified node can be connected to a trigger node, false otherwise.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
/* Not used right now so comment out
export function canConnectToTriggerNode(
    fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    if (tagNodes.includes(toType)) {
        switch (fromSubType) {
            case "NetworkInterface":
            case "NetworkDevice":
            case "Location":
                    if (["Location"].includes(toSubType)) {
                    return undefined;
                } else {
                    return STRINGS.runbookEditor.errors.tagNode.tagNodeInterfaceOrDeviceOrLocationTriggerWithWrongTag;
                }
            case "Application":
                if (["Application", "Location"].includes(toSubType)) {
                    return undefined;
                } else {
                    return STRINGS.runbookEditor.errors.tagNode.tagNodeApplicationTriggerWithWrongTag;
                }
        }
        return STRINGS.runbookEditor.errors.tagNode.tagNodeUnknownTrigger;
    } else if (priorityNodes.includes(toType)) {
        return undefined;
    } else if (dataOceanNodes.includes(toType)) {
        let objType: string | undefined = getPropertyFromPropertyObject(toProperties, "objType");
        if (objType) {
            switch (fromSubType) {
                case "NetworkInterface":
                    if (!DataOceanUtils.hasAnyFilter(objType, ["network_interface"])) {
                        return STRINGS.runbookEditor.errors.doNode.noFilterForTrigger;
                    }
                    break;
                case "NetworkDevice":
                    if (!DataOceanUtils.hasAnyFilter(objType, ["network_device"])) {
                        return STRINGS.runbookEditor.errors.doNode.noFilterForTrigger;
                    }
                    break;
                case "Application":
                    if (!DataOceanUtils.hasAnyFilter(objType, ["application"])) {
                        return STRINGS.runbookEditor.errors.doNode.noFilterForTrigger;
                    }
                    break;
                case "Location":
                    if (!DataOceanUtils.hasAnyFilter(objType, ["location"])) {
                        return STRINGS.runbookEditor.errors.doNode.noFilterForTrigger;
                    }
                    break;
            }    
        }
        return undefined;
    }
    return STRINGS.runbookEditor.errors.triggerNode.triggerNodeUnknownNode;
}
*/

/** returns true if the specified node can be connected to a data ocean node, false otherwise.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
/* Not used right now so comment out
export function canConnectToDataOceanNode(
    fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    let fromObjType: string | undefined = getPropertyFromPropertyObject(fromProperties, "objType");
    if (tagNodes.includes(toType)) {
        if (fromObjType) {
            switch (toSubType) {
                case "Application":
                    if (DataOceanUtils.hasAnyKey(fromObjType, ["application"])) {
                        return undefined;
                    } else {
                        return STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongApplicationTag;
                    }
                case "Location":
                    if (DataOceanUtils.hasAnyKey(fromObjType, ["location"])) {
                        return undefined;
                    } else {
                        return STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongLocationTag;
                    }
                case "User":
                    if (DataOceanUtils.hasAnyKey(fromObjType, ["network_client"])) {
                        return undefined;
                    } else {
                        return STRINGS.runbookEditor.errors.tagNode.tagNodeDoNodeWithWrongUserTag;
                    }
            }
        }
        return STRINGS.runbookEditor.errors.tagNode.tagNodeUnknownDataOcean;
    } else if (priorityNodes.includes(toType)) {
        return undefined;
    } else if (dataOceanNodes.includes(toType)) {
        return checkDataOceanToDataOcean("data_ocean", fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    } else if (logicalNodes.includes(toType)) {
        return undefined;
    } else if (chartNodes.includes(toType)) {
        return checkDataOceanToChart("data_ocean", fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    }
    return STRINGS.runbookEditor.errors.doNode.dataOceanNodeUnknownNode;
}
*/

/** returns true if the specified node can be connected to a logical node, false otherwise.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
/* Not used right now so comment out
export function canConnectToLogicalNode(
    fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    if (tagNodes.includes(toType)) {
        return checkDataOceanToLogicToTag("logic", fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    } else if (priorityNodes.includes(toType)) {
        return undefined;
    } else if (dataOceanNodes.includes(toType)) {
        return checkDataOceanToLogicToDataOcean("logic", fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    } else if (logicalNodes.includes(toType)) {
        return undefined;
    } else if (chartNodes.includes(toType)) {
        return checkDataOceanToLogicToChart("logic", fromSubType, fromId, fromProperties, toType, toSubType, toId, toProperties, graphDef);
    }
    return STRINGS.runbookEditor.errors.logicNode.logicalNodeUnknownNode;
}
*/

/** returns true if the two nodes can be connected, false otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
export function checkDataOceanToDataOcean(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    let fromObjType: string | undefined = getPropertyFromPropertyObject(fromProperties, "objType");
    let toObjType: string | undefined = getPropertyFromPropertyObject(toProperties, "objType");
    if (toObjType) {
        const fromKeys: Array<string> = DataOceanUtils.getKeys(fromObjType || "");
        if (!DataOceanUtils.hasAnyFilter(toObjType, fromKeys)) {
            return STRINGS.runbookEditor.errors.doNode.noFilterForConnectedNode;
        }
    }
    return undefined;
}

/** returns true if the two nodes can be connected, false otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
 export function checkDataOceanToChart(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    let fromObjType: string | undefined = getPropertyFromPropertyObject(fromProperties, "objType");
    let isTimeSeries: string | undefined = getPropertyFromPropertyObject(fromProperties, "timeSeries");
    if (isTimeSeries && !["rvbd_ui_time_chart", "rvbd_ui_correlation_chart", "rvbd_ui_cards"].includes(toType)) {
        return STRINGS.runbookEditor.errors.chartNode.chartNodeSummaryDataParent;
    } else if (!isTimeSeries && ["rvbd_ui_time_chart", "rvbd_ui_correlation_chart"].includes(toType)) {
        return STRINGS.runbookEditor.errors.chartNode.chartNodeTimeSeriesDataParent;
    }
    if (connectionGraphNodes.includes(toType) && fromObjType !== "network_client_server.traffic") {
        return STRINGS.runbookEditor.errors.chartNode.connectionGraphClientServerDataParent;
    }
    return undefined;
}

/** returns true if the two nodes can be connected, false otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
 export function checkDataOceanToLogicToDataOcean(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    const logicalNode = getNodeFromGraphDef(fromId, graphDef);
    let doNode: NodeDef | null = null;
    if (logicalNode) {
        doNode = getFirstParentOfTypeFromGraphDef(logicalNode, graphDef, dataOceanNodes);        
    }
    if (doNode) {
        let fromObjType: string | undefined = getProperty(doNode, "objType");
        let toObjType: string | undefined = getPropertyFromPropertyObject(toProperties, "objType");
        if (toObjType) {
            const fromKeys: Array<string> = DataOceanUtils.getKeys(fromObjType || "");
            if (!DataOceanUtils.hasAnyFilter(toObjType, fromKeys)) {
                return STRINGS.runbookEditor.errors.doNode.noFilterForConnectedNode;
            }
        }    
    }
    return undefined;
}

/** returns true if the two nodes can be connected, false otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns a boolean value, true if the two nodes can be connected, false otherwise.*/
 export function checkDataOceanToLogicToChart(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    const logicalNode = getNodeFromGraphDef(fromId, graphDef);
    let doNode: NodeDef | null = null;
    if (logicalNode) {
        doNode = getFirstParentOfTypeFromGraphDef(logicalNode, graphDef, dataOceanNodes);        
    }
    if (doNode) {
        let isTimeSeries: string | undefined = getProperty(doNode, "timeSeries");
        if (isTimeSeries && !["rvbd_ui_time_chart", "rvbd_ui_correlation_chart", "rvbd_ui_cards"].includes(toType)) {
            return STRINGS.runbookEditor.errors.chartNode.chartNodeSummaryDataParent;
        } else if (!isTimeSeries && ["rvbd_ui_time_chart", "rvbd_ui_correlation_chart"].includes(toType)) {
            return STRINGS.runbookEditor.errors.chartNode.chartNodeTimeSeriesDataParent;
        }
        let objType: string | undefined = getProperty(doNode, "objType");
        if (connectionGraphNodes.includes(toType) && objType !== "network_client_server.traffic") {
            return STRINGS.runbookEditor.errors.chartNode.connectionGraphClientServerDataParent;
        }
    }
    return undefined;
}

/** returns undefined if the two nodes can be connected, a string with the error otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param fromHandle the index of the from handle we are connecting to.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns undefined if the two nodes can be connected, a string with the error otherwise.*/
export function checkConnectionToTag(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, fromHandle: number | undefined, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    const newGraphDef: GraphDef = {...graphDef, nodes: [...graphDef.nodes], edges: [...(graphDef.edges || [])]};
    const newNode: NodeDef = {id: toId, name: "toNode", info: "toNode", type: toType};
    newGraphDef.nodes.push(newNode);
    const newEdge: EdgeDef = {fromNode: fromId, fromPort: fromHandle !== undefined ? fromHandle.toString() : undefined, toNode: toId};
    newGraphDef.edges.push(newEdge);
    const runbookContext = new RunbookContext(newNode, newGraphDef, DataOceanUtils.dataOceanMetaData);
    const nodeContexts: Context[] = runbookContext.getNodeContexts();
    let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
    if (!nodeContext) {
        nodeContext = runbookContext.getTriggerContext();
    }
//    const passThroughNode: NodeDef | null = getNodeFromGraphDef(fromId, graphDef);
//    if (passThroughNode) {
//        const contextNode = getFirstParentOfTypeFromGraphDef(passThroughNode, graphDef, [...dataOceanNodes, ...transformNodes, ...triggerNodes]);
//        if (contextNode) {
//            const runbookContext = new RunbookContext(contextNode, graphDef, DataOceanUtils.dataOceanMetaData);
//            let nodeContext: Context | undefined = runbookContext.getNodeContext(contextNode, graphDef);
//            if (!nodeContext) {
//                nodeContext = runbookContext.getTriggerContext();
//            }
            if (nodeContext) {
                let keys: string[] = nodeContext.keys;
                if (keys) {
                    switch (toSubType) {
                        case "Application":
                            if (hasAnyKey(keys, ["application"])) {
                                return undefined;
                            } else {
                                return STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongApplicationTag;
                            }
                        case "Location":
                            if (hasAnyKey(keys, ["location", "client_location", "server_location"])) {
                                return undefined;
                            } else {
                                return STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongLocationTag;
                            }
                        case "User":
                            if (hasAnyKey(keys, ["network_client"])) {
                                return undefined;
                            } else {
                                return STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongUserTag;
                            }
                    }
                }        
            }
//        }     
//    }
    return STRINGS.runbookEditor.errors.tagNode.tagNodeUnknownLogical;
}

/** returns undefined if the two nodes can be connected, a string with the error otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns undefined if the two nodes can be connected, a string with the error otherwise.*/
export function checkConnectionToSubflow(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>,
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    toWires: any, graphDef: GraphDef
): string | undefined {
    const newGraphDef: GraphDef = {...graphDef, nodes: [...graphDef.nodes], edges: [...(graphDef.edges || [])]};
    const newNode: NodeDef = {id: toId, name: "toNode", info: "toNode", type: toType};
    newGraphDef.nodes.push(newNode);
    const newEdge: EdgeDef = {fromNode: fromId, toNode: toId};
    newGraphDef.edges.push(newEdge);
    const runbookContext = new RunbookContext(newNode, newGraphDef, DataOceanUtils.dataOceanMetaData);
    const nodeContexts: Context[] = runbookContext.getNodeContexts();
    let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
    if (!nodeContext) {
        nodeContext = runbookContext.getTriggerContext();
    }
    if (nodeContext) {
        //let inputType = toWires.in[0].wires[0].type.id; // "";
        let inputKeys = toWires.in[0].wires[0].context.keys || [];
        /*
        if (toProperties) {
            for (const property of toProperties) {
                if (property.key === "in") {
                    inputType = property.value[0].wires[0].type.id;
                    break;
                }
            }
        }
        */
        let keys: string[] = nodeContext.keys;
        if (keys) {
            // this handles the case where there can be multiple inputs.  The PMs asked that 
            // Subflows with no key matches still be visible so we added inputKeys.length === 0
            if (inputKeys.length === 0 || hasAnyKey(keys, inputKeys || [])) {
                return undefined;
            } else {
                return STRINGS.runbookEditor.errors.subflowNode.cannotConnectToSubflow;
            }
            /* this works in the case where there is one input, if you renable this you need to go to createSubflowNodes in the CreateRunbookView and make a change
            switch (inputType) {
                case "application":
                    if (hasAnyKey(keys, ["application"])) {
                        return undefined;
                    } else {
                        return STRINGS.runbookEditor.errors.subflowNode.cannotConnectToSubflow;
                    }
                case "location":
                    if (hasAnyKey(keys, ["location", "client_location", "server_location"])) {
                        return undefined;
                    } else {
                        return STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongLocationTag;
                    }
                case "network_interface":
                    if (hasAnyKey(keys, ["network_interface"])) {
                        return undefined;
                    } else {
                        return STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongUserTag;
                    }
                case "network_device":
                    if (hasAnyKey(keys, ["network_device"])) {
                        return undefined;
                    } else {
                        return STRINGS.runbookEditor.errors.tagNode.tagNodeXformNodeWithWrongUserTag;
                    }
            }
            */
        }        
    }
    return STRINGS.runbookEditor.errors.triggerNode.triggerToSubflow;
}

/** returns undefined if the two nodes can be connected, a string with the error otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns undefined if the two nodes can be connected, a string with the error otherwise.*/
export function checkConnectionFromSubflowInputToDataOcean(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, 
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    const newGraphDef: GraphDef = {...graphDef, nodes: [...graphDef.nodes], edges: [...(graphDef.edges || [])]};
    const newNode: NodeDef = {id: toId, name: "toNode", info: "toNode", type: toType};
    newGraphDef.nodes.push(newNode);
    const newEdge: EdgeDef = {fromNode: fromId, toNode: toId};
    newGraphDef.edges.push(newEdge);
    const runbookContext = new RunbookContext(newNode, newGraphDef, DataOceanUtils.dataOceanMetaData);
    const nodeContexts: Context[] = runbookContext.getNodeContexts();
    let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
    if (!nodeContext) {
        nodeContext = runbookContext.getTriggerContext();
    }
    if (nodeContext) {
        let toObjType: string | undefined = getPropertyFromPropertyObject(toProperties, "objType");
        if (toObjType) {
            const fromKeys: Array<string> = nodeContext.keys;
            if (!DataOceanUtils.hasAnyFilter(toObjType, fromKeys)) {
                return STRINGS.runbookEditor.errors.doNode.noFilterForConnectedNode;
            }
        }        
    }
    return undefined;
}

/** returns undefined if the two nodes can be connected, a string with the error otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param fromHandle the index of the from handle we are connecting to.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns undefined if the two nodes can be connected, a string with the error otherwise.*/
export function checkConnectionFromSubflowToDataOcean(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, fromHandle: number | undefined,
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    const newGraphDef: GraphDef = {...graphDef, nodes: [...graphDef.nodes], edges: [...(graphDef.edges || [])]};
    const newNode: NodeDef = {id: toId, name: "toNode", info: "toNode", type: toType};
    newGraphDef.nodes.push(newNode);
    const newEdge: EdgeDef = {fromNode: fromId, fromPort: fromHandle !== undefined ? fromHandle.toString() : undefined, toNode: toId};
    newGraphDef.edges.push(newEdge);
    const runbookContext = new RunbookContext(newNode, newGraphDef, DataOceanUtils.dataOceanMetaData);
    const nodeContexts: Context[] = runbookContext.getNodeContexts();
    let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
    if (!nodeContext) {
        nodeContext = runbookContext.getTriggerContext();
    }
    if (nodeContext) {
        let toObjType: string | undefined = getPropertyFromPropertyObject(toProperties, "objType");
        if (toObjType) {
            const fromKeys: Array<string> = nodeContext.keys;
            if (!DataOceanUtils.hasAnyFilter(toObjType, fromKeys)) {
                return STRINGS.runbookEditor.errors.doNode.noFilterForConnectedNode;
            }
        }    
    }
    return undefined;
}

/** returns undefined if the two nodes can be connected, a string with the error otherwise.
 *  @param fromType the type of the source node.
 *  @param fromSubType the sub-type of the source node.
 *  @param fromId the id of the source node.
 *  @param fromProperties the properties of the source node.
 *  @param fromHandle the index of the from handle we are connecting to.
 *  @param toType the type of the target node.
 *  @param toSubType the sub-type of the target node.
 *  @param toId the id of the target node.
 *  @param toProperties the properties of the target node.
 *  @param graphDef the GraphDef with the runbook graph structure.
 *  @returns undefined if the two nodes can be connected, a string with the error otherwise.*/
export function checkConnectionFromSubflowToChart(
    fromType: string, fromSubType: string, fromId: string, fromProperties: Array<NodeProperty>, fromHandle: number | undefined,
    toType: string, toSubType: string, toId: string, toProperties: Array<NodeProperty>, 
    graphDef: GraphDef
): string | undefined {
    const newGraphDef: GraphDef = {...graphDef, nodes: [...graphDef.nodes], edges: [...(graphDef.edges || [])]};
    const newNode: NodeDef = {id: toId, name: "toNode", info: "toNode", type: toType};
    newGraphDef.nodes.push(newNode);
    const newEdge: EdgeDef = {fromNode: fromId, fromPort: fromHandle !== undefined ? fromHandle.toString() : undefined, toNode: toId};
    newGraphDef.edges.push(newEdge);
    const runbookContext = new RunbookContext(newNode, newGraphDef, DataOceanUtils.dataOceanMetaData);
    const nodeContexts: Context[] = runbookContext.getNodeContexts();
    let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
    if (!nodeContext) {
        nodeContext = runbookContext.getTriggerContext();
    }
    if (nodeContext) {
        /**************************************************************************************************************************************************************/
        /* How do we know if this produces time series data                                                                                                           */
        /**************************************************************************************************************************************************************/
        let isTimeSeries: boolean | undefined = nodeContext.isTimeseries;
        if (isTimeSeries && !["rvbd_ui_time_chart", "rvbd_ui_correlation_chart", "rvbd_ui_cards"].includes(toType)) {
            return STRINGS.runbookEditor.errors.chartNode.chartNodeSummaryDataParent;
        } else if (!isTimeSeries && ["rvbd_ui_time_chart", "rvbd_ui_correlation_chart"].includes(toType)) {
            return STRINGS.runbookEditor.errors.chartNode.chartNodeTimeSeriesDataParent;
        }
        if (connectionGraphNodes.includes(toType) && nodeContext.keys.includes("network_client_server.traffic")) {
            return STRINGS.runbookEditor.errors.chartNode.connectionGraphClientServerDataParent;
        }
    }
    return undefined;
}

/** returns whether or not the specified object type has any of the specified keys.
 *  @param objType a string with the object type to check.
 *  @param keys a string array with the keys to check for.  If any match then the function should return true.
 *  @returns returns true if any of the keys are used by this object type. */
function hasAnyKey(keysToCheck: string[], keys: Array<string>): boolean {
    if (keys && keysToCheck) {
        for (const queryKey of keysToCheck) {
            if (keys.includes(queryKey)) {
                return true;
            } else if (hasAnySubKey((DataOceanUtils.dataOceanMetaData.keys[queryKey]?.properties || {}), keys)) {
                return true;
            }
        }
    }
    return false;
}

/** returns whether any of the sub keys in the properties object matches any of the specified keys.
 *  @param properties the properties object within a key definition.
 *  @param keys a string array with the keys to check for.  If any match then the function should return true.
 *  @returns a boolean value true if any of the keys in the properties object matches any of the specified keys. */
function hasAnySubKey(properties: Record<string, DataOceanKey> | undefined, keys: Array<string>): boolean {
    if (properties && keys) {
        for (const queryKey in properties) {
            if (keys.includes(queryKey)) {
                return true;
            } else if (hasAnySubKey(properties[queryKey].properties, keys)) {
                return true;
            }
        }
    }
    return false;
}

/** returns the property with the specified key from the array of NodePropertys.
 *  @param properties the array of NodePropertys.
 *  @param key the key whose value is requested.
 *  @returns the value of the specified key. */
export function getPropertyFromPropertyObject(properties: Array<NodeProperty>, key: string): any {
    if (properties) {
        for (const prop of properties) {
            if (prop.key === key) {
                return prop.value;
            }
        }    
    }
    return null;
}
