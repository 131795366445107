import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { loader } from 'graphql.macro';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import {
	Callout,
	Icon,
	Intent,
	Label,
	Switch,
} from '@blueprintjs/core';
import {
	ErrorToaster,
	IconNames,
	SuccessToaster,
} from '@tir-ui/react-components';
import { DATASOURCE_STATUS } from './DataSourceStatus';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';

import { LangEN, STRINGS } from 'app-strings';
import { IntegrationLibraryService } from 'utils/services/IntegrationLibraryApiService';
import { getURL } from 'utils/hooks/useQueryParams';
import { getURLPath } from 'config';
import { PARAM_NAME } from 'components/enums/QueryParams';
import { DEFAULT_TAB_URL_STATE_KEY } from 'components/common/layout/tabbed-sub-pages/TabbedSubPages';
import { Link } from 'react-router-dom';

interface SetAternityDataSourcesInput {
	input: {
		id?: string;
		metricStreamingEnabled: boolean;
		queriesEnabled: boolean;
	};
}

type AternityIntegrationState = 'installed' | 'installedWithErrors' | 'notInstalled';

export default function AternitySaaSView() {
    const apolloClient = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [sourceToggleDisabled, setSourceToggleDisabled] = useState(false);
    const [ingestToggleDisabled, setIngestToggleDisabled] = useState(false);
    const translations: LangEN["DATA_SOURCES"]["aternityConfig"] = STRINGS.DATA_SOURCES.aternityConfig;
    const [aternityIntegrationStatus, setAternityIntegrationState] = useState<AternityIntegrationState>("notInstalled");
    const { loading: loadingData, data, error, } = useQuery(loader("./../queries/aternity-datasource.graphql"), {
    	pollInterval: 1 * 60 * 1000,
    });
    const dataSource = data?.aternityDataSource || {};

    useEffect(() => {
        async function setAternityIntegrationStatus() {
            try {
                setLoading(true);
                const result =
                    await IntegrationLibraryService.getInstalledIntegrations();
                const aternityIntegration = result.find(
                    (el) =>
                        el.integrationId.includes("Aternity") ||
                        el.integrationId.includes("aternity")
                );

                if (!aternityIntegration) {
                    setAternityIntegrationState("notInstalled");

                    return;
                }

                const connectors =
                    await IntegrationLibraryService.getConnectors(
                        aternityIntegration.integrationId
                    );
                const getAllConnectorDetails = connectors.map((connector) =>
                    IntegrationLibraryService.getConnectorDetails(
                        aternityIntegration.integrationId,
                        connector.connectorId
                    )
                );
                const connectorsWithDetails = await Promise.all(
                    getAllConnectorDetails
                );

                // No connectors / No OData connector
                if (!connectorsWithDetails) {
                    setAternityIntegrationState("installedWithErrors");

                    return;
                }

                const hasODataConnector = connectorsWithDetails.find(
                    (connector) => {
                        if (!connector?.properties) {
                            return false;
                        }

                        const domain = connector.properties.find(
                            (prop) => prop.name === "domain"
                        );

                        if (!domain || typeof domain?.value === "boolean") {
                            return false;
                        }

                        // odata case insensitive in connector domain property
                        const regex = /(?=.*-odata)/i;

                        return regex.test(domain.value);
                    }
                );

                if (!hasODataConnector) {
                    setAternityIntegrationState("installedWithErrors");

                    return;
                }

                setAternityIntegrationState("installed");
            } catch (error) {
                console.error(error);
                ErrorToaster({ message: translations.apiError });
            } finally {
                setLoading(false);
            }
        }

        setAternityIntegrationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [setAternityDataSource] = useMutation<
        any,
        SetAternityDataSourcesInput
    >(loader("./../queries/set-aternity-datasource-mutation.graphql"), {
        onCompleted: (data) => {
            SuccessToaster({
                message: translations.queryResponse.success,
            });
        },
        onError: (err) => {
            ErrorToaster({
                message: translations.queryResponse.error,
            });
            console.error(err?.message);
        },
    });

    function renderAternityIntegrationStatus() {
        const aternityPageURL = getURL(getURLPath("integrations"), {
            [PARAM_NAME.searchText]: "Aternity",
            [DEFAULT_TAB_URL_STATE_KEY]:
                aternityIntegrationStatus === "notInstalled"
                    ? "available"
                    : "installed",
        });

        if (loading) {
            return <></>;
        }

        // Aternity is missing as an installed integration
        if (aternityIntegrationStatus === "notInstalled") {
            return (
                <Callout className="row" intent={Intent.DANGER}>
                    <p>
                        {translations.statusMessage.notInstalled}{" "}
                        <Link to={aternityPageURL}>
                            {translations.statusMessage.linkText}
                        </Link>{" "}
                        {translations.statusMessage.toInstall}
                    </p>
                </Callout>
            );
        }

        // Aternity is installed, but it does not have an odata connector
        if (aternityIntegrationStatus === "installedWithErrors") {
            return (
                <Callout className="row" intent={Intent.DANGER}>
                    <p>
                        {translations.statusMessage.installedWithErrors}{" "}
                        <Link to={aternityPageURL}>
                            {translations.statusMessage.linkText}
                        </Link>{" "}
                        {translations.statusMessage.toConfigure}
                    </p>
                </Callout>
            );
        }

        // Aternity is installed and it has OData connector
        return (
            <div className="row">
                <p>
                    {translations.statusMessage.installed}{" "}
                    <Link to={aternityPageURL}>
                        {translations.statusMessage.linkText}
                    </Link>{" "}
                    {translations.statusMessage.toConfigureConnectors}
                </p>
            </div>
        );
    }

    /**
     * Render the data source status
     *
     * @returns {JSX.Element}
     */
    function renderDataSourceStatus() {
		/**
		 * Render query status
		 * 
		 * @returns {JSX.Element}
		 */
        function renderQueryStatus() {
            return (
                <>
                    {dataSource?.info?.status ===
                    DATASOURCE_STATUS.OK.toUpperCase() ? (
                        <div className="col-auto">
                            <Icon
                                intent="success"
                                icon={IconNames.TICK_CIRCLE}
                            />
                        </div>
                    ) : dataSource?.info?.status ===
                      DATASOURCE_STATUS.DISABLED.toUpperCase() ? (
                        <div className="col-auto">
                            <Icon
                                intent={Intent.DANGER}
                                icon={IconNames.DISABLE}
                            />
                        </div>
                    ) : (
                        <div className="col-8 d-inline">
                            <Icon intent="danger" icon={IconNames.ERROR} />
                            {dataSource?.info?.status === "DISABLED" && (
                                <span className="ml-2" data-testid="integrationStatus">
                                    {
                                       translations.status.disabled
                                    }
                                </span>
                            )}
                            {dataSource?.info?.status === "FAILED" &&
                                dataSource?.info?.error?.details.map(
                                    (error, i) => (
                                        renderApiError(i, error)
                                    )
                                )}
                        </div>
                    )}
                </>
            );
        }

		/**
		 * Render the api error code as a callout
		 * 
		 * @param i - error index
		 * @param error - error details
		 * 
		 * @returns {JSX.Element}
		 */
		function renderApiError(i: any, error: any) {
			return <Callout
				key={i}
				intent={Intent.WARNING}
				icon={null}
				className="my-3"
			>
				<div
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(
							STRINGS.formatString(
								STRINGS
									.runbookOutput
									.errorsAndWarnings[!error.code ||
										!Object.keys(
											STRINGS
												.runbookOutput
												.errorsAndWarnings
										).includes(
											error.code
										)
									? "GENERAL_ERROR"
									: error?.code],
								Object.fromEntries(
									[dataSource?.info?.error?.innerError?.properties.map(
										(x) => [
											x.key,
											x.value,
										]
									),
                                    ...error?.innerError?.properties.map(
										(x) => [
											x.key,
											x.value,
										]
                                    )]
								)
							)
						),
					}} />
			</Callout>;
		}

        return (
            <div className="row">
                <Label className="mr-3 font-weight-bold">
                    {translations.labels.status}
                </Label>
                {aternityIntegrationStatus === "notInstalled" && (
                    <div className="col-8 d-inline">
                        <Icon intent="danger" icon={IconNames.ERROR} />

                        <span className="ml-2" data-testid="integrationStatus">
                            {
                                translations.status.notInstalled
                            }
                        </span>
                    </div>
                )}
				{aternityIntegrationStatus === "installedWithErrors" && (
                    <div className="col-8 d-inline">
                        <Icon intent="danger" icon={IconNames.ERROR} />

                        <span className="ml-2" data-testid="integrationStatus">
                            {
                                translations.status.noConnector
                            }
                        </span>
                    </div>
                )}
                {aternityIntegrationStatus === "installed" &&
                    renderQueryStatus()}
            </div>
        );

    }



    function renderLastReceivedDate(lastUpdatedTime: null | number) {
        if (!lastUpdatedTime) {
            return <div className="col-auto">
                <span>{translations.labels.lastReceivedNull}</span>
            </div>
        }

        return <div className="col-auto d-flex flex-column" style={{ gap: '4px' }}>
            <span>
                {moment
                    .utc(
                        lastUpdatedTime *
                        1000
                    )
                    .local()
                    .format("lll")}
            </span>
            <span className="display-9">
                (
                {moment
                    .utc(
                        lastUpdatedTime *
                        1000
                    )
                    .local()
                    .fromNow()}
                )
            </span>
        </div>;
    }

    return (
        <DataLoadFacade
            loading={loadingData || loading}
            data={data}
            error={error}
            key="categories"
            showContentsWhenLoading={true}
            className={"basic"}
        >
            <div className="aternity-config bg-white my-3 p-3 h-min-5">
                {
                    <div className="container-fluid">
                        {renderAternityIntegrationStatus()}
                        <div className="row mt-4">
							<Label className="mr-3"> { translations.labels.enableDataSource } </Label>
							<Switch
								className="d-inline ml-1"
								labelElement={dataSource.queriesEnabled ? translations.labels.toggleOn : translations.labels.toggleOff}
								checked={dataSource.queriesEnabled}
                                disabled={sourceToggleDisabled || aternityIntegrationStatus !== "installed"}
								onChange={async (e) => {
                                    try {
                                        setSourceToggleDisabled(true);
                                        await setAternityDataSource({
                                            variables: {
                                                input: {
                                                    metricStreamingEnabled: dataSource.metricStreamingEnabled,
                                                    queriesEnabled: !dataSource.queriesEnabled,
                                                },
                                            },
                                        });
                                        await apolloClient.refetchQueries({
                                            include: 'active',
                                        });
                                    } catch (error) {
                                        ErrorToaster({ message: translations.toggleError })
                                    } finally {
                                        setSourceToggleDisabled(false);
                                    }
								}}
							/>
						</div>
						<div className="row">
							<Label className="mr-3">
								{
									translations.labels.enableDataIngest
								}
							</Label>
							<Switch
								className="d-inline ml-1"
								labelElement={dataSource.metricStreamingEnabled ? translations.labels.toggleOn : translations.labels.toggleOff}
								checked={dataSource.metricStreamingEnabled}
                                disabled={ingestToggleDisabled || aternityIntegrationStatus !== "installed"}
								onChange={async (e) => {
                                    try {
                                        setIngestToggleDisabled(true);
                                        await setAternityDataSource({
                                            variables: {
                                                input: {
                                                    metricStreamingEnabled: !dataSource.metricStreamingEnabled,
                                                    queriesEnabled: dataSource.queriesEnabled,
                                                },
                                            },
                                        });
                                        await apolloClient.refetchQueries({
                                            include: 'active',
                                        });
                                    } catch (error) {
                                        ErrorToaster({ message: translations.toggleError })
                                    } finally {
                                        setIngestToggleDisabled(false);
                                    }
								}}
							/>
						</div>
                        {renderDataSourceStatus()}
                        <div className="row">
                            <Label className="mr-3 font-weight-bold">
                                {
                                    translations.labels.lastReceived
                                }
                            </Label>
                            {renderLastReceivedDate(dataSource.lastIngestedEndTime)}
                        </div>
                    </div>
                }
            </div>
        </DataLoadFacade>
    );
}
