/** This file defines the subflow input editor React component.  The subflow input editor allows you 
 *  to define the inputs that the subflow is expecting.
 *  @module */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { HELP, STRINGS } from "app-strings";
import { Callout, Intent, HTMLSelect, Radio, RadioGroup, InputGroup, Button, MenuItem } from "@blueprintjs/core";
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import { OutputDataBlock } from "../transform/OutputDataBlock";
import { UniversalNode } from "../../UniversalNode";
import { useStateSafePromise } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { OutputDataElementDefinition, OutputDataValueType } from "../transform/OutputDataElement";
import { generateRandomID } from "components/common/condition-tree-builder/condition/ConditionUtils";
import { RunbookContextSummary } from "../RunbookContextSummary";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil";
import { DataOceanMetadata } from "../data-ocean/DataOceanMetadata.type";
import { SHOW_CONTEXT } from "components/enums/QueryParams";
import { DataOceanUtils } from "../data-ocean/DataOceanUtils";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { SUBFLOW_NODE_EDIT_PROPS, SubflowInputNodeUtils } from "./SubflowInputNodeUtils";
import { InputType } from "../../types/GraphTypes";
import { MultiSelectInput } from "components/common/multiselect/MultiSelectInput";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { RunbookContextUtils } from "utils/runbooks/RunbookContextUtils.class";
import { RUNTIME_SCOPE, PrimitiveVariableType, StructuredVariableType } from "utils/runbooks/VariablesUtils";
import { VariableContext } from "utils/runbooks/VariableContext";
import { Variant } from '../../../graph/types/GraphTypes';
import { ON_DEMAND_INPUT_NODE_EDIT_PROPS, OnDemandInputNodeUtils } from "../on-demand-input/OnDemandInputNodeUtils";
import { AnchorButton } from "@blueprintjs/core";
import { getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import { RunbookIntegrationDetails } from "pages/integrations/types/IntegrationTypes";
import { APP_ICONS } from 'components/sdwan/enums';
import { Icon, IconNames } from '@tir-ui/react-components';
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp";
import _ from 'lodash';
import "./SubflowInputNodeEditor.scss";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface InputNodeProperties {
    /** a string with the type of trigger, such as network_interface or network_device. */
    triggerType?: string;
    /** an enum with the output data format which is one of summarized or timeseries. */
    outputDataFormat?: OutputDataFormats;
    /** the array of OutputDataFields with the list of properties. */
    outputDataProperties?: OutputDataField[];
    /** the array of OutputDataFields with the list of metrics. */
    outputDataMetrics?: OutputDataField[];
    /** the array of input variables. */
    inputVariables?: string[];
    /** the array of output variables. */
    outputVariables?: string[];
    /** a String with the optional integration id. */
    integrationId?: string;
    /** an object containing the lists of static values for subflow runtime variables. */
    staticInputValuesLists?: any;
    /** an array containing descriptions for context values defined inside the subflow input node editor. */
    inputOrOutputValuesDescriptions?: any;
}

/** an enum that specifies the possible output formats. */
export enum OutputDataFormats {
    /** the enumerated value for summary or average data values. */
    SUMMARIZED = "summary",
    /** the enumerated value for timeseries data values. */
    TIMESERIES = "timeseries",
}

/** this enum defines the possible update event types that might be fired by the output data block and output data element. */
export enum UpdateEventType {
    /** the enumerated value for a name change. */
    NAME_CHANGED = "NAME_CHANGED",
    /** the enumerated value for a value type change. */
    VALUE_TYPE_CHANGED = "VALUE_TYPE_CHANGED",
    /** the enumerated value for a unit change. */
    UNIT_CHANGED = "UNIT_CHANGED",
    /** the enumerated value for the selection of a default (one of the existing DO ids) key or metric. */
    DEFAULT_SELECTED = "DEFAULT_SELECTED",
    /** the enumerated value for the deletion of a key or metric. */
    DELETED = "DELETED",
}

/** this enum defines the possible element types, which are keys (aka properties) and metrics. */
export enum ElementType {
    /** the enumerated value for a property element. */
    PROPERTY = "Property",
    /** the enumerated value for a metric element. */
    METRIC = "Metric"
}

/** this enum defines the possible section types, which are keys (aka properties) and metrics. */
export enum SectionType {
    /** the enumerated value for a property section. */
    PROPERTY = "PROPERTIES",
    /** the enumerated value for a metric section. */
    METRIC = "METRICS"
}

/** Describe UpdateEvent, triggered when user edits one of the fields */
export interface UpdateEvent {
    /** a string with the unique id for the element. */
    uniqueId: string | undefined;
    /** a boolean value true if the id is a default DO id and false or undefined otherwise. */
    isDoId?: boolean | undefined;
    /** the id for the elemement. */
    id: string;
    /** the value for the update. */
    value: string;
    /** the type of update. */
    type: UpdateEventType;
    /** the element type for the update. */
    element?: ElementType
}

/** Interface that describes Output Data */
export interface OutputDataField {
    /** the id of the key or metric. */
    id: string;
    /** the display label for the key or metric. */
    label: string;
    /** the type of value: string, float, integer, ipaddr, etc. */
    type: string;
    /** a string with the unit. */
    unit: string;
    /** a string with the id of one of the data ocean keys or metrics. */
    dataOceanId?: string;
}

/** Component for editing the properties of a subflow input node.
 *  @param selectedNode - Currently selected active subflow input node.
 *  @param libraryNode- Selected transform node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph.
 *  @param variant - The runbook variant that is being edited.
 *  @returns a JSX component with the subflow input node editor. */
export const SubflowInputNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, variant }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [executeSafely] = useStateSafePromise();
    const {getVariables} = useContext(VariableContext);

    const variables: VariableContextByScope = {
        runtime: getVariables(RUNTIME_SCOPE, false),
        incident: {primitiveVariables: [], structuredVariables: []},
        global: {primitiveVariables: [], structuredVariables: []}
    };

    const hasConnector: boolean = variables.runtime.primitiveVariables.find(variable => variable.type === PrimitiveVariableType.CONNECTOR) !== undefined;

    const primitiveVariables: any[] = variables.runtime.primitiveVariables.map((variable) => {
        return {display: variable.name, value: variable.name, type: variable.type };
    });
    const structuredVariables: any[] = variables.runtime.structuredVariables.map((variable) => {
        return {display: variable.name, value: variable.name, type: variable.type };
    });
    const variablesForMultiSelect: any[] = primitiveVariables.concat(structuredVariables);

    const NODE_EDIT_PROPS = variant === Variant.ON_DEMAND ? ON_DEMAND_INPUT_NODE_EDIT_PROPS : SUBFLOW_NODE_EDIT_PROPS;
    const inputType = variant === Variant.ON_DEMAND ? InputType.ON_DEMAND : InputType.SUBFLOW;

    const componentStates: InputNodeProperties = {
        triggerType: selectedNode?.getProperty(NODE_EDIT_PROPS.TRIGGER_TYPE) ? selectedNode?.getProperty(NODE_EDIT_PROPS.TRIGGER_TYPE) : inputType,
        outputDataFormat: selectedNode?.getProperty(NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT) as OutputDataFormats,
        outputDataMetrics: (selectedNode?.getProperty(NODE_EDIT_PROPS.SYNTH_METRICS) as Array<OutputDataField>)?.map(metric => { return { ...metric, id: generateRandomID() }; }),
        outputDataProperties: (selectedNode?.getProperty(NODE_EDIT_PROPS.SYNTH_KEYS) as Array<OutputDataField>)?.map(key => { return { ...key, id: generateRandomID() }; }),
        inputVariables: selectedNode?.getProperty(NODE_EDIT_PROPS.INCOMING_VARIABLES) ? selectedNode?.getProperty(NODE_EDIT_PROPS.INCOMING_VARIABLES)/*.map(variable => variable.name)*/ : [],
        ...(variant === Variant.SUBFLOW && { outputVariables: selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES)/*.map(variable => variable.name)*/ : []}),
        ...(variant === Variant.SUBFLOW && { staticInputValuesLists: selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS) : {} }),
        ...(variant === Variant.SUBFLOW && { inputOrOutputValuesDescriptions: selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS) : [] }),
        integrationId: undefined
    };
    const integrationIds = selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS) : {};
    if (integrationIds) {
        for (const key in integrationIds) {
            const value = integrationIds[key];
            if (value) {
                // Right now we only permit one integration so if we found a value that is the integration id, no need to go further
                componentStates.integrationId = value;
                break;
            }
        }
    }

    // Component states
    const [curProperties, setCurProperties] = useState<InputNodeProperties>(componentStates);

    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: true, title: STRINGS.viewRunbooks.inputDialogTitle, dialogContent: null, dialogFooter: null});
    const [contextValuesDescriptionsDialogState, setContextValuesDescriptionsDialogState] = useState<any>({showDialog: false, loading: false, title: "", dialogContent: null, dialogFooter: null});
    const [inputOrOutputValuesDescriptions, setInputOrOutputValuesDescriptions] = useState<Array<{ valueName: string, description: string }>>(curProperties.inputOrOutputValuesDescriptions);
    
    // State variables used by the static value list feature
    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
    const [staticInputValuesLists, setStaticInputValuesLists] = useState(curProperties.staticInputValuesLists);
    const [newLabels, setNewLabels] = useState({});
    const [newValues, setNewValues] = useState({});
    const [staticValueAdded, setStaticValueAdded] = useState({});
    const [isChangeValue, setIsChangeValue] = useState<string[]>([]);
    const [valuesForEdit, setValuesForEdit] = useState<Array<{name: string, index: number}>>([]);

    // State variables used by the subflow variables descriptions feature
    const [textareaValues, setTextareaValues] = useState({});

    useEffect(() => {
        const textareaValues = {};
        if (Array.isArray(inputOrOutputValuesDescriptions)) {
            inputOrOutputValuesDescriptions.forEach((item, _index) => {
                textareaValues[item.valueName] = item.description;
            });
        }
        setTextareaValues(textareaValues);
    }, [inputOrOutputValuesDescriptions]);

    useEffect(() => {
        curProperties.staticInputValuesLists = staticInputValuesLists;
    }, [curProperties, staticInputValuesLists]);

    useEffect(() => {
        curProperties.inputOrOutputValuesDescriptions = inputOrOutputValuesDescriptions;
    }, [curProperties, inputOrOutputValuesDescriptions]);

    const handleLabelChange = (variableName, value) => {
        setNewLabels({
            ...newLabels,
            [variableName]: value,
        });
    };

    const handleValueChange = (variableName, value) => {
        setNewValues({
            ...newValues,
            [variableName]: value,
        });
    };

    const exitOptionEditMode = (variableName) => {
        const newLabel = newLabels[variableName]?.trim();
        const newValue = newValues[variableName]?.trim();
        if (newLabel && newValue) {
            setNewLabels({
                ...newLabels,
                [variableName]: "",
            });
            setNewValues({
                ...newValues,
                [variableName]: "",
            });
        }
        setStaticValueAdded({
            ...staticValueAdded,
            [variableName]: staticValueAdded[variableName] !== undefined ? staticValueAdded[variableName]+1 : 0,
        });
        setIsChangeValue(isChangeValue.filter((item) => item !== variableName));
        setValuesForEdit(
            valuesForEdit.filter((item) => item.name !== variableName),
        );
    };

    const handleAddOption = (variableName) => {
        const newLabel = newLabels[variableName]?.trim();
        const newValue = newValues[variableName]?.trim();
        if (newLabel && newValue) {
            const existingValue = valuesForEdit
                ? valuesForEdit.find((item) => item.name === variableName)
                : null;
            const existingValueIndex = existingValue
                ? existingValue.index
                : staticInputValuesLists?.[variableName]?.findIndex(
                      (item) => item.label === newLabel,
                  );
            if (existingValueIndex > -1) {
                staticInputValuesLists[variableName][existingValueIndex].display =
                    `${newLabel} | ${newValue}`;
                staticInputValuesLists[variableName][existingValueIndex].label =
                    newLabel;
                staticInputValuesLists[variableName][existingValueIndex].value =
                    newValue;
                setStaticInputValuesLists({
                    ...staticInputValuesLists,
                    [variableName]: [...staticInputValuesLists[variableName]],
                });
            } else {
                const newOptionObject = {
                    display: `${newLabel} | ${newValue}`,
                    label: newLabel,
                    value: newValue,
                    type: "string",
                };
                setStaticInputValuesLists({
                    ...staticInputValuesLists,
                    [variableName]: [
                        ...(staticInputValuesLists[variableName] || []),
                        newOptionObject,
                    ],
                });
            }
        }
        exitOptionEditMode(variableName);
    };

    const renderSuggestItem: ItemRenderer<{ label: string; value: number }> = (item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item.value}
                onClick={handleClick}
                text={item.label}
            />
        );
    };
    
    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                let metaData: DataOceanMetadata = response;
                if (metaData?.keys) {
                    const onDemandRunbooksSupportedEntities = ["network_interface", "network_device", "application", "location", "network_host", "network_client", "network_server", "client_location", "server_location"];
                    metaData = {...metaData, keys: {...metaData.keys}};
                    for (const [key] of Object.entries(response.keys) as any) {
                        if (variant === Variant.ON_DEMAND && !onDemandRunbooksSupportedEntities.includes(key)) {
                            delete metaData.keys[key];
                        }
                    }
                }
                setObjMetricMetaData(metaData);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely, variant]
    );

    const [keyDefinitions, setKeyDefinitions] = useState<Array<OutputDataElementDefinition>>(curProperties?.outputDataProperties?.map((keyDef) => {
        return { id: keyDef.id, label: keyDef.label, type: keyDef.type, unit: keyDef.unit, dataOceanId: keyDef.dataOceanId }
    }) || []);

    /* Used in the metric definition which is now commented out
    const [metricDefinitions, setMetricDefinitions] = useState<Array<OutputDataElementDefinition>>(curProperties?.outputDataMetrics?.map((metricDef) => {
        return { id: metricDef.id, label: metricDef.label, type: metricDef.type, unit: metricDef.unit, dataOceanId: metricDef.dataOceanId }
    }) || []);
    */
    
    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    const [integrations, setIntegrations] = useState<RunbookIntegrationDetails[] | undefined>(undefined);
    useEffect(() => {
        const integrationsPromise = new Promise<RunbookIntegrationDetails[]>(async (resolve, reject) => {
            try {
                const newIntegrations = await IntegrationLibraryService.getRunbookIntegrations();

                resolve(newIntegrations as RunbookIntegrationDetails[]);    
            } catch (error) {
                reject(error);
            }
        });
        executeSafely(integrationsPromise).then(
            (integrations) => {
                setIntegrations(integrations);
            }, 
            () => {
                setIntegrations([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Partially update the state of the current properties
     * @param partialProps -  transform node properties that need to be updated
     */
    function syncProperties(partialProps: InputNodeProperties): void {
        const updatedProperties = { ...curProperties, ...partialProps };
        setCurProperties(updatedProperties);
    }

    /**
     * Update the selected node properties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being editied
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: InputNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }
        selectedNode.setProperty(NODE_EDIT_PROPS.TRIGGER_TYPE, curProperties?.triggerType);
        selectedNode.setProperty(NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT, curProperties?.outputDataFormat);

        const synthMetrics = curProperties?.outputDataMetrics;
        synthMetrics?.forEach((metric) => {
            metric.id = metric.dataOceanId ? metric.dataOceanId : metric.label.replace(/ /g, "_");
        });
        selectedNode.setProperty(NODE_EDIT_PROPS.SYNTH_METRICS, synthMetrics);

        const synthKeys = curProperties?.outputDataProperties;
        synthKeys?.forEach((key) => {
            key.id = key.dataOceanId ? key.dataOceanId : key.label.replace(/ /g, "_");
        });
        selectedNode.setProperty(NODE_EDIT_PROPS.SYNTH_KEYS, synthKeys);

        const inputVariables = curProperties?.inputVariables || [];
        selectedNode.setProperty(NODE_EDIT_PROPS.INCOMING_VARIABLES, inputVariables);
/* This is if we want to include the full variable def in the properties, I think we only need the name
        selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INCOMING_VARIABLES, inputVariables.map((variable) => {
            for (const primitiveVariable of variables.runtime.primitiveVariables) {
                if (primitiveVariable.name === variable) {
                    return {name: primitiveVariable.name, type: primitiveVariable.type, unit: primitiveVariable.unit};
                }
            }
            for (const structuredVariable of variables.runtime.structuredVariables) {
                if (structuredVariable.name === variable) {
                    return {name: structuredVariable.name, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries};
                }
            }
            return {name: variable};
        }));
*/
        if (variant === Variant.SUBFLOW) {
            const outputVariables: string[] = curProperties?.outputVariables || [];
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES, outputVariables);

            const inputVariables: string[] = curProperties?.inputVariables || [];
            const integrationId = curProperties?.integrationId;
            let integrationIds = {};
            if (integrationId && inputVariables) {
                for (const variableName of inputVariables) {
                    for (const variable of variables.runtime.primitiveVariables) {
                        if (variableName === variable.name && variable.type === PrimitiveVariableType.CONNECTOR) {
                            integrationIds[variable.name] = integrationId;
                            break;
                        }    
                    }
                }
            }
            const staticInputValuesLists = curProperties?.staticInputValuesLists || {};
            const inputOrOutputValuesDescriptions = curProperties?.inputOrOutputValuesDescriptions || [];
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS, staticInputValuesLists);
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS, inputOrOutputValuesDescriptions);
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS, integrationIds);    
        }
/* This is if we want to include the full variable def in the properties, I think we only need the name
        selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES, outputVariables.map((variable) => {
            for (const primitiveVariable of variables.runtime.primitiveVariables) {
                if (primitiveVariable.name === variable) {
                    return {name: primitiveVariable.name, type: primitiveVariable.type, unit: primitiveVariable.unit};
                }
            }
            for (const structuredVariable of variables.runtime.structuredVariables) {
                if (structuredVariable.name === variable) {
                    return {name: structuredVariable.name, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries};
                }
            }
            return {name: variable};
        }));
*/
    }

    /** istanbul ignore next */
    function getNodeProperties(properties: InputNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        const outputProperties = {};
        outputProperties[NODE_EDIT_PROPS.TRIGGER_TYPE] = curProperties?.triggerType;

        let synthMetrics: Array<OutputDataField> = curProperties?.outputDataMetrics || [];
        synthMetrics = synthMetrics?.map((metric) => {
            return { ...metric, id: metric.dataOceanId ? metric.dataOceanId : metric.label.replace(/ /g, "_") };
        });

        let synthKeys: Array<OutputDataField> = curProperties?.outputDataProperties || [];
        synthKeys = synthKeys?.map((key) => {
            return { ...key, id: key.dataOceanId ? key.dataOceanId : key.label.replace(/ /g, "_") };
        });

        outputProperties[NODE_EDIT_PROPS.SYNTH_METRICS] = synthMetrics;
        outputProperties[NODE_EDIT_PROPS.SYNTH_KEYS] = synthKeys;

        outputProperties[NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT] = curProperties?.outputDataFormat;

        outputProperties[NODE_EDIT_PROPS.INCOMING_VARIABLES] = curProperties?.inputVariables;
        if (variant === Variant.SUBFLOW) {
            const outputVariables: string[] | undefined = curProperties?.outputVariables;
            outputProperties[SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES] = outputVariables;
            outputProperties[SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS] = curProperties?.staticInputValuesLists;
            outputProperties[SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS] = curProperties?.inputOrOutputValuesDescriptions;
            const inputVariables: string[] = curProperties?.inputVariables || [];
            const integrationId = curProperties?.integrationId;
            let integrationIds: any = undefined;
            if (integrationId && inputVariables) {
                integrationIds = {};
                for (const variableName of inputVariables) {
                    for (const variable of variables.runtime.primitiveVariables) {
                        if (variableName === variable.name && variable.type === PrimitiveVariableType.CONNECTOR) {
                            integrationIds[variable.name] = integrationId;
                            break;
                        }    
                    }
                }
            }
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS, integrationIds);    
        }

        return outputProperties;
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            if (variant === Variant.SUBFLOW) {
                SubflowInputNodeUtils.validateNodeProperties(
                    curProperties,
                    errorMessages,
                );
            } else {
                OnDemandInputNodeUtils.validateNodeProperties(
                    curProperties,
                    errorMessages,
                );
            }
            return errorMessages;
        }
    };

    function handleOutputDataKeyChange(event: UpdateEvent) {
        let synthKeys: Array<OutputDataField> = curProperties?.outputDataProperties || [];
        synthKeys = ([] as Array<OutputDataField>).concat(synthKeys);
        const uniqueId = event.uniqueId;
        //const id = event.id;
        const type = event.type;
        const value = event.value;

        let keyDef: any = synthKeys.find((key) => key.id === uniqueId);
        if (!keyDef) {
            // We are defining a new metric
            keyDef = {
                id: uniqueId,
                label: '',
                type: Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0],
                unit: ''
            };
            synthKeys.push(keyDef);
        }

        switch (type) {
            case UpdateEventType.NAME_CHANGED:
                //metricDef.id = value.replace(/ /g, "_");
                keyDef.label = value;
                if (keyDef.dataOceanId) {
                    keyDef.type = Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0];
                    keyDef.unit = Unit.BASE_UNITS[0];
                }
                delete keyDef.dataOceanId;
                delete keyDef.dataOceanKeys;
                break;
            case UpdateEventType.UNIT_CHANGED:
                keyDef.unit = value;
                break;
            case UpdateEventType.VALUE_TYPE_CHANGED:
                keyDef.type = value;
                break;
            case UpdateEventType.DEFAULT_SELECTED:
                keyDef.dataOceanId = event.isDoId ? event.id : null;
                keyDef.label = event.value;
                keyDef.type = "object";
                let expandedKeys: Array<GenericKey> = [];
                const keyId = keyDef.dataOceanId;
                const doKeyDef = objMetricMetaData?.keys[keyId];
                if (doKeyDef) {
                    NodeUtils.expandKeyProperties(doKeyDef, keyId, expandedKeys);
                }
                keyDef.dataOceanKeys = expandedKeys;
                break;
            case UpdateEventType.DELETED:
                for (let i = 0; i < synthKeys.length; i++) {
                    if (synthKeys[i].id === uniqueId) {
                        synthKeys.splice(i, 1);
                        break;
                    }
                }
                break;
        }

        syncProperties({ outputDataProperties: synthKeys });
        setKeyDefinitions(synthKeys);
    }

    /* Used in the metric definition which is now commented out
    function handleOutputDataMetricChange(event: UpdateEvent) {
        let synthMetrics: Array<OutputDataField> = curProperties?.outputDataMetrics || [];
        synthMetrics = ([] as Array<OutputDataField>).concat(synthMetrics);
        const uniqueId = event.uniqueId;
        //const id = event.id;
        const type = event.type;
        const value = event.value;

        let metricDef: any = synthMetrics.find((metric) => metric.id === uniqueId);
        if (!metricDef) {
            // We are defining a new metric
            metricDef = {
                id: uniqueId,
                label: '',
                type: Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0],
                unit: ''
            };
            synthMetrics.push(metricDef);
        }

        switch (type) {
            case UpdateEventType.NAME_CHANGED:
                //metricDef.id = value.replace(/ /g, "_");
                metricDef.label = value;
                if (metricDef.dataOceanId) {
                    metricDef.type = Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0];
                    metricDef.unit = Unit.BASE_UNITS[0];
                }
                delete metricDef.dataOceanId;
                break;
            case UpdateEventType.UNIT_CHANGED:
                metricDef.unit = value;
                break;
            case UpdateEventType.VALUE_TYPE_CHANGED:
                metricDef.type = value;
                break;
            case UpdateEventType.DEFAULT_SELECTED:
                metricDef.dataOceanId = event.isDoId ? event.id : null;
                metricDef.label = event.value;
                metricDef.type = objMetricMetaData?.metrics[event.id].type;
                metricDef.unit = objMetricMetaData?.metrics[event.id].unit;
                break;
            case UpdateEventType.DELETED:
                for (let i = 0; i < synthMetrics.length; i++) {
                    if (synthMetrics[i].id === uniqueId) {
                        synthMetrics.splice(i, 1);
                        break;
                    }
                }
                break;
        }
        syncProperties({ outputDataMetrics: synthMetrics });
        setMetricDefinitions(synthMetrics);
    }
    */

    const descriptions = {
        startingEntityDesc: STRINGS.runbookEditor.nodeEditor.subflowInputStartingEntityDesc,
        contextValuesReceivedFromCallerDesc: STRINGS.runbookEditor.nodeEditor.subflowInputStartingEntityDesc
    }

    if (variant === Variant.ON_DEMAND) {
        descriptions.startingEntityDesc = STRINGS.runbookEditor.nodeEditor.onDemandInputStartingEntityDesc;
        descriptions.contextValuesReceivedFromCallerDesc = STRINGS.runbookEditor.nodeEditor.onDemandInputToCallerContextDesc;
    }

    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData || !integrations) {
        return <tr><td colSpan={2}><DataLoadFacade loading /></td></tr>;
    }

    const connectorOptions = [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectConnectorText, value: ""}].concat(
        integrations.map((integration: RunbookIntegrationDetails) => {
            return {label: integration.name, value: integration.id};
        })
    );

    return (
        <>
            <BasicDialog dialogState={dialogState} className="transform-template-dialog" onClose={() => {
                setDialogState(updateDialogState(dialogState, false, false, []));
            }} />
            <BasicDialog dialogState={contextValuesDescriptionsDialogState} className="edit-descriptions-dialog" onClose={() => {
                setContextValuesDescriptionsDialogState(updateDialogState(contextValuesDescriptionsDialogState, false, false, []));
            }} />
            <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>
                <InlineHelp 
                    helpMapping={
                        HELP.RunbookNodeCategory[variant === Variant.ON_DEMAND ? "Trigger" : "SubflowInput"][variant === Variant.ON_DEMAND ? "InputNode" : "SubflowInputNode"].StartingEntity
                    }
                >
                    {STRINGS.runbookEditor.nodeEditor.subflowInputStartingEntity}
                </InlineHelp>
            </td></tr>
            <tr><td className="display-8 pt-3 pb-3" colSpan={2}>
                <Callout intent={Intent.PRIMARY}>{descriptions.startingEntityDesc}</Callout>
            </td></tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <OutputDataBlock key={keyDefinitions.length + generateRandomID()}
                        sectionLabel={STRINGS.runbookEditor.nodeEditor.inputDataSection}
                        sectionName={SectionType.PROPERTY}
                        elementName={ElementType.PROPERTY}
                        suggestionsList={RunbookContextUtils.getAvailableKeysForKeyMap(objMetricMetaData.keys, [], getTypes())}
                        onChange={(e: UpdateEvent) => { handleOutputDataKeyChange(e) }}
                        definitions={keyDefinitions}
                        loadFromVar={false}
                        isSubflow={true}
                        isOnDemandNodeEditor={variant === Variant.ON_DEMAND ? true : false}
                    />
                    {/* not supporting metrics right now <OutputDataBlock key={metricDefinitions.length + generateRandomID()}
                        sectionLabel={STRINGS.runbookEditor.nodeEditor.inputDataSection}
                        sectionName={SectionType.METRIC}
                        elementName={ElementType.METRIC}
                        suggestionsList={objMetricMetaData.metrics as Object}
                        onChange={(e: UpdateEvent) => { handleOutputDataMetricChange(e) }}
                        definitions={metricDefinitions}
                        loadFromVar={false}
                    />*/}
                </td>
            </tr>
            <tr className={variant === Variant.ON_DEMAND ? "d-none" : ""}>
                <td colSpan={2}>
                    <div className="d-flex flex-row pt-3">
                        <label className="mb-0">{STRINGS.runbookEditor.nodeEditor.inputDataFormat}</label>
                        <RadioGroup
                            name="outputDataFormat"
                            disabled={false}
                            onChange={e => {
                                const updatedPartialProperties = {
                                    outputDataFormat: e.currentTarget.value as OutputDataFormats,
                                }
                                syncProperties(updatedPartialProperties);
                            }}
                            selectedValue={curProperties?.outputDataFormat}
                            inline={true}
                            className="pl-4 align-self-center"
                        >
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.summarized}
                                value={OutputDataFormats.SUMMARIZED}
                                className="mb-0"
                            />
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.timeSeries}
                                value={OutputDataFormats.TIMESERIES}
                                className="mb-0"
                            />
                        </RadioGroup>
                    </div>
                </td>
            </tr>
            <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>
                <InlineHelp 
                    helpMapping={
                        HELP.RunbookNodeCategory[variant === Variant.ON_DEMAND ? "Trigger" : "SubflowInput"][variant === Variant.ON_DEMAND ? "InputNode" : "SubflowInputNode"].ContextValuesReceivedFromCaller
                    }
                >
                    {variant === Variant.ON_DEMAND 
                        ? STRINGS.runbookEditor.nodeEditor.onDemandInputAdditionalParametersTitle 
                        : STRINGS.runbookEditor.nodeEditor.subflowInputFromCallerContext
                    }
                </InlineHelp>
            </td></tr>
            <tr><td className="display-8 pt-3 pb-3" colSpan={2}>
                <Callout intent={Intent.PRIMARY}>{descriptions.contextValuesReceivedFromCallerDesc}</Callout>
            </td></tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    {variant === Variant.ON_DEMAND && <p className="display-9 font-weight-500 mb-2 p-0">{STRINGS.runbookEditor.nodeEditor.onDemandInputTypeVariableName}</p>}
                    <MultiSelectInput
                        sortable
                        items={variablesForMultiSelect?.filter(variable => (variant !== Variant.ON_DEMAND && variable) || (variant === Variant.ON_DEMAND && variable.type !== StructuredVariableType.CUSTOM)) /*[{ display: "Application", value: "Application" }] metrics.map(metric => {
                            return { display: metric.label, value: metric.value }
                        })*/ }
                        selectedItems={ 
                            curProperties?.inputVariables?.map((variable) => {
                                return { display: variable, value: variable }
                            }) || []
                            
                            /*[{ display: "Application", value: "Application" }] selectedMetrics.map(metric => {
                            return { display: metric.label, value: metric.value }
                        })*/ }
                        onChange={ updatedValues => {
                            curProperties.inputVariables = updatedValues ? updatedValues.map(item => {
                                return item.value
                            }) : [];
                            if (updatedValues?.length) {
                                const newStaticInputValuesLists = {};
                                updatedValues.forEach(updatedValue => {
                                    if (primitiveVariables?.find(variable => variable.display === updatedValue.value)) {
                                        newStaticInputValuesLists[updatedValue.value] = staticInputValuesLists[updatedValue.value] || [];
                                    }
                                });
                                setStaticInputValuesLists(newStaticInputValuesLists);
                            } else {
                                setStaticInputValuesLists({});
                            }
                            /*
                            currentProperties['metrics'] = updatedValues ? updatedValues.map(item => {
                                return item.value
                            }) : [];
                            onMetricsChanged();
                            */
                        } }
                        placeholder={variant !== Variant.ON_DEMAND ? STRINGS.runbookEditor.nodeEditor.subflowInputFromCallerContextPlaceHolder : "" }
                        disabled={false /*required && metrics.length === 1 && selectedMetrics.length > 0*/}
                        inputOrOutputValuesDescriptions={curProperties?.inputOrOutputValuesDescriptions}
                    />
                    {variant === Variant.SUBFLOW && !!curProperties?.inputVariables?.length &&
                    <div className="enter-context-values-descriptions pb-0 text-md-right">
                        <Button minimal onClick={
                            () => openContextValuesDescriptionsDialog(
                                "input", 
                                curProperties.inputVariables, 
                                contextValuesDescriptionsDialogState, 
                                setContextValuesDescriptionsDialogState, 
                                setInputOrOutputValuesDescriptions, 
                                curProperties.inputOrOutputValuesDescriptions,
                                textareaValues, 
                                setTextareaValues,
                            )
                        }>
                            <span>{STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptions}</span>
                        </Button>
                    </div>} 
                    {variant === Variant.SUBFLOW && !!Object.keys(staticInputValuesLists).length && (
                        <div className="display-8 font-weight-bold pt-2 pb-3">
                            <div className="d-inline-block clickable"
                                onClick={() => {
                                    if (showAdvanced) {
                                        setNewLabels({});
                                        setNewValues({});
                                        setIsChangeValue([]);
                                        setValuesForEdit([]);
                                    }
                                    setShowAdvanced(!showAdvanced);
                                }}
                            >
                                <Icon data-testid="show-advanced-section" className="align-middle" icon={showAdvanced ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                                <span>{STRINGS.runbookEditor.nodeEditor.runbookContext.advancedSection}</span>
                            </div>
                        </div>
                    )}
                    {showAdvanced && !!Object.keys(staticInputValuesLists).length && Object.keys(staticInputValuesLists).map((variable, index) => (
                        <div key={index} className="static-input-values pb-3">
                            <div className="display-8 pb-2">{STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.staticValuesListFor} <b>{variable}</b>:</div>
                            <div className="d-flex pb-2">
                                <Suggest
                                    key={staticValueAdded[variable]}
                                    className="mr-2"
                                    items={
                                        staticInputValuesLists[variable]?.length 
                                        ? staticInputValuesLists[variable].map(item => ({ label: item.label, value: item.label })) 
                                        : []
                                    }
                                    itemRenderer={renderSuggestItem}
                                    onItemSelect={(item)=>{
                                        const label = item.label;
                                        const existingLabelIndex = staticInputValuesLists[variable]?.findIndex(item => item.label === label);
                                        handleLabelChange(variable, label);
                                        const newValuesForEdit = [...valuesForEdit];
                                        const findIndex = newValuesForEdit.findIndex(item => item.name === variable);
                                        if (findIndex > -1) {
                                            newValuesForEdit[findIndex].index = existingLabelIndex;
                                            setValuesForEdit(newValuesForEdit);
                                        } else {
                                            setValuesForEdit([...valuesForEdit, {
                                                name: variable,
                                                index: existingLabelIndex,
                                            }]);
                                        }
                                        handleValueChange(variable, staticInputValuesLists[variable].find(item => item.label === label).value);
                                        setIsChangeValue(prevState => [...prevState, variable]);
                                    }}
                                    inputValueRenderer={() => newLabels[variable] || ""}
                                    inputProps={{
                                        placeholder: STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterLabel
                                    }}
                                    onQueryChange={(query) => {
                                        handleLabelChange(variable, query);
                                    }}
                                    popoverProps={{
                                        usePortal: false
                                    }}
                                />
                                <InputGroup
                                    className="flex-grow-1 mr-2"
                                    placeholder={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterValue}
                                    value={newValues[variable] || ""}
                                    onChange={(e) => handleValueChange(variable, e.target.value)}
                                />
                                <Button 
                                    intent="primary" 
                                    disabled={!newLabels[variable] || !newValues[variable]} 
                                    text={
                                        isChangeValue.includes(variable) 
                                        ? STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.changeValue 
                                        : STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.addValue
                                    } 
                                    style={{marginLeft: "auto"}} 
                                    onClick={() => { 
                                        handleAddOption(variable);
                                    }} />
                            </div>
                            {!!staticInputValuesLists[variable]?.length && <div className="pb-2 add-subflow-static-value">
                                <MultiSelectInput
                                    key={staticValueAdded[variable]}
                                    sortable
                                    items={staticInputValuesLists[variable]}
                                    selectedItems={staticInputValuesLists[variable]}
                                    onChange={ updatedValues => {
                                        setStaticInputValuesLists({
                                            ...staticInputValuesLists,
                                            [variable]: updatedValues,
                                        });
                                        if (!updatedValues?.length) {
                                            exitOptionEditMode(variable);
                                        }
                                    } }
                                    placeholder={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.addedValues}
                                    valuesForEdit={valuesForEdit}
                                    staticInputValuesLists={staticInputValuesLists}
                                    variableName={variable}
                                    exitOptionEditMode={exitOptionEditMode}
                                />
                            </div>}
                        </div>
                    ))}
                    {variant === Variant.ON_DEMAND && <AnchorButton text={STRINGS.runbookEditor.nodeEditor.onDemandInputCreateEditVariable} intent="primary" minimal={true} className="on-demand-create-variable p-0 font-weight-normal bg-transparent" small={true} onClick={() => {
                        const variableIcon: HTMLButtonElement | null = document.querySelector("#variables-icon");
                        if (variableIcon) {
                            variableIcon.click();
                        }
                    }}/>}
                </td>
            </tr>
            {(variant === Variant.SUBFLOW) && <>
                <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <InlineHelp helpMapping={HELP.RunbookNodeCategory.SubflowInput.SubflowInputNode.ContextValuesSentBackToCaller}>
                        {STRINGS.runbookEditor.nodeEditor.subflowInputToCallerContext}
                    </InlineHelp>
                </td></tr>
                <tr><td className="display-8 pt-3 pb-3" colSpan={2}>
                    <Callout intent={Intent.PRIMARY}>{STRINGS.runbookEditor.nodeEditor.subflowInputToCallerContextDesc}</Callout>
                </td></tr>
                <tr>
                    <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                        <MultiSelectInput
                            sortable
                            items={variablesForMultiSelect?.filter(variable => variable.type !== PrimitiveVariableType.AUTH_PROFILE && variable.type !== PrimitiveVariableType.ALLUVIO_EDGE) /*[{ display: "Status", value: "Status" }] metrics.map(metric => {
                                return { display: metric.label, value: metric.value }
                            })*/ }
                            selectedItems={ 
                                curProperties?.outputVariables?.map((variable) => {
                                    return { display: variable, value: variable }
                                }) || []

                                /*[{ display: "Status", value: "Status" }] selectedMetrics.map(metric => {
                                return { display: metric.label, value: metric.value }
                            })*/ }
                            onChange={ updatedValues => {
                                curProperties.outputVariables = updatedValues ? updatedValues.map(item => {
                                    return item.value
                                }) : [];
                                /*
                                currentProperties['metrics'] = updatedValues ? updatedValues.map(item => {
                                    return item.value
                                }) : [];
                                onMetricsChanged();
                                */
                            } }
                            placeholder={ STRINGS.runbookEditor.nodeEditor.subflowInputToCallerContextPlaceHolder }
                            disabled={false /*required && metrics.length === 1 && selectedMetrics.length > 0*/}
                            inputOrOutputValuesDescriptions={curProperties?.inputOrOutputValuesDescriptions}
                        />
                    </td>
                </tr>
                {variant === Variant.SUBFLOW && !!curProperties?.outputVariables?.length &&
                    <div className="enter-context-values-descriptions pb-0 text-md-right">
                        <Button minimal onClick={
                            () => openContextValuesDescriptionsDialog(
                                "output", 
                                curProperties.outputVariables, 
                                contextValuesDescriptionsDialogState, 
                                setContextValuesDescriptionsDialogState, 
                                setInputOrOutputValuesDescriptions,
                                curProperties.inputOrOutputValuesDescriptions,
                                textareaValues, 
                                setTextareaValues,
                            )
                        }>
                            <span>{STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptions}</span>
                        </Button>
                    </div>}
                {hasConnector && <>
                    <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>{STRINGS.runbookEditor.nodeEditor.subflowConnectorContext}</td></tr>
                    <tr><td className="display-8 pt-3 pb-3" colSpan={2}><Callout intent={Intent.PRIMARY}>{STRINGS.runbookEditor.nodeEditor.subflowConnectorContextDesc}</Callout></td></tr>
                    <tr>
                        <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                            <HTMLSelect
                                data-testid="subflow-input-connector-select"
                                className="selector-min-width"
                                fill={true}
                                options={connectorOptions}
                                defaultValue={curProperties.integrationId || ""}
                                onChange={
                                    (event) => {
                                        const value = event.currentTarget.value;
                                        curProperties.integrationId = value || "";
                                    }
                                }
                            />
                        </td>
                    </tr>
                </>}
            </>}
            {SHOW_CONTEXT && <RunbookContextSummary
                currentProperties={getNodeProperties(curProperties, selectedNode, libraryNode)}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});

interface ContextValuesDescriptionsDialogProps {
    /** the context value type (input or output). */
	contextValueType: string;
    /** an array containing the runtime variables used for input or output context. */
    contextValues: string[] | undefined;
    /** change handler for the textarea element. */
    onChange: (newDescriptions: Array<{ valueName: string, description: string }>) => void;
    /** the current state value for the input or output runtime variables descriptions. */
    currentInputOrOutputValuesDescriptions: Array<{ valueName: string, description: string }>;
    /** the values displayed inside the textarea elements which hold the input or output variables descriptions. */
    textareaValues: any;
    /** the handler which set the state values for the values of the input or output variables descriptions. */
    setTextareaValues: Function;
}

const ContextValuesDescriptionsDialog = ({ 
    contextValueType, 
    contextValues, 
    onChange, 
    currentInputOrOutputValuesDescriptions,
    textareaValues, 
    setTextareaValues
}: ContextValuesDescriptionsDialogProps): JSX.Element => {
    const [selectValue, setSelectValue] = useState<string>("");
    const [descriptions, setDescriptions] = 
        useState<Array<{ valueName: string, description: string }>>(currentInputOrOutputValuesDescriptions);
    return <>
        <div className="d-flex align-items-md-center mb-3">
            <div className="mr-3">{
                STRINGS.formatString(STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.enterForSelected, 
                contextValueType)
            }</div>
            <div>
                <HTMLSelect
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.formatString(
                            STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.select, 
                            _.startCase(contextValueType)
                        ), value: ""}].concat(
                            contextValues?.map((value) => {return {label: value, value};}) || []
                        )
                    }
                    value={selectValue}
                    onChange={
                        (event) => {
                            setSelectValue(event.target.value);
                        }
                    }
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <textarea 
                    className="w-100 bg-white text-black" 
                    placeholder={STRINGS.formatString(
                        STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.placeholder, 
                        contextValueType
                    )}
                    value={textareaValues?.[selectValue] || ""} 
                    onChange={(event) => {
                        const newTextareaValues = textareaValues;
                            newTextareaValues[selectValue] = event.currentTarget.value;
                            setTextareaValues(prev => { return {...prev, ...newTextareaValues}});
                        const valueDescriptionExistingIndex = descriptions.findIndex(item => item.valueName === selectValue);
                        let newDescriptions = [...descriptions];
                        if (valueDescriptionExistingIndex > -1) {
                            newDescriptions[valueDescriptionExistingIndex].description = event.currentTarget.value;
                        } else {
                            newDescriptions = [...descriptions, { valueName: selectValue, description: event.currentTarget.value}];
                        }
                        setDescriptions(newDescriptions);
                        onChange(newDescriptions);
                    }}
                    disabled={!selectValue}
                />
            </div>
        </div>
    </>
};

/** Function for opening the enter descriptions dialog.
 *  @param contextValueType - the context value type (input or output).
 *  @param contextValues - an array containing the runtime variables used for input or output context.
 *  @param contextValuesDescriptionsDialogState - the descriptions dialog state value.
 *  @param setContextValuesDescriptionsDialogState - the descriptions dialog state setter. 
 *  @param setInputOrOutputValuesDescriptions - state setter for the input or output runtime variables descriptions. 
 *  @param currentInputOrOutputValuesDescriptions - the current state value for the input or output runtime variables descriptions.
 *  @param textareaValues - the values displayed inside the textarea elements which hold the input or output variables descriptions.
 *  @param setTextareaValues - the handler which set the state values for the values of the input or output variables descriptions. */
function openContextValuesDescriptionsDialog(
    contextValueType: string, 
    contextValues: string[] | undefined, 
    contextValuesDescriptionsDialogState: any, 
    setContextValuesDescriptionsDialogState: React.Dispatch<any>, 
    setInputOrOutputValuesDescriptions: React.Dispatch<React.SetStateAction<{
        valueName: string;
        description: string;
    }[]>>, 
    currentInputOrOutputValuesDescriptions: Array<{ valueName: string, description: string }>,
    textareaValues, 
    setTextareaValues,
): void {
    const defaultValue = null;
    let descriptions: Array<{ valueName: string, description: string }> = defaultValue || [];
    setContextValuesDescriptionsDialogState({
      showDialog: true,
      doNotAllowOutsideClick: true,
      title: STRINGS.formatString(
        STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor
          .enterDescriptionsModal.title,
        _.startCase(contextValueType),
      ),
      dialogContent: (
        <>
          <ContextValuesDescriptionsDialog
            contextValueType={contextValueType}
            contextValues={contextValues}
            currentInputOrOutputValuesDescriptions={currentInputOrOutputValuesDescriptions}
            onChange={(value: Array<{ valueName: string, description: string }>) => {
                descriptions = value;
            }}
            textareaValues={textareaValues}
            setTextareaValues={setTextareaValues}
          />
        </>
      ),
      closeable: true,
      dialogFooter: (
        <div className="d-flex justify-content-between flex-grow-1">
          <Button
            className="ml-0"
            text={STRINGS.runbookEditor.discardAndCloseBtn}
            intent={Intent.DANGER}
            onClick={() => {
              setContextValuesDescriptionsDialogState({
                ...contextValuesDescriptionsDialogState,
                showDialog: false,
                closeable: true,
              });
            }}
          />
          <Button
            icon={IconNames.SAVED}
            text={STRINGS.runbookEditor.saveAndCloseBtn}
            intent={Intent.SUCCESS}
            onClick={() => {
                setInputOrOutputValuesDescriptions(descriptions);
                setContextValuesDescriptionsDialogState(
                    { ...contextValuesDescriptionsDialogState, showDialog: false, closeable: true }
                );
            }}
          />
        </div>
      ),
    } as DialogState);
  }
