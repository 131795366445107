/** This module contains the implementation for the runbook view.  The runbook view
 *  displays to the user the contents of a runbook.
 *  @module
 */
import React, { ReactNode, useEffect, useState } from "react";
import { STRINGS } from "app-strings";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME, useQueryParams } from "utils/hooks";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { CardsView } from "../../../../components/common/vis-framework/widget/cards-view/CardsView";
import { TableView } from "../../../../components/common/vis-framework/widget/table-view/TableView";
import { TextView } from "../../../../components/common/vis-framework/widget/text-view/TextView";
import { BarView } from "../../../../components/common/vis-framework/widget/bar-view/BarView";
import { PieView } from "../../../../components/common/vis-framework/widget/pie-view/PieView";
import { TimeseriesView } from "../../../../components/common/vis-framework/widget/timeseries-view/TimeseriesView";
import { ConnectionGraphView } from "../../../../components/common/vis-framework/widget/connection-graph-view/ConnectionGraphView";
import { 
    RunbookOutput, DataSet, DataPoint, GraphqlDataValue, GraphqlData, DATA_TYPE, SeverityScore, Column, 
    GraphqlEnumValue, AverageData, TimeData 
} from "./Runbook.type";
import { WIDGET_TYPE, Widget, WidgetOptions } from "components/common/vis-framework/widget/Widget.type";
import { SEVERITY } from "components/enums";
import { GaugesView } from "../../../../components/common/vis-framework/widget/gauges-view/GaugesView";
import { formatVariables, processDataset } from 'utils/runbooks/RunbookUtils';
import classNames from "classnames";
import { RunbookViewContainer } from "../runbook-view-container/RunbookViewContainer";
import { WidgetToolBar } from "components/common/widget-toolbar/WidgetToolbar";
import { computeWidgetContainerId, scrollToElement } from "reporting-infrastructure/utils/commonUtils";
import { PARAM_NAME } from "components/enums/QueryParams";
import { DebugView } from "../../../../components/common/vis-framework/widget/debug-view/DebugView";
import { BubbleView } from "components/common/vis-framework/widget/bubble-view/BubbleView";
import { CorrelationView } from "components/common/vis-framework/widget/correlation-view/CorrelationView";
import { INCIDENT_SCOPE_BUILTIN_VARIABLES, RUNBOOK_SCOPE_BUILTIN_VARIABLES, removeTypename } from "utils/runbooks/VariablesUtils";
import "./RunbookView.scss";

// The height of the widgets
const widgetHeight: string | undefined = "250px";

/** an interface that describes the properties that can be passed in to the component.*/
export interface RunbookViewProps {
    /** a string with the unique id of the incident.*/
    incidentId?: string;
    /** a string with the unique id of the runbook. */
    runbookId?: string;
    /** a runbook that should be used to populate the supporting data view rather than using a 
    * a lookup based on the issueId.*/
    runbook?: RunbookOutput | null;
    /** Content to diplay when the query to fetch runbook output for given incident and trigger ID combo returns empty. */
    noRunbookMessage?: ReactNode;
    /** a callback that is called when the runbook data has been received which passes the data to the callback. */
    onRunbookDataReceived?: (data: Array<DataSet>) => void;
    /** a callback that is called when the template has been extracted from the runbook data which passes the template to the callback. */
    onRunbookTemplateAvailable?: (template: any) => void;
    /** a callback that is called when the runbook output has been received which passes the runbook to the callback. */
    onRunbookReceived?: (runbook: RunbookOutput) => void;
    /** CSS classes to be applied to the runbook view container node. */
    className?: string;
    /** a boolean value, if true show the widget toolbar, if false, do not show the toolbar. */
    showWidgetToolbar?: boolean;
}
 
/** Creates the supporting data view, which is a component that may hold any number of 
 *      tables, cards and charts passed from analytics to the UI.
 *  @param props an object with the properties passed to the supporting data view.
 *  @returns JSX with the supporting data component.*/
export const RunbookView = ({
    noRunbookMessage = STRINGS.incidents.runbookOutputs.noRunbook, showWidgetToolbar = true,
    ...props
}: RunbookViewProps) => {
    const { params } = useQueryParams({ 
        listenOnlyTo: [PARAM_NAME.pageLocationId ]
    });
    let {loading, data, error, run} = useQuery({
        query: new Query(loader("./runbooks.graphql")),
        requiredFilters: [FILTER_NAME.incidentIds, FILTER_NAME.runbookId],
        filters: {
            [FILTER_NAME.incidentIds]: props.incidentId ? [props.incidentId] : props.runbookId ? [] : undefined,
            [FILTER_NAME.runbookId]: (props.runbookId ? props.runbookId : undefined)
		},
        skipGlobalFilters: true,
        timeNotRequired: true,
        lazy: true,
    });

    useEffect(() => {
        if (props.runbookId) {
            run({
                filters: {
                    [FILTER_NAME.incidentIds]: props.incidentId ? [props.incidentId] : props.runbookId ? [] : undefined,
                    [FILTER_NAME.runbookId]: props.runbookId,
                },
                // Always fetch runbook data fresh because it could've changed since the last pull
                fetchPolicy: "network-only",
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentId, props.runbookId])

    const [incidentVariables, setIncidentVariables] = useState<any>();

    const incidentVariablesQuery = useQuery({
		name: 'IncidentVariables',
		query: new Query(loader('./../../../../utils/hooks/incident-variables-query.graphql')),
	});

    useEffect(() => {
        setIncidentVariables(removeTypename(incidentVariablesQuery.data));
        // API Fetch for incident and global variables
    }, [incidentVariablesQuery.data])

    let unprocessedRunbook: RunbookOutput | null = null;
    let runbook: RunbookOutput | null = null;
    let template;
    let datasets;
    if (!loading) {
        if (data && data?.runbooks?.nodes?.length > 0) {
            const runbookIndex = 0;
            //runbook = {
            //    id: "0",
            //    targetId: "1",
            //    name: "Interface has high Utilization and is congested.  The VoIP application is degraded",
            //    severity: {value: SEVERITY.CRITICAL},
            //    datasets: []
            //};
            unprocessedRunbook = data.runbooks.nodes[runbookIndex];
            runbook = JSON.parse(JSON.stringify(unprocessedRunbook));
            if (!runbook) {
                runbook = {id: "0", targetId: "1", name: "", severity: {value: SEVERITY.CRITICAL}, datasets: []};
            }
            runbook.targetId = "1";
            runbook.severity = {value: SEVERITY.CRITICAL};
            runbook.datasets = [];

            const dsFromQuery = data.runbooks.nodes[runbookIndex]!.datasets;

            if (typeof dsFromQuery === "string") {
                // This was from the first version of the Aternity demo, the datasets were passed as a string
                // keeping for a few days in case we need to go back.
                datasets = JSON.parse(dsFromQuery as string) as Array<DataSet>;
            } else if (typeof dsFromQuery === "object") {
                // This is the second version where the datasets are passed as an object but the schema is 
                // different than the schema in the DB.  We have two places where the runbook is viewed one
                // in test mode which gets the data directly from the runbook and is in the exact same format
                // as the DB and another when we get the data from the DB through graphql which has a format 
                // that can be supported by the graphql schema.  Since there are two formats modify the graphql
                // format to match the format we get directly from the API and leave all downstream code untouched.
                datasets = translateDatasetSchema(dsFromQuery);
            }

            template = JSON.parse(data.runbooks.nodes[runbookIndex]!.template);
            if (template && template.label) {
                runbook.name = template.label;
            } 

            if (datasets && datasets.length > 0 && template) {
                for (const dataset of datasets) {
                    const processedDataset = processDataset(dataset, template.nodes);
                    if (processedDataset) {
                        runbook.datasets.push(processedDataset);
                    }
                }
            }
            scrollToElement(params.plid);
        }
    }

    const {onRunbookTemplateAvailable, onRunbookDataReceived, onRunbookReceived} = props;
    useEffect(() => {
        if (template && onRunbookTemplateAvailable) {
            onRunbookTemplateAvailable(template);
        }
        if (datasets && onRunbookDataReceived) {
            onRunbookDataReceived(datasets);
        }
        if (unprocessedRunbook && onRunbookReceived) {
            onRunbookReceived(unprocessedRunbook);
        }
    }, [template, datasets, unprocessedRunbook, onRunbookTemplateAvailable, onRunbookDataReceived, onRunbookReceived]);

    let incidentId = (props.incidentId ? props.incidentId : "");
    if (props.runbook) {
        incidentId = props.runbook.id;
        loading = false;
        data = {data: {}};
        error = undefined;
        runbook = props.runbook;
    }

    const supportingJsx: Array<ReactNode> = [];
    if (runbook) {
        const widgetList: Array<Widget> = [];
        const widgetIdToDatasetMap: Record<string, Array<DataSet>> = {};
        for (const dataset of runbook.datasets) {
            if (dataset.widgets) {
                for (const datasetWidget of dataset.widgets) {
                    // A card can have two data sets so check to see if this widget was already registered for another dataset
                    let widget: Widget | undefined = undefined;
                    for (const checkWidget of widgetList) {
                        if (checkWidget.id === datasetWidget.id) {
                            widget = checkWidget;
                            break;
                        }
                    }
                    const variables = (runbook.variableResults ?
                        (runbook.variableResults.hasOwnProperty('primitiveVariables') ?
                            runbook.variableResults.primitiveVariables.concat(RUNBOOK_SCOPE_BUILTIN_VARIABLES) :
                            RUNBOOK_SCOPE_BUILTIN_VARIABLES) : RUNBOOK_SCOPE_BUILTIN_VARIABLES)
                                .concat([...incidentVariables?.incidentVariables?.variables?.primitiveVariables || [],
                                ...INCIDENT_SCOPE_BUILTIN_VARIABLES]);
                    if (datasetWidget.name) {
                        datasetWidget.name = formatVariables(datasetWidget.name, variables)
                    }
                    if (datasetWidget.options && datasetWidget.options.notes) {
                        datasetWidget.options.notes = formatVariables(datasetWidget.options.notes, variables)
                    }
                    if (!widget) {
                        widgetIdToDatasetMap[datasetWidget.id] = [];
                        widgetList.push(datasetWidget)
                    } else {
                        // We want to collect up all the options from the dataset, what if there is a conflict?
                        if (datasetWidget.options) {
                            if (!widget.options) {
                                widget.options = {};
                            }
                            Object.assign(widget.options, datasetWidget.options);    
                        }
                    }
                    widgetIdToDatasetMap[datasetWidget.id].push(dataset);
                }    
            }
        }

        // Sort the datasets based on the widget row order
        widgetList.sort((widgetA, widgetB): number => {
            const rowA = widgetA.row !== null && widgetA.row !== undefined ? widgetA.row : 0;
            const rowB = widgetB.row !== null && widgetB.row !== undefined ? widgetB.row : 0;
            if (rowA < rowB) {
                return -1;
            } else if (rowA > rowB) {
                return 1;
            }
            return 0;
        });

        for (const widget of widgetList) {
            let widgetDatasets: Array<DataSet> = widgetIdToDatasetMap[widget.id];
            switch (widget.type) {
                case WIDGET_TYPE.TABLE:
                    supportingJsx.push(
                        createTable(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.TEXT:
                    supportingJsx.push(
                        createText(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.CONNECTION_GRAPH:
                    supportingJsx.push(
                        createConnectionGraph(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.CARDS: {
                    const datasets: Array<DataSet> = new Array(4).fill(undefined);
                    for (let index = 0; index < widgetDatasets.length; index++) {
                        if (widgetDatasets[index].type ===  DATA_TYPE.SUMMARY) {
                            datasets[widgetDatasets[index].isComparison ? 1 : 0] = widgetDatasets[index];
                        } else if (widgetDatasets[index].type ===  DATA_TYPE.TIMESERIES) {
                            datasets[widgetDatasets[index].isComparison ? 3 : 2] = widgetDatasets[index];
                        }
                    }
                    supportingJsx.push(createCards(
                        incidentId, widget, datasets,  props.runbookId, showWidgetToolbar
                    ));
                    break;
                }
                case WIDGET_TYPE.TIMESERIES:
                    supportingJsx.push(
                        createTimeseries(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.BAR:
                    supportingJsx.push(
                        createBar(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.PIE:
                    supportingJsx.push(
                        createPie(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.BUBBLE:
                    supportingJsx.push(
                        createBubble(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.CORRELATION:
                    supportingJsx.push(
                        createCorrelation(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.GAUGES:
                    supportingJsx.push(
                        createGauges(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                    );
                    break;
                case WIDGET_TYPE.DEBUG:
                    if (widget?.options?.showInOutput) {
                        supportingJsx.push(
                            createDebug(incidentId, widget, widgetDatasets, props.runbookId, showWidgetToolbar)
                        );    
                    }
                    break;
            }
        }
    } else {
        supportingJsx.push(
            typeof noRunbookMessage === "string" ?
            <div key="no-runbook-message" className="text-center display-8 py-4">{noRunbookMessage}</div> :
            noRunbookMessage
        );
    }

    // Hack Alert: Fix for Bugs 10501, 10231
    // The presence of headers in tables was somehow oddly throwing highcharts' width calculation for
    // it's time series charts. After attempting several changes, I resorted to using this hack. I'm not
    // proud of it! When rendering runbook output, we add a "hide-table-headers" class to runbook container
    // which will cause the table headers to be hidden. We will then remove this class after a short timeout
    // to give highcharts enough time to calculate the width correctly while headers stay hidden. We will also
    // hide the headers when user resizes the page for a second to do the same. When a resize happens, a flag
    // named reflowOnResize which is set to true for timeseries charts in runbook outputs will cause the chart
    // to undergo a reflow after half a second while the headers stay hidden.
    // const runbookViewContainer = useRef<HTMLDivElement>(null);
    // const hideHeadersRef = useRef(true);
    // useEffect(() => {
    //     function showHeadersAfterTimeout () {
    //         hideHeadersRef.current = false;
    //         if (runbookViewContainer.current) {
    //             runbookViewContainer.current.classList.remove("hide-table-headers");
    //         } else {
    //             setTimeout(showHeadersAfterTimeout, 1000);
    //         }
    //     }
    //     function onResize () {
    //         if (hideHeadersRef.current !== true && runbookViewContainer.current) {
    //             hideHeadersRef.current = true;
    //             runbookViewContainer.current.classList.add("hide-table-headers");
    //             setTimeout(showHeadersAfterTimeout, 1000);
    //         }
    //     }
    //     setTimeout(showHeadersAfterTimeout, 1500);
    //     window.addEventListener("resize", onResize);
    //     return () => {
    //         window.removeEventListener("resize", onResize);
    //     }
    // }, []);

    return <DataLoadFacade loading={loading} error={error} data={data} className={classNames("runbook-view-data-load", props.className)}>
            {/* <div className={classNames("runbook-view-container", props.className, "hide-table-headers")} ref={runbookViewContainer}>{supportingJsx}</div> */}
            <div className={classNames("runbook-view-container", props.className)}>{supportingJsx}</div>
        </DataLoadFacade>;
};

/** wraps the view with a view container that is used to standardize the display of the notes and toolbars.
 *  @param component the runbook view that is being wrapped.
 *  @param name the name of the component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param runbookId a string with the unique id of the runbook.
 *  @param widgetId the id of the runbook view widget.
 *  @param options any options that have been set in the editor for the runbook view.
 *  @param datasets the Array of DataSet objects that is used to extract the DataSetInfo which has the actual times, data sources, filters, etc.
 *  @param addToolbar a boolean, if true show the toolbar.
 *  @param onVantagePointsChange the handler for vantage point change events.
 *  @param showAllVantagePoints an optional boolean value, if true, show the all vantage points option.
 *  @returns the RunbookViewContainer React component that wraps the view. */
function wrapWithViewContainer(
    component: ReactNode, name: string | undefined, incidentId: string, runbookId: string | undefined, 
    widgetId: string, options: WidgetOptions, datasets: Array<DataSet> | undefined, addToolBar = true, 
    vantagePoints?: string[], onVantagePointsChange?: (dsIds: string[]) => void, showAllVantagePoints?: boolean
): JSX.Element {
    const widgetToolBar = addToolBar ? <WidgetToolBar incidentId={incidentId} runbookId={runbookId} widgetId={widgetId} /> : "";
    const contId = computeWidgetContainerId(runbookId, widgetId);
    return <RunbookViewContainer 
        name={name} key={widgetId} id={contId} toolbar={widgetToolBar} options={options} datasets={datasets} 
        selectedVantagePoints={vantagePoints} onVantagePointsChange={onVantagePointsChange} showAllVantagePoints={showAllVantagePoints}
    >
        {component}
    </RunbookViewContainer>;
}

/** Create a table component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the table component.*/
function createTable(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    return <VantageTableWrapper 
        key={"vantage-wrapper-table-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar} 
    />;
}

const VantageTableWrapper = (props: any): JSX.Element => {
    const [vantagePoints, setVantagePoints] = useState<string[]>([]);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let options = props.widget.options || {}; 
    let key = "table";
    let triggerColumnId: string | undefined = undefined;
    let triggerSeverity: SeverityScore | undefined = undefined;
    if (newDataSets?.length) {
        key = newDataSets[0].id;
        triggerColumnId = newDataSets[0].trigger?.id;
        triggerSeverity = newDataSets[0].severity;
    }
    const component = <TableView 
        incidentId={props.incidentId} runbookId={props.runbookId} datasets={newDataSets} key={key} 
        triggerColumnId={triggerColumnId} triggerSeverity={triggerSeverity}
        columns={options.columns ? options.columns : []} widget={props.widget}
        sortBy={[{id: options.sortColumn ? String(options.sortColumn) : '',
                desc: options.sortOrder ? (options.sortOrder === 'desc' ? true : false) : false }]}
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, true
    );
};

/** Create a cards component.  The cards component can display text data.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the cards component.*/
function createText(
    incidentId: string, widget: Widget,
    datasets: Array<DataSet>, runbookId: string | undefined, showToolbar: boolean
): JSX.Element {
     return <VantageTextWrapper
        key={"vantage-wrapper-text-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar}
    />;
}

const VantageTextWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    const options = props.widget.options || {};
    const component = <TextView key={props.widget.id}
        datasets={newDataSets} widget={props.widget} height={widgetHeight}
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** Create a connection graph component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the connection graph component.*/
function createConnectionGraph(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    return <VantageConnectionGraphWrapper 
        key={"vantage-wrapper-cg-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar}
    />;
}

const VantageConnectionGraphWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let options = props.widget.options || {}; 
    const component =  <ConnectionGraphView 
        incidentId={props.incidentId} datasets={newDataSets} key={newDataSets[0].id} 
        widget={props.widget} height={widgetHeight} 
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** Create a bar component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the bar component.*/
function createBar(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    return <VantageBarWrapper 
        key={"vantage-wrapper-bar-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar}
    />;
}

const VantageBarWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let options = props.widget.options || {}; 
    const component =  <BarView 
        incidentId={props.incidentId} datasets={newDataSets} key={props.datasets[0].id} 
        widget={props.widget} height={widgetHeight}
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** Create a pie chart component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the bar component.*/
function createPie(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    return <VantagePieWrapper key={"vantage-wrapper-pie-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar}
    />;
}

const VantagePieWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let options = props.widget.options || {};
    const component = <PieView
        incidentId={props.incidentId} datasets={newDataSets} key={newDataSets[0].id}
        widget={props.widget} height={widgetHeight}
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** Create a bubble chart component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the bubble chart component.*/
function createBubble(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    return <VantageBubbleWrapper 
        key={"vantage-wrapper-bubble-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar}
    />;
}

const VantageBubbleWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let options = props.widget.options || {};
    const component = <BubbleView
        incidentId={props.incidentId} datasets={newDataSets} key={props.datasets[0].id}
        widget={props.widget} height={widgetHeight}
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** Create a cards component.  The cards component can display both summary data (big numbers) and 
 *  time series data (sparklines).
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the cards component.*/
function createCards(
    incidentId: string, widget: Widget,
    datasets: Array<DataSet>, runbookId: string | undefined, showToolbar: boolean
): JSX.Element {
    return <VantageCardsWrapper 
        key={"vantage-wrapper-cards-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar}
    />;
}

const VantageCardsWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let options = props.widget.options || {};
    const component = <CardsView incidentId={props.incidentId} key={props.widget.id}
        datasets={newDataSets} widget={props.widget} height={widgetHeight}
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** Create a gauges component.  The gauges component will display the summary data as a set of gauges, one
 *  row of gauges for each row of data.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the cards component.*/
function createGauges(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    return <VantageGaugesWrapper 
        key={"vantage-wrapper-gauges-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar}
    />;
}

const VantageGaugesWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let newDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let options = props.widget.options || {}; 
    const component = <GaugesView incidentId={props.incidentId} key={props.widget.id}
        datasets={newDataSets} widget={props.widget}
    />;
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** Create a time series component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook. 
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the time series component.*/
function createTimeseries(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    return <VantageTimeWrapper 
        key={"vantage-wrapper-time-" + widget.id} incidentId={incidentId} datasets={datasets} widget={widget} 
        runbookId={runbookId} showToolbar={showToolbar} 
    />;
}

const VantageTimeWrapper = (props: any): JSX.Element => {
    const initDsList = getDataSourcesFromDataSets(props.datasets);
    const [vantagePoints, setVantagePoints] = useState<string[]>(initDsList.length ? [initDsList[0]] : []);
    let vantagePointDataSets: DataSet[] = filterDataSets(props.datasets, vantagePoints);
    let newDataSets = [...fillGapsInData(expandDataSetsToEdgeTimes(vantagePointDataSets))];

    const component =  <TimeseriesView 
        incidentId={props.incidentId} datasets={newDataSets}
        key={props.datasets[0].id} height={widgetHeight} widget={props.widget}
    />;
    let options = props.widget.options || {}; 
    return wrapWithViewContainer(
        component, props.widget.name, props.incidentId, props.runbookId, props.widget?.id, options, props.datasets, props.showToolbar,
        vantagePoints, (dsIds) => {setVantagePoints(dsIds?.length && dsIds[0] !== "all" ? dsIds : []);}, false
    );
};

/** returns the sorted list of timestamps from earliest to latest timestamp.
 * @param data the TimeData or AverageData object with the time data (average data is ignored)
 * @returns the list of timestamps sorted from earliest to latest timestamp. */
export function getSortedTimestamps(data: TimeData | AverageData | GraphqlData[]) {
    return Object.keys(data).sort((a, b) => {
        const dateA = new Date(parseFloat(a) * 1000).getTime();
        const dateB = new Date(parseFloat(b) * 1000).getTime();

        return (dateA === dateB) ? 0 : ((dateA > dateB)? 1: -1);
    });
}

/** Expands the chart display to include incident start time and end time.  Note that this modifies the object that was passed in
 *  @param vantagePointDataSets 
 *  @returns Array of timestamps including points forstart time/end time of the incident. */
function expandDataSetsToEdgeTimes(vantagePointDataSets: DataSet[]): DataSet[] {      
    return vantagePointDataSets.map(dataSet => {
        const info = dataSet.info;
        const hasStartTime = info?.actualTimes?.length && info.actualTimes.length > 0 && info.actualTimes[0].startTime;
        const hasEndTime = info?.actualTimes?.length && info.actualTimes.length > 0 && info.actualTimes[0].endTime;

        if (!hasStartTime || !hasEndTime) {
            return dataSet;
        }

        const startTime = `${info.actualTimes![0].startTime}.000000000`;
        const endTime = `${info.actualTimes![0].endTime}.000000000`;

        dataSet.datapoints?.forEach(datapoint => {
            if (datapoint && datapoint?.data) {
                const timestamps = getSortedTimestamps(datapoint.data);
                const firstTimestamp = timestamps[0];
                const startDate = new Date(parseInt(startTime) * 1000).getTime();
                const firstDateOnChart = new Date(parseInt(firstTimestamp) * 1000).getTime();


                const isChartNotFromStartTime = startDate < firstDateOnChart;
                if (isChartNotFromStartTime) {
                    const datapointShape = Object.keys(datapoint?.data[firstTimestamp]).reduce(
                        (accumulator, current) => {
                            accumulator[current] = null;
                            return accumulator;
                        }, {});

                    datapoint.data[startTime] = datapointShape;
                }


                const lastTimestamp = timestamps[timestamps.length - 1];
                const endDate = new Date(parseInt(endTime) * 1000);
                const lastDateOnChart = new Date(parseInt(lastTimestamp) * 1000);

                const isChartNotUntilEndTime = endDate > lastDateOnChart;
                if (isChartNotUntilEndTime) {
                    const datapointShape = Object.keys(datapoint?.data[firstTimestamp]).reduce(
                        (accumulator, current) => {
                            accumulator[current] = null;
                            return accumulator;
                        }, {});

                    datapoint.data[endTime] = datapointShape;
                }
            }
        });

        return dataSet;
    });
}

/** fills in the gaps between data points.  Note that this modifies the object that was passed in.
 *  @param dataSets the array of DataSets with the time data.
 *  @returns the array of DataSets with the nodified data. */
export function fillGapsInData(dataSets: DataSet[]): DataSet[] {      
    return dataSets.map(dataSet => {
        const columnDefs: Record<string, Column> = {};
        dataSet?.metrics?.forEach((metric: Column) => {
            columnDefs[metric.id] = metric;
        });

        dataSet.datapoints?.forEach(datapoint => {
            if (datapoint && datapoint?.data) {
                const timestamps = getSortedTimestamps(datapoint.data);
                const firstTimestamp = timestamps[0];

                let granularity: number | undefined = datapoint.granularity;
                if (!granularity) {
                    granularity = dataSet.info?.actualTimes?.length && dataSet.info?.actualTimes[0]?.granularities?.length 
                        ? parseInt(dataSet.info?.actualTimes[0]?.granularities[0]) 
                        : undefined;
                }
                if (granularity && !Number.isNaN(granularity) && granularity > 0) {
                    for (let tsIndex = 0; tsIndex < timestamps.length - 1; tsIndex++) {
                        const dateA = new Date(parseFloat(timestamps[tsIndex]) * 1000).getTime()/1000;
                        const dateB = new Date(parseFloat(timestamps[tsIndex + 1]) * 1000).getTime()/1000;
                        if (dateB - dateA >= 2*granularity) {
                            let newTsIndex = 0;
                            while (dateA + (newTsIndex + 1) * granularity < dateB) {
                                const datapointShape = Object.keys(datapoint?.data[firstTimestamp]).reduce(
                                    (accumulator, current) => {
                                        const metric: Column = columnDefs[current];
                                        accumulator[current] = metric && metric.absentMeansZero ? "0.0" : null;
                                        return accumulator;
                                    }, {});
            
                                datapoint.data[`${dateA + ++newTsIndex * granularity}`] = datapointShape;            
                            }
                        }
                    }
                }
            }
        });
        return dataSet;
    });
}

/** filters the data sets based on the vantage points.
 *  @param datasets the Array of DataSets with the data.
 *  @param vantagePoints the Array of String data source ids.
 *  @returns the Array of filtered DataSets. */
function filterDataSets(datasets: Array<DataSet>, vantagePoints: string[]): Array<DataSet> {
    let newDataSets: DataSet[] = datasets;
    if (vantagePoints?.length && vantagePoints[0] !== "all") {
        newDataSets = JSON.parse(JSON.stringify(datasets || []));
        for (const dataset of newDataSets) {
            const filteredDataPoints: DataPoint[] = [];
            for (const datapoint of dataset?.datapoints || []) {
                if (vantagePoints.includes(datapoint.keys?.["data_source.id"])) {
                    filteredDataPoints.push(datapoint);
                }
            }
            if (dataset) {
                dataset.datapoints = filteredDataPoints;    
            }
        }
    }
    return newDataSets;
}

/** returns the list of data source ids that were encounterer in the list of data sets.
 *  @param datasets the DataSet array with the data.
 *  @returns a String Array with the unique data source ids. */
export function getDataSourcesFromDataSets(datasets: DataSet[]): string[] {
    let traversedIds: string[] = [];
    for (const dataset of (datasets || [])) {
        if (dataset?.datapoints) {
            for (const datapoint of dataset.datapoints || []) {
                if (datapoint.keys) {
                    let hasADataPoint: boolean = false;

                    if (datapoint.data) {
                        if (dataset.type === DATA_TYPE.SUMMARY) {
                            for (const metric in datapoint.data) {
                                if (datapoint.data[metric] !== null && datapoint.data[metric] !== undefined) {
                                    hasADataPoint = true;
                                    break;
                                }
                            }
                        } else {
                            for (const timestamp in datapoint.data) {
                                if (timestamp !== null && timestamp !== undefined) {
                                    for (const metric in datapoint.data[timestamp]) {
                                        if (
                                            datapoint.data[timestamp][metric] !== null && 
                                            datapoint.data[timestamp][metric] !== undefined
                                        ) {
                                            hasADataPoint = true;
                                            break;
                                        }
                                    }
                                }
                                if (hasADataPoint) {
                                    break;
                                }
                            }                            
                        }    
                    }

                    if (
                        hasADataPoint &&
                        datapoint.keys["data_source.id"] && datapoint.keys["data_source.name"] && 
                        !traversedIds.includes(datapoint.keys["data_source.id"])
                    ) {
                        traversedIds.push(datapoint.keys["data_source.id"]);
                    }
                }
            }
        }
    }
    return traversedIds;
}

/** Create a correlation component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the runbook. 
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the correlation chart component.*/
function createCorrelation(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    let options = widget.options || {}; 
    const component =  <CorrelationView 
        incidentId={incidentId} datasets={datasets}
        key={datasets[0].id} height={widgetHeight} widget={widget}
    />;
    return wrapWithViewContainer(
        component, widget.name, incidentId, runbookId, widget?.id, options, datasets, showToolbar
    );
}

/** Create a debug view component.
 *  @param incidentId a string with the unique id of the incident.
 *  @param widget the Widget object weith the information about the widget.
 *  @param datasets An array with the datasets to be displayed.  The data set specification includes the definition
 *      of the key and metric columns as well as the title of the data set as well as the actual data points
 *  @param runbookId a string with the unique id of the incident.
 *  @param showToolbar a boolean value, if true show the toolbar.
 *  @returns JSX with the debug view component.*/
function createDebug(
    incidentId: string, widget: Widget, datasets: Array<DataSet>, runbookId: string | undefined, 
    showToolbar: boolean
): JSX.Element {
    let options = widget.options || {}; 
    const component =  <DebugView 
        datasets={datasets} key={datasets[0].id} widget={widget} height={widgetHeight}
    />;
    return wrapWithViewContainer(
        component, widget.name, incidentId, runbookId, widget?.id, options, datasets, showToolbar
    );
}

/** translate the schema returned from GraphQL into the schema that the runbook uses to store into the database.
 *  @parma passedDatasets the Array of DataSets passed by graphql in the graphql schema.
 *  @return the Array of DataSets using the schema as stored in ADX.*/
export function translateDatasetSchema(passedDatasets: Array<DataSet>): Array<DataSet> {
    let datasets: Array<DataSet> = [];
    try {
        datasets = JSON.parse(JSON.stringify(passedDatasets));
    } catch (error) {
        console.error("Could not parse the dataset returned by the query!");
    }
    if (datasets) {
        for (const dataset of datasets) {
            let newDatapoints: Array<any> = [];
            if (dataset.metrics) {
                dataset.metrics = translateMetricSchema(dataset.metrics);
            }
            if (dataset.datapoints) {
                for (const datapoint of dataset.datapoints) {
                    const dataKeys: Array<GraphqlDataValue> = datapoint.keys as Array<GraphqlDataValue>;
                    const data: Array<GraphqlData> = datapoint.data as Array<GraphqlData>;
                    let newDatapoint: DataPoint = {keys: {}, data: {}, granularity: datapoint.granularity};

                    // The keys are handled the same for summary and timeseries data
                    if (dataKeys) {
                        for (const keyValue of dataKeys) {
                            newDatapoint.keys[keyValue.id] = keyValue.value;
                        }
                    }

                    if (data && data.length > 0) {
                        if (!data[0].timestamp) {
                            // Summary data
                            if (data[0].metrics) {
                                const dataMetrics: Array<GraphqlDataValue> = data[0].metrics as Array<GraphqlDataValue>;
                                for (const metricValue of dataMetrics) {
                                    newDatapoint.data[metricValue.id] = metricValue.value
                                }
                            }
                        } else if (data[0].timestamp) {
                            // Timeseries data
                            for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
                                if (data[dataIndex].timestamp) {
                                    newDatapoint.data[data[dataIndex].timestamp!] = {};
                                    if (data[dataIndex].metrics) {
                                        const dataMetrics: Array<GraphqlDataValue> = data[dataIndex].metrics as Array<GraphqlDataValue>;
                                        for (const metricValue of dataMetrics) {
                                            newDatapoint.data[data[dataIndex].timestamp!][metricValue.id] = metricValue.value;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    newDatapoints.push(newDatapoint);
                }
                dataset.datapoints = newDatapoints;
            }
        }
    }
    return datasets;
} 

/** translate the schema returned from GraphQL into the schema that the runbook uses to store into the database.
 *  @parma passedMetrics the Array of Columns passed by graphql in the graphql schema.
 *  @return the Array of Columns using the schema as stored in ADX.*/
function translateMetricSchema(passedMetrics: Array<Column>): Array<Column> {
    let metrics: Array<Column> = [];
    for (let metric of passedMetrics) {
        if (metric.enum) {
            metric = JSON.parse(JSON.stringify(metric));
            if (metric.enum) {
                const enumObj = {};
                for (const enumGraphql of metric.enum as Array<GraphqlEnumValue>) {
                    enumObj[enumGraphql.key] = enumGraphql.value;
                    if (enumGraphql.weight !== null && enumGraphql.weight !== undefined) {
                        if (!metric.order_by_weight) {
                            metric.order_by_weight = {};
                        }
                        metric.order_by_weight[enumGraphql.key] = enumGraphql.weight;
                    }
                }
                metric.enum = enumObj;    
            }
        }
        metrics.push(metric);
    }
    return metrics;
}
